import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { Heading, Accordian, SearchInputField, Spinner, Icon } from '@components';

import { componentName } from '../constants';
import { useSearch } from '../hooks';

import CompCheckbox from './CompCheckbox';

const Placeholder = ({ copy }) => <div className={`${componentName}__search-results__placeholder`}>{copy}</div>;

function SearchTitles() {
  const { formatMessage } = useIntl();
  const { searchValue, setSearchValue, isLoading, data, noResults } = useSearch();
  return (
    <Accordian.Item remainOpen className={classnames(`${componentName}__accordian-item`)}>
      <Accordian.ItemHeader className={classnames(`${componentName}__accordian-item-header`)}>
        <Heading size="xs" level="6" uppercase className={classnames(`${componentName}__section-heading`)}>
          {formatMessage({ id: 'all_titles' })}
        </Heading>
      </Accordian.ItemHeader>
      <Accordian.ItemContent className={classnames(`${componentName}__accordian-item-content`)}>
        <SearchInputField value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        <div className={`${componentName}__search-results`}>
          {noResults ? (
            <Placeholder copy={<FormattedMessage id="no_results_found" />} />
          ) : (
            <>
              {isLoading && <Spinner />}
              {!isLoading &&
                data.map((title) => (
                  <CompCheckbox key={title.id} comp={title} hasMarketData={title.marketData}></CompCheckbox>
                ))}

              {!isLoading && data.length === 0 && (
                <Placeholder
                  copy={
                    <>
                      <Icon icon="search" />
                      <FormattedMessage id="comps_search_placeholder" />
                    </>
                  }
                />
              )}
            </>
          )}
        </div>
      </Accordian.ItemContent>
    </Accordian.Item>
  );
}

export default SearchTitles;
