import React from 'react';
import PropTypes from 'prop-types';

import { COMPONENT_BASE_CLASS } from '../constants';
import { ItemProvider } from './ItemContext';

function Item({ children, isOpen, onOpen, onClose, remainOpen, className }) {
  return (
    <ItemProvider isOpenProp={isOpen} remainOpenProp={remainOpen} onOpen={onOpen} onClose={onClose}>
      <section className={`${COMPONENT_BASE_CLASS}__item ${className}`}>{children}</section>
    </ItemProvider>
  );
}

Item.propTypes = {
  children: PropTypes.node,
  remainOpen: PropTypes.bool,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

Item.defaultProps = {
  children: '',
  remainOpen: false,
  isOpen: false,
  className: '',
};

export default Item;
