import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAppContextState, useLastRefresh } from '@modules';
import { DEFAULT_METRIC_LIST_ORDER } from '@modules/Core';
import { useParametersListener } from '@modules/FilterTypes';
import { metricKeyPropType } from '@modules/Metrics';

import './_title-list.scss';

import { getParamsForTitleListFetch } from './selectors';
import { ListContainer } from './components';
import { useDataFetchAndAppend, useDataFetchAndReset, useTitleListData } from './hooks';

const FETCH_START_PAGE = 1;

const propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.string),
  /**
   * Setting the desired order the incoming metrics should be displayed in.
   */
  metricListOrder: PropTypes.arrayOf(metricKeyPropType),
  className: PropTypes.string,
  /**
   * Overrides any existing params in fetch with these specific params
   * For e.g siteIds: [1234] to fetch titles for a specific site.
   */
  params: PropTypes.shape({}),
  defaultSortMetric: PropTypes.string,
  onSelectItem: PropTypes.func,
  clearOnUnmount: PropTypes.bool,
  /**
   * The useDataFetch hooks listen to certain filter params.
   * This will add additional params to listen to depending on where TitlesList is being used.
   */
  additionalFilters: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  metrics: [],
  metricListOrder: DEFAULT_METRIC_LIST_ORDER,
  className: '',
  params: null,
  defaultSortMetric: null,
  onSelectItem: () => {},
  clearOnUnmount: true,
  additionalFilters: [],
};

/**
 * TitleList
 *
 * @param {string} defaultSortMetric default metric used to sort data in api request.
 * @param {object} params
 * {
 *   siteIds {array}
 * }
 */
const TitleList = ({ metrics, metricListOrder, params, clearOnUnmount, additionalFilters, ...rest }) => {
  const { pageSearch } = useAppContextState();
  const [pageNumber, setPageNumber] = useState(FETCH_START_PAGE);

  const lastRefresh = useLastRefresh();
  const [data, isLoading, isLazyLoading, isMaxPagesReached, reset] = useTitleListData();

  /**
   * If a filter changes or the search input changes
   * reset pageNumber back to start.
   */
  useParametersListener(
    getParamsForTitleListFetch(additionalFilters),
    () => {
      setPageNumber(FETCH_START_PAGE);
    },
    [metrics, pageSearch, params, lastRefresh]
  );

  // Fires to load next page
  useDataFetchAndAppend({ globalParams: params, metrics, pageNumber, additionalFilters });

  // Fires after search, refresh, selected attr change, param change
  useDataFetchAndReset({ globalParams: params, metrics, additionalFilters });

  const onScrollBottom = () => {
    if (!isLoading && !isMaxPagesReached) {
      setPageNumber(pageNumber + 1);
    }
  };
  const onScrollBottomRef = useRef(onScrollBottom);
  useEffect(() => {
    onScrollBottomRef.current = onScrollBottom;
  });

  const onScrollBottomMemoized = useCallback(() => {
    onScrollBottomRef.current();
  }, []);

  useEffect(() => {
    return () => {
      if (clearOnUnmount) {
        reset([]);
      }
    };
  }, [clearOnUnmount, reset]);

  return (
    <ListContainer
      metrics={metrics}
      listData={data}
      onScrollBottom={onScrollBottomMemoized}
      isInitiallyLoading={isLoading}
      isLazyLoading={isLazyLoading}
      hasSearch={pageSearch.length > 0}
      {...rest}
    />
  );
};

TitleList.propTypes = propTypes;
TitleList.defaultProps = defaultProps;

export default TitleList;
