import { SHOW_BANNER, HIDE_BANNER, CLEAR_BANNER_KEY } from './actionTypes';
import { STATE_KEY } from './constants';

const initialState = {
  key: null,
  isVisible: false,
  status: 'success',
  message: '',
  messageValues: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BANNER:
      return {
        key: action.key,
        isVisible: true,
        status: action.status,
        message: action.message,
        messageValues: action.messageValues,
      };

    case HIDE_BANNER:
      return {
        ...state,
        isVisible: false,
      };

    case CLEAR_BANNER_KEY:
      return { ...state, key: null };
    default:
      return state;
  }
};

export default {
  [STATE_KEY]: reducer,
};
