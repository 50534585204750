import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Text, IconCustom } from '@components';
import { itemFormatter } from '@modules/Formatters';

import {
  COL_CIRCUIT_NAME,
  COL_REPORTED_SITES,
  COL_FEED,
  COL_REALTIME_REPORTING_SITES,
  COL_REPORTED_SITES_YESTERDAY,
} from '../constants';
import useCheckReportingView from './useCheckReportingView';

const renderCell = ({ value }) => <Text size="s">{value}</Text>;
const renderHeaderCell = ({ label }) => (
  <Text size="s" bold>
    {label}
  </Text>
);

const renderSitesCell = ({ value }) => renderCell({ value: itemFormatter(value) });

const renderFeedCell = ({ value }) => (value ? <IconCustom icon={`${value}-logo`} className="feed-icon" /> : '-');

export const useTableColumns = () => {
  const { formatMessage } = useIntl();
  const { isNonRealTimeReportingView, isRealTimeReportingView } = useCheckReportingView();

  const tableColumnsNonRealtime = useMemo(
    () => [
      {
        key: COL_FEED,
        label: formatMessage({ id: 'report_indicator_th_data_feed' }),
        renderCell: renderFeedCell,
        renderHeaderCell,
        width: '14%',
      },
      {
        key: COL_CIRCUIT_NAME,
        label: formatMessage({ id: 'report_indicator_th_circuit' }),
        renderCell,
        renderHeaderCell,
        width: '43%',
        sort: true,
      },
      {
        key: COL_REPORTED_SITES,
        label: formatMessage({ id: 'report_indicator_th_sites_reported' }),
        renderCell: renderSitesCell,
        renderHeaderCell,
        width: '43%',
        sort: true,
      },
    ],
    [formatMessage]
  );

  const tableColumnsRealtime = useMemo(
    () => [
      {
        key: COL_FEED,
        label: formatMessage({ id: 'report_indicator_th_data_feed' }),
        renderCell: renderFeedCell,
        renderHeaderCell,
        width: '12%',
      },
      {
        key: COL_CIRCUIT_NAME,
        label: formatMessage({ id: 'report_indicator_th_circuit' }),
        renderCell,
        renderHeaderCell,
        width: '30%',
        sort: true,
      },
      {
        key: COL_REALTIME_REPORTING_SITES,
        label: formatMessage({ id: 'report_indicator_th_sites_reporting' }),
        renderCell: renderSitesCell,
        renderHeaderCell,
        width: '24%',
        sort: true,
      },
      {
        key: COL_REPORTED_SITES_YESTERDAY,
        label: formatMessage({ id: 'report_indicator_th_sites_reported_yesterday' }),
        renderCell: renderSitesCell,
        renderHeaderCell,
        width: '34%',
        sort: true,
      },
    ],
    [formatMessage]
  );

  if (isNonRealTimeReportingView) {
    return tableColumnsNonRealtime;
  }

  if (isRealTimeReportingView) {
    return tableColumnsRealtime;
  }
};
