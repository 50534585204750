import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelected } from './selectors';
import { setSelected, setSortMetric } from './actions';

/**
 * This hooks is able to set type and metric of sort filterType
 * It also detects when a main metric is change and then it'll update the sort metric.
 * Whether you want to use your own metric selected regardless of main metric selected use:
 * setSortMetricSelected
 *
 * Notes:
 * Anytime you want to update this sortMetric by the main metric selected,
 * call setSortMetricSelected at any change from main metric.
 * */
export function useSort() {
  const dispatch = useDispatch();
  const selected = useSelector(getSelected);

  // Update sort direction/type
  const setSortTypeSelected = useCallback((value) => {
    dispatch(setSelected(value));
  }, []);

  // Updates sort metric independently from what is on main metric selected.
  const setSortMetricSelected = useCallback((metric) => {
    dispatch(setSortMetric(metric));
  }, []);

  return [selected, setSortTypeSelected, setSortMetricSelected];
}
