import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelected } from './selectors';
import { setSelected as setSelectedAction } from './actions';

export function useDates() {
  const dispatch = useDispatch();
  const selected = useSelector(getSelected);

  const setSelected = useCallback(
    (values) => {
      dispatch(setSelectedAction(values));
    },
    [dispatch]
  );

  return [selected, setSelected];
}
