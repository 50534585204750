import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from '@components';
import { hideBanner } from './actions';
import { isBannerVisible, getStatus, getMessage, getMessageValues } from './selectors';
import './_notification-banner.scss';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  isVisible: PropTypes.bool,
  status: PropTypes.oneOf(['success', 'error']),
  message: PropTypes.string,
  hideBanner: PropTypes.func,
  messageValues: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  isVisible: isBannerVisible(state),
  status: getStatus(state),
  message: getMessage(state),
  messageValues: getMessageValues(state),
});

const mapDispatchToProps = { hideBanner };

const enhance = connect(mapStateToProps, mapDispatchToProps);

const componentClass = 'notification-banner';

const NotificationBanner = ({ isVisible, status, message }) => (
  <div className={componentClass}>
    <Alert type={status} inline hide={!isVisible} alertMessage={message && <FormattedMessage id={message} />} />
  </div>
);

NotificationBanner.propTypes = propTypes;

export default enhance(NotificationBanner);
