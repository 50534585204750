import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { percentageFormatter } from '@modules';
import { Arrow } from '@components';

import './_percentage-movement.scss';

const propTypes = {
  value: PropTypes.number,
  size: PropTypes.oneOf(['xs', 'xxs']),
  precision: PropTypes.number,
  className: PropTypes.string,
};
const defaultProps = {
  value: null,
  size: 'xs',
  precision: 0,
  className: '',
};

/**
 * PercentageMovement Indicator
 * @param {number} value
 * @param {number} size
 * @param {number} precision Number of decimal places to show
 */
const PercentageMovement = ({ value, size = 'xs', precision = 0, className }) => {
  const arrowDirection = value > 0 ? 'up' : 'down';
  return value ? (
    <div
      className={classnames('text-number', `text-number--${size}`, className, {
        'text--success': value > 0,
        'text--danger': value < 0,
        'text--warning': value === 0,
      })}
    >
      {value !== 0 && <Arrow dir={arrowDirection} className="percentage__arrow" />}
      <span>{percentageFormatter(Math.abs(value), precision)}</span>
    </div>
  ) : null;
};

PercentageMovement.propTypes = propTypes;
PercentageMovement.defaultProps = defaultProps;

export default PercentageMovement;
