import { createSelector } from '@reduxjs/toolkit';
import _isFunction from 'lodash/isFunction';
import { KEY as DATES_KEY, getParameters as getDatesParameters } from './dates';
import { KEY as TERRITORY_KEY, getParameters as getTerritoryParams } from './territory';
import { KEY as COMPS_TITLES_KEY, getParameters as getCompsTitlesPage } from './compsTitles';
import { KEY as SITES_KEY, getParameters as getSitesParams } from './sites';
import { KEY as METRIC_KEY, getParameters as getMetricParams } from './metric';
import { KEY as SITE_ATTRIBUTE_GROUP, getParameters as getSiteAttributeGroupParams } from './siteAttributeGroup';
import { KEY as SORT_KEY, getParameters as getSortByParams } from './sort';
import { KEY as OCCUPANCY_KEY, getParameters as getOccupancyParams } from './occupancy';
import { KEY as PERFORMANCE_KEY, getParameters as getPerformanceParams } from './performance';
import { KEY as CURRENCY_KEY, getParameters as getCurrencyParameters } from './currency';

const getParametersByFilterTypeMap = {
  [DATES_KEY]: getDatesParameters,
  [TERRITORY_KEY]: getTerritoryParams,
  [COMPS_TITLES_KEY]: getCompsTitlesPage,
  [METRIC_KEY]: getMetricParams,
  [SITES_KEY]: getSitesParams,
  [SITE_ATTRIBUTE_GROUP]: getSiteAttributeGroupParams,
  [SORT_KEY]: getSortByParams,
  [OCCUPANCY_KEY]: getOccupancyParams,
  [PERFORMANCE_KEY]: getPerformanceParams,
  [CURRENCY_KEY]: getCurrencyParameters,
};

/**
 * Maps all filter type keys to their individual getParameters selectors and returns a selector
 * to query the store and return parameters
 *
 * @param {string[]} filterTypes - array of filter keys to map to parameters
 *
 * @returns {func} selector which will return parameters for the filterTypes supplied
 */
export const getParametersFactory = (filterTypes) => {
  let selectors = [];

  filterTypes.forEach((filterType) => {
    const selector = getParametersByFilterTypeMap[filterType];

    if (_isFunction(selector)) {
      selectors.push(selector);
    }
  });

  return createSelector(selectors, (...params) =>
    params.reduce((acc, val) => {
      return { ...acc, ...val };
    })
  );
};
