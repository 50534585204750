import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOccupancy } from '@modules/FilterTypes/occupancy';

import { Button } from '@components';
import { useTheatreMetadata } from '@modules';
import { useAppContextDispatch } from '@modules';

/**
 * BackArrow
 * If a previousUrl path was saved, use it. Otherwise default back to '/theatres'
 */
export default function BackArrow({ className }) {
  const history = useHistory();
  const [, setOccupancy] = useOccupancy();
  const { previousUrl, setPreviousUrl } = useTheatreMetadata();
  const appContextDispatch = useAppContextDispatch();
  const cleanUp = useCallback(() => {
    appContextDispatch({ type: 'SET_PAGE_SEARCH', pageSearch: '' });
    setOccupancy([]);
    setPreviousUrl(null);
  }, [setPreviousUrl, setOccupancy, appContextDispatch]);

  const goToPreviousPage = useCallback(() => {
    const path = previousUrl || '/theatres';

    cleanUp();

    history.push(path);
  }, [history, previousUrl, cleanUp]);

  useEffect(() => {
    // Clearing within unMount so Browser Back button can avail of the clearing too.
    return cleanUp;
  }, [cleanUp]);

  return <Button className={className} icon="back" onClick={goToPreviousPage} />;
}
