import { createReducer } from '@reduxjs/toolkit';

import update from 'immutability-helper';

import _isNull from 'lodash/isNull';

import { updateValues, setLastRefreshed, reset, pausePoll, setMetrics } from './actions';
import { STATE_KEY } from './constants';

const initialState = {
  metrics: '',
  oldValues: {
    admissions: null,
    eventRevenue: null,
    eventRevenueBO: null,
  },
  newValues: {
    admissions: null,
    eventRevenue: null,
    eventRevenueBO: null,
  },
  lastRefreshed: new Date(),
  paused: false,
};

const dataRefresh = createReducer(initialState, {
  [updateValues.toString()]: (state, { payload }) => {
    const { oldValues } = state;
    const { admissions, eventRevenue, eventRevenueBO } = payload;

    // If values are null, then it is the first request so we just initialise with
    // all the values set to the same. In most cases only one revenue value
    // will be sent in the payload, which means it will be removed from the store by update fn
    if (Object.values(oldValues).every(_isNull)) {
      return update(state, {
        oldValues: {
          admissions: { $set: admissions },
          eventRevenue: { $set: eventRevenue },
          eventRevenueBO: { $set: eventRevenueBO },
        },
        newValues: {
          admissions: { $set: admissions },
          eventRevenue: { $set: eventRevenue },
          eventRevenueBO: { $set: eventRevenueBO },
        },
      });
    }

    // Otherwise we just update the newValues to the latest ones that come back from the api
    return update(state, {
      newValues: {
        admissions: { $set: admissions },
        eventRevenue: { $set: eventRevenue },
        eventRevenueBO: { $set: eventRevenueBO },
      },
    });
  },
  [setLastRefreshed.toString()]: (state) => {
    const { newValues } = state;

    /**
     * When refreshing, we update the `lastRefreshed` date.
     * Then set the oldValues the same as the newValues so
     */
    return update(state, {
      lastRefreshed: { $set: new Date() },
      oldValues: {
        admissions: { $set: newValues.admissions },
        eventRevenue: { $set: newValues.eventRevenue },
        eventRevenueBO: { $set: newValues.eventRevenueBO },
      },
    });
  },
  [pausePoll.toString()]: (state, { payload }) => {
    return update(state, {
      paused: { $set: payload },
    });
  },
  [reset.toString()]: (state) => {
    return update(state, {
      newValues: {
        admissions: { $set: null },
        eventRevenue: { $set: null },
        eventRevenueBO: { $set: null },
      },
      oldValues: {
        admissions: { $set: null },
        eventRevenue: { $set: null },
        eventRevenueBO: { $set: null },
      },
    });
  },
  [setMetrics.toString()]: (state, { payload }) => {
    return update(state, {
      metrics: { $set: payload },
    });
  },
});

export default {
  [STATE_KEY]: dataRefresh,
};
