import { createReducer } from '@reduxjs/toolkit';
import { setTerritoriesList, setTerritoryAsFavourite, removeTerritoryAsFavourite } from './actions';
import { STATE_KEY } from './constants';

const initialState = [];

const setTerritories = (territoriesArray) => {
  let territoriesById = {};

  territoriesArray.forEach((territory) => {
    territoriesById[territory.id] = territory;
  });

  return territoriesById;
};

const territoriesReducer = createReducer(initialState, {
  [setTerritoriesList.toString()]: (_, action) => setTerritories(action.payload),
  [setTerritoryAsFavourite.toString()]: (state, { payload }) => {
    state[payload].favorite = true;
    return state;
  },
  [removeTerritoryAsFavourite.toString()]: (state, { payload }) => {
    state[payload].favorite = false;
    return state;
  },
});

export default {
  [STATE_KEY]: territoriesReducer,
};
