import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Spinner } from '@components';
import Pagination from '@modules/Pagination';

import List from './List';
import { usePagination } from '../hooks';

const propTypes = {
  className: PropTypes.string,
  hasAside: PropTypes.bool,
  paginated: PropTypes.bool,
  onSelectItem: PropTypes.func,
  params: PropTypes.shape({}),
  isInitiallyLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
};
const defaultProps = {
  hasAside: false,
  className: '',
  onSelectItem: () => {},
  listData: null,
  isInitiallyLoading: false,
  isLazyLoading: false,
};

const componentName = 'theatre-list';

/**
 * TheatreList Container
 * @param {bool} isInitiallyLoading True when first page of results are loading.
 * @param {bool} isLazyLoading - True when any pages of results after the firts is loading.
 */
const ListContainer = ({ className, isInitiallyLoading, isLazyLoading, ...props }) => {
  const [current, setCurrent, numPages, hasPages] = usePagination();

  return (
    <div className={`${componentName}-ctn ${className}`}>
      {isInitiallyLoading && <Spinner />}
      {!isInitiallyLoading && (
        <>
          <List {...props} componentName={componentName} />
          {!props.paginated && (
            <div
              className={classnames('lazy-load', {
                'lazy-load--is-visible': isLazyLoading,
              })}
            >
              <Spinner size={'s'} />
            </div>
          )}
        </>
      )}
      {props.paginated && hasPages && <Pagination value={current} onChange={setCurrent} size={numPages} />}
    </div>
  );
};

ListContainer.propTypes = propTypes;
ListContainer.defaultProps = defaultProps;

export default ListContainer;
