import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { BackToTop } from '@components';
import { TheatreList, TheatreSelectorButtons } from '@modules';
import { useSelectedSiteAttributeGroup } from '@modules/SiteAttributionSelector';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';

import ViewTransition from './ViewTransition';
import { useAdvancedSalesPerspectiveMetrics } from '../../hooks';

const propTypes = {
  baseEventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

const defaultProps = {
  onSelectItem: _noop,
};

const ViewTheatres = ({ baseEventId, onSelectItem }) => {
  const [, selectedAttribute] = useSelectedSiteAttributeGroup();
  const [eventParamKey] = useEventParamKey();
  const metrics = useAdvancedSalesPerspectiveMetrics();

  return (
    <ViewTransition className="film-metadata__view film-metadata__view--theatres">
      <TheatreSelectorButtons metrics={metrics} />
      <TheatreList
        className="film-metadata__theatres-list"
        hasAside={selectedAttribute && selectedAttribute.id === null}
        params={{ [eventParamKey]: [baseEventId] }}
        onSelectItem={onSelectItem}
      />
      <BackToTop />
    </ViewTransition>
  );
};

ViewTheatres.propTypes = propTypes;
ViewTheatres.defaultProps = defaultProps;

export default ViewTheatres;
