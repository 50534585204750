import { useSelector } from 'react-redux';
import { getSelectedTerritory } from '@modules/TerritorySelect';
import { useCircuitAttribute } from '@modules/Auth';
import { getReportingReportedSites } from '@modules/ReportingIndicator';

export function useStudioHelpers() {
  const reportedSites = useSelector(getReportingReportedSites);
  const { hasPresales: hasTerritoryPresales, isIntegrated: isTerritoryIntegrated } = useSelector(getSelectedTerritory);
  const isPresales = useCircuitAttribute('hasPresales', !!hasTerritoryPresales);

  return {
    isPresales,
    isSitesReported: reportedSites > 0,
    isIntegrated: !!isTerritoryIntegrated,
  };
}
