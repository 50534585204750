import React from 'react';
import { Section, Tabs, Tab } from '@components';
import { BASE_CLASS, SETTINGS_PAGE } from '../constants';
import UserSettingsPassword from './Password/UserSettingsPassword';
import UserSettingsList from './UserSettingsList';
import UserSettingsLanguage from './UserSettingsLanguage';
import UserSettingsCurrency from './UserSettingsCurrency';
import UserSettingsTimeFormat from './UserSettingsTimeFormat';
import UserSettingsTitlePreference from './UserSettingsTitlePreference';
import UserSettingsDefaultTerritory from './DefaultTerritory/UserSettingsDefaultTerritory';

const componentClass = `${BASE_CLASS}-main`;

const UserSettingsMain = ({ ...props }) => {
  return (
    <>
      <Section.Container
        {...props}
        tag={Tabs}
        homeTab={SETTINGS_PAGE.SETTINGS}
        component="article"
        className={componentClass}
      >
        <Tab tabKey={SETTINGS_PAGE.SETTINGS} component={UserSettingsList} className={`${componentClass}__page`} />
        <Tab tabKey={SETTINGS_PAGE.PASSWORD} component={UserSettingsPassword} className={`${componentClass}__page`} />
        <Tab tabKey={SETTINGS_PAGE.LANGUAGE} component={UserSettingsLanguage} className={`${componentClass}__page`} />
        <Tab tabKey={SETTINGS_PAGE.CURRENCY} component={UserSettingsCurrency} className={`${componentClass}__page`} />
        <Tab
          tabKey={SETTINGS_PAGE.TITLE_PREFERENCE}
          component={UserSettingsTitlePreference}
          className={`${componentClass}__page`}
        />
        <Tab
          tabKey={SETTINGS_PAGE.DEFAULT_TERRITORY}
          component={UserSettingsDefaultTerritory}
          className={`${componentClass}__page`}
        />
        <Tab
          tabKey={SETTINGS_PAGE.TIME_FORMAT}
          component={UserSettingsTimeFormat}
          className={`${componentClass}__page`}
        />
      </Section.Container>
    </>
  );
};

export default UserSettingsMain;
