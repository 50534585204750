import { loadState, saveState, clearState, APP_END } from '@modules/Core';
import { getIsAuthenticated } from '@modules/Auth';
import { LOCALSTORAGE_KEY } from './constants';
import { getShareObject } from './selectors';
import { getSharedState } from './helpers';

export const sharedObjectMiddleware = (store) => {
  let shareObject;

  return (next) => (action) => {
    const result = next(action);

    if (action.type === APP_END) {
      // Logging out - clear LS state.
      shareObject = undefined;
      clearState(LOCALSTORAGE_KEY);
    } else {
      const state = store.getState();
      const newShareObject = getShareObject(state);
      const isAuthenticated = getIsAuthenticated(state);

      // Taking advantage of memoization,
      // Action that change anything within filters will change this object reference too.
      if (isAuthenticated && newShareObject !== shareObject) {
        shareObject = newShareObject;
        saveState(LOCALSTORAGE_KEY, newShareObject);
      }
    }

    return result;
  };
};

export const getInitialState = () => {
  return getSharedState() || loadState(LOCALSTORAGE_KEY) || {};
};
