import React, { forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Section, Spinner } from '@components';
import { BASE_CLASS, SETTINGS_PAGE } from '../constants';
import UserSettingsHeader from './UserSettingsHeader';
import SelectableList from './SelectableList';
import { updateLocalTitle } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '@modules/Auth';
import { isUpdatingUserSettings, getLocalTitle } from '../selectors';

const componentClass = `${BASE_CLASS}-titlePreference`;

const UserSettingsTitlePreference = forwardRef(function UserSettingsIsLocalTitle(props, ref) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userProfile = useSelector(getUserProfile);
  const isUpdating = useSelector(isUpdatingUserSettings);
  const isLocalTitle = useSelector(getLocalTitle);

  const options = [
    { id: 0, name: 'title_preference.us_title', value: false },
    { id: 1, name: 'title_preference.local_title', value: true },
  ];

  const setUserDefaultIsLocalTitle = useCallback(
    (isLocalTitle) => {
      const { email } = userProfile;

      dispatch(updateLocalTitle(email, isLocalTitle));
    },
    [dispatch, userProfile]
  );

  return (
    <Section.Container
      {...props}
      className={classnames(props.className, componentClass, { [`${BASE_CLASS}-main--loading`]: isUpdating })}
      ref={ref}
      bordered
      tag="section"
    >
      <UserSettingsHeader
        prevIcon="back"
        prevPage={SETTINGS_PAGE.SETTINGS}
        title={formatMessage({ id: 'user_settings.title-preference' })}
      />
      <Section.Container>
        <SelectableList
          data={options}
          value={!!isLocalTitle}
          valueKey={'value'}
          labelKey={'name'}
          icon={'check'}
          onChange={setUserDefaultIsLocalTitle}
        />
      </Section.Container>
      {isUpdating && <Spinner />}
    </Section.Container>
  );
});

UserSettingsTitlePreference.propTypes = {
  className: PropTypes.string,
};

export default UserSettingsTitlePreference;
