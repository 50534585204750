import { createSelector } from '@reduxjs/toolkit';
import { isLoading } from '@showtime/cea-dep-utility';
import { STATE_KEY as filtersKey, getFilters } from '@modules/Filters';
import { compressJSON } from '@modules/Core';
import { SHARE_LINK_FETCH_KEY } from './constants';

export const getShareObject = createSelector([getFilters], (filters) => ({ [filtersKey]: filters }));

export const getShareObjectCompressed = createSelector([getShareObject], compressJSON);

export const getIsLoadingShareLink = (state) => isLoading(state, SHARE_LINK_FETCH_KEY);
