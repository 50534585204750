import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';

import { HeaderGroup, Heading, Section, Button, useOverlay, SearchInputField } from '@components';
import Pagination from '@modules/Pagination';
import { useDates } from '@modules/FilterTypes/dates';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';

import { BASE_CLASS } from '../constants';
import { DisplayReportedSitesInOverlay } from './DisplayReportedSites';
import ReportingTable from './ReportingTable';
import { useCircuitsDataFetch, usePagination, useSort } from '../hooks';
import { useReportingIndicatorContext } from '../Context';

const OverlayContent = ({ className }) => {
  const { formatMessage } = useIntl();
  const [eventParamKey] = useEventParamKey();
  const { close } = useOverlay();
  const reportingIndicatorContext = useReportingIndicatorContext();
  const { circuits, search, setSearch } = useCircuitsDataFetch({
    [eventParamKey]: reportingIndicatorContext[eventParamKey],
  });
  const { currentPage, numPages, setCurrentPage, resetPagination, hasPages } = usePagination();
  const { resetSort } = useSort();
  const [selectedDates] = useDates();

  const onSearchChange = useCallback(
    (searchValue) => {
      setCurrentPage(1);
      setSearch(searchValue);
    },
    [setCurrentPage, setSearch]
  );

  useEffect(() => {
    return () => {
      resetPagination();
      resetSort();
    };
  }, [resetPagination, resetSort]);

  return (
    <Section.Container bordered component="section" className={`${className} ${BASE_CLASS}__overlay-content`}>
      <Section tag={HeaderGroup} className="content__header">
        <HeaderGroup.Left>
          <Button onClick={close} icon="exit" />
        </HeaderGroup.Left>
        <HeaderGroup.Center>
          <Heading level={2} size="m">
            {formatMessage(
              { id: 'report_indicator_market_report_title' },
              { today: `${moment(selectedDates[0], 'YYYY-MM-DD').format('ddd, MMM Do')}` }
            )}
          </Heading>
        </HeaderGroup.Center>
      </Section>
      <Section className="content__body">
        <DisplayReportedSitesInOverlay />

        {!_isEmpty(circuits) && (
          <SearchInputField
            className="content__body__search"
            value={search}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder={formatMessage({ id: 'report_indicator_search_placeholder' })}
          />
        )}
        <ReportingTable />
        {hasPages && (
          <Pagination
            className={`${BASE_CLASS}__pagination`}
            value={currentPage}
            onChange={setCurrentPage}
            size={numPages}
          />
        )}
      </Section>
    </Section.Container>
  );
};

OverlayContent.propTypes = {
  className: PropTypes.string,
};

OverlayContent.defaultProps = {
  className: '',
};

export default OverlayContent;
