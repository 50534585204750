import React, { useMemo, useCallback } from 'react';
import _isFunction from 'lodash/isFunction';
import { PAGINATION_SHAPE } from './constants';

const propTypes = PAGINATION_SHAPE;

const setPageNumber = (pageNumber) => (pageNumber < 10 ? `0${pageNumber}` : pageNumber);

function PageDropdown({ size, value, onChange }) {
  const pageOptions = useMemo(() => {
    let opts = [];
    for (var i = 1; i <= size; i++) {
      opts.push(i);
    }
    return opts;
  }, [size]);

  const onNewSelect = useCallback(
    (e) => {
      if (_isFunction(onChange)) {
        onChange(parseInt(e.target.value));
      }
    },
    [onChange]
  );

  return (
    <select className="pagination__pages" value={value} name="pages" onChange={onNewSelect}>
      {pageOptions.map((page) => (
        <option key={page} value={page}>
          {setPageNumber(page)}
        </option>
      ))}
    </select>
  );
}

PageDropdown.propTypes = propTypes;

export default PageDropdown;
