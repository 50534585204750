import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as classnames from 'classnames';
import _find from 'lodash/find';

import { Overlay } from '@components';
import { Text, OccupancyPercentage } from '@components';
import { useLongPress } from '@modules/Core/hooks';
import { PerformanceModal } from './Modals';
import { getPerformances } from '../selectors';
import { usePerformanceCardsPosition, usePerformanceCardsOpacity } from '../hooks/index';

const componentName = 'performance__card';

const propTypes = {
  id: PropTypes.number.isRequired,
  event: PropTypes.string.isRequired,
  occupancy: PropTypes.number.isRequired,
  datetime: PropTypes.string.isRequired,
  runtime: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClickCard: PropTypes.func.isRequired,
};

const PerformanceCard = ({ id, event, occupancy, datetime, runtime, isSelected, onClickCard }) => {
  const performanceData = useSelector(getPerformances);
  const perfCardRef = useRef(null);
  const [titleSelectedId, setTitleSelectedId] = useState();

  const titleData = useMemo(() => {
    return _find(performanceData, ['id', id]);
  }, [performanceData, id]);

  const onLongPressCard = () => {
    setTitleSelectedId(id);
  };

  useLongPress(perfCardRef, onLongPressCard);

  usePerformanceCardsPosition(perfCardRef, { datetime, runtime });

  usePerformanceCardsOpacity(perfCardRef, { occupancy });

  return (
    <>
      <div
        className={classnames(componentName, { [`${componentName}--selected`]: isSelected })}
        onClick={() => onClickCard(id)}
        ref={perfCardRef}
      >
        <Text size="m" className={`${componentName}__title`}>
          {event}
        </Text>
        <OccupancyPercentage value={occupancy} size="m" className={`${componentName}__occupancy`} />
      </div>
      <Overlay.Provider type="modal" isOpen={!!titleSelectedId} onClose={() => setTitleSelectedId(null)}>
        {titleSelectedId && <PerformanceModal data={titleData} />}
      </Overlay.Provider>
    </>
  );
};

PerformanceCard.propTypes = propTypes;

export default PerformanceCard;
