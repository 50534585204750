import React from 'react';
import PropTypes from 'prop-types';

const componentName = 'icon';

/**
 * Icon
 *
 * Use <Icon> Component for any icons that are added via the upicons iconFont.
 *
 * @param {string} icon    - Name of icon. e.g 'favourite' or 'copy-and-paste'
 * @param {string} className  Optional. More specific className provided for specific styling.
 *
 * Example Usuage:
 * <Icon icon="favourite" className="specific-className" />
 *
 * @returns icon node with neccessary classNames for styling.
 * e.g <i className="icon icon--favourite specific-className-passed-as-prop" />
 */
const IconComponent = React.forwardRef(function Icon({ className, icon }, ref) {
  return <i data-testid="Icon" ref={ref} className={`${componentName} ${componentName}--${icon} ${className}`} />;
});

IconComponent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
};

IconComponent.defaultProps = {
  className: '',
  icon: '',
};

export default IconComponent;
