import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import _isFinite from 'lodash/isFinite';

const propTypes = {
  size: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const ProgressCircle = (props) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  const { size, progress, strokeWidth, color, className } = props;

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);

    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';
  }, [setOffset, progress, circumference, offset]);

  return (
    <svg className={className} width={size} height={size}>
      <circle
        ref={circleRef}
        stroke={color}
        cx={center}
        cy={center}
        r={radius}
        fill="none"
        strokeLinecap="round"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        transform={`rotate(-90 ${center} ${center})`}
        strokeDashoffset={_isFinite(offset) ? offset : null}
      />
    </svg>
  );
};

ProgressCircle.propTypes = propTypes;

export default ProgressCircle;
