import PropTypes from 'prop-types';

export const STATE_KEY = 'territories';

export const FETCH_KEY_SET_AS_FAVOURITE = 'territories.setFavourite';
export const FETCH_KEY_SET_AS_DEFAULT_TERRITORY = 'territories.setDefaultTerritory';
export const FETCH_KEY_REMOVE_AS_FAVOURITE = 'territories.removeFavourite';

export const OPTION_SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  favourite: PropTypes.bool,
});
