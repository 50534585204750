import React, { useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { metricKeyPropType } from '@modules/Metrics';
import {
  useSortMetricsByOrder,
  useResizeHeightChanged,
  DEFAULT_METRIC_LIST_ORDER,
  APP_HEADER_HEIGHT,
} from '@modules/Core';

import { useData } from './hooks';
import { List, ListWithHero } from './components';
import { BASE_CLASS } from './constants';

import './_metric-list.scss';

const BaseMetricList = ({ metricListClassName, sortedMetrics, ...props }) => {
  return (
    <div className={`${BASE_CLASS} ${metricListClassName}`}>
      <List metrics={sortedMetrics} {...props} />
    </div>
  );
};

const PinnedMetricList = ({
  monitorHeightRef,
  metricListClassName,
  metricListContainerClassName,
  sortedMetrics,
  ...props
}) => {
  let metricListContainerTopPosition = useRef(null);

  const onContainerHeightChange = useCallback(
    (height) => {
      metricListContainerTopPosition.current = height + APP_HEADER_HEIGHT;
    },
    [metricListContainerTopPosition]
  );
  // Monitoring the height of elements above this metricList. If they change, this needs to recalculate its new top position.
  useResizeHeightChanged(monitorHeightRef, onContainerHeightChange);
  return (
    <div
      className={classnames(`${BASE_CLASS}-container--pinned ${metricListContainerClassName}`)}
      style={{ top: `${metricListContainerTopPosition.current}px` }}
    >
      <BaseMetricList metricListClassName={metricListClassName} sortedMetrics={sortedMetrics} {...props} />
    </div>
  );
};

const propTypes = {
  params: PropTypes.shape({}),
  metrics: PropTypes.arrayOf(metricKeyPropType).isRequired,
  /**
   * Determines order list items will be displayed.
   */
  metricListOrder: PropTypes.arrayOf(metricKeyPropType),
  /**
   * Optional extra classname added to .metric-list
   */
  metricListClassName: PropTypes.string,
  /**
   * Optional extra classname added to .metric-list-container or .metric-list-hero-container
   */
  metricListContainerClassName: PropTypes.string,
  /**
   * If specified, MetricList will monitor the height of this element.
   * If height changes, MetricList will re-calculate it's scrollTrigger top position to get
   * the correct animStartPos for the listHeroanimation.
   */
  monitorHeightRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  /**
   * If true, the metricList will be pinned to the view and remain while scrolling.
   */
  pin: PropTypes.bool,
  /**
   * If true, a different metric layout is rendered.
   * The first metric will sit inline and a metric animation will fire on scroll.
   * Note: this will automatically pin the metricList to the view.
   */
  showHero: PropTypes.bool,
};

const defaultProps = {
  metricListOrder: DEFAULT_METRIC_LIST_ORDER,
  params: null,
  pin: false,
  showHero: false,
  metricListClassName: '',
  heroContainerClassName: '',
  monitorHeightRef: null,
};

/**
 * Metrics Lists
 *
 * Default Display { pin: false, showHero: false } will display simple base list that scrolls with view.
 * Pinned Display { pin: true, showHero: false } will display simple base list that sticks and remain in view whilst scrolling.
 * Hero Display { pin: true||false, showHero: true } will render the ListWithHero component. This depending on the number of items
 *  will display a hero inline item and add an animation on scroll to display all items. Hero displays will always be pinned by default.
 */
const Metrics = ({
  pin,
  showHero,
  metricListContainerClassName,
  monitorHeightRef,
  metrics,
  params,
  metricListOrder,
  metricListClassName,
  ...props
}) => {
  useData(metrics, params);

  const [sortedMetrics] = useSortMetricsByOrder(metrics, metricListOrder);

  return showHero ? (
    <ListWithHero
      monitorHeightRef={monitorHeightRef}
      metricListContainerClassName={metricListContainerClassName}
      metricListClassName={metricListClassName}
      metrics={sortedMetrics}
      {...props}
    />
  ) : (
    <>
      {pin ? (
        <PinnedMetricList
          monitorHeightRef={monitorHeightRef}
          metricListClassName={metricListClassName}
          metricListContainerClassName={metricListContainerClassName}
          sortedMetrics={sortedMetrics}
          {...props}
        />
      ) : (
        <BaseMetricList metricListClassName={metricListClassName} sortedMetrics={sortedMetrics} {...props} />
      )}
    </>
  );
};

Metrics.propTypes = propTypes;
Metrics.defaultProps = defaultProps;

export default Metrics;
