import { useDrag } from 'react-use-gesture';
 
export default function useSwipeX({ onSwipeLeft, onSwipeRight }) {
  const bind = useDrag(({ down, direction: [xDir], distance, cancel, axis }) => {
    if (down && distance > window.innerWidth / 2 && axis === 'x') {
      const xDirection = Math.round(xDir);

      if (xDirection === -1) {
        onSwipeLeft();
        cancel();
      } else if (xDirection === 1) {
        onSwipeRight();
        cancel();
      }
    }
  });

  return bind;
};
