import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { APP_HEADER_HEIGHT } from '@modules/Core';

const METRIC_TIMELINE_EASE_IN = 'power1.in';

const SCROLL_TRIGGER_ANIM_ID = 'navShadowAnim';

/**
 * useNavShadowAnimation
 * As soon as the page is scrolled a box-shadow is applied to the bottom of the nav
 */
export const useNavShadowAnimation = (isAnimationEnabled) => {
  let navContainerRef = useRef(null);
  let anim = useRef(null);

  useEffect(() => {
    if (navContainerRef.current) {
      if (isAnimationEnabled) {
        anim.current = gsap.timeline({
          scrollTrigger: {
            id: SCROLL_TRIGGER_ANIM_ID,
            trigger: navContainerRef.current,
            start: `-${APP_HEADER_HEIGHT}px top`,
            toggleActions: 'restart none none reverse',
          },
        });

        anim.current.to(navContainerRef.current, {
          boxShadow: '0px 2px 4px 0px rgba(3, 53, 78, 0.8)',
          duration: 0.2,
          ease: METRIC_TIMELINE_EASE_IN,
        });
      } else {
        if (anim.current) {
          anim.current = null;
          ScrollTrigger.getById(SCROLL_TRIGGER_ANIM_ID).kill();
          gsap.set(navContainerRef.current, { boxShadow: 'none' });
        }
      }
    }
  }, [navContainerRef, isAnimationEnabled, anim]);

  const navContainerRefBinder = navContainerRef;

  return [navContainerRefBinder];
};
