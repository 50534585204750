import { useState, useEffect, useMemo } from 'react';

/**
 * useIsElementInView
 * Observes target element and returns a boolean when the element comes in and out of the viewport view.
 */
export default function useIsElementInView() {
  const [isElementVisible, setIsElementVisible] = useState(false);
  const [element, setElement] = useState(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries) => {
        // If the node we are observing is comes into the viewport
        if (entries && entries[0].isIntersecting) {
          setIsElementVisible(true);
        } else {
          setIsElementVisible(false);
        }
      }),
    [setIsElementVisible]
  );

  useEffect(() => {
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [element, observer]);
  return [isElementVisible, setElement];
}
