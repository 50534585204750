import { createSetFilterActionType, createSetSelectedActionType, createUpdateFilterActionType } from './actionTypes';

/**
 * Updates a filters `selected` attribute
 *
 * @param {string} key - filter key
 * @param {any} values - values to be added to the store
 */
export const setSelectedAction = (key, values) => ({
  type: createSetSelectedActionType(key),
  values,
});

/**
 * Sets a filter full object in the store,
 *
 * @param {string} key - filter key
 * @param {any} value - values to be set on the filter key store
 */
export const setFilterAction = (key, value) => ({
  type: createSetFilterActionType(key),
  value,
});

/**
 * Updates a filters overall object merging the values with the current store,
 *
 * @param {string} key - filter key
 * @param {any} value - values to be set on the filter key store
 */
export const updateFilterAction = (key, value) => ({
  type: createUpdateFilterActionType(key),
  value,
});
