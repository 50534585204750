import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '../Typography';

const BASE_COMPONENT_CLASS = 'checkbox-field';

/**
 * Checkbox
 *
 * @param {boolean} defaultTheme - By this property we force this spinner to use default color instead of auto theme colours.
 */
const Checkbox = ({ className, defaultTheme, label, disabled, indeterminate, value, onChange, ...props }) => {
  return (
    <label
      {...props}
      className={classnames(className, BASE_COMPONENT_CLASS, { [`${BASE_COMPONENT_CLASS}--disabled`]: disabled })}
    >
      <input
        className={classnames(`${BASE_COMPONENT_CLASS}__input`, {
          [`${BASE_COMPONENT_CLASS}__input--indeterminate`]: indeterminate,
          [`${BASE_COMPONENT_CLASS}__input--default-theme`]: defaultTheme,
        })}
        type="checkbox"
        indeterminate={indeterminate ? '' : undefined}
        onChange={(event) => onChange(!value, event)}
        checked={value}
        disabled={disabled}
      />
      {label && <Text className={`${BASE_COMPONENT_CLASS}__label`}>{label}</Text>}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.node,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  defaultTheme: PropTypes.bool,
};

Checkbox.defaultProps = {
  indeterminate: false,
  disabled: false,
  defaultTheme: false,
  onChange: () => {},
};

export default Checkbox;
