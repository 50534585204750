import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from '@components';

const ICON_ALIGN_LEFT = 'left';
const ICON_ALIGN_RIGHT = 'right';
const ICON_ALIGNMENTS = [ICON_ALIGN_LEFT, ICON_ALIGN_RIGHT];

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  icon: PropTypes.string,
  children: PropTypes.node,
  iconAlign: PropTypes.oneOf(ICON_ALIGNMENTS),
  disabled: PropTypes.bool,
  defaultTheme: PropTypes.bool,
};

const defaultProps = {
  className: '',
  type: 'button',
  onClick: () => {},
  primary: false,
  secondary: false,
  icon: null,
  children: null,
  iconAlign: ICON_ALIGN_LEFT,
  disabled: false,
  defaultTheme: false,
};

const componentName = 'button';

/**
 * Button
 * @param {str}  type       - actual type of the button.
 * @param {func} onClick    - callback
 * @param {str}  icon       - name of icon to use.
 * @param {bool} iconAlign  - Placement of icon with regards to the text
 * @param {bool} primary    - Adds Primary styling
 * @param {bool} secondary  - Adds secondary styling
 * @param {boolean} defaultTheme - By this property we force this spinner to use default color instead of auto theme colours.
 */
const Button = ({
  className,
  type,
  icon,
  children,
  onClick,
  primary,
  secondary,
  iconAlign,
  disabled,
  defaultTheme,
}) => {
  return (
    <button
      data-testid="Button"
      className={classnames(`${componentName} ${className}`, {
        [`${componentName}--primary`]: primary,
        [`${componentName}--secondary`]: secondary,
        [`${componentName}--default-theme`]: defaultTheme,
        [`${componentName}--disabled`]: disabled,
        [`${componentName}--has-icon-${iconAlign}`]: icon && children,
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && iconAlign === ICON_ALIGN_LEFT ? <Icon icon={icon} /> : null}
      {children}
      {icon && iconAlign === ICON_ALIGN_RIGHT ? <Icon icon={icon} /> : null}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
