import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUnavailableMetrics } from '@modules/TerritorySelect';

import { getAdvancedSalesAnalysisMetrics } from './helpers';

export const useAdvancedSalesPerspectiveMetrics = () => {
  const unavailableMetrics = useSelector(getUnavailableMetrics);

  return useMemo(() => {
    return getAdvancedSalesAnalysisMetrics().filter((metric) => !unavailableMetrics.includes(metric));
  }, [unavailableMetrics]);
};
