import { useSelector } from 'react-redux';

import { useAction, useAPIPath } from '@modules/Core';
import { useParametersListener } from '@modules/FilterTypes';

import { reloadSiteAttributeGroups } from './actions';
import { getSiteAttributeGroups, getIsLoadingSiteAttributeGroups, getSiteAttrGroupsParams } from './selectors';

export const useSiteAttributeGroupsLoader = () => {
  const loadAttributes = useAction(reloadSiteAttributeGroups);
  const [subSectionPath] = useAPIPath();

  useParametersListener(
    getSiteAttrGroupsParams,
    (params) => {
      loadAttributes(params, subSectionPath);
    },
    [loadAttributes, subSectionPath]
  );

  return [useSelector(getSiteAttributeGroups), useSelector(getIsLoadingSiteAttributeGroups)];
};

export const useSiteAttributeGroups = () => {
  useSiteAttributeGroupsLoader();

  return [useSelector(getSiteAttributeGroups), useSelector(getIsLoadingSiteAttributeGroups)];
};
