import React from 'react';
import PropTypes from 'prop-types';

import { BackToTop } from '@components';
import { TheatreList, TheatreSelectorButtons } from '@modules';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';
import ViewTransition from './ViewTransition';
import { useMarketPerspectiveMetrics } from '../../hooks';

const propTypes = {
  baseEventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteAttributeGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

const ViewSites = ({ baseEventId, siteAttributeGroupId }) => {
  const [eventParamKey] = useEventParamKey();
  const metrics = useMarketPerspectiveMetrics();
  return (
    <ViewTransition className="film-metadata__view film-metadata__view--theatres">
      <TheatreSelectorButtons hideAttributeSelector metrics={metrics} />
      <TheatreList
        className="film-metadata__theatres-list"
        params={{
          siteGroupAttributeId: [siteAttributeGroupId],
          [eventParamKey]: [baseEventId],
        }}
        ignoreSelectedAttributeGroup
        hasAside
      />
      <BackToTop />
    </ViewTransition>
  );
};

ViewSites.propTypes = propTypes;

export default ViewSites;
