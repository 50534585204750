import React from 'react';
import PropTypes from 'prop-types';

import { COMPONENT_BASE_CLASS } from '../constants';
function Content({ children }) {
  return <div className={`${COMPONENT_BASE_CLASS}__content`}>{children}</div>;
}

Content.propTypes = {
  children: PropTypes.node,
};

export default Content;
