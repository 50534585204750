import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {};
const defaultProps = {};

const AppMain = ({ children, ...props }) => (
  <main className="app-main" {...props}>
    {children}
  </main>
);

AppMain.propTypes = propTypes;
AppMain.defaultProps = defaultProps;

export default AppMain;
