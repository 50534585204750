/**
 * Calculates the capacity remaining for a metric
 *
 * @param {number} admissions admissions value
 * @param {number} capacity capacity value
 * Minimum returned value is zero.
 */
export const calculateCapacityRemaining = (admissions, capacity) => {
  const capacityRemaining = capacity - admissions;
  return capacityRemaining < 0 ? 0 : capacityRemaining;
};
