import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@components';
import { COMPONENT_BASE_CLASS } from '../constants';
import { SortBySelector } from '@modules';
import { FormattedMessage } from 'react-intl';

function PlannerViewActions({ isFullscreen, onClose, onExpand }) {
  return (
    <div className={`${COMPONENT_BASE_CLASS}__actions`}>
      {isFullscreen ? (
        <Button onClick={onClose} icon="express" className={'close-button'} />
      ) : (
        <Button onClick={onExpand} icon="expand" className={'expand-button'} />
      )}
      <SortBySelector
        theme="light"
        hideSort={true}
        showOccupancySlider
        buttonLabel={<FormattedMessage id={'occupancy'} />}
      />
    </div>
  );
}

PlannerViewActions.propTypes = {
  isFullscreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onExpand: PropTypes.func,
};

PlannerViewActions.defaultProps = {
  isFullscreen: false,
  onClose: () => {},
  onExpand: () => {},
};

export default PlannerViewActions;
