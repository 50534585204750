import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '../Typography';

const componentName = 'radio-button';

const ButtonLabel = ({ label }) => {
  return (
    <Text tag="span" size="m">
      {label}
    </Text>
  );
};

/**
 * Radio Button
 *
 * @param {string}  className custom className for external styling
 * @param {bool}    isChecked - Determines if this radio is checked.
 * @param {node}    label radio label text
 * @param {str}     labelAlign position of label relative to radio
 * @param {str}     name - Unique name given to RadioGroup, used as name attribute on radio input.
 * @param {func}    onChange - callback fired when any of the radio buttons is selected.
 * @param {bool}    spaceBetween By default the radio and label will align away from each other with a space between
 * @param {node}    value current active value for the radioGroup
 * @param {bool}    defaultTheme - Forced to use colours of default theme
 */
const Button = ({ label, labelAlign, spaceBetween, isChecked, value, name, onChange, className, defaultTheme }) => {
  const radioId = useRef(`radio-button-${value}`);
  const valueType = useRef(typeof value);

  /**
   * @param {object} event event.currentTarget.value - Radio inputs send back their value as a string.
   * @returns {string | number | boolean} - depending on the initial input value type.
   */
  const handleRadioSelected = (event) => {
    let selectedValue;
    switch (valueType.current) {
      case 'boolean':
        selectedValue = event.currentTarget.value.toLowerCase() === 'true';
        break;
      case 'number':
        selectedValue = parseInt(event.currentTarget.value, 10);
        break;
      case 'string':
      default:
        selectedValue = event.currentTarget.value;
    }

    onChange(selectedValue, event);
  };
  return (
    <label
      htmlFor={radioId.current}
      className={classnames(componentName, className, {
        [`${componentName}--space-between`]: spaceBetween,
      })}
    >
      {labelAlign === 'left' && <ButtonLabel label={label} />}
      <div className={classnames(`${componentName}__input`, { [`${componentName}--default-theme`]: defaultTheme })}>
        <input
          className={classnames(`${componentName}__input--real`)}
          type="radio"
          value={value}
          name={name || radioId.current}
          onChange={handleRadioSelected}
          id={radioId.current}
          checked={isChecked}
        />
        <span
          className={classnames(`${componentName}__input--fake`, {
            'is--checked': isChecked,
          })}
        />
      </div>
      {labelAlign === 'right' && <ButtonLabel label={label} />}
    </label>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  label: PropTypes.node,
  labelAlign: PropTypes.oneOf(['left', 'right']),
  name: PropTypes.string,
  onChange: PropTypes.func,
  spaceBetween: PropTypes.bool,
  value: PropTypes.node,
};

Button.defaultProps = {
  className: '',
  isChecked: false,
  label: null,
  labelAlign: 'left',
  name: '',
  onChange: () => {},
  spaceBetween: true,
  value: null,
};

export default React.memo(Button);
