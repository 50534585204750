import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  component: PropTypes.elementType,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  component: 'div',
  scrollable: false,
};

const OverlayContent = ({ component: Component, scrollable, className, ...props }) => (
  <Component
    {...props}
    className={classnames(className, 'overlay-content', { 'overlay-content--scrollable': scrollable })}
  />
);

OverlayContent.propTypes = propTypes;
OverlayContent.defaultProps = defaultProps;

export default OverlayContent;
