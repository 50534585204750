import { createReducer } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';

import { STATE_KEY, EMPTY_GROUPS } from './constants';
import { SET_SITE_ATTRIBUTE_GROUPS, CLEAR_SITE_ATTRIBUTE_GROUPS } from './actionTypes';

const reducer = createReducer(null, {
  [SET_SITE_ATTRIBUTE_GROUPS]: (_, { groups }) => (groups.length > 0 ? _sortBy(groups, 'name') : EMPTY_GROUPS),
  [CLEAR_SITE_ATTRIBUTE_GROUPS]: () => null,
});

export default { [STATE_KEY]: reducer };
