import React, { createContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import _isUndefined from 'lodash/isUndefined';
import _isFunction from 'lodash/isFunction';

const noop = () => {};
const initialState = {
  isOpen: false,
  isHeaderVisible: true,
  setIsHeaderVisible: noop,
  openDrawer: noop,
  closeDrawer: noop,
  toggleDrawer: noop,
};

const DrawerContext = createContext(initialState);

/**
 * Manages all the logic to do with opening/closing the drawer, exposes
 * status and helpers
 *
 * @param {boolean} isOpenProp isOpen prop passed into component
 * @param {func} hasOpened callback called when drawer has opened
 * @param {func} hasClosed callback called when drawer has closed
 */
const useIsOpenApi = (isOpenProp, hasOpened, hasClosed) => {
  const hasOpenProp = () => !_isUndefined(isOpenProp);
  const [isOpen, setIsOpen] = useState(hasOpenProp() ? isOpenProp : false);

  const openDrawer = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (hasOpenProp() && isOpenProp !== isOpen) {
      setIsOpen(isOpenProp);
    }
  }, [isOpenProp]);

  useEffect(() => {
    if (isOpen && _isFunction(hasOpened)) {
      hasOpened();
    } else if (!isOpen && _isFunction(hasClosed)) {
      hasClosed();
    }
  }, [isOpen]);

  return {
    isOpen,
    openDrawer,
    closeDrawer,
    toggleDrawer,
  };
};

function DrawerProvider({ children, isOpenProp, hasOpened, hasClosed }) {
  const isOpenApi = useIsOpenApi(isOpenProp, hasOpened, hasClosed);
  const [isHeaderVisible, setIsHeaderVisible] = useState(initialState.isHeaderVisible);

  return (
    <DrawerContext.Provider value={{ ...isOpenApi, isHeaderVisible, setIsHeaderVisible }}>
      {children}
    </DrawerContext.Provider>
  );
}

DrawerProvider.propTypes = {
  children: PropTypes.node,
  isOpenProp: PropTypes.bool,
  hasOpened: PropTypes.func,
  hasClosed: PropTypes.func,
};

export { DrawerContext, DrawerProvider };
