import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _noop from 'lodash/noop';
import { getShareObjectCompressed, getIsLoadingShareLink } from './selectors';
import { fetchShareLink } from './actions';

export default function useShareLink() {
  const dispatch = useDispatch();
  const share = useSelector(getShareObjectCompressed);
  const [shareLink, setShareLink] = useState(null);
  const isLoadingShareLink = useSelector(getIsLoadingShareLink);

  useEffect(() => {
    let onFinish = setShareLink;
    dispatch(fetchShareLink(share)).then(onFinish, onFinish);

    return () => {
      setShareLink(null);
      onFinish = _noop; // Ignore pending results if share data has changed
    };
  }, [share, dispatch]);

  return [shareLink, isLoadingShareLink];
}
