import { PERFORMANCE_DISPLAY_FORMAT } from '@modules/Core';

export const COMPONENT_BASE_CLASS = 'planner-view';

export const STATE_KEY = 'plannerView';

export const PERFORMANCES_FETCH_KEY = `${STATE_KEY}.fetchPerformances`;
export const PERFORMANCE_ATTRIBUTES_FETCH_KEY = `${STATE_KEY}.fetchPerformanceAttributes`;
export const PERFORMANCE_MODAL_DATA_FETCH_KEY = `${STATE_KEY}.fetchPerformanceModalData`;

export const SCREEN_SORTING_KEY = 'screenCode';

export const TIME_LINE_FORMAT = PERFORMANCE_DISPLAY_FORMAT;

/**
 * This constant has SCSS dependency
 * @variable $time-gutter
 * @file _plannerView.scss
 * */
export const GAP_HOUR_TIME_LINE = 80;
