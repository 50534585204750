import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useChart, useIsToday } from '@modules/Core';
import { getBaseEventId, useSelectedMetric, useCompColorPairs, BASE_COLOR } from '@modules/Comps';
import { useOverlayChartData, useChartValueAxisFormatter, useChartCategoryAxisFormatter } from '../hooks';
import { getOverlayConfig, getOverlaySeriesConfig, getDataField } from '../helpers';

function CompsChartFullScreen({ className }) {
  const [data] = useOverlayChartData();
  const baseId = useSelector(getBaseEventId);
  const [selectedMetric] = useSelectedMetric();
  const compColorPairs = useCompColorPairs();
  const isToday = useIsToday();

  const seriesConfig = useMemo(() => {
    const baseConfig = {
      dataField: getDataField(baseId, selectedMetric),
      color: BASE_COLOR,
    };
    const compsDataFields = compColorPairs.map(({ id, color }) => ({
      dataField: getDataField(id, selectedMetric),
      color: color,
    }));

    return getOverlaySeriesConfig(baseConfig, compsDataFields, isToday);
  }, [baseId, compColorPairs, selectedMetric, isToday]);

  const chartConfig = useMemo(() => {
    return { ...getOverlayConfig(isToday) };
  }, [isToday]);

  const { chartRef, setChartData, updateChartSeriesConfig, chart } = useChart(chartConfig);

  useEffect(() => {
    updateChartSeriesConfig(seriesConfig);
  }, [seriesConfig, updateChartSeriesConfig]);

  useEffect(() => {
    setChartData(data);
  }, [data, setChartData]);

  useChartValueAxisFormatter(chart, selectedMetric);
  useChartCategoryAxisFormatter(chart);

  return <div ref={chartRef} className={className}></div>;
}

export default CompsChartFullScreen;
