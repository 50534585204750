import { useSelector } from 'react-redux';
import {
  getReportingLastUpdated,
  getReportingFeedType,
  getReportingReportedSites,
  getReportingReportedSitesYesterday,
  getReportingRealTimeReportingSites,
} from '../selectors';

export const useSummaryData = () => {
  return {
    lastUpdated: useSelector(getReportingLastUpdated),
    feedType: useSelector(getReportingFeedType),
    reportedSites: useSelector(getReportingReportedSites),
    reportedSitesYesterday: useSelector(getReportingReportedSitesYesterday),
    realTimeSitesReporting: useSelector(getReportingRealTimeReportingSites),
  };
};
