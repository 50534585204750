import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { setTitleList, appendTitleList, resetTitleList, setMaxPagesReached, resetMaxPagesReached } from './actions';
import { STATE_KEY } from './constants';

const initialTitlesList = [];
const setTitles = (titlesArray) => titlesArray;
const appendTitles = (state, titlesArray) => [...state, ...titlesArray];
const resetTitles = () => initialTitlesList;

const titlesListReducer = createReducer(initialTitlesList, {
  [setTitleList.toString()]: (_, action) => setTitles(action.payload),
  [appendTitleList.toString()]: (state, action) => appendTitles(state, action.payload),
  [resetTitleList.toString()]: () => resetTitles(),
});

const initialMaxPagesReached = false;
const setMaxPages = (hasMaxPagesBeenReached) => hasMaxPagesBeenReached;
const resetMaxPages = () => initialMaxPagesReached;

const maxPagesReached = createReducer(initialMaxPagesReached, {
  [setMaxPagesReached.toString()]: (_, action) => setMaxPages(action.payload),
  [resetMaxPagesReached.toString()]: () => resetMaxPages(),
});

export default {
  [STATE_KEY]: combineReducers({
    titles: titlesListReducer,
    maxPagesReached: maxPagesReached,
  }),
};
