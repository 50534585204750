import { useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { configureAccounting } from '../helpers';
import { getSelectedTerritory } from '@modules/TerritorySelect';
import { getLocalCurrency } from '@modules/UserSettings';
import { DEFAULT_CURRENCY_ISO_CODE } from '@modules/Core';

const ConfigureAccounting = ({ children }) => {
  const { formatMessage } = useIntl();
  const isLocalCurrency = useSelector(getLocalCurrency);
  const territory = useSelector(getSelectedTerritory);
  const currency = useMemo(() => (isLocalCurrency ? territory.currency : DEFAULT_CURRENCY_ISO_CODE), [
    territory,
    isLocalCurrency,
  ]);

  const config = useMemo(
    () => ({
      userCurrency: currency,
      currencyFormat: formatMessage({ id: 'format_currency' }),
      decimalDelimiter: formatMessage({ id: 'format_decimal' }),
      thousandDelimiter: formatMessage({ id: 'format_thousand' }),
      abbreviations: {},
    }),
    [currency, formatMessage]
  );

  configureAccounting(config);

  useEffect(() => {
    configureAccounting(config);
  }, [config]);

  return null;
};

export default ConfigureAccounting;
