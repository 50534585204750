import { createAction } from '@reduxjs/toolkit';

import { asyncAction } from '@showtime/cea-dep-utility';

import { intercomApi } from '@modules/Core';
import { successBanner } from '@modules/NotificationBanner';

import { STATE_KEY } from './constants';

export const setUserProfile = createAction(`${STATE_KEY}/SET_PROFILE`);
export const resetUserProfile = createAction(`${STATE_KEY}/RESET_PROFILE`);
export const setLoginError = createAction(`${STATE_KEY}/SET_LOGIN_ERROR`);
export const setForgotPasswordSuccess = createAction(`${STATE_KEY}/SET_FORGOT_PASSWORD_SUCCESS`);
export const setResetPasswordSuccess = createAction(`${STATE_KEY}/SET_RESET_PASSWORD_SUCCESS`);
export const setUserProfileAttribute = createAction(`${STATE_KEY}/SET_PROFILE_ATTRIBUTE`, (attribute, value) => {
  return {
    payload: {
      attribute,
      value,
    },
  };
});

export function getMe() {
  return asyncAction({
    key: 'user.getMe',
    url: '/api/user/me',
    method: 'GET',
    success(profile) {
      this.dispatch(setUserProfile(profile));
    },
  });
}

export function loginRequest(email, password) {
  return asyncAction({
    key: 'user.loginRequest',
    url: '/api/user/login',
    method: 'POST',
    body: {
      email: email,
      password: password,
      rememberMe: true,
    },
    useDefaultErrorHandler: false,
    success() {
      this.dispatch(getMe());
    },
  });
}

export function logoutRequest() {
  return asyncAction({
    key: 'user.logoutRequest',
    url: '/api/user/logout',
    method: 'POST',
    useDefaultErrorHandler: false,
    success() {
      this.dispatch(resetUserProfile());
      localStorage.removeItem('comscore:reduxState');

      intercomApi('shutdown');
    },
  });
}

export function forgotPassword(email) {
  return asyncAction({
    key: 'user.forgotPassword',
    url: '/api/user/forgot-password',
    method: 'POST',
    body: {
      email,
    },
    success() {
      this.dispatch(setForgotPasswordSuccess());
    },
  });
}

export function resetPasswordRequest(password, key) {
  return (dispatch) => {
    dispatch(
      asyncAction({
        key: 'user.resetPassword',
        url: `/api/user/forgot-password/${key}`,
        method: 'POST',
        body: {
          password,
        },
        success() {
          this.dispatch(setResetPasswordSuccess());
        },
      })
    );
  };
}

export function updatePassword(oldPassword, newPassword) {
  return asyncAction({
    key: 'user.updatePassword',
    url: '/api/user/reset-password',
    method: 'POST',
    body: {
      oldPassword,
      password: newPassword,
    },
    success({ responseStatus }) {
      if (responseStatus === 202 || responseStatus === 200) {
        this.dispatch(successBanner('password_updated'));
      }
    },
  });
}
