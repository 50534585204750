export const KEY = 'sort';
export const KEY_METRIC = 'metric';

export const PARAM_KEY = 'sort';

export const ORDER_OPTION_ASC = 'asc';
export const ORDER_OPTION_DESC = 'desc';

export const DEFAULT_ORDER_OPTION = ORDER_OPTION_DESC;

// More action types.
export const SET_SORT_METRIC = 'SET_SORT_METRIC';
