import { createReducer } from '@reduxjs/toolkit';
import _findIndex from 'lodash/findIndex';
import { STATE_KEY, MAX_SELECTIONS } from './constants';
import {
  setSearchResults,
  setSuggestedTitles,
  selectCompTitle,
  deselectCompTitle,
  setSelectedComps,
  setNoResults,
  cleanNoResults,
} from './actions';

let initialState = {
  suggestedTitles: [],
  searchResults: [],
  selectedComps: [],
  noResults: false,
};

const compsSelectionDrawerReducer = createReducer(initialState, {
  [setSearchResults.toString()]: (state, action) => {
    state.searchResults = action.payload;
    return state;
  },
  [setSuggestedTitles.toString()]: (state, action) => {
    state.suggestedTitles = action.payload;
    return state;
  },
  [selectCompTitle.toString()]: (state, action) => {
    if (state.selectedComps.length < MAX_SELECTIONS) {
      state.selectedComps.push(action.payload);
    }

    return state;
  },
  [deselectCompTitle.toString()]: (state, action) => {
    const indexToRemove = _findIndex(state.selectedComps, { id: action.payload.id });

    if (indexToRemove > -1) {
      state.selectedComps.splice(indexToRemove, 1);
    }

    return state;
  },

  [setSelectedComps.toString()]: (state, action) => {
    state.selectedComps = action.payload;

    return state;
  },

  [setNoResults.toString()]: (state, action) => {
    state.noResults = true;

    return state;
  },
  [cleanNoResults.toString()]: (state, action) => {
    state.noResults = false;

    return state;
  },
});

export default {
  [STATE_KEY]: compsSelectionDrawerReducer,
};
