import React, { useEffect, useState, useLayoutEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isNull from 'lodash/isNull';
import { useSelector } from 'react-redux';

import { metricKeyPropType } from '@modules/Metrics';
import { useMetric } from '@modules/FilterTypes/metric';

import { BASE_CLASS } from '../constants';
import { ListItemStatic, ListItemSelectable } from './ListItem';
import ScrollIndicator from './ScrollIndicator';
import { hasDataMovement } from '../selectors';

const LAYOUT_OPTION_BLOCK = 'block';
const LAYOUT_OPTION_INLINE = 'inline';
const LAYOUT_OPTIONS = [LAYOUT_OPTION_BLOCK, LAYOUT_OPTION_INLINE];

const propTypes = {
  selectable: PropTypes.bool,
  metrics: PropTypes.arrayOf(metricKeyPropType),
  layout: PropTypes.oneOf(LAYOUT_OPTIONS),
};

const defaultProps = {
  selectable: false,
  metrics: [],
  inline: false,
  layout: 'block',
};

const scrollStartPos = 0;

const List = ({ selectable, metrics, layout }) => {
  const [metric, setMetric] = useMetric();
  const [activeMetric, setActiveMetric] = useState(metric);
  const isSoloList = metrics.length === 1;
  const hasMetricDataMovement = useSelector(hasDataMovement);

  const listRef = useRef(null);
  const listCtnRef = useRef(null);
  const scrollRef = useRef(null);

  const [isScrollIndicatorVisible, setIsScrollIndicatorVisible] = useState(null); // True if List element is wider than the current clientWidth.
  const [scrollEndPos, setScrollEndPos] = useState(null);
  const [activeIndicator, setActiveIndicator] = useState(0);

  useEffect(() => {
    setMetric(activeMetric);
  }, [activeMetric, setMetric]);

  // Making sure a default metric is selected when selectable is changed to true
  useEffect(() => {
    if (selectable && _isNull(metric)) {
      setActiveMetric(metrics[0]);
    }
  }, [selectable, metric, metrics]);

  useLayoutEffect(() => {
    if (listCtnRef.current && listRef.current && scrollRef.current) {
      // Get Scroll width to determine whether to show scroll indicator
      setIsScrollIndicatorVisible(listRef.current.scrollWidth > listCtnRef.current.clientWidth);

      // Set End Scroll Position
      setScrollEndPos(scrollRef.current.scrollWidth - listCtnRef.current.clientWidth);

      // Set height of metric list container based on the tallest list item + when it adds movements
      listCtnRef.current.style.height = `${listRef.current.clientHeight + (hasMetricDataMovement ? 10 : 0)}px`;
    }
  }, [listCtnRef, listRef, scrollRef, hasMetricDataMovement]);

  const onScroll = useCallback(
    (event) => {
      if (scrollEndPos !== event.target.scrollWidth - listCtnRef.current.clientWidth) {
        // if there happens to be a difference in scrollWidth, reset it once.
        setScrollEndPos(event.target.scrollWidth - listCtnRef.current.clientWidth);
      }

      if (event.target.scrollLeft === scrollStartPos) {
        setActiveIndicator(0);
      }

      if (event.target.scrollLeft === scrollEndPos) {
        setActiveIndicator(1);
      }
    },
    [scrollEndPos, listCtnRef]
  );

  return (
    <div
      className={classnames({
        [`${BASE_CLASS}-layout--inline`]: layout === LAYOUT_OPTION_INLINE,
        [`${BASE_CLASS}-layout--block`]: layout === LAYOUT_OPTION_BLOCK,
      })}
    >
      <div ref={listCtnRef} className={classnames(`${BASE_CLASS}__ctn`)}>
        <div className={classnames(`${BASE_CLASS}__scroll-ctn`)} onScroll={onScroll} ref={scrollRef}>
          <ul className={classnames(`${BASE_CLASS}__list`)} ref={listRef}>
            {metrics.map((metric, index) => {
              return selectable && !isSoloList ? (
                <ListItemSelectable
                  index={index}
                  isActive={selectable && activeMetric === metric}
                  key={metric}
                  metricKey={metric}
                  onSelectMetric={() => selectable && setActiveMetric(metric)}
                />
              ) : (
                <ListItemStatic key={metric} isSoloList={isSoloList} metricKey={metric} index={index} />
              );
            })}
          </ul>
        </div>
      </div>
      <ScrollIndicator isVisible={isScrollIndicatorVisible} activeIndicator={activeIndicator} />
    </div>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
