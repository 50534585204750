import { getRule, hasRule } from '@showtime/cea-dep-permissions';
import { SCREEN_RULE } from './constants';

export const hasExhibitorTheatrePerspective = () => hasRule(SCREEN_RULE);

export const getTheatreAnalysisMetrics = () => {
  let rules = [];

  getRule(
    SCREEN_RULE,
    (ruleProps) => {
      rules = ruleProps.metrics;
    },
    () => {
      console.error('Page metrics not found at screen rules.');
    }
  );

  return rules;
};
