import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BASE_CLASS } from './common';

const propTypes = {
  tag: PropTypes.elementType,
  className: PropTypes.string,
  bordered: PropTypes.bool,
  scrollable: PropTypes.bool,
};

const defaultProps = {
  tag: 'div',
};

const SectionContainerComponent = React.forwardRef(function SectionContainer(props, ref) {
  const { tag: Tag, className, bordered, scrollable, ...rest } = props;

  return (
    <Tag
      {...rest}
      ref={ref}
      className={classnames(className, `${BASE_CLASS}-container`, {
        [`${BASE_CLASS}-container--bordered`]: bordered,
        [`${BASE_CLASS}-container--scrollable`]: scrollable,
      })}
    />
  );
});

SectionContainerComponent.propTypes = propTypes;
SectionContainerComponent.defaultProps = defaultProps;

export default SectionContainerComponent;
