import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { gsap } from 'gsap';
import { Text, Button, HorizontalSwipeButtons } from '@components';
import { BASE_CLASS } from '../constants';
import { useBookmarksContext } from '../hooks';

const propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
};

const BookmarksListItem = ({ id, name, createdBy, editable, onClick, onEdit }) => {
  const { formatMessage } = useIntl();
  const { setDeleteConfirmId } = useBookmarksContext();

  let itemRef = useRef(null);

  const onClickBookmark = useCallback(() => {
    gsap.to(itemRef.current, {
      backgroundColor: '#dce7ef',
      duration: 0.1,
      repeat: 1,
      yoyo: true,
    });
    onClick(id);
  }, [onClick, id]);

  useEffect(() => {
    return () => (itemRef.current = null);
  }, []);

  return (
    <HorizontalSwipeButtons
      ref={itemRef}
      onClick={onClickBookmark}
      rightButtons={[
        <Button
          icon={'edit'}
          disabled={!editable}
          key={`${id}-action-edit`}
          className={`${BASE_CLASS}__list__actions--edit`}
          onClick={() => onEdit(id)}
        />,
        <Button
          icon={'delete'}
          disabled={!editable}
          key={`${id}-action-delete`}
          className={`${BASE_CLASS}__list__actions--delete`}
          onClick={() => setDeleteConfirmId(id)}
        />,
      ]}
    >
      <li ref={itemRef} className={`${BASE_CLASS}__list__item`}>
        <Text bold>{name}</Text>
        {createdBy && <Text>{`${formatMessage({ id: 'bookmarks.createdBy' })} ${createdBy}`}</Text>}
      </li>
    </HorizontalSwipeButtons>
  );
};

BookmarksListItem.propTypes = propTypes;

export default BookmarksListItem;
