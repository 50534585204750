import React, { useCallback, useState, forwardRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';
import _get from 'lodash/get';

import { Heading, Text, Card, IconCustom } from '@components';
import { COMPS_DATA_TYPE_REAL, compsPropType, useSelectedMetric, useCompColor } from '@modules/Comps';
import { isLoadingBaseEvent } from '@modules/Comps';

import { Stat, StatGroup } from './CompsStas';
import { useCardType } from '../hooks';

const DATE_STORAGE_FORMAT = 'YYYY-MM-DD';
const formatDate = (date) => moment(date, DATE_STORAGE_FORMAT).format('ddd, MMM D, Y');

const COLOR_CLASS_MAP = {
  '#fd7e14': 'bg-color--a',
  '#20c997': 'bg-color--b',
  '#ea78b7': 'bg-color--c',
  '#f5af25': 'bg-color--d',
};

const dataMap = {
  admissions: {
    dayValue: 'dayAdmissions',
    openingDay: 'admissionsOpeningDay',
    openingWeekend: 'admissionsOpeningWknd',
    totalRun: 'admissionsTotalRun',
  },
  eventRevenue: {
    dayValue: 'dayEventRevenue',
    openingDay: 'eventRevenueOpeningDay',
    openingWeekend: 'eventRevenueOpeningWknd',
    totalRun: 'eventRevenueTotalRun',
  },
  eventRevenueBO: {
    dayValue: 'dayEventRevenueBO',
    openingDay: 'eventRevenueOpeningDay',
    openingWeekend: 'eventRevenueOpeningWknd',
    totalRun: 'eventRevenueTotalRun',
  },
};

const getKey = (metric, value) => dataMap[metric][value];

const componentClass = 'comps-list__item';

const CompsListItem = forwardRef(function CompsListItem(props, ref) {
  const { id, name, imageUrl, dayDate, day, ...data } = props;
  const [selectedMetric] = useSelectedMetric();
  const [imageLoaded, setImageLoaded] = useState(false);
  const { formatMessage } = useIntl();
  const { getCardType, getCardTypeLabel } = useCardType();
  const isLoading = useSelector(isLoadingBaseEvent);

  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true);
  }, [setImageLoaded]);

  const color = useCompColor(id);

  const isShowtimeLogoVisible = getCardType() === COMPS_DATA_TYPE_REAL;

  const getDataValueByMetric = useCallback((key) => _get(data, getKey(selectedMetric, key), 0), [data, selectedMetric]);

  const marketDataNotTracked = useMemo(() => {
    let areDefaultValues = 0;
    ['openingDay', 'openingWeekend', 'totalRun'].forEach((key) => (areDefaultValues += getDataValueByMetric(key)));
    return areDefaultValues === 0;
  }, [getDataValueByMetric]);

  return (
    <Card ref={ref} className={componentClass}>
      <div className={`${componentClass}__bg ${COLOR_CLASS_MAP[color]}`}>
        {imageUrl && (
          <picture className="bg__picture">
            <img
              alt={name}
              className={classnames('bg__image', {
                'bg__image--is-loaded': imageLoaded,
              })}
              src={imageUrl}
              onLoad={handleImageLoaded}
            />
          </picture>
        )}
      </div>

      <div className={`${componentClass}__body`}>
        <Heading
          bold
          size="m"
          level={3}
          className={classnames(`${componentClass}__title`, { [`${componentClass}__title--skeleton`]: isLoading })}
        >
          {!isLoading && name}
        </Heading>

        <div className="stats">
          <div className={'stats__ctn stats__ctn--local'}>
            <StatGroup
              header={
                <>
                  <Text className="stat__comps-title" size="s" bold>
                    {formatMessage({ id: getCardTypeLabel() })}
                    {isShowtimeLogoVisible && <IconCustom icon="showtime-logo" className="stat__logo" />}
                  </Text>
                  <Text className="stat__date" tag="span" italic>
                    {dayDate && formatDate(dayDate)}
                  </Text>
                </>
              }
            >
              <Stat label={formatMessage({ id: 'day_n' }, { n: day })} value={getDataValueByMetric('dayValue')} />
            </StatGroup>
          </div>

          <div className="stats__ctn stats__ctn--comscore">
            <StatGroup
              header={
                <>
                  <Text size="s" bold>
                    {formatMessage({ id: 'comps_total_market_data' })}
                  </Text>
                  <IconCustom
                    icon="comscore-logo-with-bg"
                    className={classnames(`stat__logo`, { [`stat__logo stat__logo--skeleton`]: isLoading })}
                  />
                </>
              }
              marketDataNotTracked={!isLoading && marketDataNotTracked}
            >
              <Stat label={<FormattedMessage id="comps_opening_day" />} value={getDataValueByMetric('openingDay')} />
              <Stat
                label={<FormattedMessage id="comps_opening_weekend" />}
                value={getDataValueByMetric('openingWeekend')}
              />
              <Stat
                label={<FormattedMessage id="comps_opening_total_run" />}
                value={getDataValueByMetric('totalRun')}
              />
            </StatGroup>
          </div>
        </div>
      </div>
    </Card>
  );
});

CompsListItem.propTypes = {
  ...compsPropType,
};

export default CompsListItem;
