import { createSelector } from '@reduxjs/toolkit';

import { KEY, MA_EVENT_PARAM_KEY, EVENT_PARAM_KEY } from './constants';
import { URL_PATHS } from '@modules/Core';
import { getSelectedSelector } from '@modules/Filters';

export const getSelected = getSelectedSelector(KEY, []);

export const getParameters = createSelector([getSelected], (selected) => {
  const isMarketAnalysis = window.location.pathname.split('/')[1] === URL_PATHS.MARKETS;
  if (selected.length) {
    // TODO event param keys for advanced sales
    return { [isMarketAnalysis ? MA_EVENT_PARAM_KEY : EVENT_PARAM_KEY]: selected.map(({ id }) => id) };
  } else {
    return {};
  }
});
