import React, { useEffect, useCallback, useState, useRef, useImperativeHandle, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import { Text } from '@components';

import './_date-selector.scss';
import { useDates } from '@modules/FilterTypes/dates';
import { useResizeWidthChanged } from '@modules/Core';

import { SUBTEXT_FORMAT_LONG, SUBTEXT_FORMAT_SHORT } from './constants';
import { formatDate, getMinMaxNumDays } from './helpers';
import { Slider } from './Slider';
import { getStartOfCinemaWeekForSelectedTerritory } from '@modules/TerritorySelect';

const propTypes = {
  className: PropTypes.string,
  minDaysBefore: PropTypes.number,
  daysAfter: PropTypes.number,
};
const defaultProps = {
  className: '',
  minDaysBefore: null,
  daysAfter: null,
};

// Date selector default to be today.
const INITIAL_SLIDER_VALUE = formatDate(moment());

/**
 * checkIsInMinMaxRange
 * Callback fired from Slider to check if current value is between the correct range of min and max values.
 * In this case it needs to check dates between.
 * @param {string} selectedValue 'yyyy-mm-dd' format
 * @param {string} minValue 'yyyy-mm-dd' format
 * @param {string} maxValue 'yyyy-mm-dd' format
 *
 * 'day' -> limit the granularity to a unit other than milliseconds,
 * '[]' -> inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion.
 */
const checkIsInMinMaxRange = (selectedValue, minValue, maxValue) =>
  moment(selectedValue).isBetween(minValue, maxValue, 'day', '[]');

/**
 * setSlideValue
 * Slides are created using indexes.
 * This callback is fired everytime a slide is created or everytime the slider active/highlghted changes.
 * Takes the new slide index and the value of the initial slide
 * was, to determine what this new slide should be.
 * @param {number} slidePosition
 * @param {string} string e.g 'yyyy-mm-dd';
 */
const setSlideValue = (slidePosition, initialDate) => formatDate(moment(initialDate).add(slidePosition, 'days'));

const componentName = 'date-selector';
/**
 * DateSelector
 *
 * Features:
 * - Will display differing number of items depending on screen width.
 * - Will re-initlialise on width resize so it can re-calculate slidesPerView to display.
 * - Minimum and maximumDates can set to restrict the slider.
 *    - NB: Uses Keen-slider non-endless version now, therefore all items are rendered to the DOM.
 *    - As a result min and max dates shouldn't be too large.
 * - 'Today' text will be shown for today's date.
 * - onSliderActiveChange is only fired on animationEnd of the slider or if manual selection was applied.
 * - onClick manually of a date item, moves this to be the active date.
 * - prev() and next() methods are exposed so date-selector can be updated externally.
 * - active item will always be shown in centre of screen.
 *
 * @param {number} minDaysBefore - The number of days that are shown before today in the selector are determined
 * by what day today is in the current cinema week + this minDaysBefore value. The default number of days shown
 * before is 1 week + number of days already passed in this cinema week
 * @param {number} daysAfter - shows EXACTLY this amount of days after today in the slider.
 */
const DateSelectorComponent = React.forwardRef(function DateSelector({ className, minDaysBefore, daysAfter }, ref) {
  const startOfCinemaWeek = useSelector(getStartOfCinemaWeekForSelectedTerritory);

  const sliderRef = useRef(null);
  const resizerObserverRef = useRef(null);

  const today = formatDate(moment());

  const { minimumNumDaysBefore, maximumNumDaysAfter } = getMinMaxNumDays(startOfCinemaWeek, minDaysBefore, daysAfter);

  // plus 1 is the actual active day being included too.
  const [slidesInDOM, setSlidesInDOM] = useState(minimumNumDaysBefore + maximumNumDaysAfter + 1);

  useEffect(() => {
    if (typeof startOfCinemaWeek !== 'undefined') {
      setSlidesInDOM(minimumNumDaysBefore + maximumNumDaysAfter + 1);
      reInitSlider();
    }
  }, [startOfCinemaWeek]);

  // External selectedDates from the filters
  const [selectedDates, setSelected] = useDates();

  // Everytime this value is changed, the Slider will be re-initialised.
  const [sliderKeyIncrement, setSliderKeyIncrement] = useState(1);

  // This is the current day in the center as scrolling is taking place.
  // It is not neccesarily the final active date selected.
  const [currentDateHighlighted, setCurrentDateHighlighted] = useState(selectedDates[0]);

  // If the current day in the center as user is scrolling is today/yesterday/tomorrow, this will evaluate to true.
  // It is not neccesarily the final active date selected.
  const [hasMainDatesHighlighted, setHasMainDatesHighlighted] = useState(false);

  // minimumValue is always based off of today
  const minimumValue = useMemo(() => formatDate(moment(today).subtract(minimumNumDaysBefore, 'days')), [
    today,
    minimumNumDaysBefore,
  ]);

  // maximumValue is always based off of today
  const maximumValue = useMemo(() => formatDate(moment(today).add(maximumNumDaysAfter, 'days')), [
    today,
    maximumNumDaysAfter,
  ]);

  /**
   * getInitialSliderValue
   * Date - selector items should always be based off of today.
   * onInit check selectedDates filter.
   * @returns
   *  If there is no date in filters - return today.
   *  If there is a date in filders && it is within the minMaxRange -> return this date.
   *  If there is a date in filters && it is NOT within the minMaxRange -> return today.
   */
  const getInitialSliderValue = () => {
    if (selectedDates[0] && checkIsInMinMaxRange(selectedDates[0], minimumValue, maximumValue)) {
      return selectedDates[0];
    }
    return INITIAL_SLIDER_VALUE;
  };

  // onMounting of the Slider this is the initial starting value.
  // This should not change unless the slider is being re-initialised again.
  const [initialSliderValue, setInitialSliderValue] = useState(getInitialSliderValue());

  // Calculate activeIndex in relation to the position of the minimValue.
  // This will be used to center the activeValue initially.
  const initialActiveIndex = useMemo(() => moment(initialSliderValue).diff(moment(minimumValue), 'days'), [
    initialSliderValue,
    minimumValue,
  ]);

  // Re-initialise the slider.
  const reInitSlider = (newDate) => {
    const value = newDate || getInitialSliderValue() || INITIAL_SLIDER_VALUE;
    setSliderKeyIncrement(sliderKeyIncrement + 1);
    setInitialSliderValue(value);
  };

  const moveToNextSlide = useCallback(() => sliderRef.current.moveToNextSlide(), [sliderRef]);

  const moveToPrevSlide = useCallback(() => sliderRef.current.moveToPrevSlide(), [sliderRef]);

  useImperativeHandle(
    ref,
    () => ({
      moveToNextSlide,
      moveToPrevSlide,
      minimumDateValue: minimumValue,
      maximumDateValue: maximumValue,
      checkIsInMinMaxRange: (value) => checkIsInMinMaxRange(value, minimumValue, maximumValue),
    }),
    [moveToNextSlide, moveToPrevSlide, minimumValue, maximumValue]
  );

  //checkIsInMinMaxRange(value)();

  const onResizeCallback = () => {
    reInitSlider();
  };
  useResizeWidthChanged(resizerObserverRef, onResizeCallback);
  useResizeWidthChanged(null, onResizeCallback);

  /**
   * Fires only when the last slide in a swipe anim becomes active.
   * @param {string} selected date string in 'YYYY-MM-DD' format
   */
  const onSliderActiveChange = useCallback(
    (selected) => {
      if (selected && selected !== selectedDates[0]) {
        setSelected([selected, selected]);
      }
    },
    [setSelected, selectedDates]
  );

  /**
   * Fires every time the slider is swiped and a new slide is highlighted.
   * Check if today is highlighted.
   * If it is, show today's subtext below.
   * @param {string} value date string in 'YYYY-MM-DD' format
   */
  const onSliderMoveChange = useCallback((value) => {
    const isThisValToday = moment().isSame(moment(value), 'day');
    const isThisYesterday = moment().subtract(1, 'days').isSame(moment(value), 'day');
    const isThisTomorrow = moment().add(1, 'days').isSame(moment(value), 'day');

    // main dates: yesterday/today/tomorrow, check if one of them have been highlighted to show the full date
    const isOneMainDateHighlighted = isThisValToday || isThisYesterday || isThisTomorrow;
    setHasMainDatesHighlighted(isOneMainDateHighlighted);

    setCurrentDateHighlighted(value);
  }, []);

  const subtextDateFormat = hasMainDatesHighlighted ? SUBTEXT_FORMAT_LONG : SUBTEXT_FORMAT_SHORT;

  if (typeof startOfCinemaWeek === 'undefined') {
    return <div className={classnames(`${componentName} ${componentName}--empty`)} ref={resizerObserverRef} />;
  }
  return (
    <div className={classnames(componentName, className)} ref={resizerObserverRef}>
      <Slider
        ref={sliderRef}
        key={sliderKeyIncrement}
        className={classnames(`${componentName}__slider`)}
        initialActiveValue={initialSliderValue}
        initialActiveIndex={initialActiveIndex}
        minimumValue={minimumValue}
        maximumValue={maximumValue}
        slidesInDOM={slidesInDOM}
        onActiveChange={onSliderActiveChange}
        onMoveChange={onSliderMoveChange}
        setSlideValue={setSlideValue}
        checkIsInMinMaxRange={checkIsInMinMaxRange}
      />
      <Text tag="span" className={classnames(`${componentName}__subtext`)}>
        {moment(currentDateHighlighted).format(subtextDateFormat)}
      </Text>
    </div>
  );
});

DateSelectorComponent.propTypes = propTypes;
DateSelectorComponent.defaultProps = defaultProps;

export default DateSelectorComponent;
