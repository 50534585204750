import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './header-group.scss';

/**
 * Collection of components to achieve a common mobile design header pattern of:
 * - Central element (e.g. title) is always centered at the viewport
 * - There are side elements of varying width, that should not break central element's alignment.
 */

const propTypes = {
  className: PropTypes.string,
  tag: PropTypes.elementType,
};

// Those are plain block components of exact same signature, so turned them to a factory to DRY.
const makeHeaderGroupComponent = (name) => {
  let displayName = 'HeaderGroup';
  let className = 'header-group';
  let tag = 'header';
  if (name) {
    displayName += `.${name}`;
    className += `__${name.toLowerCase()}`;
    tag = 'section';
  }

  const Component = ({ tag: Tag, ...props }) => <Tag {...props} className={classnames(props.className, className)} />;

  Component.propTypes = propTypes;
  Component.defaultProps = { tag };
  Component.displayName = displayName;

  return Component;
};

const HeaderGroup = makeHeaderGroupComponent();
HeaderGroup.Left = makeHeaderGroupComponent('Left');
HeaderGroup.Center = makeHeaderGroupComponent('Center');
HeaderGroup.Right = makeHeaderGroupComponent('Right');

export default HeaderGroup;
