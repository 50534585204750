import _cloneDeep from 'lodash/cloneDeep';

/**
 *
 * @param {DataReference} dataReference Data item in the forma
 *
 * @type DataReference:
 * @property {Date} time the time value used in the chart
 */

export const isNow = (dataReference) => {
  const now = new Date();
  const hours = now.getHours();
  const hourNumber = dataReference.time.getHours();

  return hourNumber === hours;
};
/**
 *
 * Generates the config for an amcharts4 axisRange
 * that spans from now to the end of the day.
 * This range will convert the line of the targetSeries
 * to a dash array
 *
 * @param {string} targetSeries id for series to be "dashed"
 */
export const getDashLineRange = (targetSeries, now) => {
  let dashLineEnd = new Date();
  dashLineEnd.setDate(dashLineEnd.getDate() + 1);
  dashLineEnd.setHours(24);
  dashLineEnd.setMinutes(0);

  return _cloneDeep({
    series: targetSeries,
    date: now,
    endDate: dashLineEnd,
    grid: {
      visible: false,
    },
    contents: {
      strokeDasharray: '1,8',
      strokeLinecap: 'round',
    },
  });
};

//Common LineSeries config
export const seriesCommon = {
  type: 'LineSeries',
  strokeWidth: 3,
  tensionX: 1,
  showOnInit: false,
  bullets: [
    {
      type: 'CircleBullet',
      circle: {
        visible: false,
      },
    },
  ],
  dataItems: {
    locations: {
      // positions data item at the start of data cell.
      dateX: 0,
    },
  },
};

export const getDataField = (field, metric) => `${field}_${metric}`;
export const getFormattedField = (field) => `${field}_formatted`;
export const getMovementField = (field) => `${field}_movement`;
