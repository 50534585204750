import { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';

import { isEmail } from '@modules/Core';

const useField = (validator) => {
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const validate = useCallback(() => {
    const errorMessage = validator(value);

    setHasError(!!errorMessage.length);

    return errorMessage;
  }, [value, validator]);

  useEffect(() => {
    setErrorMessage(!!errorMessage.length);
  }, [errorMessage]);

  return [{ value, hasError, onChange }, validate];
};

export const useLoginForm = () => {
  const { formatMessage } = useIntl();
  const renderRequiredField = (value) => (_isEmpty(value) ? formatMessage({ id: 'required_field' }) : '');
  const emailValidator = (value) => {
    const requiredError = renderRequiredField(value);
    if (!_isEmpty(requiredError)) {
      return requiredError;
    }

    if (!isEmail(value)) {
      return formatMessage({ id: 'invalid_email' });
    }

    return '';
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [emailField, validateEmail] = useField(emailValidator);
  const [passwordField, validatePassword] = useField(renderRequiredField);

  const submitForm = (onSuccess) => {
    setErrorMessage('');

    const errors = [validatePassword(), validateEmail()];
    let globalError = '';

    errors.forEach((error) => {
      if (error.length) {
        globalError = error;
      }
    });

    if (globalError.length) {
      setErrorMessage(globalError);
    } else {
      onSuccess();
    }
  };

  return {
    errorMessage,
    emailField,
    passwordField,
    submitForm,
  };
};
