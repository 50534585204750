import { combineReducers, createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import moment from 'moment';

import { cleanUpComps } from '@modules/Comps';

import { STATE_KEY, TIME_FORMAT, BASE_TIME_RANGE } from './constants';
import { setEmbeddedChartData, setFullscreenChartData, setDateAxisMin, setDateAxisMax } from './actions';

const returnPayload = (_, { payload }) => payload;

const initialDateAxisRangeState = {
  minimum: moment(BASE_TIME_RANGE.start, TIME_FORMAT).toDate(),
  maximum: moment(BASE_TIME_RANGE.end, TIME_FORMAT).toDate(),
};

const dateAxisRangeReducer = createReducer(initialDateAxisRangeState, {
  [setDateAxisMin.toString()]: (state, { payload }) => {
    return update(state, { minimum: { $set: payload } });
  },
  [setDateAxisMax.toString()]: (state, { payload }) => {
    return update(state, { maximum: { $set: payload } });
  },
  [cleanUpComps.toString()]: () => initialDateAxisRangeState,
});

const reducerFactory = (initialState, setterAction) =>
  createReducer(initialState, {
    [setterAction]: returnPayload,
    [cleanUpComps.toString()]: () => initialState,
  });

const embeddedChart = reducerFactory([], setEmbeddedChartData.toString());
const fullscreenChartData = reducerFactory([], setFullscreenChartData.toString());

export default {
  [STATE_KEY]: combineReducers({
    dateAxisRange: dateAxisRangeReducer,
    embeddedChartData: embeddedChart,
    fullscreenChartData: fullscreenChartData,
  }),
};
