import React from 'react';
import { hasStudioFilmPerspective } from './helpers';
import { Route, Switch } from 'react-router-dom';

import { URL_PATHS } from '@modules/Core';

import { FilmAnalysis } from './FilmAnalysis/';
import { FilmMetadata } from './FilmMetadata/';

function Routes() {
  return (
    hasStudioFilmPerspective() && (
      <Switch>
        <Route path={`/${URL_PATHS.FILMS}`} exact component={FilmAnalysis} />
        <Route
          path={[`/${URL_PATHS.FILMS}/:id/:screen/:site_attribute_group_id/:site_attribute_id`, `/${URL_PATHS.FILMS}/:id/:screen`]}
          component={FilmMetadata}
        />
      </Switch>
    )
  );
}

export default Routes;
