import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import _maxBy from 'lodash/maxBy';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import { componentName } from './constants';
import Row from './Row';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
  idField: PropTypes.string,
};

function Chart({ data, idField, valueField, ...rowProps }) {
  const chartData = useMemo(() => _cloneDeep(data), [data]);
  const max = useMemo(() => {
    const max = _maxBy(chartData, valueField);
    return max ? max[valueField] : 0;
  }, [chartData, valueField]);

  const rowRenderer = useCallback(
    (dataItem, index) => {
      return (
        <Row key={dataItem[idField] || index} dataItem={dataItem} max={max} valueField={valueField} {...rowProps} />
      );
    },
    [rowProps, idField, max, valueField]
  );

  const sortFunction = useCallback(
    (a, b) => {
      return _get(b, valueField, 0) - _get(a, valueField, 0);
    },
    [valueField]
  );

  return (
    <table className={componentName}>
      <tbody>{chartData.sort(sortFunction).map(rowRenderer)}</tbody>
    </table>
  );
}

Chart.propTypes = propTypes;

export default Chart;
