import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import _uniq from 'lodash/uniq';

import { useParametersListener } from '@modules/FilterTypes';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';

import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useBaseEventMetadata } from '@modules/Comps';
import { useLastRefresh } from '@modules';

import { getEmbeddedChartData, isLoadingEmbeddedData, hasEmbeddedDataErrored, getChartParams } from '../selectors';
import { fetchEmbeddedChartData } from '../actions';

export const useEmbeddedChartDataFetcher = (metrics) => {
  const dispatchFetch = useAction(fetchEmbeddedChartData);
  const [baseEventMetadata] = useBaseEventMetadata();
  const lastRefresh = useLastRefresh();
  const [subSectionPath, dataPath] = useAPIPath();
  const [eventParamKey] = useEventParamKey();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getChartParams,
    (params) => {
      if (baseEventMetadata.id !== null) {
        if (!params[eventParamKey]) {
          params[eventParamKey] = [baseEventMetadata.id];
        } else {
          params[eventParamKey] = _uniq([baseEventMetadata.id, ...params[eventParamKey]]);
        }
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        dispatchFetch({ ...params, ...timeParams }, metrics, subSectionPath, dataPath);
      }
    },
    [baseEventMetadata.id, lastRefresh, metrics, subSectionPath, dataPath, isAdvancedSales, isToday]
  );
};

export const useEmbeddedChartData = () => {
  const { formatMessage } = useIntl();
  const isAdvancedSales = useIsAdvancedSales();
  const getDataSelector = useMemo(() => getEmbeddedChartData(isAdvancedSales, formatMessage), [isAdvancedSales, formatMessage]);
  const data = useSelector(getDataSelector);
  const isLoading = useSelector(isLoadingEmbeddedData);
  const error = useSelector(hasEmbeddedDataErrored);

  return [data, isLoading, error]
};
