import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useIsAdvancedSales, getTimeParams, useIsToday, useAPIPath, useAction } from '@modules/Core';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';
import { fetchSiteFormatData } from '../actions';
import { getParametersForTheatresFormats, isLoadingListItemFormats } from '../selectors';

/**
 * useFormatDataFetch
 * Check if data was already loaded.
 * @param {number} siteId - Current site id of selected card.
 * @param {array}  evid || pictureId array of numbers, event performance ids.
 * @param {bool}   isNullData - determines whether to fire for data or not if it was already loaded.
 */
export const useFormatDataFetch = (config) => {
  const [eventParamKey] = useEventParamKey();
  const fetchFormatData = useAction(fetchSiteFormatData);
  const params = useSelector(getParametersForTheatresFormats);
  const isFetching = useSelector(isLoadingListItemFormats(config.siteId));
  const [subSectionPath, dataPath] = useAPIPath(true, '');
  const isAdvancedSales = useIsAdvancedSales();
  const isToday = useIsToday();

  useEffect(() => {
    const { siteId, isNullData } = config;
    if (isNullData && !isFetching && config[eventParamKey]) {
      const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
      fetchFormatData(siteId, config[eventParamKey], { ...params, ...timeParams }, eventParamKey, subSectionPath, dataPath);
    }
  }, [subSectionPath, params, fetchFormatData, isFetching, eventParamKey, config, isToday, isAdvancedSales]);
};
