import React from 'react';
import PropTypes from 'prop-types';

import { Text, Icon } from '@components';

const propTypes = {
  text: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

const SidebarMenuButton = ({ onClick, icon, text }) => {
  return (
    <button onClick={onClick} className="sidebar-menu__button sidebar-menu__item">
      {icon && <Icon icon={icon} />}
      {text && (
        <Text tag="span" size="l" bold>
          {text}
        </Text>
      )}
    </button>
  );
};

SidebarMenuButton.propTypes = propTypes;

export default SidebarMenuButton;
