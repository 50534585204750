import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { COMPONENT_BASE_CLASS } from './constants';
import { Text, Icon } from '@components';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import './_selectable-list.scss';

/**
 * Lists
 * Rendering reusable lists at user-settings component.
 *
 * @param className {string} - In case you want to bespoke this component.
 * @param icon {string} - icon to at left of the item.
 * @param data {array} - Data given to render as many lines are need.
 * @param value {any} - Default value.
 * @param valueKey {string} - Matches the key value at data to use the value.
 * @param labelKey {string} - Matches the key value at data to show the text (using formatMessage)
 * @param labelFormatter {function} - The parent component can shape the text shown by this function.
 * @param onChange {function} - Return the value selected anytime the user changes it.
 */
const SelectableList = ({ className, data, value, valueKey, labelKey, labelFormatter, icon, onChange }) => {
  const { formatMessage } = useIntl();
  const [listLocalValues, setListLocalValues] = useState(value);

  const getTextValue = (item) => {
    if (typeof labelFormatter === 'function') return labelFormatter(item);
    else if (labelKey !== '') return formatMessage({ id: item[labelKey] });
    return ' - ';
  };

  const isSelected = useCallback((item) => item && item[valueKey] === listLocalValues, [listLocalValues]);

  const setValueAndNotify = useCallback((item) => {
    setListLocalValues(item);
    onChange(item);
  });

  return (
    <div className={classnames(COMPONENT_BASE_CLASS, className)}>
      {data.map((item, index) => (
        <div
          key={`list-${index}`}
          onClick={() => setValueAndNotify(item[valueKey])}
          className={classnames(`${COMPONENT_BASE_CLASS}__item`, { ['selected']: isSelected(item) })}
        >
          <Text bold={isSelected(item)}>{getTextValue(item)}</Text>
          {icon && isSelected(item) && <Icon icon={icon} />}
        </div>
      ))}
    </div>
  );
};

SelectableList.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  data: PropTypes.array,
  value: PropTypes.any,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  labelFormatter: PropTypes.func,
  onChange: PropTypes.func,
};

SelectableList.defaultProps = {
  className: '',
  data: [],
  value: null,
  onChange: () => {},
};

export default SelectableList;
