import _cloneDeep from 'lodash/cloneDeep';

import { BASE_SERIES_ID } from '../constants';

import { getDashLineRange, seriesCommon, isNow } from './commonConfig';
import { getCoreConfig } from './getCoreConfig';

const TIME_KEY = 'time';

export const getEmbeddedSeriesConfig = (baseSeriesConfig, compsSeries = [], isToday) => {
  return [
    {
      ...seriesCommon,
      id: BASE_SERIES_ID,
      stroke: baseSeriesConfig.color,
      dataFields: {
        valueY: baseSeriesConfig.dataField,
        dateX: TIME_KEY,
      },
      bullets: [
        {
          type: 'CircleBullet',
          circle: {
            opacity: 0,
            radius: 3,
            fill: '#fff',
            adapter: [
              {
                key: 'opacity',
                callback: (_, target) => {
                  const data = target.dataItem.dataContext;

                  if (data) {
                    return isToday && isNow(data) ? 1 : 0;
                  } else {
                    return 0;
                  }
                },
              },
            ],
          },
        },
      ],
    },
    ...compsSeries.map(({ dataField, color }) => {
      return {
        ...seriesCommon,
        stroke: color,
        dataFields: {
          valueY: dataField,
          dateX: TIME_KEY,
        },
      };
    }),
  ];
};

export const getAdvancedSalesEmbeddedSeriesConfig = (baseSeriesConfig, compsSeries = [], isToday, metricKey) => {
  let config = getEmbeddedSeriesConfig(baseSeriesConfig, compsSeries, isToday);
  config = config.map((item) => {
    item.dataFields.categoryX = 'id';
    return item;
  });
  return config;
};

const core = getCoreConfig();
const commonEmbeddedConfig = {
  ...core,
  properties: {
    paddingTop: 4,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
};

export const getEmbeddedAdvancedSalesConfig = () => {
  const embeddedAdvancedSalesChartConfig = {
    ...commonEmbeddedConfig,
    xAxes: core.xAxes.map((axis) => ({
      ...axis,
      type: 'CategoryAxis',
      dataFields: {
        value: 'value',
        category: 'id',
      },
    })),
  };

  embeddedAdvancedSalesChartConfig.xAxes[0].renderer.minGridDistance = 25;
  return _cloneDeep(embeddedAdvancedSalesChartConfig);
};

export const getEmbeddedConfig = (isToday) => {
  let now = new Date();
  now.setMinutes(0);

  const axisRanges = isToday
    ? [
        getDashLineRange(BASE_SERIES_ID, now),
        {
          date: now,
          endDate: now,
          grid: {
            stroke: '#fff',
            strokeWidth: 1,
            strokeOpacity: 0.9,
            strokeDasharray: '2,2',
          },
        },
      ]
    : [];

  const embeddedChartConfig = {
    ...commonEmbeddedConfig,
    xAxes: commonEmbeddedConfig.xAxes.map((axis) => ({
      type: 'DateAxis',
      dataFields: {
        category: 'time',
      },
      renderer: {
        location: 0.0001,
      },
      ...axis,
    })),
    axisRanges: axisRanges,
    // data is spaced every 15 minutes.
    baseInterval: { timeUnit: 'minute', count: 15 },
    // should prevent zooming to a state where you'd see less than hour worth of data.
    // (4 * 15minutes data increment)
    minZoomCount: 4,
  };

  // display the same x-axes points as the full screen chart
  embeddedChartConfig.xAxes[0].gridIntervals = [
    { timeUnit: 'hour', count: 1 },
    { timeUnit: 'hour', count: 2 },
    { timeUnit: 'hour', count: 3 },
  ];
  embeddedChartConfig.xAxes[0].renderer.minGridDistance = 35;

  return _cloneDeep(embeddedChartConfig);
};
