import React, { forwardRef, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import classnames from 'classnames';
import { Section, Spinner } from '@components';
import { BASE_CLASS, SETTINGS_PAGE } from '../constants';
import UserSettingsHeader from './UserSettingsHeader';
import SelectableList from './SelectableList';
import { updateUserLanguage } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '@modules/Auth';
import { getAvailableLanguages, getUserLocale, isUpdatingUserSettings } from '../selectors';

const componentClass = `${BASE_CLASS}-language`;

const UserSettingsLanguage = forwardRef(function UserSettingsLanguage(props, ref) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userLocale = useSelector(getUserLocale);
  const userProfile = useSelector(getUserProfile);
  const availableLanguages = useSelector(getAvailableLanguages);
  const isUpdating = useSelector(isUpdatingUserSettings);

  const userLanguage = useMemo(() => _find(availableLanguages, { isoCode: userLocale }), [
    availableLanguages,
    userLocale,
  ]);

  const setUserDefaultLanguage = useCallback(
    (value) => {
      const { email } = userProfile;
      const { isoCode } = _find(availableLanguages, { id: value });

      dispatch(updateUserLanguage(email, value, isoCode));
    },
    [dispatch, userProfile, availableLanguages]
  );

  return (
    <Section.Container
      {...props}
      className={classnames(props.className, componentClass, { [`${BASE_CLASS}-main--loading`]: isUpdating })}
      ref={ref}
      bordered
      tag="section"
    >
      <UserSettingsHeader
        prevIcon="back"
        prevPage={SETTINGS_PAGE.SETTINGS}
        title={formatMessage({ id: 'user_settings.language' })}
      />
      <Section.Container>
        <SelectableList
          data={availableLanguages}
          value={userLanguage.id}
          valueKey={'id'}
          labelKey={'isoCode'}
          icon={'check'}
          onChange={setUserDefaultLanguage}
        />
      </Section.Container>
      {isUpdating && <Spinner />}
    </Section.Container>
  );
});

UserSettingsLanguage.propTypes = {
  className: PropTypes.string,
};

export default UserSettingsLanguage;
