import { useMemo } from 'react';
import moment from 'moment';
import { useDates } from '@modules/FilterTypes/dates';

const FILTER_DATE_FORMAT = 'YYYY-MM-DD';

export function useIsHistorical() {
  const [dates] = useDates();

  return useMemo(() => {
    return moment(dates[0], FILTER_DATE_FORMAT).isBefore(moment(), 'd');
  }, [dates]);
}

export function useIsFuture() {
  const [dates] = useDates();

  return useMemo(() => {
    return moment(dates[0], FILTER_DATE_FORMAT).isAfter(moment(), 'd');
  }, [dates]);
}

export function useIsToday() {
  const [dates] = useDates();

  return useMemo(() => {
    return moment().format(FILTER_DATE_FORMAT) === dates[0];
  }, [dates]);
}

export function useIsTodayOrFuture() {
  const [dates] = useDates();

  return useMemo(() => {
    return moment(dates[0], FILTER_DATE_FORMAT).isSameOrAfter(moment(), 'd');
  }, [dates]);
}

export function useIsTodayOrHistorical() {
  const [dates] = useDates();

  return useMemo(() => {
    return moment(dates[0], FILTER_DATE_FORMAT).isSameOrBefore(moment(), 'd');
  }, [dates]);
}
