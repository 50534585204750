export const BASE_CLASS = 'user-settings';

export const STATE_KEY = 'userSettings';

export const SETTINGS_PAGE = {
  SETTINGS: 'settings',
  PASSWORD: 'password',
  LANGUAGE: 'language',
  CURRENCY: 'currency',
  TIME_FORMAT: 'timeFormat',
  TITLE_PREFERENCE: 'titlePreference',
  DEFAULT_TERRITORY: 'defaultTerritory',
};

export const FETCH_UPDATE_KEY = 'user.update';

export const FETCH_UPDATE_PASSWORD = 'user.updatePassword';

export const USER_SETTING_KEYS = {
  timeFormat: 'timeFormat',
  language: 'language',
  languageId: 'languageId',
  localCurrency: 'localCurrency',
  localTitle: 'localTitle',
  availableLanguages: 'availableLanguages',
};
