import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { HorizontalSwipe, Icon } from '@components';

import { getFullCompsList } from '@modules/Comps';
import { useCompsTitles, getSelected } from '@modules/FilterTypes/compsTitles';
import { useLockBodyScroll } from '@modules/Core/hooks';

import { CompsListItem } from './components';

import './_comps-list.scss';
import { useSelector } from 'react-redux';

const SLIDER_BG_COLOR = '#f15e5e';

const propTypes = { className: PropTypes.string };
const defaultProps = {};

const CompsList = ({ className }) => {
  const compItemRef = useRef(null);
  const data = useSelector(getFullCompsList);
  const selectedTitles = useSelector(getSelected);

  const [, , removeComp] = useCompsTitles();
  const [isSwiping, setIsSwiping] = useState(false);

  const isTitleIdSelected = useCallback((titleId) => !!selectedTitles.find(({ id }) => id === titleId), [
    selectedTitles,
  ]);

  useLockBodyScroll(isSwiping);

  return (
    <div className={classnames(`comps-list ${className}`)}>
      {data.map((data, index) => {
        if (index === 0) {
          return <CompsListItem key={data.id} {...data} />;
        }

        const onCompSwipe = () => {
          removeComp(data.id);
          setIsSwiping(false);
        };

        if (isTitleIdSelected(data.id)) {
          return (
            <HorizontalSwipe
              key={data.id}
              iconSize={'sm'}
              ref={compItemRef}
              bounds={{ left: -250 }}
              rightAction
              rightColor={SLIDER_BG_COLOR}
              rightIcon={<Icon icon={'remove'} />}
              onSwipeStarts={() => setIsSwiping(true)}
              onSwipeEnds={() => setIsSwiping(false)}
              hasMarginBottom
              className={'card'}
              onSwipe={onCompSwipe}
            >
              <CompsListItem {...data} ref={compItemRef} />
            </HorizontalSwipe>
          );
        }

        return null;
      })}
    </div>
  );
};

CompsList.propTypes = propTypes;
CompsList.defaultProps = defaultProps;

export default CompsList;
