import { useStudioHelpers } from '@pages/studio/hooks';
import { useIsAdvancedSales, useIsHistorical, useIsTodayOrFuture } from '@modules/Core';

/**
 * useCheckReportingView
 * Returns booleans on what type of reporting data should currently be shown to the report indicator.
 */
const useCheckReportingView = () => {
  const isAdvancedSales = useIsAdvancedSales();
  if (isAdvancedSales) {
    return { isNonRealTimeReportingView: false, isRealTimeReportingView: true };
  }

  const { isIntegrated } = useStudioHelpers();
  const isHistorical = useIsHistorical();
  const isTodayOrFuture = useIsTodayOrFuture();

  const isNonRealTimeReportingView = !isIntegrated || (isIntegrated && isHistorical);
  const isRealTimeReportingView = !isNonRealTimeReportingView && isTodayOrFuture;

  return { isNonRealTimeReportingView, isRealTimeReportingView };
};

export default useCheckReportingView;
