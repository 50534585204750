import { asyncAction, buildQuery } from '@showtime/cea-dep-utility';

import _isFunction from 'lodash/isFunction';
import { API_ROOT_PATH, decompressJSON } from '@modules/Core';
import { updateFilterAction } from '@modules/Filters';
import {
  APPEND_BOOKMARKS_DATA,
  CLEAR_BOOKMARKS_DATA,
  DELETE_BOOKMARK_ID,
  SET_BOOKMARK_SELECTED,
  SET_BOOKMARKS_DATA,
} from './actionTypes';
import {
  BOOKMARKS_FETCH_DELETE_KEY,
  BOOKMARKS_FETCH_KEY,
  BOOKMARKS_FETCH_KEY_LAZY,
  BOOKMARKS_FETCH_UPDATE_KEY,
} from './constants';
import { getDefaultParams } from './helpers';

export const setSelected = (value) => ({
  type: SET_BOOKMARK_SELECTED,
  key: 'selected',
  value,
});

export const setBookmarksData = (key, value) => ({
  type: SET_BOOKMARKS_DATA,
  key,
  value,
});

export const appendBookmarksData = (key, value) => ({
  type: APPEND_BOOKMARKS_DATA,
  key,
  value,
});

export const clearBookmarksData = (key) => ({
  type: CLEAR_BOOKMARKS_DATA,
  key,
});

export const deleteBookmarkId = (key, value) => ({
  type: DELETE_BOOKMARK_ID,
  key,
  value,
});

/**
 * Setting up bookmarks values at filters.
 * We decompress the filter property at bookmarks object.
 * Then we Dispatch an action for each filter saved at the bookmark.
 * */
export const loadBookmarkFilters = (compressedFilters) => (dispatch) => {
  const filters = decompressJSON(compressedFilters) || {};
  Object.entries(filters).map(([filterKey, values]) => {
    dispatch(updateFilterAction(filterKey, values));
  });
};

export const fetchBookmarks = (privacy, params, isLazyLoad) => {
  const queryParams = buildQuery({
    ...getDefaultParams(),
    ...params,
  });
  return asyncAction({
    key: isLazyLoad ? BOOKMARKS_FETCH_KEY_LAZY : BOOKMARKS_FETCH_KEY,
    url: `${API_ROOT_PATH}/bookmarks${queryParams}`,
    success(response) {
      delete response.responseStatus;

      if (isLazyLoad) this.dispatch(appendBookmarksData(privacy, response));
      else this.dispatch(setBookmarksData(privacy, response));
    },
  });
};

export const createBookmark = (params, onDone) => {
  return asyncAction({
    key: BOOKMARKS_FETCH_UPDATE_KEY,
    url: `${API_ROOT_PATH}/bookmarks`,
    method: 'POST',
    body: {
      ...getDefaultParams(),
      ...params,
    },
    success(response) {
      delete response.responseStatus;
      if (_isFunction(onDone)) {
        onDone(response);
      }
      return response;
    },
  });
};

export const updateBookmark = (params, id, onDone) => {
  return asyncAction({
    key: BOOKMARKS_FETCH_UPDATE_KEY,
    url: `${API_ROOT_PATH}/bookmarks/${id}`,
    method: 'PUT',
    body: {
      ...getDefaultParams(),
      ...params,
    },
    success(response) {
      if (_isFunction(onDone)) {
        onDone();
      }
      return response;
    },
  });
};

export const deleteBookmark = (id, onDone) => {
  return asyncAction({
    key: BOOKMARKS_FETCH_DELETE_KEY,
    url: `${API_ROOT_PATH}/bookmarks/${id}`,
    method: 'DELETE',
    success(response) {
      if (_isFunction(onDone)) {
        onDone();
      }
      return response;
    },
  });
};
