import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './_toggle-button.scss';

const propTypes = {
  disabled: PropTypes.bool,
  isToggled: PropTypes.bool,
  className: PropTypes.string,
  defaultTheme: PropTypes.bool,
  onChange: PropTypes.func,
};
const defaultProps = {
  disabled: false,
  isToggled: false,
  className: '',
  defaultTheme: false,
  onChange: () => {},
};

const componentName = 'toggle-button';

const ToggleButton = ({ isToggled, className, defaultTheme, onChange, disabled }) => {
  const [isToggledInternal, setCheckedInternal] = useState(isToggled);

  const onToggle = useCallback(() => {
    if (!disabled) {
      setCheckedInternal(!isToggledInternal);
      onChange(!isToggledInternal);
    }
  }, [isToggledInternal, onChange, disabled]);

  useEffect(() => {
    if (isToggledInternal !== isToggled) {
      setCheckedInternal(isToggled);
    }
  }, [isToggled, isToggledInternal]);

  return (
    <div
      className={classnames(
        componentName,
        {
          [`${componentName}--disabled`]: disabled,
        },
        className
      )}
    >
      <label className={classnames({ 'default-theme': defaultTheme })}>
        <input type="checkbox" checked={isToggled} onChange={onToggle} disabled={disabled} />
        <span className={'slider round'} />
      </label>
    </div>
  );
};

ToggleButton.propTypes = propTypes;
ToggleButton.defaultProps = defaultProps;

export default ToggleButton;
