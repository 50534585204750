import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { useAppContextState } from '@modules';
import { getTimeParams, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';

import { titleListRequest } from '../actions';
import { getParamsForTitleListFetch } from '../selectors';

/**
 * useDataFetchAndAppend
 *
 * When page number chnages
 * Increment page number.
 * Keep search
 * Append to titleslist
 */
export const useDataFetchAndAppend = ({ globalParams, metrics, pageNumber, additionalFilters }) => {
  const dispatch = useDispatch();
  /**
   * Note: Purposely did not add these to the useEffect depandancy list
   * globalParams, pageSearch, paramsForTitlesListFetch, metrics, defaultSortMetric
   * If pageNumber changes it should just load next page with the exact same parameters.
   * If pageSearch or params change, the other useFetchDataAfterFilterSearchChanges will take over.
   */
  const { pageSearch } = useAppContextState();
  const [subSectionPath, dataPath] = useAPIPath();
  const paramsForTitlesListFetch = useSelector(getParamsForTitleListFetch(additionalFilters));
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useEffect(() => {
    if (pageNumber > 1) {
      const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
      dispatch(
        titleListRequest({
          metrics,
          params: { ...paramsForTitlesListFetch, ...timeParams },
          search: pageSearch,
          pageNumber,
          globalParams,
          subSectionPath,
          dataPath,
          excludeDeltas: isAdvancedSales,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber, subSectionPath, dataPath, isAdvancedSales, isToday]);
};
