import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

function useDeepCompareMemoize(value, debug) {
  const ref = useRef([]);

  if (!_isEqual(value, ref.current)) {
    if (debug) {
      ref.current.forEach((dep, index) => {
        if (!_isEqual(dep, value[index])) {
          console.log('-----');
          console.log('Dependency Updated');

          console.log('Old Value', _cloneDeep(dep));
          console.log('New Value', _cloneDeep(value[index]));
        }
      });
    }

    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffect(callback, dependencies, debug) {
  useEffect(callback, useDeepCompareMemoize(dependencies, debug));
}

/**
 * Uses a selector to read the parameters from the store, and then feeds those parameters into a
 * callback function
 *
 * @param {function} paramsSelector selector that reads the filter params from the store
 * @param {function} callback callback which is executed any time the parameters update
 * @param {any[]} dependencies additional dependencies that will trigger the update
 */
export const useParametersListener = (paramsSelector, callback, dependencies = [], debug = false) => {
  const params = useSelector(paramsSelector);

  useDeepCompareEffect(() => callback(params), [params, ...dependencies], debug);
};
