import React, { useEffect, useCallback, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector, connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { errorByPattern } from '@showtime/cea-dep-utility';
import _get from 'lodash/get';

import { PasswordField, Alert, Text, Button } from '@components';
import { resetPasswordRequest, getHasResetPasswordSuccess, AuthLayout } from '@modules/Auth';
import { getError, getHttpError } from './helpers';

const componentName = 'auth-reset';

const mapStateToProps = (state) => ({
  resetPasswordError: errorByPattern(state, 'user.resetPassword'), // token error
});

const enhance = connect(mapStateToProps);

const Reset = (props) => {
  const dispatch = useDispatch();
  const getToken = () => _get(props, 'match.params.token');
  const key = getToken();

  const [validationError, setValidationError] = useState({ status: undefined });
  const [userPassword, setUserPassword] = useState('');
  const [userConfirmPassword, setUserConfirmPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const httpError = useMemo(() => getHttpError(props.resetPasswordError), [props.resetPasswordError]);

  useEffect(() => {
    // If resetPasswordError has changed it means that the request is complete
    setIsSubmitted(false);
  }, [props.resetPasswordError]);

  const hasResetPasswordSuccess = useSelector(getHasResetPasswordSuccess);

  const onSetPassword = (action) => (ev) => {
    action(ev.target.value);
  };

  useEffect(() => {
    setIsSubmitted(false);
  }, [userPassword, userConfirmPassword]);

  const hasValidationOrHttpError = () => {
    return !!validationError.status || !!httpError.status;
  };

  const onSubmit = useCallback(() => {
    const validationError = getError(userPassword, userConfirmPassword);
    setValidationError(validationError);
    if (!validationError.status && !isSubmitted) {
      setIsSubmitted(true);
      dispatch(resetPasswordRequest(userPassword, key));
    }
  }, [dispatch, isSubmitted, userPassword, userConfirmPassword, key]);

  if (hasResetPasswordSuccess) {
    return <Redirect to={{ pathname: '/user/login' }} />;
  }

  const renderAlert = () => {
    if (validationError.status) {
      return <Alert type={validationError.status} alertMessage={<FormattedMessage id={validationError.message} />} />;
    } else if (httpError.status) {
      return <Alert type={httpError.status} alertMessage={<FormattedMessage id={httpError.message} />} />;
    }
    return null;
  };
  const hasError = hasValidationOrHttpError();

  return (
    <AuthLayout.Container>
      {renderAlert()}
      <Text className={`${componentName}__title`} size="m" bold>
        <FormattedMessage id="reset_your_password" />
      </Text>
      <div className={`${componentName}__form-group`}>
        <PasswordField
          className={`${componentName}__form-field`}
          label={<FormattedMessage id="new_password" />}
          value={userPassword}
          hasError={hasError}
          onChange={onSetPassword(setUserPassword)}
        />
        <PasswordField
          className={`${componentName}__form-field`}
          label={<FormattedMessage id="confirm_new_password" />}
          value={userConfirmPassword}
          hasError={hasError}
          onChange={onSetPassword(setUserConfirmPassword)}
          subtext={<FormattedMessage id="confirm_password_subtext" />}
        />
        <Button primary defaultTheme className="auth-button" onClick={onSubmit}>
          <Text tag="span" size="m">
            <FormattedMessage id="reset_password" />
          </Text>
        </Button>
      </div>
    </AuthLayout.Container>
  );
};

Reset.propTypes = {
  resetPasswordError: propTypes.object,
};

export default enhance(Reset);
