import { createSelector } from '@reduxjs/toolkit';
import _isObject from 'lodash/isObject';
import _property from 'lodash/property';

import { KEY, SELECTED_PARAM_KEY, EXCLUDED_PARAM_KEY } from './constants';
import { getSelectedSelector, createAttributeSelector } from '@modules/Filters';

export const getSelected = getSelectedSelector(KEY, []);
export const getIsExcluded = createAttributeSelector(KEY, 'isExcluded', false);
export const getIsDisabled = createAttributeSelector(KEY, 'isDisabled', false);

export const getParameters = createSelector(
  [getSelected, getIsExcluded, getIsDisabled],
  (selected, isExcluded, isDisabled) => {
    if (selected !== null && !isDisabled) {
      const selectedIds = !_isObject(selected[0]) ? selected : selected.map(_property('id'));

      return selectedIds.length ? { [isExcluded ? EXCLUDED_PARAM_KEY : SELECTED_PARAM_KEY]: selectedIds } : {};
    } else {
      return {};
    }
  }
);

export const hasSitesSelected = createSelector(
  [getSelected, getIsExcluded],
  (selected, isExcluded) => (selected && selected.length) || isExcluded
);
