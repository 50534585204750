import { useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import { useTabsContext } from './Context';

/**
 * Module for tab transition implementation details.
 * naming
 * `{leavingTab}_{enteringTab}`
 */
export const TAB_TRANSITION = {
  REMAIN_FROMRIGHT: 'remain_fromLeft',
  TORIGHT_REMAIN: 'toRight_remain',
};

const TAB_TRANSITION_MIRROR = {
  remain_fromLeft: 'REMAIN_FROMRIGHT',
  toRight_remain: 'TORIGHT_REMAIN',
};

export const TAB_TRANSITIONS = Object.values(TAB_TRANSITION);
export const TAB_TRANSITION_PROP_TYPE = PropTypes.oneOf(TAB_TRANSITIONS);

const TAB_TRANSITION_EASE = 'power1.easeOut';
const TAB_TRANSITION_DELAY = 0.05;

const TRANSITION_DURATION = {
  REMAIN_FROMRIGHT: 0.35,
  TORIGHT_REMAIN: 0.35,
};

const addAbsolute = (element) => element.classList.add('tabs-tab--transition-absolute');
const removeAbsolute = (element) => element.classList.remove('tabs-tab--transition-absolute');

const remain = (duration) => (element, onComplete) => {
  gsap.timeline({ onComplete }).set(element, {}, `+=${duration}`);
};

const leaveToRight = (duration) => (element, onComplete) => {
  gsap
    .timeline({ onComplete })
    .call(addAbsolute, [element])
    .to(
      element,
      { xPercent: 100, duration: duration - TAB_TRANSITION_DELAY, ease: TAB_TRANSITION_EASE, clearProps: 'all' },
      `+=${TAB_TRANSITION_DELAY}`
    )
    .call(removeAbsolute, [element]);
};

const enterFromRight = (duration) => (element, onComplete) => {
  gsap
    .timeline({ onComplete })
    .call(addAbsolute, [element])
    .from(
      element,
      { xPercent: 100, duration: duration - TAB_TRANSITION_DELAY, ease: TAB_TRANSITION_EASE, clearProps: 'all' },
      `+=${TAB_TRANSITION_DELAY}`
    )
    .call(removeAbsolute, [element]);
};

const makeTransition = (type, isEntering, duration) => {
  let factory;
  switch (type) {
    case TAB_TRANSITION.REMAIN_FROMRIGHT:
      factory = isEntering ? enterFromRight : remain;
      break;
    case TAB_TRANSITION.TORIGHT_REMAIN:
      factory = isEntering ? remain : leaveToRight;
      break;
    default:
      factory = remain;
      break;
  }

  return factory(duration);
};

/**
 * hook returns props for Transition component.
 */
export default function useTabTransition(isTabActive, props = {}) {
  const defaultElementRef = useRef();
  const nodeRef = props.elementRef || defaultElementRef;
  const { transitionType } = useTabsContext();
  const duration = TRANSITION_DURATION[TAB_TRANSITION_MIRROR[transitionType]] || 0;
  const addEndListener = useMemo(() => {
    const transition = makeTransition(transitionType, isTabActive, duration);

    return (done) => {
      const element = nodeRef && nodeRef.current;
      if (element) {
        transition(element, done);
      } else {
        done();
      }
    };
  }, [duration, isTabActive, nodeRef, transitionType]);

  return {
    in: isTabActive,
    nodeRef,
    timeout: duration * 1000,
    addEndListener,
  };
}
