import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { useClipboard } from 'use-clipboard-copy';
import PropTypes from 'prop-types';

import { Button, Text, Spinner } from '@components';
import { useNotificationBanner } from '@modules/NotificationBanner';
import { useShareLink } from '@modules/Share';

import { componentName } from '../constants';

function CopyButton({ closeDrawer }) {
  const { formatMessage } = useIntl();
  const { success } = useNotificationBanner();
  const [shareLink, isLoadingShareLink] = useShareLink();
  const { copy } = useClipboard({
    onSuccess: () => {
      closeDrawer();
      success(formatMessage({ id: 'link_copied' }));
    },
  });

  const onCopyLink = useCallback(() => {
    if (shareLink) {
      copy(shareLink);
    }
  }, [copy, shareLink]);

  return (
    <Button
      icon="copy-and-paste"
      onClick={onCopyLink}
      className={`${componentName}__btn`}
      disabled={isLoadingShareLink}
    >
      <Text className={classnames(`${componentName}__text`)} tag="div" size="m">
        {formatMessage({ id: 'copy_link' })}
      </Text>
      {isLoadingShareLink && <Spinner defaultTheme size={'xs'} />}
    </Button>
  );
}

CopyButton.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
};

export default CopyButton;
