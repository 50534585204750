import { loadState, saveState, clearState, appEnd } from '@modules/Core';

import { LOCALSTORAGE_USER_KEY } from './constants';
import { setUserProfile, resetUserProfile } from './actions';
import { initPermissionsService } from './helpers';

const authMiddleware = (store) => (next) => (action) => {
  if (action.type === setUserProfile.toString()) {
    initPermissionsService(action.payload.policies, action.payload.screenRules);
    saveState(LOCALSTORAGE_USER_KEY, action.payload);
  } else if (action.type === resetUserProfile.toString()) {
    clearState(LOCALSTORAGE_USER_KEY);

    // Request state cleanup of everything.
    next(appEnd());
  }

  return next(action);
};

export const getUserModel = () => {
  return loadState(LOCALSTORAGE_USER_KEY) || null;
};

export default authMiddleware;
