import { useParametersListener } from '@modules/FilterTypes';
import { getTimeParams, useAction, useAPIPath, useIsAdvancedSales, useIsToday } from '@modules/Core';
import { getPerformancesParams } from '../selectors';
import { loadPerformances } from '../actions';
import { usePlannerViewContext } from '../Context';

export default function usePerformancesFetcher() {
  const { metrics, baseSiteId } = usePlannerViewContext();
  const dispatchFetch = useAction(loadPerformances);
  const mets = metrics.concat(['runWeek']).join(',');
  const [subSectionPath, dataPath] = useAPIPath(true, '');
  const isAdvancedSales = useIsAdvancedSales();
  const isToday = useIsToday();

  useParametersListener(
    getPerformancesParams,
    (params) => {
      if (baseSiteId && params.dt && params.df) {
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        params.delta = mets;
        params.mets = mets;
        params.siteIds = [baseSiteId];
        dispatchFetch({ ...params, ...timeParams }, dataPath);
      }
    },
    [baseSiteId, metrics, subSectionPath, isToday, isAdvancedSales, dataPath]
  );
}
