import { useEffect, useState } from 'react';

import useResizeObserver from './useResizeObserver';
/**
 * useResizeHeightChanged
 * @param {RefObject} ref
 * @param {func} callback returns DOMRect ReadOnly
 * Listens to height changes only of given elementRef.
 * Will fire callback when width only changes.
 * 
 * Examples of use:
 * 
 * useResizeHeightChanged(elementRed, callbackFunc); - specific element ref passed
 *
 * useResizeHeightChanged(null, callbackFunc); - no ref passed - will default to the body element
 */
export default function useResizeHeightChanged(ref, callback) {
  const [currentHeight, setCurrentHeight] = useState();

  const [, height] = useResizeObserver(ref);

  useEffect(() => {
    if (callback && currentHeight !== height) {
      callback(height);
      setCurrentHeight(height);
    }
  }, [height, callback, currentHeight]);
}
