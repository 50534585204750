import React from 'react';
import { useIntl } from 'react-intl';
import { Button, TabLink, TAB_TRANSITION } from '@components';
import { BASE_CLASS, BOOKMARKS_PAGES } from '../constants';

const componentClass = `${BASE_CLASS}__footer`;

const BookmarksFooter = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={componentClass}>
      <TabLink
        primary
        defaultTheme
        component={Button}
        tabTo={BOOKMARKS_PAGES.FORM}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'bookmarks.create' })}
      </TabLink>
    </div>
  );
};

export default BookmarksFooter;
