import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import update from 'immutability-helper';

import { STATE_KEY, INITIAL_SORT } from './constants';
import {
  setReportingSummary,
  setReportingCircuits,
  setNumPages,
  setCurrentPage,
  setSortField,
  setSortDir,
  resetPagination,
  resetSort,
  resetReportingCircuits,
} from './actions';

const setAttribute = (state, attribute, value) =>
  update(state, {
    [attribute]: {
      $set: value,
    },
  });

const initialSummaryState = [];
const setSummaryState = (infoArray) => infoArray;
const summaryReducer = createReducer(initialSummaryState, {
  [setReportingSummary.toString()]: (_, { payload }) => setSummaryState(payload),
});

const initialCircuitsState = [];
const setCircuits = (sitesArray) => sitesArray;
const circuitsReducer = createReducer(initialCircuitsState, {
  [setReportingCircuits.toString()]: (_, { payload }) => setCircuits(payload),
  [resetReportingCircuits.toString()]: (_, { payload }) => initialSummaryState,
});

const getInitialPaginationState = () => ({
  current: 1,
  numPages: 0,
});
const paginationReducer = createReducer(getInitialPaginationState(), {
  [setCurrentPage.toString()]: (state, { payload }) => setAttribute(state, 'current', payload),
  [setNumPages.toString()]: (state, { payload }) => setAttribute(state, 'numPages', payload),
  [resetPagination.toString()]: getInitialPaginationState,
});

const getInitialSortState = () => INITIAL_SORT;
const sortReducer = createReducer(getInitialSortState(), {
  [setSortField.toString()]: (state, { payload }) => setAttribute(state, 'field', payload),
  [setSortDir.toString()]: (state, { payload }) => setAttribute(state, 'dir', payload),
  [resetSort.toString()]: getInitialSortState,
});

export default {
  [STATE_KEY]: combineReducers({
    summary: summaryReducer,
    circuits: circuitsReducer,
    pagination: paginationReducer,
    sort: sortReducer,
  }),
};
