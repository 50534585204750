import Provider from './Overlay';
import Content from './OverlayContent';
import { OverlayConsumer as Consumer } from './Context';

export { useOverlay } from './Context';

export default {
  Provider,
  Consumer,
  Content,
};
