import { createSelector } from '@reduxjs/toolkit';
import _isEmpty from 'lodash/isEmpty';
import { KEY, PARAM_KEY_FROM, PARAM_KEY_TO, DEFAULT_FROM_OCCUPANCY, DEFAULT_TO_OCCUPANCY } from './constants';
import { getSelectedSelector } from '@modules/Filters';
import { getSortMetricSelected } from '@modules/FilterTypes/sort';

export const getSelected = getSelectedSelector(KEY, []);

export const getParameters = createSelector([getSelected, getSortMetricSelected], (selected, sortMetricSelected) => {
  const fromValue = selected[0];
  const toValue = selected[1];
  const hasNoDefaultValues = fromValue !== DEFAULT_FROM_OCCUPANCY || toValue !== DEFAULT_TO_OCCUPANCY;

  if (!_isEmpty(selected) && sortMetricSelected === 'occupancy' && hasNoDefaultValues) {
    return { [PARAM_KEY_FROM]: fromValue, [PARAM_KEY_TO]: toValue };
  }
  return {};
});
