import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import PropTypes from 'prop-types';

import { sharedObjectMiddleware, getInitialState } from '@modules/Share/redux';
import authMiddleware from '@modules/Auth/middleware';

// TODO find a better place for the root reducer
import rootReducer from '../../rootReducer';

const prod = process.env.NODE_ENV !== 'development';

function configureStore() {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers =
    !prod && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? // eslint-disable-next-line no-underscore-dangle,indent
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
      : compose;
  const enhancer = composeEnhancers(applyMiddleware(thunk, authMiddleware, sharedObjectMiddleware));

  return createStore(rootReducer, getInitialState(), enhancer);
}

const store = configureStore();

const StoreProvider = ({ children }) => <Provider store={store}>{children}</Provider>;
StoreProvider.propTypes = {
  children: PropTypes.node,
};

export default StoreProvider;
