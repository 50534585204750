import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import _find from 'lodash/find';

import { useAction } from '@modules/Core';
import { useCompsTitles } from '@modules/FilterTypes/compsTitles';

import { assignColors } from '../actions';
import { getBaseEventId, getColors } from '../selectors';
import { BASE_COLOR } from '../constants';

/**
 * Hook to manage the keep selected comps colors consistent
 * throughout the screen. Will listen to selected comps titles
 * and make sure that each unique comp id has its own color
 */
export const useCompsColors = () => {
  const dispatchAssignColors = useAction(assignColors);
  const [compsTitles] = useCompsTitles();

  useEffect(() => {
    dispatchAssignColors(compsTitles.map(({ id }) => id));
  }, [compsTitles, dispatchAssignColors]);
};

export const useCompColorPairs = () => {
  return useSelector(getColors);
};

export const useCompColor = (id) => {
  const baseId = useSelector(getBaseEventId);
  const colorPairs = useSelector(getColors);

  // Casting both ids to string
  if (`${id}` === `${baseId}`) {
    return BASE_COLOR;
  } else {
    const pair = _find(colorPairs, { id }) || {};
    return pair.color;
  }
};
