import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './_slider.scss';
import Slider from './Slider';

const DEFAULT_SLIDER_SLIDE_WIDTH = 100;

const propTypes = {
  className: PropTypes.string,
  slideWidth: PropTypes.number,
};
const defaultProps = {
  className: '',
  slideWidth: DEFAULT_SLIDER_SLIDE_WIDTH,
};

/**
 * SliderWrapper
 * Aim of this wrapper is to get the configuration ready before the actual Slider component is mounted.
 * Main configuration is to determine the slidesPerView based on the current wrapper
 * width available and the chosen slideWidth.
 *
 * @param {number} slideWidth optional custom width for each slide default to DEFAULT_SLIDER_ITEM_WIDTH.
 */
const SliderWrapperComponent = React.forwardRef(function SliderWrapper({ slideWidth, ...otherProps }, ref) {
  const sliderCtnRef = useRef(null);

  const [slidesPerView, setSlidesPerView] = useState(0);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (sliderCtnRef) {
      const slidesPerView = Math.round(sliderCtnRef.current.clientWidth / slideWidth);
      setSlidesPerView(slidesPerView);
      setIsReady(true);
    }
  }, [setSlidesPerView, slideWidth, setIsReady]);

  return (
    <div className={classnames('slider-wrapper')} ref={sliderCtnRef}>
      {isReady && <Slider ref={ref} slidesPerView={slidesPerView} {...otherProps} />}
    </div>
  );
});

SliderWrapperComponent.propTypes = propTypes;
SliderWrapperComponent.defaultProps = defaultProps;

export default SliderWrapperComponent;
