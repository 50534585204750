import _isNull from 'lodash/isNull';
import { SHOW_BANNER, HIDE_BANNER, CLEAR_BANNER_KEY } from './actionTypes';
import { BANNER_APPEARANCE_TIME } from './constants';
import { getBannerKey } from './selectors';

/**
 * Shows the success banner
 * @param {string} status
 * @param {string} message
 * @param {any} messageValues
 * @param {boolean} dismissible
 */
export const showBanner = (status, message, messageValues, dismissible = true) => (dispatch) => {
  //Tidying up any existing banner timeouts to prevent leaks
  dispatch(clearBannerTimeout());
  const key = dismissible ? setTimeout(hide, BANNER_APPEARANCE_TIME) : null;

  function hide() {
    dispatch(hideBanner(key));
  }

  dispatch({
    type: SHOW_BANNER,
    key,
    status,
    message,
    messageValues,
  });
};

const clearBannerTimeout = () => (dispatch, getState) => {
  const key = getBannerKey(getState());

  if (!_isNull(key)) {
    clearTimeout(key);
    dispatch({
      type: CLEAR_BANNER_KEY,
    });
  }
};

/**
 * Hides the banner
 */
export const hideBanner = () => (dispatch) => {
  dispatch(clearBannerTimeout());
  dispatch({
    type: HIDE_BANNER,
  });
};

/**
 * Shows Success Banner
 * @param {string} message
 * @param {any} messageValues
 * @param {boolean} dismissible
 */
export const successBanner = (message, messageValues, dismissible) => {
  return showBanner('success', message, messageValues, dismissible);
};

/**
 * Shows Error Banner
 * @param {string} message
 * @param {any} messageValues
 * @param {boolean} dismissible
 */
export const errorBanner = (message, messageValues, dismissible) => {
  return showBanner('error', message, messageValues, dismissible);
};
