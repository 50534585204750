import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { InputField, PasswordField, Alert, Text, Button } from '@components';

import { useLoginRequest, getHasResetPasswordSuccess, AuthLayout } from '@modules/Auth';
import { useLoginForm } from './hooks';

const componentName = 'auth-login';

const Login = () => {
  const { formatMessage } = useIntl();
  const [loginRequest, isLoading, loginRequestError] = useLoginRequest();
  const hasResetPasswordSuccess = useSelector(getHasResetPasswordSuccess);
  const [serverError, setServerError] = useState('');

  const { emailField, passwordField, submitForm, errorMessage } = useLoginForm();

  const logUserIn = (event) => {
    event.preventDefault();

    submitForm(() => {
      loginRequest(emailField.value, passwordField.value);
    });
  };

  useEffect(() => {
    if (loginRequestError.status === 401) {
      setServerError(formatMessage({ id: 'login_error' }));
    } else if (loginRequestError.status === 500) {
      setServerError(formatMessage({ id: 'login_error' }));
    }
  }, [formatMessage, loginRequestError]);

  return (
    <AuthLayout.Container hasTopImage>
      {errorMessage.length > 0 && <Alert type="error" alertMessage={errorMessage} />}
      {serverError.length > 0 && <Alert type="error" alertMessage={serverError} />}
      {hasResetPasswordSuccess && (
        <div className={`${componentName}__reset-success-message`}>
          <Text className={`${componentName}__title`} size="m" bold>
            <FormattedMessage id="success" />
          </Text>
          <Text size="m">
            <FormattedMessage id="reset_success" />
          </Text>
        </div>
      )}
      <form className={`${componentName}__form-group`} onSubmit={logUserIn}>
        <InputField
          className={`${componentName}__form-field`}
          type="text"
          label={<FormattedMessage id="email" />}
          inputMode="email"
          {...emailField}
          hasError={emailField.hasError || serverError.length > 0}
        />
        <PasswordField
          className={`${componentName}__form-field`}
          label={<FormattedMessage id="password" />}
          {...passwordField}
          hasError={passwordField.hasError || serverError.length > 0}
          hasIcon
        />
        <Button primary defaultTheme className="auth-button" type="submit" disabled={isLoading}>
          <Text tag="span" size="m">
            <FormattedMessage id="login" />
          </Text>
        </Button>
      </form>
      <Link className="auth-link" to="/user/forgot">
        <Text tag="span" size="m" bold>
          <FormattedMessage id="forgot_password" />
        </Text>
      </Link>
    </AuthLayout.Container>
  );
};

export default Login;
