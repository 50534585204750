import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isUndefined from 'lodash/isUndefined';

import { PercentageMovement, Heading, Text } from '@components';
import { isMetricValueEmpty } from '@modules/Core';
import { MetricTitle, MetricNumber, CapacityNumberBlock, metricKeyPropType } from '@modules/Metrics';

const ItemMetric = ({ metricKey, value, movement, capacityRemaining, isHighlighted }) => {
  const isCapacityMetric = metricKey === 'capacity';
  return (
    <div className={classnames('item__metric', { 'item__metric--highlighted': isHighlighted })}>
      {isCapacityMetric && (
        <Heading className="item__value" level={3} size={isHighlighted ? 'l' : 'm'}>
          <CapacityNumberBlock capacity={value} capacityRemaining={capacityRemaining} />
        </Heading>
      )}
      {!isCapacityMetric && (
        <Heading level={3} className="item__metric__value" size={isHighlighted ? 'l' : 'm'}>
          <MetricNumber metricKey={metricKey} number={value} />
        </Heading>
      )}
      <Text>
        <MetricTitle metricKey={metricKey} />
      </Text>
      {!isCapacityMetric && !_isUndefined(movement) && (
        <>
          {!isMetricValueEmpty(movement) ? (
            <PercentageMovement className="item__metric__percentage" value={movement} size="xs" />
          ) : (
            <span className="item__metric__no-data">-</span>
          )}
        </>
      )}
    </div>
  );
};

ItemMetric.propTypes = {
  metricKey: metricKeyPropType,
  value: PropTypes.node,
  movement: PropTypes.number,
  capacityRemaining: PropTypes.number,
  isHighlighted: PropTypes.bool,
};

ItemMetric.defaultProps = {
  metricKey: null,
  value: null,
  movement: undefined,
  capacityRemaining: null,
  isHighlighted: false,
};

export default ItemMetric;
