import { useSelector } from 'react-redux';
import { useAction } from '@modules/Core';
import { loginRequest } from '../actions';
import { isLoginRequestLoading, loginRequestError } from '../selectors';

export const useLoginRequest = () => {
  const defaultError = {
    error: {
      status: null,
    },
  };
  const isLoading = useSelector(isLoginRequestLoading);
  const { error } = useSelector(loginRequestError) || defaultError;
  const dispatcher = useAction(loginRequest);

  return [dispatcher, isLoading, error];
};
