import React, { useCallback, useMemo } from 'react';
import _isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Heading, Button } from '@components';
import { useBookmarksContext } from '../hooks';
import { deleteBookmark, deleteBookmarkId } from '../actions';
import { BASE_CLASS } from '../constants';
import { isDeletingBookmark } from '../selectors';

const componentClass = `${BASE_CLASS}__delete-confirm`;

/**
 * Component to confirm a delete action.
 * Handled by Context API we pass the ID of the bookmark we want to remove.
 * Having an Id to remove I'll show the drawer.
 *
 * */
const DeleteBookmarkConfirm = () => {
  const dispatch = useDispatch();
  const { privacyTabActive, deleteConfirmId, setDeleteConfirmId } = useBookmarksContext();
  const isDeleting = useSelector(isDeletingBookmark);

  const isOpen = useMemo(() => !_isNil(deleteConfirmId), [deleteConfirmId]);

  const onBookmarkDelete = useCallback(() => dispatch(deleteBookmark(deleteConfirmId, onDeleteDone)), [
    deleteConfirmId,
  ]);

  const onDeleteDone = () => {
    // Removing bookmark deleted in reducers without fetching again.
    dispatch(deleteBookmarkId(privacyTabActive, deleteConfirmId));
    setDeleteConfirmId();
  };

  const onCancel = () => setDeleteConfirmId();

  return (
    <Drawer defaultTheme className={componentClass} isOpen={isOpen} hasClosed={onCancel}>
      <Drawer.Header isVisibleOnScreen>
        <Heading size="m">
          <FormattedMessage id={'bookmarks.confirm.title'} />
        </Heading>
      </Drawer.Header>
      <Drawer.Content>
        <FormattedMessage id={'bookmarks.confirm.sub-title'} />
      </Drawer.Content>
      <Drawer.Footer align={'right'}>
        <Button secondary defaultTheme onClick={onCancel} disabled={isDeleting}>
          <FormattedMessage id={'cancel'} />
        </Button>
        <Button primary defaultTheme onClick={onBookmarkDelete} disabled={isDeleting}>
          <FormattedMessage id={'delete'} />
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};

export default DeleteBookmarkConfirm;
