import { createSelector } from '@reduxjs/toolkit';

import { KEY, PARAM_KEY } from './constants';
import { getSelectedSelector } from '@modules/Filters';

export const getSelected = getSelectedSelector(KEY, null);

export const getParameters = createSelector([getSelected], (selected) => {
  if (selected !== null) {
    return { [PARAM_KEY]: selected };
  } else {
    return {};
  }
});
