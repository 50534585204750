import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './_alert.scss';

import { Text, Icon } from '@components';

const componentName = 'alert';

const ALERT_ERROR = 'error';
const ALERT_SUCCESS = 'success';
const ALERT_INFO = 'info';
const ALERT_TYPES = [ALERT_ERROR, ALERT_SUCCESS, ALERT_INFO];

const propTypes = {
  type: PropTypes.oneOf(ALERT_TYPES).isRequired,
  alertMessage: PropTypes.node.isRequired,
  inline: PropTypes.bool,
  hide: PropTypes.bool,
};

const defaultProps = {
  type: 'error',
};

const Alert = ({ type, alertMessage, inline, hide }) => {
  const currentIcon = useMemo(() => {
    switch (type) {
      case ALERT_ERROR:
        return 'error-alert';
      case ALERT_SUCCESS:
        return 'check';
      case ALERT_INFO:
      default:
        return 'info';
    }
  }, [type]);

  return (
    <div
      data-testid="Alert"
      className={classnames(componentName, `${componentName}--${type}`, {
        [`${componentName}--inline`]: inline,
        [`${componentName}--hidden`]: hide,
      })}
    >
      <Icon className={`${componentName}__icon`} icon={currentIcon} />
      <Text className={`${componentName}__message`} size="m" bold>
        {alertMessage}
      </Text>
    </div>
  );
};

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
