import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { STATE_KEY } from './constants';
import { setInfoList, setPrevUrl } from './actions';

const setInfo = (infoArray) => infoArray;
const initialInfoState = [];
const infoReducer = createReducer(initialInfoState, {
  [setInfoList.toString()]: (_, { payload }) => setInfo(payload),
});

const setPreviousUrl = (url) => url;
const initialPrevUrlState = null;
const prevUrlReducer = createReducer(initialPrevUrlState, {
  [setPrevUrl.toString()]: (_, { payload }) => setPreviousUrl(payload),
});

export default {
  [STATE_KEY]: combineReducers({
    previousUrl: prevUrlReducer,
    info: infoReducer,
  }),
};
