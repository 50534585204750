import { asyncAction } from '@showtime/cea-dep-utility';

import { VALID_USER_TIME_FORMATS } from '@modules/Core';
import { setUserProfileAttribute, getMe } from '@modules/Auth';

import { errorBanner } from '@modules/NotificationBanner';
import { USER_SETTING_KEYS, FETCH_UPDATE_KEY } from './constants';

export const updateUser = (params) => {
  return asyncAction({
    key: FETCH_UPDATE_KEY,
    url: '/api/user/me',
    method: 'PUT',
    body: { ...params },
    success() {
      this.dispatch(getMe());
    },
    error() {
      this.dispatch(errorBanner('user_settings.error_update'));
    },
  });
};

export const updateUserLanguage = (email, languageId, isoCode) => (dispatch) => {
  dispatch(setUserProfileAttribute(USER_SETTING_KEYS.languageId, languageId));
  dispatch(setUserProfileAttribute(USER_SETTING_KEYS.language, isoCode));

  return dispatch(updateUser({ email, languageId }));
};

export const updateLocalTitle = (email, localTitle) => (dispatch) => {
  dispatch(setUserProfileAttribute(USER_SETTING_KEYS.localTitle, localTitle));

  return dispatch(updateUser({ email, localTitle }));
};

export const updateLocalCurrency = (email, localCurrency) => (dispatch) => {
  dispatch(setUserProfileAttribute(USER_SETTING_KEYS.localCurrency, localCurrency));

  return dispatch(updateUser({ email, localCurrency }));
};

export const updateUserTimeFormat = (email, timeFormat) => (dispatch) => {
  if (VALID_USER_TIME_FORMATS.includes(timeFormat)) {
    dispatch(setUserProfileAttribute(USER_SETTING_KEYS.timeFormat, timeFormat));

    dispatch(updateUser({ email, timeFormat }));
  }
};
