import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { Text, IconCustom } from '@components';
import { useIsAdvancedSales } from '@modules/Core';
import { itemFormatter } from '@modules/Formatters';

import { useSummaryData, useCheckReportingView } from '../hooks';
import { BASE_CLASS } from '../constants';

const getReportedValue = (value) => (value ? itemFormatter(value) : '-');

const DisplaySitesReported = ({ className, children }) => {
  return <div className={`${BASE_CLASS}__display-reported ${className}`}>{children}</div>;
};
DisplaySitesReported.propTypes = { className: PropTypes.string, children: PropTypes.node.isRequired };
DisplaySitesReported.defaultProps = { className: '' };

const DisplayReportedItem = ({ message, size, icon }) => {
  return (
    <Text className="display-reported-item" size={size}>
      {icon && <IconCustom icon={`${icon}-logo`} className="reported-item-icon" />}
      {message}
    </Text>
  );
};
DisplayReportedItem.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  message: PropTypes.node,
};
DisplayReportedItem.defaultProps = { icon: null };

/**
 * DisplayReportedSitesInOverlay
 * View shown inside Overlay.
 */
export const DisplayReportedSitesInOverlay = ({ className }) => {
  const { formatMessage } = useIntl();
  const isAdvancedSales = useIsAdvancedSales();
  const { reportedSites, realTimeSitesReporting, reportedSitesYesterday } = useSummaryData();
  const { isNonRealTimeReportingView, isRealTimeReportingView } = useCheckReportingView();

  const size = 's';

  const reportedSitesValue = useMemo(() => getReportedValue(reportedSites), [reportedSites]);
  const reportingRealTimeSitesValue = useMemo(() => getReportedValue(realTimeSitesReporting), [realTimeSitesReporting]);
  const reportingSitesYesterdayValue = useMemo(() => getReportedValue(reportedSitesYesterday), [
    reportedSitesYesterday,
  ]);

  return (
    <DisplaySitesReported className={className}>
      {isNonRealTimeReportingView && (
        <DisplayReportedItem
          size={size}
          message={formatMessage({ id: 'report_indicator_sites_reported' }, { num: reportedSitesValue })}
        />
      )}
      {isRealTimeReportingView && (
        <>
          <DisplayReportedItem
            size={size}
            message={formatMessage(
              { id: 'report_indicator_sites_reporting_real_time' },
              { num: reportingRealTimeSitesValue }
            )}
            icon={'showtime'}
          />
          <DisplayReportedItem
            size={size}
            message={formatMessage(
              { id: 'report_indicator_sites_reported_yesterday' },
              { num: reportingSitesYesterdayValue }
            )}
            // Advanced sales should always show the showtime icon
            icon={isAdvancedSales ? 'showtime' : 'comscore'}
          />
        </>
      )}
    </DisplaySitesReported>
  );
};
DisplayReportedSitesInOverlay.propTypes = { className: PropTypes.string };
DisplayReportedSitesInOverlay.defaultProps = { className: '' };

/**
 * DisplayReportedSitesInButton
 * View shown before clicking to open overlay.
 */
export const DisplayReportedSitesInButton = ({ className }) => {
  const { formatMessage } = useIntl();
  const { reportedSites, realTimeSitesReporting } = useSummaryData();
  const { isNonRealTimeReportingView, isRealTimeReportingView } = useCheckReportingView();

  const size = 's';
  const reportedSitesValue = useMemo(() => getReportedValue(reportedSites), [reportedSites]);

  const reportingRealTimeSitesValue = useMemo(() => getReportedValue(realTimeSitesReporting), [realTimeSitesReporting]);

  return (
    <DisplaySitesReported className={className}>
      {isNonRealTimeReportingView && (
        <DisplayReportedItem
          icon={'comscore'}
          size={size}
          message={formatMessage({ id: 'report_indicator_sites_reported' }, { num: reportedSitesValue })}
        />
      )}
      {isRealTimeReportingView && (
        <DisplayReportedItem
          size={size}
          message={formatMessage({ id: 'report_indicator_sites_reporting' }, { num: reportingRealTimeSitesValue })}
          icon={'showtime'}
        />
      )}
    </DisplaySitesReported>
  );
};
DisplayReportedSitesInButton.propTypes = { className: PropTypes.string };
DisplayReportedSitesInButton.defaultProps = { className: '' };
