import _isNull from 'lodash/isNull';
import _property from 'lodash/property';
import { createSelector } from '@reduxjs/toolkit';
import { isLoading, error } from '@showtime/cea-dep-utility';
import { getState } from './common';
import { getUserProfile } from './user';

export const getHasAuthError = createSelector([getState], _property('error'));

export const getIsAuthenticated = createSelector([getUserProfile], (user) => !_isNull(user));

export const getIsEmailSent = createSelector([getState], _property('isEmailSent'));

export const getHasResetPasswordSuccess = createSelector([getState], _property('hasResetPasswordSuccess'));

export const isLoginRequestLoading = (state) => isLoading(state, 'user.loginRequest');

export const isUpdatingPassword = (state) => isLoading(state, 'user.updatePassword');

export const loginRequestError = (state) => error(state, 'user.loginRequest');
