import { useSelector } from 'react-redux';
import { getBaseEventId, getParameters } from '../selectors';
import { fetchCompEvents } from '../actions';
import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useParametersListener } from '@modules/FilterTypes';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';
import _isNull from 'lodash/isNull';
import { useLastRefresh } from '@modules';

export const useCompsDataFetch = (metrics) => {
  const [eventParamKey] = useEventParamKey();
  const baseId = useSelector(getBaseEventId);
  const dispatchFetch = useAction(fetchCompEvents);
  const lastRefresh = useLastRefresh();
  const [subSectionPath, dataPath] = useAPIPath();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getParameters,
    (params) => {
      if (!_isNull(baseId) && params.territoryId) {
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        dispatchFetch(baseId, { ...params, ...timeParams }, metrics, eventParamKey, subSectionPath, dataPath);
      }
    },
    [baseId, lastRefresh, subSectionPath, eventParamKey, dataPath, isAdvancedSales, isToday]
  );
};
