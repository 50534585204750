import { createFilterReducer, attributeSetter, createFilterActionType } from '@modules/Filters';
import { DEFAULT_ORDER_OPTION, KEY, SET_SORT_METRIC } from './constants';

export const initialState = {
  selected: DEFAULT_ORDER_OPTION,
  metric: null,
};

const sortReducer = createFilterReducer(KEY, initialState, {
  [createFilterActionType(KEY, SET_SORT_METRIC)]: attributeSetter('metric'),
});

export default {
  [KEY]: sortReducer,
};
