import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TabsNav } from '@components';
import { MetricTitle } from '@modules/Metrics';

import { useSelectedMetric } from '../hooks';
import { compsMetricsPropType } from '../constants';

const propTypes = {
  metrics: PropTypes.arrayOf(compsMetricsPropType).isRequired,
};

function CompsMetricToggle({ metrics }) {
  const [selectedMetric, setSelectedMetric] = useSelectedMetric();

  const activeTab = useMemo(() => {
    return metrics.indexOf(selectedMetric);
  }, [selectedMetric, metrics]);

  const handleChangeTab = useCallback(
    (newTab) => {
      setSelectedMetric(metrics[newTab]);
    },
    [setSelectedMetric, metrics]
  );

  return (
    <TabsNav
      className="comps-metric-toggle"
      lightTheme
      type="material"
      active={activeTab}
      onChangeTab={handleChangeTab}
      tabs={[
        ...metrics.map((metric) => {
          return {
            key: metric,
            label: <MetricTitle metricKey={metric} />,
          };
        }),
      ]}
    />
  );
}

CompsMetricToggle.propTypes = propTypes;

export default CompsMetricToggle;
