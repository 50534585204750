import { useEffect } from 'react';
import { useUserTimeFormat } from '@modules/Auth';

export default function useChartCategoryAxisFormatter(chart) {
  const timeFormat = useUserTimeFormat();

  useEffect(() => {
    if (chart !== null) {
      const xAxis = chart.xAxes.getIndex(0);

      xAxis.dateFormats.setKey('hour', timeFormat);
    }
  }, [chart, timeFormat]);
}
