import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAction } from '@modules/Core';
import { getTitles, isFetchingTitleList, isLazyLoadingTitleList, getIsMaxPagesReached } from '../selectors';
import { resetTitleList, resetMaxPagesReached } from '../actions';

export const useTitleListData = () => {
  const resetTitles = useAction(resetTitleList);
  const resetMaxPages = useAction(resetMaxPagesReached);

  const reset = useCallback(
    (id) => {
      resetMaxPages();
      resetTitles();
    },
    [resetMaxPages, resetTitles]
  );

  return [
    useSelector(getTitles),
    useSelector(isFetchingTitleList),
    useSelector(isLazyLoadingTitleList),
    useSelector(getIsMaxPagesReached),
    reset,
  ];
};
