import { getCookie } from '@showtime/cea-dep-utility';

const { REACT_APP_INTERCOM_ID: APP_ID } = process.env;

let hasInit = false;

export const hasIntercom = () => {
  return !getCookie('st_staff');
};

export function initIntercom() {
  const userModel = JSON.parse(window.localStorage.getItem('comscore:user'));

  if (APP_ID && hasIntercom() && !hasInit) {
    window.intercomSettings = {
      app_id: APP_ID,
      email: userModel.email,
      user_hash: userModel.intercomToken,
      name: `${userModel.name.firstName} ${userModel.name.lastName}`,
      alignment: 'left',
      horizontal_padding: 60,
      vertical_padding: 60,
      language_override: userModel.language,
    };

    snippet();

    hasInit = true;
  }

  return userModel;
}

export function intercomApi(action) {
  try {
    window.Intercom(action);
  } catch (e) {
    console.warn('⚠️ You need to have intercom initialized in the application ⚠️');
    console.error(e);
  }
}

/**
 *
 *
 *
 * Intercom snippet (Prettier formatted it)
 *
 *
 *
 * */
function snippet(id) {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    // eslint-disable-next-line no-inner-declarations
    function l() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + APP_ID;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    }
    if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      try {
        l();
      } catch (e) {
        w.addEventListener('load', l, false);
      }
    }
  }
}
