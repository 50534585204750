import React, { useMemo } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import _isFinite from 'lodash/isFinite';

import { calculateCapacityRemaining, PERFORMANCE_BACKEND_TIME_FORMAT, useIsAdvancedSales } from '@modules/Core';
import { useUserTimeFormat } from '@modules/Auth';
import { useMetric } from '@modules/FilterTypes/metric';
import { Text } from '@components';
import { MetricNumber } from '@modules/Metrics';

import { MetricLabel } from '../components/';

const TimeCell = ({ time }) => {
  const format = useUserTimeFormat();
  return time === null ? time : <Text size="s">{moment(time, PERFORMANCE_BACKEND_TIME_FORMAT).format(format)}</Text>;
};

const DateCell = ({ time }) => {
  return time === null ? time : <Text size="s">{moment(time).format('MMM DD')}</Text>;
};

const renderHeaderCell = ({ label }) => {
  return (
    <Text size="s" bold>
      {label === 'metricLabel' ? <MetricLabel /> : label}
    </Text>
  );
};

const renderCell = ({ value }) => (value ? <Text size="s">{value}</Text> : null);

const renderDateCell = ({ value }) => {
  const isAdvancedSales = useIsAdvancedSales();
  if (!isAdvancedSales) {
    // We only render the date cell for advanced sales
    return <TimeCell time={value} />;
  }

  return (
    <>
      <DateCell time={value} />
      <TimeCell time={value} />
    </>
  );
};

const renderFormatsCell = ({ value }) => (value ? renderCell({ value: value.join(', ') }) : null);

const renderMetricCell =
  (metric) =>
  ({ rowData }) => {
    const metricNumber =
      metric === 'capacity' ? calculateCapacityRemaining(rowData.admissions, rowData.capacity) : rowData[metric];
    const metricKey = metric === 'capacity' ? 'capacityRemaining' : metric;

    return _isFinite(metricNumber)
      ? renderCell({ value: <MetricNumber metricKey={metricKey} number={metricNumber} /> })
      : null;
  };

export const useTableColumns = (hasTime) => {
  const { formatMessage } = useIntl();
  const [metric] = useMetric();

  const tableColumns = useMemo(() => {
    let columns = [
      {
        key: 'screenName',
        label: formatMessage({ id: 'screen' }),
        renderCell,
        renderHeaderCell,
        width: hasTime ? '24%' : '40%',
      },
      {
        key: 'printFormats',
        label: formatMessage({ id: 'format' }),
        renderCell: renderFormatsCell,
        renderHeaderCell,
        width: hasTime ? '24%' : '30%',
      },
      {
        key: 'metric',
        label: 'metricLabel',
        renderCell: renderMetricCell(metric),
        renderHeaderCell,
        width: hasTime ? '28%' : '30%',
      },
    ];

    if (hasTime) {
      columns.unshift({
        key: 'datetime',
        label: formatMessage({ id: 'time' }),
        renderCell: renderDateCell,
        renderHeaderCell,
        width: '24%',
      });
    }

    return columns;
  }, [formatMessage, metric, hasTime]);
  return tableColumns;
};
