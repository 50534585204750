import { createSelector } from '@reduxjs/toolkit';
import _property from 'lodash/property';

import { isLoading } from '@showtime/cea-dep-utility';

import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { KEY as compsTitles } from '@modules/FilterTypes/compsTitles';
import { getSelected as getMetric } from '@modules/FilterTypes/metric';
import { getParametersFactory } from '@modules/FilterTypes';

import { STATE_KEY, COMPS_LIST_FETCH_KEY } from './constants';

export const getComps = _property(STATE_KEY);

export const getParameters = getParametersFactory([dates, territory, currency, sites, compsTitles]);
export const getBaseEventId = createSelector([getComps], _property('baseEventId'));

export const isLoadingBaseEvent = (state) => isLoading(state, COMPS_LIST_FETCH_KEY);

export const getBaseEventMetadata = createSelector([getComps], _property('baseEventMetadata'));
export const getCompsEventsMetadata = createSelector([getComps], _property('compsTitles'));

export const getFullCompsList = createSelector(
  [getBaseEventMetadata, getCompsEventsMetadata],
  (baseEvent, compsEvents) => [baseEvent, ...compsEvents]
);

export const getColors = createSelector([getComps], _property('colorMap'));

export const getSelectedMetric = createSelector([getMetric], (metric) => metric);
