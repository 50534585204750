import { useSelector, useDispatch } from 'react-redux';
import gsap from 'gsap';
import { useAppContextState } from '@modules';
import { getTimeParams, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useParametersListener } from '@modules/FilterTypes';
import { isUpdatingUserSettings } from '@modules/UserSettings';

import { titleListRequest } from '../actions';
import { getParamsForTitleListFetch } from '../selectors';
import { useLastRefresh } from '@modules';

/**
 * useDataFetchAndReset
 *
 * When filters change:
 * Reset pageNumber to 1
 * Keep search
 * Clear out titles not append
 *
 * When search changes
 * Reset pageNumber to 1
 * Keep search
 * Clear out titles not append
 */
export const useDataFetchAndReset = ({ globalParams, metrics, additionalFilters }) => {
  const dispatch = useDispatch();
  const { pageSearch } = useAppContextState();
  const [subSectionPath, dataPath] = useAPIPath();
  const lastRefresh = useLastRefresh();
  const isUpdatingUser = useSelector(isUpdatingUserSettings);
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getParamsForTitleListFetch(additionalFilters),
    (params) => {
      if (!isUpdatingUser && metrics && metrics.length && params.df) {
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        // Reset Page scroll back to top when any of the filter, pageSearch or lastRefresh changes.
        gsap.to(window, {
          duration: 0.5,
          scrollTo: 0,
          onStart: () => {
            dispatch(
              titleListRequest({
                subSectionPath,
                metrics,
                params: {...params, ...timeParams},
                search: pageSearch,
                pageNumber: 1,
                globalParams,
                dataPath,
                excludeDeltas: isAdvancedSales,
              })
            );
          },
        });
      }
    },
    [isUpdatingUser, metrics, pageSearch, lastRefresh, globalParams, subSectionPath, dataPath, isAdvancedSales, isToday]
  );
};
