import PropTypes from 'prop-types';

export const BASE_CLASS = 'sites';

export const STATE_KEY = 'sites';

export const SITES_FETCH_KEY = `${STATE_KEY}.fetchSites`;

export const SITES_PAGE = {
  SITES: 'sites',
  SITE_ATTRIBUTE_GROUPS: 'site-attribute-groups',
  SITES_BY_ATTRIBUTE: 'sites-by-attribute',
};

export const EMPTY_SITES = [];

export const SITE_SHAPE = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  siteAttrId: PropTypes.arrayOf(PropTypes.number),
};
