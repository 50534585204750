import { createSelector } from '@reduxjs/toolkit';

import { KEY, PARAM_KEY_FROM, PARAM_KEY_TO } from './constants';
import { getSelectedSelector } from '@modules/Filters';

export const getSelected = getSelectedSelector(KEY, []);

export const getParameters = createSelector([getSelected], (selected) => {
  if (selected.length) {
    return { [PARAM_KEY_FROM]: selected[0], [PARAM_KEY_TO]: selected[1], rcp: 'lastCinemaWeek' };
  } else {
    return {};
  }
});
