import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useChart, useIsToday } from '@modules/Core';
import { useSelectedMetric, useCompColorPairs, getBaseEventId, BASE_COLOR } from '@modules/Comps';
import { baseClass } from '../constants';
import { useEmbeddedChartData, useChartValueAxisFormatter, useChartCategoryAxisFormatter } from '../hooks';
import { getEmbeddedConfig, getEmbeddedSeriesConfig, getDataField } from '../helpers';

function EmbeddedCompsChart() {
  const [data] = useEmbeddedChartData();
  const baseId = useSelector(getBaseEventId);
  const [selectedMetric] = useSelectedMetric();
  const compColorPairs = useCompColorPairs();
  const isToday = useIsToday();

  const seriesConfig = useMemo(() => {
    const baseConfig = {
      dataField: getDataField(baseId, selectedMetric),
      color: BASE_COLOR,
    };
    const compsDataFields = compColorPairs.map(({ id, color }) => ({
      dataField: getDataField(id, selectedMetric),
      color: color,
    }));

    return getEmbeddedSeriesConfig(baseConfig, compsDataFields, isToday);
  }, [baseId, compColorPairs, selectedMetric, isToday]);

  const compsChartConfig = useMemo(() => {
    return { ...getEmbeddedConfig(isToday) };
  }, [isToday]);

  const { chartRef, setChartData, updateChartSeriesConfig, chart } = useChart(compsChartConfig);

  useEffect(() => {
    updateChartSeriesConfig(seriesConfig);
  }, [seriesConfig, updateChartSeriesConfig]);

  useEffect(() => {
    setChartData(data);
  }, [data, setChartData]);

  useChartValueAxisFormatter(chart, selectedMetric);

  useChartCategoryAxisFormatter(chart);

  return <div ref={chartRef} className={`${baseClass}__embedded`} />;
}

export default EmbeddedCompsChart;
