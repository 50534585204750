import React, { useCallback } from 'react';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';
import PropTypes from 'prop-types';

const OVERLAY_TRANSITION_EASE = 'power1.easeInOut';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  backdrop: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  content: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  children: PropTypes.node,
};

const OverlayTransition = ({ isOpen, backdrop, content, children }) => {
  const endListener = useCallback(
    (_, onComplete) => {
      const dir = isOpen ? 'from' : 'to';
      const timeline = gsap.timeline({ paused: true, onComplete });
      if (backdrop && backdrop.current) {
        timeline[dir](
          [backdrop.current],
          {
            autoAlpha: 0,
            duration: 0.3,
            ease: OVERLAY_TRANSITION_EASE,
          },
          '<'
        );
      }
      if (content && content.current) {
        timeline[dir](
          [content.current],
          {
            scale: 0.9,
            duration: 0.3,
            ease: OVERLAY_TRANSITION_EASE,
          },
          '<'
        );
      }

      timeline.play();
    },
    [isOpen, backdrop, content]
  );

  return (
    <Transition
      timeout={500}
      nodeRef={backdrop}
      in={isOpen}
      appear
      mountOnEnter
      unmountOnExit
      addEndListener={endListener}
    >
      {children}
    </Transition>
  );
};

OverlayTransition.propTypes = propTypes;

export default OverlayTransition;
