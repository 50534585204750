import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import _findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ToggleSwitch } from '@components';
import {
  useSetPageName,
  useSetRefreshMetrics,
  PAGE_NAME_THEATRE_METADATA,
  MetricList,
  Page,
  useTheatreMetadataDataFetch,
  useTheatreMetadata,
  DateSelector,
  DateComparisonPeriod,
  DateChangeSwipe,
} from '@modules';
import { useTheatrePerspectiveMetrics } from '../hooks';
import { ViewFilms, ViewScreen, BackArrow } from './components';
import { PAGE_VIEWS, PAGE_VIEWS_FILMS, PAGE_VIEWS_SCREEN } from './constants';
import { useResetOccupancy } from '@modules/FilterTypes/occupancy';
import { SortBySelector } from '@modules';
import { useSort } from '@modules/FilterTypes/sort';
import { KEY as OCCUPANCY_KEY } from '@modules/FilterTypes/occupancy';
import './_theatre-metadata.scss';

const componentName = 'theatre-metadata';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      screen: PropTypes.string,
    }),
  }).isRequired,
};

/**
 * TheatreMetadata
 * @param {}
 */
const TheatreMetadata = ({ match }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const metrics = useTheatrePerspectiveMetrics();

  const switchOptions = useMemo(
    () =>
      PAGE_VIEWS.map((view, index) => {
        return {
          key: index,
          path: view,
          label: formatMessage({ id: `theatre_metadata_view_${view}` }),
        };
      }),
    [formatMessage]
  );

  const siteId = match.params.id;
  const view = match.params.screen;
  const viewIndex = useMemo(() => _findIndex(switchOptions, { path: view }), [view, switchOptions]);
  const isFilmsView = PAGE_VIEWS[viewIndex] === PAGE_VIEWS_FILMS;
  const isScreenView = PAGE_VIEWS[viewIndex] === PAGE_VIEWS_SCREEN;

  const sortMetric = useMemo(() => {
    if (metrics.includes('eventRevenue')) {
      return 'eventRevenue';
    } else if (metrics.includes('eventRevenueBO')) {
      return 'eventRevenueBO';
    } else {
      return OCCUPANCY_KEY;
    }
  }, [metrics]);

  const resetOccupancy = useResetOccupancy();
  const [, , setSortMetricSelected] = useSort();
  const { setInfo, theatreName, isLoadingInfo } = useTheatreMetadata();

  useSetPageName(PAGE_NAME_THEATRE_METADATA, view);
  useSetRefreshMetrics(metrics);
  useTheatreMetadataDataFetch(parseInt(siteId));

  const handleViewChange = useCallback(
    (index) => {
      if (index !== viewIndex) {
        const { path } = switchOptions[index];

        history.push(`/theatres/${siteId}/${path}`);
        resetOccupancy();
      }
    },
    [viewIndex, switchOptions, history, siteId]
  );

  useEffect(() => {
    return () => {
      setInfo([]);
    };
  }, [setInfo]);

  useEffect(() => {
    setSortMetricSelected(sortMetric);
  }, [setSortMetricSelected, sortMetric]);

  const dateSelectorRef = useRef(null);
  const onPageSwipeLeft = useCallback(() => dateSelectorRef.current.moveToNextSlide(), [dateSelectorRef]);
  const onPageSwipeRight = useCallback(() => dateSelectorRef.current.moveToPrevSlide(), [dateSelectorRef]);
  const checkIsInMinMaxRange = dateSelectorRef.current ? dateSelectorRef.current.checkIsInMinMaxRange : null;

  return (
    <Page className={classnames(`${componentName} ${componentName}--${PAGE_VIEWS[viewIndex]}`)}>
      <div className={`${componentName}__actions-ctn`}>
        <DateSelector className={classnames(`${componentName}__date-selector`)} ref={dateSelectorRef} />
        <DateComparisonPeriod />

        <nav className={classnames(`${componentName}__nav`)}>
          <Page.BackNavHeader
            title={isLoadingInfo ? '-' : theatreName}
            backArrow={<BackArrow className={`${componentName}__back`} />}
          />

          <div className={classnames(`${componentName}__nav__switch-ctn`)}>
            <ToggleSwitch active={viewIndex} onChange={handleViewChange} options={switchOptions} match={match} />
          </div>
        </nav>

        <MetricList
          metricListClassName={classnames(`${componentName}__metric-list`)}
          metrics={metrics}
          params={{ siteIds: [parseInt(siteId)] }}
        />

        {isFilmsView && (
          <SortBySelector
            showOccupancySlider
            buttonClassName={classnames(`${componentName}__occupancy`)}
            buttonLabel={<FormattedMessage id={'occupancy'} />}
          />
        )}
      </div>

      <DateChangeSwipe
        className={classnames(`${componentName}__swipe-ctn`)}
        onSwipeLeft={onPageSwipeLeft}
        onSwipeRight={onPageSwipeRight}
        checkIsInMinMaxRange={checkIsInMinMaxRange}
        isDisabled={isScreenView}
      >
        {isFilmsView && <ViewFilms baseSiteId={parseInt(siteId)} />}
        {isScreenView && <ViewScreen baseSiteId={parseInt(siteId)} />}
      </DateChangeSwipe>
    </Page>
  );
};

TheatreMetadata.propTypes = propTypes;

export default TheatreMetadata;
