import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from '@components';

import PageDropdown from './PageDropdown';
import { PAGINATION_SHAPE } from './constants';
import { usePaginationApi } from './hooks';

const componentName = 'pagination';

function Pagination({ value, onChange, size, className }) {
  const { canGoBack, setCurrent, canGoForward, next, previous, current } = usePaginationApi(value, onChange, size);

  return (
    <div className={`${componentName} ${className}`}>
      <div className={`${componentName}__left`}>
        <Button icon="chevron-left" disabled={!canGoBack} onClick={previous}>
          <FormattedMessage id="previous" />
        </Button>
      </div>
      {(canGoBack || canGoForward) && <PageDropdown value={current} onChange={setCurrent} size={size} />}

      <div className={`${componentName}__right`}>
        <Button icon="chevron-right" disabled={!canGoForward} iconAlign="right" onClick={next}>
          <FormattedMessage id="next" />
        </Button>
      </div>
    </div>
  );
}

Pagination.propTypes = { ...PAGINATION_SHAPE, className: PropTypes.string };
Pagination.defaultProps = { className: '' };

export default Pagination;
