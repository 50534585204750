import React, { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import { Spinner, Table } from '@components';

import { BASE_CLASS, COL_CIRCUIT_NAME, INITIAL_SORT } from '../constants';
import { useTableColumns, useCircuitsData, useSort } from '../hooks';

const ReportingTable = () => {
  const { formatMessage } = useIntl();
  const tableColumns = useTableColumns();
  const [circuits, isLoadingReportedCircuits] = useCircuitsData();
  const { sortField, sortDir, setSortField, setSortDir } = useSort();

  let firstLoad = useRef(true);

  useEffect(() => {
    if (isLoadingReportedCircuits) {
      firstLoad.current = false;
    }
  }, [isLoadingReportedCircuits, firstLoad]);

  const setSortBy = useCallback(
    ({ id, dir }) => {
      setSortField(id);
      setSortDir(dir);
    },
    [setSortField, setSortDir]
  );

  return (
    <div className={`${BASE_CLASS}__table-ctn`}>
      {(isLoadingReportedCircuits && firstLoad.current) || firstLoad.current ? (
        <Spinner />
      ) : (
        <>
          {_isEmpty(circuits) ? (
            <div className={`${BASE_CLASS}__table-ctn__empty`}>{formatMessage({ id: 'no_results_found' })}</div>
          ) : (
            <>
              <div className={`${BASE_CLASS}__table-ctn-inner`}>
                <Table className={`${BASE_CLASS}__table`} colBordersVisible>
                  <Table.Head
                    columns={tableColumns}
                    onSortChange={setSortBy}
                    sortBy={{ id: sortField, dir: sortDir }}
                    initialSort={INITIAL_SORT}
                  />
                  <Table.Body columns={tableColumns} data={circuits} dataUniqKey={COL_CIRCUIT_NAME} />
                </Table>
              </div>
              <div
                className={classnames(`${BASE_CLASS}__table-ctn__loader`, {
                  [`${BASE_CLASS}__table-ctn__loader--is-loading`]: isLoadingReportedCircuits,
                })}
              >
                <Spinner />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

ReportingTable.propTypes = {};

export default ReportingTable;
