import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const XSMALL = 'xs';
const SMALL = 's';
const MEDIUM = 'm';
const LARGE = 'l';
const XLARGE = 'xl';
const XXLARGE = 'xxl';

const DEFAULT_SIZE_MAPPINGS = {
  [XSMALL]: ['small'],
  [SMALL]: ['span'],
  [MEDIUM]: ['p', 'div', 'label'],
};

// If no size is passed, default size id determined by the tag.
const getDefaultSizeByTag = (tag) => {
  let defaultSize = MEDIUM;

  Object.entries(DEFAULT_SIZE_MAPPINGS).map(([size, tagArray]) => {
    if (tagArray.includes(tag)) {
      defaultSize = size;
    }
  });
  return defaultSize;
};
/**
 * Text
 *
 * @param {string} tag
 * @param {string} size
 *
 * @returns {node}
 */
const Text = ({ tag, bold, italic, size, className, ...props }) => {
  const Tag = tag;
  const textSize = size || getDefaultSizeByTag(tag);

  return (
    <Tag
      className={classnames(`${className}`, `text--${textSize}`, {
        bold,
        italic,
      })}
      {...props}
    />
  );
};

Text.propTypes = {
  size: PropTypes.oneOf([XSMALL, SMALL, MEDIUM, LARGE, XLARGE, XXLARGE]),
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  tag: PropTypes.string,
  className: PropTypes.string.isRequired,
};

Text.defaultProps = {
  className: '',
  italic: false,
  bold: false,
  tag: 'p',
  size: null,
};

export default Text;
