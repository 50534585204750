import { useState, useCallback } from 'react';

export default function useSearch(initialSearch) {
  const [search, setSearch] = useState(initialSearch);

  const matchesSearch = useCallback(
    (value) => {
      if (search) {
        const _search = search.toLowerCase();
        const _value = value.toLowerCase();

        return _value.includes(_search);
      }

      return true;
    },
    [search]
  );

  return [search, setSearch, matchesSearch];
}
