import _property from 'lodash/property';
import { createSelector } from '@reduxjs/toolkit';
import { isLoading } from '@showtime/cea-dep-utility';
import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { getParametersFactory } from '@modules/FilterTypes';
import { STATE_KEY, TITLES_FETCH_KEY, TITLES_LAZY_LOAD_FETCH_KEY } from './constants';

export const isFetchingTitleList = (state) => isLoading(state, TITLES_FETCH_KEY);

export const isLazyLoadingTitleList = (state) => isLoading(state, TITLES_LAZY_LOAD_FETCH_KEY);

export const getTitleList = _property(STATE_KEY);

export const getTitles = createSelector([getTitleList], _property('titles'));

export const getParamsForTitleListFetch = (additionalFilters) =>
  getParametersFactory([dates, territory, currency, sites].concat(additionalFilters));

export const getIsMaxPagesReached = createSelector([getTitleList], _property('maxPagesReached'));
