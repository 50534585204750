import React from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@components';

export default function NoPresalesIntegration() {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id: 'presales_data_integration' });

  return (
    <div className="empty-state empty-state--no-presales">
      <Icon icon="contact" />
      <div className="empty-state__text">{message}</div>
    </div>
  );
}
