import update from 'immutability-helper';
import { createReducer, combineReducers } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';

import { STATE_KEY, EMPTY_SITES } from './constants';
import {
  NO_RESULTS,
  SET_SITES_FULL,
  SET_SITES_SEARCH,
  CLEAR_SITES,
  SET_SITES_BY_ATTR,
  CLEAR_SITES_BY_ATTR,
} from './actionTypes';

const sitesFullReducer = createReducer(null, {
  [SET_SITES_FULL]: (_, { sites }) => (sites.length > 0 ? _sortBy(sites, 'name') : EMPTY_SITES),
  [CLEAR_SITES]: () => null,
});

const initialSitesSearchReducer = {
  results: [],
  noResults: false,
};

const sitesSearchReducer = createReducer(initialSitesSearchReducer, {
  [SET_SITES_SEARCH]: (state, { sites }) =>
    update(state, {
      results: { $set: sites.length > 0 ? _sortBy(sites, 'name') : EMPTY_SITES },
    }),
  [NO_RESULTS]: (state, { hasNoResults }) =>
    update(state, {
      noResults: { $set: hasNoResults },
    }),
});

const initialSitesByAttributeId = {};
const sitesByAttribute = createReducer(initialSitesByAttributeId, {
  [SET_SITES_BY_ATTR]: (state, { attrId, sites }) =>
    update(state, {
      [attrId]: { $set: sites },
    }),
  [CLEAR_SITES_BY_ATTR]: () => initialSitesByAttributeId,
});

export default {
  [STATE_KEY]: combineReducers({
    full: sitesFullReducer,
    search: sitesSearchReducer,
    byAttribute: sitesByAttribute,
  }),
};
