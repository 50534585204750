export const BASE_CLASS = 'reporting-indicator';

export const STATE_KEY = 'reporting';
export const REPORT_SUMMARY_FETCH_KEY = 'fetch.reporting.summary';
export const REPORT_CIRCUITS_FETCH_KEY = 'fetch.reporting.circuits';

export const COL_CIRCUIT_NAME = 'circuitName';
export const COL_REPORTED_SITES = 'reportedSites';
export const COL_REPORTED_SITES_YESTERDAY = 'reportedSitesYesterday';
export const COL_REALTIME_REPORTING_SITES = 'reportedSites';
export const COL_FEED = 'feed';

export const INITIAL_SORT = {
  field: COL_CIRCUIT_NAME,
  dir: 'asc',
};
