import { createAction } from '@reduxjs/toolkit';

import { asyncAction } from '@showtime/cea-dep-utility';

import { API_ROOT_PATH } from '@modules/Core';
import { getTimeParams, getUserDateParam } from '@modules/Core/helpers';

import { STATE_KEY, REPORT_SUMMARY_FETCH_KEY, REPORT_CIRCUITS_FETCH_KEY } from './constants';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_SUMMARY = 'SET_SUMMARY';
export const setReportingSummary = createAction(createActionType(SET_SUMMARY));

const SET_CIRCUITS = 'SET_CIRCUITS';
export const setReportingCircuits = createAction(createActionType(SET_CIRCUITS));

const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const setCurrentPage = createAction(createActionType(SET_CURRENT_PAGE));

const SET_NUM_PAGES = 'SET_NUM_PAGES';
export const setNumPages = createAction(createActionType(SET_NUM_PAGES));

const RESET_PAGINATION = 'RESET_PAGINATION';
export const resetPagination = createAction(createActionType(RESET_PAGINATION));

const SET_SORT_FIELD = 'SET_SORT_FIELD';
export const setSortField = createAction(createActionType(SET_SORT_FIELD));

const SET_SORT_DIR = 'SET_SORT_DIR';
export const setSortDir = createAction(createActionType(SET_SORT_DIR));

const RESET_SORT = 'RESET_SORT';
export const resetSort = createAction(createActionType(RESET_SORT));

const RESET_REPORTING_CIRCUITS = 'RESET_REPORTING_CIRCUITS';
export const resetReportingCircuits = createAction(createActionType(RESET_REPORTING_CIRCUITS));

/**
 * fetchReportingSummary
 *
 * E.g Body Params sent:
 {
  df: "2020-08-13"
  dt: "2020-08-13"
  eventId: [1] || pictureId [1],
  performanceId: [1],
 }
 */
export function fetchReportingSummary(params, eventParamKey, subSectionPath = '') {
  const { df, dt, territoryId } = params;
  return asyncAction({
    key: REPORT_SUMMARY_FETCH_KEY,
    url: `${API_ROOT_PATH}${subSectionPath}/reporting-summary?_query=json`,
    body: {
      df,
      dt,
      territoryId,
      [eventParamKey]: params[eventParamKey],
      ...getTimeParams(),
      ...getUserDateParam(),
      // siteIds // Not used yet. May be in future flows
    },
    method: 'POST',
    success(response) {
      this.dispatch(setReportingSummary(response));
    },
    useDefaultErrorHandler: false,
  });
}

const getFeedType = (data) => {
  if (data.showtime) {
    return 'showtime';
  }

  if (data.comscore) {
    return 'comscore';
  }

  return 'comscore';
};

/**
 * fetchReportingCircuits
 *
 * E.g Body Params sent:
 {
  df: "2020-08-13"
  dt: "2020-08-13"
  eventId: [1],
  performanceId: [1],
 }
 */
const REPORTED_CIRCUITS_LIST_PAGE_SIZE = 30;

export function fetchReportingCircuits(params) {
  const { df, dt, page, search, sortField, sortDir, territoryId, subSectionPath, eventParamKey } = params;
  return (dispatch) => {
    dispatch(
      asyncAction({
        key: REPORT_CIRCUITS_FETCH_KEY,
        url: `${API_ROOT_PATH}${subSectionPath}/reporting-stats/sites?_query=json`,
        body: {
          df,
          dt,
          page,
          search,
          sort: [`${sortField},${sortDir}`],
          size: REPORTED_CIRCUITS_LIST_PAGE_SIZE,
          territoryId: territoryId,
          [eventParamKey]: params[eventParamKey],
          ...getTimeParams(),
          ...getUserDateParam(),
        },
        method: 'POST',
        success(response) {
          const content = response.content.map((item) => {
            return {
              ...item,
              feed: getFeedType(item),
            };
          });
          this.dispatch(setReportingCircuits(content));
          this.dispatch(setNumPages(Math.ceil(response.totalPages)));
        },
        error() {
          dispatch(resetPagination());
          dispatch(resetSort());
          dispatch(resetReportingCircuits());
        },
        useDefaultErrorHandler: false,
      })
    );
  };
}
