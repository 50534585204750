import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Text, Spinner } from '@components';
import { useTerritory } from '@modules/FilterTypes/territory';
import { useCurrency } from '@modules/FilterTypes/currency';

import { OPTION_SHAPE } from '../constants';
import { setTerritoryAsFavouriteRequest, removeTerritoryAsFavouriteRequest } from '../actions';
import { getSelectedTerritory, getSelectedTerritoryCurrencyIsoCode, isSettingFavourite } from '../selectors';
import { getLocalCurrency } from '@modules/UserSettings';

const propTypes = {
  option: OPTION_SHAPE,
  onSelectItem: PropTypes.func,
  disabled: PropTypes.bool,
};

function ListItem({ option, onSelectItem = () => {}, disabled = false }) {
  const dispatch = useDispatch();
  const [, setSelectedTerritory] = useTerritory();
  const [, setSelectedCurrency] = useCurrency();
  const isLoading = useSelector(isSettingFavourite(option.id));
  const selectedTerritory = useSelector(getSelectedTerritory);
  const isActive = !disabled && selectedTerritory.id === option.id;
  const selectedTerritoryCurrencyIsoCode = useSelector(getSelectedTerritoryCurrencyIsoCode);
  const isLocalCurrency = useSelector(getLocalCurrency);

  const onItemClick = useCallback(() => {
    setSelectedTerritory(option.id);
    onSelectItem();
  }, [setSelectedTerritory, option.id, onSelectItem]);

  useEffect(() => {
    if (isLocalCurrency) {
      setSelectedCurrency(selectedTerritoryCurrencyIsoCode);
    }
  }, [isLocalCurrency, setSelectedCurrency, selectedTerritoryCurrencyIsoCode]);

  const onFavouriteSelect = useCallback(() => {
    if (option.favorite) {
      dispatch(removeTerritoryAsFavouriteRequest(option.id));
    } else {
      dispatch(setTerritoryAsFavouriteRequest(option.id));
    }
  }, [option.favorite, option.id, dispatch]);

  return (
    <li className="list__item" key={option.id}>
      <Button
        className={classnames('btn-territory', {
          'btn-territory--is-active': isActive,
          'btn-territory--is-disabled': disabled,
          bold: isActive,
        })}
        onClick={onItemClick}
      >
        <Text tag="span" size="m">
          {option.name}
        </Text>
      </Button>
      {isLoading && <Spinner defaultTheme size={'xs'} />}
      {!isLoading && (
        <Button
          className={classnames('btn-favourite', {
            'btn-favourite--is-active': option.favorite,
          })}
          icon={option.favorite ? 'favourite' : 'unfavourite'}
          onClick={onFavouriteSelect}
        />
      )}
    </li>
  );
}

ListItem.propTypes = propTypes;

export default ListItem;
