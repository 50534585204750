import moment from 'moment';
import 'moment/locale/es';

const RELATIVE_TIME = ['future', 'past', 's', 'ss', 'm', 'mm', 'h', 'hh', 'd', 'dd', 'w', 'ww', 'M', 'MM', 'y', 'yy'];

const MONTH = [
  'month_january',
  'month_february',
  'month_march',
  'month_april',
  'month_may',
  'month_june',
  'month_july',
  'month_august',
  'month_september',
  'month_october',
  'month_november',
  'month_december',
];

const MONTH_SHORT = [
  'month_january_short',
  'month_february_short',
  'month_march_short',
  'month_april_short',
  'month_may_short',
  'month_june_short',
  'month_july_short',
  'month_august_short',
  'month_september_short',
  'month_october_short',
  'month_november_short',
  'month_december_short',
];

const DOW = ['dow_sunday', 'dow_monday', 'dow_tuesday', 'dow_wednesday', 'dow_thursday', 'dow_friday', 'dow_saturday'];

const DOW_SHORT = [
  'dow_sunday_short',
  'dow_monday_short',
  'dow_tuesday_short',
  'dow_wednesday_short',
  'dow_thursday_short',
  'dow_friday_short',
  'dow_saturday_short',
];

const messagesPropertyGetter = (messages) => (property) => messages[property];

/**
 * Relative Time should return an object to moment.
 */
const getRelativeTimeMessage = (messages) => {
  const rt = {};
  RELATIVE_TIME.forEach((item) => {
    rt[item] = messages[item];
  });

  return rt;
};

/**
 * Sets moment to use target locale.
 * Updates formatting to use strings provided in messages.
 * Only works at app start. Existing moment instances won't respond to locale change.
 *
 * @param {string} locale - target locale.
 * @param {Object} messages - target messages dictionary.
 */
export default function setupMoment(locale, messages) {
  const getMessage = messagesPropertyGetter(messages); 
  moment.updateLocale(locale, {
    months: MONTH.map(getMessage),
    monthsShort: MONTH_SHORT.map(getMessage),
    weekdays: DOW.map(getMessage),
    weekdaysShort: DOW_SHORT.map(getMessage),
    relativeTime: getRelativeTimeMessage(messages),
  });
  moment.locale(locale);
};
