import React, { useState, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _filter from 'lodash/filter';

import './_film-metadata.scss';
import { ToggleSwitch } from '@components';
import {
  MetricList,
  Page,
  useSetPageName,
  useSetRefreshMetrics,
  PAGE_NAME_FILM_METADATA,
  ReportingIndicator,
} from '@modules';
import { URL_PATHS, useAction } from '@modules/Core';
import { getSiteAttributeGroups } from '@modules/SiteAttributeGroups';
import { useSelectedSiteAttributeGroup } from '@modules/SiteAttributionSelector';
import { useOccupancy } from '@modules/FilterTypes/occupancy';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';
import {
  setBaseEventId,
  useCompsDataFetch,
  useCompsEventsRemover,
  useBaseEventMetadata,
  COMPS_AVAILABLE_METRICS,
} from '@modules/Comps';

import { ViewComps, ViewSites, ViewTheatres, BackArrow, PosterBg } from './components';
import { PAGE_VIEWS, PAGE_VIEWS_CIRCUITS, PAGE_VIEWS_COMPS } from './constants';
import { useNavShadowAnimation } from './hooks';
import { useFilmPerspectiveMetrics } from '../hooks';

const componentName = 'film-metadata';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      screen: PropTypes.string,
      site_attribute_id: PropTypes.string,
      site_attribute_group_id: PropTypes.string,
    }),
  }).isRequired,
};

/**
 * FilmMetadata
 * @param {}
 */
const FilmMetadata = ({ match }) => {
  const history = useHistory();
  const [eventParamKey] = useEventParamKey();
  const [, setOccupancy] = useOccupancy();
  const siteAttributeGroups = useSelector(getSiteAttributeGroups);

  const switchOptions = useMemo(
    () =>
      PAGE_VIEWS.map((view, index) => {
        return {
          key: index,
          path: view,
          label: <FormattedMessage id={`film_metadata_view_${view}`} />,
        };
      }),
    []
  );

  const eventId = match.params.id;
  const view = match.params.screen;
  const siteAttributeGroupId = match.params.site_attribute_group_id;
  const siteAttributeId = match.params.site_attribute_id;
  const [selectedAttributeName, setSelectedAttributeName] = useState();

  const viewIndex = useMemo(() => _findIndex(switchOptions, { path: view }), [switchOptions, view]);

  const metrics = useFilmPerspectiveMetrics();
  const compsMetrics = useMemo(() => {
    return metrics.filter((metric) => COMPS_AVAILABLE_METRICS.includes(metric));
  }, [metrics]);

  const initId = useAction(setBaseEventId);

  const [filmMetadata, isLoadingBaseEvent] = useBaseEventMetadata();

  const isSitesView = typeof siteAttributeId !== 'undefined';
  const isCompsView = !isSitesView && PAGE_VIEWS[viewIndex] === PAGE_VIEWS_COMPS;
  const isTheatresView = !isSitesView && PAGE_VIEWS[viewIndex] === PAGE_VIEWS_CIRCUITS;

  useEffect(() => {
    initId(eventId);
  }, [siteAttributeGroupId, siteAttributeId, eventId, initId]);

  const [navContainerRefBinder] = useNavShadowAnimation(isTheatresView);

  useSetPageName(PAGE_NAME_FILM_METADATA, view);
  useSetRefreshMetrics(metrics);

  useCompsDataFetch(compsMetrics);
  useCompsEventsRemover();

  const handleViewChange = useCallback(
    (index) => {
      if (index !== viewIndex) {
        const { path } = switchOptions[index];
        history.push(`/${URL_PATHS.FILMS}/${eventId}/${path}`);
      }
    },
    [viewIndex, history, eventId, switchOptions]
  );

  const [, selectedSiteAttribute, setSelectedSiteAttributeGroupId] = useSelectedSiteAttributeGroup();

  const selectSiteAttributeGroup = useCallback(
    (id) => {
      history.push(`/${URL_PATHS.FILMS}/${eventId}/${PAGE_VIEWS_CIRCUITS}/${selectedSiteAttribute.id}/${id}`);
    },
    [history, eventId, selectedSiteAttribute]
  );

  useEffect(() => {
    return () => {
      setOccupancy([]);
    };
  }, [setOccupancy]);

  const metricParams = isSitesView
    ? {
        siteGroupAttributeId: [siteAttributeId],
        [eventParamKey]: [eventId],
      }
    : { [eventParamKey]: [eventId] };

  useEffect(() => {
    setSelectedSiteAttributeGroupId(siteAttributeGroupId);
  }, [setSelectedSiteAttributeGroupId, siteAttributeGroupId]);

  useEffect(() => {
    const selectedGroupAttributes = _get(
      _filter(siteAttributeGroups, { id: parseInt(siteAttributeGroupId) }, []),
      '[0].attributes',
      []
    );

    setSelectedAttributeName(
      _get(_filter(selectedGroupAttributes, { id: parseInt(siteAttributeId) }, []), '[0].name', '')
    );
  }, [siteAttributeGroups]);

  return (
    <Page className={classnames(`${componentName} ${componentName}--${PAGE_VIEWS[viewIndex]}`)}>
      <PosterBg imageUrl={filmMetadata.imageUrl} name={filmMetadata.name} showPoster={isCompsView} />

      <div className={classnames(`${componentName}__content`)}>
        <nav ref={navContainerRefBinder} className={classnames(`${componentName}__nav`)}>
          <ReportingIndicator
            className={classnames(`${componentName}__report-indicator`)}
            params={{ [eventParamKey]: [eventId] }}
          />
          <Page.BackNavHeader
            title={isLoadingBaseEvent || filmMetadata.name === '' ? ' - ' : filmMetadata.name}
            subtext={<FormattedMessage id="titlesList_run_week" values={{num: filmMetadata.runWeek }} />}
            backArrow={<BackArrow className={`${componentName}__back`} />}
          />

          <div className={classnames(`${componentName}__nav__switch-ctn`)}>
            <ToggleSwitch
              className={classnames(`${componentName}__nav__switch`)}
              active={viewIndex}
              onChange={handleViewChange}
              options={switchOptions}
              match={match}
            />
          </div>

          {isSitesView && (
            <Page.BackNavHeader
              title={selectedAttributeName && <FormattedMessage id={selectedAttributeName} defaultMessage={selectedAttributeName} />}
              className={'sub-header'}
              subtext={''}
              backArrow={
                <BackArrow
                  resetMetricOnCleanUp={!isSitesView}
                  goToPath={`/${URL_PATHS.FILMS}/${eventId}/${PAGE_VIEWS_CIRCUITS}`}
                  className={`${componentName}__back`}
                />
              }
            />
          )}
        </nav>

        <MetricList
          key={PAGE_VIEWS[viewIndex] || 'sites'}
          selectable={isSitesView || isTheatresView}
          metrics={metrics}
          params={metricParams}
        />

        {isCompsView && <ViewComps metrics={compsMetrics} />}
        {isTheatresView && <ViewTheatres onSelectItem={selectSiteAttributeGroup} baseEventId={eventId} />}
        {isSitesView && <ViewSites siteAttributeGroupId={siteAttributeId} baseEventId={eventId} />}
      </div>
    </Page>
  );
};

FilmMetadata.propTypes = propTypes;

export default FilmMetadata;
