import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from '@components';

const componentName = 'radio-group';

/**
 * Radio Group
 * @param {nodes}   children - Expects Radio.Button elements
 * @param {string}  name - Unique name for this RadioGroup, used as name attribute on radio input.
 * @param {node}    value current active value for the radioGroup
 * @param {node}    label - label to use as form field group
 * @param {func}    onChange - callback fired when any of the radio buttons is selected.
 * @param {bool}    defaultTheme - Forced to use colours of default theme
 */
const Group = ({ children, name, value, onChange, className, label, defaultTheme }) => {
  return (
    <div className={classnames(componentName, className)}>
      {label && (
        <Text className={`${componentName}__label`} tag="span" size="m" bold>
          {label}
        </Text>
      )}
      {React.Children.map(children, (child) => {
        const propsFromGroup = {
          name,
          onChange,
          defaultTheme,
          isChecked: value === child.props.value,
        };

        return React.cloneElement(child, Object.assign({}, child.props, propsFromGroup));
      })}
    </div>
  );
};

Group.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.node,
  label: PropTypes.node,
  onChange: PropTypes.func,
  defaultTheme: PropTypes.bool,
};
Group.defaultProps = {
  className: '',
  children: null,
  name: '',
  value: null,
  label: null,
  defaultTheme: false,
  onChange: () => {},
};

export default Group;
