import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { captureException } from '@sentry/browser';
import popcorn from '@images/popcorn.svg';

import { Text, Heading } from '@components';

const componentName = 'app-error-boundary';

const AppError = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={componentName}>
      <img className={`${componentName}__image`} src={popcorn} alt="" />
      <Heading>{formatMessage({ id: 'app_error_heading' })}</Heading>
      <Text>{formatMessage({ id: 'app_error_subtext' })}</Text>
    </div>
  );
};

/**
 * ErrorBoundary
 * TODO update when react supports hooks version of ErrorBoundary rather than class.
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <AppError />;
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
