import { setSelectedAction, createFilterActionType } from '@modules/Filters';

import { KEY, SET_IS_EXCLUDED, SET_IS_DISABLED } from './constants';

export const setSelected = (values) => setSelectedAction(KEY, values);
export const setIsExcluded = (values) => ({
  type: createFilterActionType(KEY, SET_IS_EXCLUDED),
  values,
});

export const setFilterState = (selected, isExcluded) => (dispatch) => {
  dispatch(setSelected(selected));
  dispatch(setIsExcluded(isExcluded));
};

export const disable = () => ({
  type: createFilterActionType(KEY, SET_IS_DISABLED),
  values: true,
});

export const enable = () => ({
  type: createFilterActionType(KEY, SET_IS_DISABLED),
  values: false,
});
