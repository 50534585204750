import React from 'react';
import PropTypes from 'prop-types';

import MetricNumber from './MetricNumber';

/**
 * @returns Outputs the capacity number and capacityRemaining one below another.
 * e.g
 * 5.33k
 * (100K)
 */
function CapacityNumberBlock({ capacity, capacityRemaining }) {
  return <MetricNumber key={'capacityRemaining'} metricKey={'capacityRemaining'} number={capacityRemaining} />;
}

CapacityNumberBlock.propTypes = {
  capacity: PropTypes.number,
  capacityRemaining: PropTypes.number,
};

export default CapacityNumberBlock;
