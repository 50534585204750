import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { Drawer, Heading, Button, Accordian } from '@components';

import { SuggestedTitles, SearchTitles, SelectedTitles } from './components';

import { componentName } from './constants';

import './_comps-selection-drawer.scss';

import { useDrawerSession, SearchProvider } from './hooks';

export * from './constants';

const CompsDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const { initSession, commitToStore, restoreToOriginal } = useDrawerSession();
  const hasOpened = () => {
    setIsOpen(true);
    initSession();
  };

  const onApply = () => {
    commitToStore();
    setIsOpen(false);
  };

  const onCancel = () => {
    restoreToOriginal();
    setIsOpen(false);
  };

  return (
    <Drawer isOpen={isOpen} hasOpened={hasOpened} hasClosed={() => setIsOpen(false)}>
      <Drawer.Header>
        <Heading size="m" className={classnames(`${componentName}__heading`)}>
          {formatMessage({ id: 'compsSelectionDrawer_heading' })}
        </Heading>
      </Drawer.Header>

      <Drawer.Content>
        <Accordian className={classnames(`${componentName}__accordian`)}>
          <SelectedTitles />

          <SuggestedTitles />

          <Drawer.Divider></Drawer.Divider>

          <SearchTitles />
        </Accordian>
      </Drawer.Content>

      <Drawer.Footer align="right">
        <Button onClick={onCancel} secondary>
          {formatMessage({ id: 'cancel' })}
        </Button>
        <Button onClick={onApply} primary>
          {formatMessage({ id: 'apply' })}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};

const CompsSelectionDrawer = () => {
  return (
    <SearchProvider>
      <CompsDrawer />
    </SearchProvider>
  );
};

export default CompsSelectionDrawer;
