import React, { useEffect } from 'react';
import { getSelected as getOccupancySelected } from '@modules/FilterTypes/occupancy';
import { leftPositionFromStartHour, widthCardByMovieDuration } from '../helpers';
import { useSelector } from 'react-redux';
import { itemFormatter } from '../../Formatters/helpers';

/**
 * This hook helps to assign a position to every performanceCard in Screen Planner.
 * The amount in pixels are formulated by helpers using an accurate calc
 * conversion between pixels and minutes by timeLine component.
 *
 * Width - Length of the movie in pixels.
 * Left - Pixels that determines a relative position
 *
 * */
export const usePerformanceCardsPosition = (perfCardRef, title) => {
  useEffect(() => {
    const { datetime, runtime } = title;

    if (perfCardRef.current) {
      // Getting pixels by tile time
      const left = leftPositionFromStartHour(datetime);
      const width = widthCardByMovieDuration(datetime, runtime);

      // Applying styles
      perfCardRef.current.style.left = `${left}px`;
      perfCardRef.current.style.width = `${width}px`;
    }
  }, [perfCardRef.current, title]);
};

/**
 * This functions make the card to use different opacity values.
 * depending on the SortBySelector current values.
 * */
export const usePerformanceCardsOpacity = (perfCardRef, title) => {
  const occupancySelected = useSelector(getOccupancySelected);

  useEffect(() => {
    if (perfCardRef.current) {
      const occupancy = itemFormatter(title.occupancy, 0);
      const occupancyTo = occupancySelected[1] || 100;
      const occupancyFrom = occupancySelected[0] || 0;

      // Applying styles
      const opacity = occupancy >= occupancyFrom && occupancy <= occupancyTo ? 1 : 0.4;
      perfCardRef.current.style.opacity = opacity;
    }
  }, [perfCardRef.current, occupancySelected, title]);
};
