import React, { useEffect, useRef } from 'react';
import { COMPONENT_BASE_CLASS } from '../constants';
import moment from 'moment';
import { useIsToday, PERFORMANCE_BACKEND_TIME_FORMAT } from '@modules/Core';
import { leftPositionFromStartHour } from '../helpers';

const componentClass = `${COMPONENT_BASE_CLASS}__current-time-divider`;

function CurrentTimeLineDivider() {
  const isToday = useIsToday();
  const elementRef = useRef();

  useEffect(() => {
    if (elementRef.current) {
      const currentTime = moment().format(PERFORMANCE_BACKEND_TIME_FORMAT);
      const lefPosition = leftPositionFromStartHour(currentTime);
      elementRef.current.style.left = `${lefPosition}px`;
    }
  }, [elementRef.current]);

  return isToday && <div className={componentClass} ref={elementRef} />;
}

export default CurrentTimeLineDivider;
