import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const componentName = 'market-film-metadata';

const PosterBg = ({ showPoster, imageUrl, name }) => {
  return (
    <div className={classnames(`${componentName}__poster-bg`)}>
      {showPoster && (
        <div
          className={classnames('poster-bg__top-ctn', {
            'poster-bg__top-ctn--gradient': !imageUrl,
            'poster-bg__top-ctn--poster': imageUrl,
          })}
        >
          {imageUrl ? <img alt={name} className="poster-bg__image" src={imageUrl} /> : null}
        </div>
      )}
      <div
        className={classnames('bg__gradient', {
          'bg__gradient--with-poster': showPoster,
        })}
      ></div>
    </div>
  );
};

PosterBg.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  showPoster: PropTypes.bool,
};

PosterBg.defaultProps = {
  imageUrl: null,
  name: null,
  showPoster: true,
};

export default React.memo(PosterBg);
