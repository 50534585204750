import { createSelector } from '@reduxjs/toolkit';
import _property from 'lodash/property';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _mapValues from 'lodash/mapValues';
import { PERFORMANCES_FETCH_KEY, SCREEN_SORTING_KEY, STATE_KEY } from './constants';
import { getParametersFactory } from '../FilterTypes';
import { KEY as metric } from '@modules/FilterTypes/metric';
import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { PERFORMANCE_BACKEND_TIME_FORMAT } from '@modules/Core';
import { isLoading } from '@showtime/cea-dep-utility';
import moment from 'moment';

export const getPerformancesParams = getParametersFactory([dates, metric, currency]);

export const getPerformanceModalDataParams = getParametersFactory([dates, territory, currency]);

export const getPlannerView = _property(STATE_KEY);

export const getPerformances = createSelector([getPlannerView], _property('data'));

export const getPerformanceModalData = createSelector([getPlannerView], _property('performanceModalData'));

export const getPerformanceAttributes = createSelector([getPlannerView], _property('performanceAttributes'));

export const isPerformanceRequestLoading = (state) => isLoading(state, PERFORMANCES_FETCH_KEY);

/**
 * Screens are grouped by SCREEN_SORTING_KEY
 * @return {object[]} - {screenName, values}
 */
export const getPerformancesGroupedByScreen = createSelector([getPerformances], (data) => {
  const screensList = [];
  const screens = _groupBy(data, SCREEN_SORTING_KEY);
  _mapValues(screens, (values, screen) => {
    const valuesSortedByDate = _sortBy(values, ({ datetime }) => moment(datetime, PERFORMANCE_BACKEND_TIME_FORMAT), [
      'asc',
    ]);
    screensList.push({ [SCREEN_SORTING_KEY]: screen, values: valuesSortedByDate });
  });
  return screensList;
});

export const getEarliestPerformanceTime = createSelector([getPerformances], (data) => {
  const times = data
    .map((item) => {
      const date = new Date(item.datetime);
      return date.getTime();
    })
    .filter((date) => !isNaN(date));

  return data.length ? new Date(Math.min(...times)) : null;
});
