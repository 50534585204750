import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const SCROLL_TRIGGER_ID = 'theatreDiagonalLineScrollTrigger';
const DIAGONAL_LINE_ANGLE = '82%';
/**
 * useActionCtnAnimation
 * Purple metric Container animation.
 * Anim Starts with a diagonal line at bottom pointing downward from ltr
 * Anim Ends with container having a straight line.
 * Anim is scrubbing with the scroll bar and it should take 50px of scrolling to complete.
 */
export const useActionCtnAnimation = () => {
  let animTimeline = useRef(null);
  let actionsContainerRef = useRef(null);
  useEffect(() => {
    animTimeline.current = gsap.timeline();
    animTimeline.current.fromTo(
      actionsContainerRef.current,
      {
        '-webkit-clip-path': `polygon(0% 0%, 100% 0%, 100% 100%, 0% ${DIAGONAL_LINE_ANGLE})`,
        clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, 0% ${DIAGONAL_LINE_ANGLE})`,
      },
      {
        '-webkit-clip-path': `polygon(0% 0%, 100% 0%, 100% ${DIAGONAL_LINE_ANGLE}, 0% ${DIAGONAL_LINE_ANGLE})`,
        clipPath: `polygon(0% 0%, 100% 0%, 100% ${DIAGONAL_LINE_ANGLE}, 0% ${DIAGONAL_LINE_ANGLE})`,
      }
    );

    ScrollTrigger.create({
      id: SCROLL_TRIGGER_ID,
      animation: animTimeline.current,
      toggleActions: 'restart reverse none reverse',
      end: 'top -50px',
      ease: 'power1.easeInOut',
      scrub: true,
    });

    return () => {
      // Cleanup ScrollTrigger instances when leaving view
      const currentScrollTrigger = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (currentScrollTrigger) {
        currentScrollTrigger.kill(true);
      }
    };
  }, [animTimeline]);

  const actionsContainerRefBinder = actionsContainerRef;

  return [actionsContainerRefBinder];
};
