import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { APP_END } from '@modules/Core';
import { createSetFilterActionType, createSetSelectedActionType, createUpdateFilterActionType } from './actionTypes';

const defaultState = {
  selected: [],
};

export const attributeSetter = (attribute) => {
  return (state, { values }) =>
    update(state, {
      [attribute]: { $set: values },
    });
};

/**
 * Builds a filter reducer associated with a filter key.
 *
 * ```
 * [key] : {
 *   data: [],
 *   selected: [],
 * }
 * ```
 *
 * passing in an initial state with any one of the above keys will override the default values
 * which is an array
 * ```
 * {
 *   data: {} // Other data structure
 * }
 * ```
 *
 * @param {string} key - unique filter identifier
 * @param {any} initialState - state to be merged with the filter default state
 * @param {any} actions - additional actions that should be
 */
export const createFilterReducer = (key, initialState = {}, actions = {}) => {
  const getInitialState = () => ({
    ...defaultState,
    ...initialState,
  });

  return createReducer(getInitialState(), {
    [createSetSelectedActionType(key)]: attributeSetter('selected'),
    [createSetFilterActionType(key)]: (state, { value }) => update(state, { $set: value }),
    [createUpdateFilterActionType(key)]: (state, { value }) => update(state, { $merge: { ...value } }),
    [APP_END]: getInitialState,
    ...actions,
  });
};
