import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { gsap } from 'gsap';

import { Icon } from '@components';

import './_arrow.scss';

const propTypes = {
  animate: PropTypes.bool,
  animPlay: PropTypes.bool,
  dir: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  className: PropTypes.string,
  arrowPathClassName: PropTypes.string,
};
const defaultProps = {
  animate: false,
  animPlay: false,
  dir: 'down',
  className: '',
  arrowPathClassName: '',
};

const componentName = 'arrow';
/**
 * Arrow
 * @param {bool} animate controls if animations can be ran or not.
 * @param {bool} animPlay Will play or reverse the animation depending on value.
 *                        The animation will rotate the arrow 180 degrees
 * @param {str} dir Initial direction arrow should be pointing.
 */
const Arrow = ({ animate, animPlay, dir, className }) => {
  let arrowRef = useRef(null);
  let animTimeline = useRef(null);

  useEffect(() => {
    if (animate) {
      animTimeline.current = gsap.timeline({
        paused: true,
      });
      animTimeline.current.to([arrowRef.current], {
        rotate: 180,
        transformOrigin: '50% 50%',
        duration: 0.2,
      });
    }
  }, [arrowRef, animTimeline, animate]);

  useEffect(() => {
    if (animate) {
      if (animPlay) {
        animTimeline.current.play();
      } else {
        animTimeline.current.reverse();
      }
    }
  }, [animPlay, animate]);

  return (
    <div data-testid="Arrow" className={classnames(componentName, className, `${componentName}--${dir}`)}>
      <Icon ref={arrowRef} icon="down" />
    </div>
  );
};

Arrow.propTypes = propTypes;
Arrow.defaultProps = defaultProps;

export default Arrow;
