import { useEffect, useMemo } from 'react';
import { isPassword } from '@modules/Core';
import _get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { FETCH_UPDATE_PASSWORD } from '../../../constants';
import { clearError } from '@showtime/cea-dep-utility';

const FORM_FIELDS = {
  NEW_PASSWORD: 'new_password',
  CURRENT_PASSWORD: 'current_password',
  CONFIRM_NEW_PASSWORD: 'confirm_new_password',
};

export const useAlert = (httpError, currentPassword, newPassword, confirmPassword, submitForm) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearError(FETCH_UPDATE_PASSWORD));
  }, [currentPassword, submitForm]);

  return useMemo(() => {
    // Form fields error handler
    let status,
      formField = [],
      message = false;

    if (submitForm) {
      if (currentPassword === '' || newPassword === '' || confirmPassword === '') {
        if (currentPassword === '') formField.push(FORM_FIELDS.CURRENT_PASSWORD);
        else if (newPassword === '') formField.push(FORM_FIELDS.NEW_PASSWORD);
        else if (confirmPassword === '') formField.push(FORM_FIELDS.CONFIRM_NEW_PASSWORD);
        status = 'error';
        message = 'required_field';
      } else if (!isPassword(newPassword)) {
        status = 'error';
        message = 'error_incorrect_password_type';
        formField = [FORM_FIELDS.NEW_PASSWORD];
      } else if (newPassword && confirmPassword && newPassword !== confirmPassword) {
        status = 'error';
        message = 'error_match_passwords';
        formField = [FORM_FIELDS.NEW_PASSWORD, FORM_FIELDS.CONFIRM_NEW_PASSWORD];
      }
    }

    // Http error handler
    const httpErrorStatus = _get(httpError, 'error.status');
    const httpErrorCode = _get(httpError, 'error.body.errorCode');

    if (httpErrorStatus === 500) {
      status = 'error';
      message = 'server_error';

      if (httpErrorCode === 'invalid_password') {
        message = 'error_incorrect_password';
        formField = [FORM_FIELDS.CURRENT_PASSWORD];
      }
    }

    return { status, message, formField };
  }, [httpError, currentPassword, newPassword, confirmPassword, submitForm]);
};
