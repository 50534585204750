import _zip from 'lodash/zip';
import _merge from 'lodash/merge';

const series1 = [
  { time: '0:00', value: 0 },
  { time: '1:00', value: 0 },
  { time: '2:00', value: 0 },
  { time: '3:00', value: 0 },
  { time: '4:00', value: 134.9706 },
  { time: '5:00', value: 330.2849 },
  { time: '6:00', value: 471.45259999999996 },
  { time: '7:00', value: 643.0337 },
  { time: '8:00', value: 822.0423 },
  { time: '9:00', value: 1049.5279 },
  { time: '10:00', value: 1175.401 },
  { time: '11:00', value: 1366.7599 },
  { time: '12:00', value: 2137.4676 },
  { time: '13:00', value: 2929.3154 },
  { time: '14:00', value: 3680.3775 },
  { time: '15:00', value: 4451.4809000000005 },
  { time: '16:00', value: 5252.734 },
  { time: '17:00', value: 6023.441900000001 },
  { time: '18:00', value: 6023.441900000001 },
  { time: '19:00', value: 6023.441900000001 },
  { time: '20:00', value: 6023.441900000001 },
  { time: '21:00', value: 6023.441900000001 },
  { time: '22:00', value: 6023.441900000001 },
  { time: '23:00', value: 6023.441900000001 },
];

const series2 = [
  { time: '0:00', value2: 0 },
  { time: '1:00', value2: 0 },
  { time: '2:00', value2: 0 },
  { time: '3:00', value2: 0 },
  { time: '4:00', value2: 264.0083 },
  { time: '5:00', value2: 576.1858 },
  { time: '6:00', value2: 953.9808 },
  { time: '7:00', value2: 1363.3319000000001 },
  { time: '8:00', value2: 1660.4344 },
  { time: '9:00', value2: 1997.3114 },
  { time: '10:00', value2: 2290.5903 },
  { time: '11:00', value2: 2712.7309999999998 },
  { time: '12:00', value2: 4443.659799999999 },
  { time: '13:00', value2: 6309.2957 },
  { time: '14:00', value2: 8023.4797 },
  { time: '15:00', value2: 9878.6112 },
  { time: '16:00', value2: 10240.687 },
  { time: '17:00', value2: 10577.5795 },
  { time: '18:00', value2: 10577.5795 },
  { time: '19:00', value2: 10577.5795 },
  { time: '20:00', value2: 10577.5795 },
  { time: '21:00', value2: 10577.5795 },
  { time: '22:00', value2: 10577.5795 },
  { time: '23:00', value2: 10577.5795 },
];
const series3 = [
  { time: '0:00', value3: 0 },
  { time: '1:00', value3: 0 },
  { time: '2:00', value3: 0 },
  { time: '3:00', value3: 0 },
  { time: '4:00', value3: 66.002075 },
  { time: '5:00', value3: 144.04645 },
  { time: '6:00', value3: 238.4952 },
  { time: '7:00', value3: 340.83297500000003 },
  { time: '8:00', value3: 415.1086 },
  { time: '9:00', value3: 499.32785 },
  { time: '10:00', value3: 572.647575 },
  { time: '11:00', value3: 678.1827499999999 },
  { time: '12:00', value3: 1110.9149499999999 },
  { time: '13:00', value3: 1577.323925 },
  { time: '14:00', value3: 2005.869925 },
  { time: '15:00', value3: 2469.6528 },
  { time: '16:00', value3: 2935.17175 },
  { time: '17:00', value3: 3394.394875 },
  { time: '18:00', value3: 3394.394875 },
  { time: '19:00', value3: 3394.394875 },
  { time: '20:00', value3: 3394.394875 },
  { time: '21:00', value3: 3394.394875 },
  { time: '22:00', value3: 3394.394875 },
  { time: '23:00', value3: 3394.394875 },
];
const zipped = _zip(series1, series2, series3).map((zipped) => {
  return _merge(...zipped);
});

export default zipped;
