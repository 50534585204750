import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { gsap } from 'gsap';

const VIEW_TRANSITION_EASE = 'Power1.easeInOut';

const ViewTransition = ({ className, children, ...props }) => {
  let viewRef = useRef(null);
  let animTimeline = useRef(null);
  /**
   * ANIMATION EFFECTS
   * onpageMount, slowly fade in once.
   */
  useEffect(() => {
    animTimeline.current = gsap.timeline({ paused: true, delay: 0.25 });
    animTimeline.current.fromTo(
      [viewRef.current],
      {
        duration: 0.5,
        opacity: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.5,
        opacity: 1,
        ease: VIEW_TRANSITION_EASE,
      }
    );
    animTimeline.current.play();

    return () => {
      animTimeline.current.reverse();
    };
  }, [animTimeline]);

  return (
    <div className={classnames('view-transition', className, {})} ref={viewRef} {...props}>
      {<>{children}</>}
    </div>
  );
};

ViewTransition.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

ViewTransition.defaultProps = {
  className: '',
  children: null,
};

export default ViewTransition;
