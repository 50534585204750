import _property from 'lodash/property';
import { createSelector } from '@reduxjs/toolkit';
import { getUserProfile } from '../Auth';
import { isLoading } from '@showtime/cea-dep-utility';
import { USER_SETTING_KEYS, FETCH_UPDATE_KEY } from './constants';

export const getUserLocale = createSelector([getUserProfile], _property(USER_SETTING_KEYS.language));

export const getLocalTitle = createSelector([getUserProfile], _property(USER_SETTING_KEYS.localTitle));

export const getLocalCurrency = createSelector([getUserProfile], _property(USER_SETTING_KEYS.localCurrency));

export const getAvailableLanguages = createSelector([getUserProfile], _property(USER_SETTING_KEYS.availableLanguages));

export const isUpdatingUserSettings = (state) => isLoading(state, FETCH_UPDATE_KEY);
