import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';

import TopImage from '@images/tickets.svg';

import Header from './Header';
import Content from './Content';
import Footer from './Footer';

/**
 * Authentication page Container component
 * Renders the container of the authentication pages and components common to all of them
 *
 * @param {string} className
 * @param {node} children - Content specific to each auth page
 * @param {bool} hasTopImage - Determines if the container renders the top image or not (eg: Reset password pages do not display this image)
 *
 */

const componentName = 'auth-container';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  hasTopImage: PropTypes.bool,
};

const defaultProps = {
  className: '',
  hasTopImage: false,
};

export const Container = ({ children, hasTopImage, className }) => {
  return (
    <div className={classnames(componentName, className)}>
      {/* TODO: animation for the top image */}
      {hasTopImage && (
        <div className={`${componentName}__top-image-container`}>
          <img className={`${componentName}__top-image`} src={TopImage} alt="Tickets" />
        </div>
      )}
      {/* TODO: wrap these 3 components to apply the corresponding animation */}
      <Header className={classnames(`${componentName}__header`, {[`${componentName}__header--no-margin`]: hasTopImage })}/>
      <Content>{children}</Content>
      <Footer />
    </div>
  );
};

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
