/**
 * Validates an email string
 *
 * @param {string} email email string to test
 */
export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 * Validates a password
 *
 * @param {string} password password string to test
 */
export function isPassword(password) {
  const re =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_+}{,:;=|’<>.^)(-[\\\]])[A-Za-z\d@$!%*#?&_+}{,:;=|’<>.^)(-[\\\]]{8,}$/;
  return re.test(password);
}
