import PropTypes from 'prop-types';

export const BASE_CLASS = 'table';

export const COLUMNS_SHAPE = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    /**
      * renderCell is passed this shape as a param:
      {
        value: PropTypes.number -> current cellValue
        key: PropTypes.string -> current cellValue key
        rowData: PropTypes.shape({}) -> current rows data object
        columnData: PropTypes.shape({}) -> current column object
      }
    */
    renderCell: PropTypes.func.isRequired,
    /**
     * renderHeaderCell is passed current column object as a param
     */
    renderHeaderCell: PropTypes.func,
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]),
    sort: PropTypes.bool,
  })
).isRequired;
