import React from 'react';
import { Accordian, Item, ItemHeader, ItemContent } from './components';

import './_accordian.scss';

function AccordianComponent(props) {
  return <Accordian {...props} />;
}

AccordianComponent.Item = Item;
AccordianComponent.ItemContent = ItemContent;
AccordianComponent.ItemHeader = ItemHeader;

export default AccordianComponent;
