import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '@components';

const componentName = 'input-field';

/**
 * Defines props used by the field wrapping elements
 * Every other prop is forwarded to <input> tag.
 */
const propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  hasError: PropTypes.bool,
  subtext: PropTypes.node,
  children: PropTypes.node,
  inputRef: PropTypes.any,
  border: PropTypes.bool,
};

const defaultProps = {
  type: 'text',
  hasError: false,
  border: true,
};

export const InputField = ({ label, className, hasError, children, subtext, inputRef, border, ...inputProps }) => {
  return (
    <div className={classnames(className, `${componentName}__container`)}>
      {label && (
        <Text className={`${componentName}__label`} tag="span" size="m" bold>
          {label}
        </Text>
      )}
      <div className={`${componentName}__input-container`}>
        <input
          {...inputProps}
          className={classnames(componentName, {
            [`${componentName}--error`]: hasError,
            [`${componentName}--border`]: border,
          })}
          ref={inputRef}
        />
        {children}
      </div>
      {subtext && <Text className={`${componentName}__subtext`}>{subtext}</Text>}
    </div>
  );
};

InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;

export default InputField;
