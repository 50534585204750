import { createSelector } from '@reduxjs/toolkit';
import { KEY, PARAM_KEY, ORDER_OPTION_ASC, ORDER_OPTION_DESC, DEFAULT_ORDER_OPTION, KEY_METRIC } from './constants';
import { getSelectedSelector, createAttributeSelector } from '@modules/Filters';

export const getSelected = getSelectedSelector(KEY, DEFAULT_ORDER_OPTION);

export const getSortMetricSelected = createAttributeSelector(KEY, KEY_METRIC, null);

export const getParameters = createSelector([getSelected, getSortMetricSelected], (type, metric) => {
  if (type && (type === ORDER_OPTION_ASC || type === ORDER_OPTION_DESC)) {
    return { [PARAM_KEY]: metric ? [`${metric},${type}`] : [] };
  }
  return {};
});
