import React, { useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { SearchInputField, Text } from '@components';
import List from './List';
import { getNonFavouriteTerritores, getFavouriteTerritores } from '../selectors';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSearch } from '@modules/Core';

import { getSelectedTerritory } from '@modules/TerritorySelect';

const componentName = 'territory-select';

function Territories({ className, onSelectItem }) {
  const { formatMessage } = useIntl();
  const territory = useSelector(getSelectedTerritory);
  const [search, setSearch, matchesSearch] = useSearch('');
  const listData = useSelector(getNonFavouriteTerritores);
  const favouriteListData = useSelector(getFavouriteTerritores);

  const noResultOnSearch = useMemo(
    () => !listData.concat(favouriteListData).filter(({ name }) => matchesSearch(name)).length,
    [listData, favouriteListData, matchesSearch]
  );

  return (
    <div className={classnames(componentName, className)}>
      <div className={classnames(`${componentName}__search`)}>
        <SearchInputField value={search} onChange={(event) => setSearch(event.target.value)} />
      </div>

      <div className={classnames(`${componentName}__scroll`)}>
        <div className={classnames(`${componentName}__scroll__inner`)}>
          {!_isEmpty(territory) && (
            <>
              <List
                title={formatMessage({ id: 'user_settings.selected_territory' })}
                disabled={true}
                options={[territory]}
                onSelectItem={() => {}}
              />
              <hr className={classnames(`${componentName}__divider`)} />
            </>
          )}
          {favouriteListData.length > 0 && (
            <>
              <List
                title={formatMessage({ id: 'user_settings.favourite_territories' })}
                options={favouriteListData}
                onSelectItem={onSelectItem}
                filterNameBy={search}
              />
              <hr className={classnames(`${componentName}__divider`)} />
            </>
          )}
          <List
            title={formatMessage({ id: 'user_settings.territories' })}
            options={listData}
            onSelectItem={onSelectItem}
            filterNameBy={search}
          />
          {noResultOnSearch && (
            <Text className={classnames(`${componentName}__no-result`)}>
              <FormattedMessage id={'no_results_found'} />
            </Text>
          )}
        </div>
      </div>
    </div>
  );
}

Territories.propTypes = {
  className: PropTypes.string,
  onSelectItem: PropTypes.func,
};

export default Territories;
