import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import { clearError } from '@showtime/cea-dep-utility';
import { BOOKMARKS_FETCH_UPDATE_KEY, MAX_NAME_FIELD_CHARS } from '../constants';

export const useAlertFormValidation = (httpError, nameField, submitForm) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!_isEmpty(httpError)) dispatch(clearError(BOOKMARKS_FETCH_UPDATE_KEY));
  }, [nameField, submitForm]);

  return useMemo(() => {
    let status,
      message = false;

    // Form validation error
    if (submitForm) {
      if (nameField === '') {
        status = 'error';
        message = formatMessage({ id: 'bookmark.error.name_required' });
      }

      if (nameField && nameField.length > MAX_NAME_FIELD_CHARS) {
        status = 'error';
        message = formatMessage({ id: 'bookmark.error.name_too_long' }, { maxChars: MAX_NAME_FIELD_CHARS });
      }
    }

    // Http error handler
    const httpErrorStatus = _get(httpError, 'error.status');

    if (httpErrorStatus === 409) {
      status = 'error';
      message = formatMessage({ id: 'bookmark.error.name_duplication' });
    } else if (httpErrorStatus >= 400 && 500 <= httpErrorStatus) {
      status = 'error';
      message = formatMessage({ id: 'bookmark.error.unexpected' });
    }

    return { status, message };
  }, [httpError, nameField, submitForm]);
};
