import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { CapacityNumberInline } from '@modules/Metrics';
import { Heading } from '@components';

import { getValueByTheatreID, getCapacityRemainingByTheatreID } from '../selectors';

function CapacityValue({ id }) {
  const value = useSelector(getValueByTheatreID(id));
  const capacityRemaining = useSelector(getCapacityRemainingByTheatreID(id));
  return (
    <Heading>{value ? <CapacityNumberInline capacityRemaining={capacityRemaining} capacity={value} /> : ' - '}</Heading>
  );
}

CapacityValue.propTyoes = { id: PropTypes.number };

export default CapacityValue;
