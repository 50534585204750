import React, { useEffect } from 'react';
import { arrayOf } from 'prop-types';
import { CompsList, CompsSelectionDrawer } from '@modules';
import { useAction } from '@modules/Core';
import { CompsColors, CompsMetricToggle, COMPS_AVAILABLE_METRICS, useSelectedMetric } from '@modules/Comps';
import { CompsLineChart } from '@modules/CompsChart';
import { metricKeyPropType } from '@modules/Metrics';
import { setSelected } from '@modules/FilterTypes/metric';

import ViewTransition from './ViewTransition';

const propTypes = {
  metrics: arrayOf(metricKeyPropType),
};

const ViewComps = ({ metrics }) => {
  const setSelectedMetric = useAction(setSelected);
  const [selectedMetric] = useSelectedMetric();

  // Initializing selected metric as the first compsMetric
  useEffect(() => {
    setSelectedMetric(metrics[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics]);

  return COMPS_AVAILABLE_METRICS.includes(selectedMetric) ? (
    <ViewTransition className="film-metadata__view film-metadata__view--comps">
      <div className="comps__toggle">
        <CompsMetricToggle metrics={metrics} />
      </div>
      <div className="comps__chart">
        <CompsLineChart metrics={metrics} />
      </div>
      <CompsList className="comps__list" />
      <CompsSelectionDrawer />
      <CompsColors />
    </ViewTransition>
  ) : null;
};

ViewComps.propTypes = propTypes;

export default ViewComps;
