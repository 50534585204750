import { useSelector } from 'react-redux';
import { useAction } from '@modules/Core';
import { setCurrentPage, resetPagination } from '../actions';
import { getCurrentPage, getNumPages, hasPages } from '../selectors';

export function usePagination() {
  return {
    currentPage: useSelector(getCurrentPage),
    numPages: useSelector(getNumPages),
    setCurrentPage: useAction(setCurrentPage),
    resetPagination: useAction(resetPagination),
    hasPages: useSelector(hasPages),
  };
}
