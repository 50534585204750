import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import _uniq from 'lodash/uniq';

import { useParametersListener } from '@modules/FilterTypes';
import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useBaseEventMetadata } from '@modules/Comps';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';

import { useLastRefresh } from '@modules';

import {
  getFullscreenChartData,
  isLoadingFullscreenData,
  hasFullscreenDataErrored,
  getChartParams,
} from '../selectors';
import { fetchFullScreenChartData } from '../actions';

export const useOverlayChartFetcher = (metrics) => {
  const dispatchFetch = useAction(fetchFullScreenChartData);
  const [baseEventMetadata] = useBaseEventMetadata();
  const lastRefreshed = useLastRefresh();
  const [subSectionPath, dataPath] = useAPIPath();
  const [eventParamKey] = useEventParamKey();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getChartParams,
    (params) => {
      if (baseEventMetadata.id !== null) {
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        if (!params[eventParamKey]) {
          params[eventParamKey] = [baseEventMetadata.id];
        } else {
          params[eventParamKey] = _uniq([baseEventMetadata.id, ...params[eventParamKey]]);
        }

        dispatchFetch({...params, ...timeParams}, metrics, subSectionPath, dataPath);
      }
    },
    [baseEventMetadata.id, lastRefreshed, metrics, subSectionPath, dataPath, isAdvancedSales, isToday]
  );
};

export const useOverlayChartData = () => {
  const { formatMessage } = useIntl();
  const isAdvancedSales = useIsAdvancedSales();
  const getDataSelector = useMemo(() => getFullscreenChartData(isAdvancedSales, formatMessage), [isAdvancedSales, formatMessage]);
  const data = useSelector(getDataSelector);
  const isLoading = useSelector(isLoadingFullscreenData);
  const error = useSelector(hasFullscreenDataErrored);

  return [data, isLoading, error];
};
