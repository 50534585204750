import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useAppContextState } from '@modules';
import { useDates } from '@modules/FilterTypes/dates';
import { useSwipeX } from '@modules/Core';
import { formatDate } from './helpers';

const changeDate = (operation, value, increment = 1) => {
  const momentDate = moment(value);
  switch (operation) {
    case 'subtract':
      return formatDate(momentDate.subtract(increment, 'days'));

    default:
    case 'add':
      return formatDate(momentDate.add(increment, 'days'));
  }
};

/**
 * DateChangeSwipe
 * Wraps children in a div.
 * Whenever it detects a swipe along the x-axis of this div onSwipeLeft or onSwipeRight callbacks will fire.
 * Updates main page dates filter onSwipeChange.
 * @param {node} children
 */
const DateChangeSwipe = ({ children, className, onSwipeLeft, onSwipeRight, checkIsInMinMaxRange, isDisabled }) => {
  const [selectedDates, setSelected] = useDates();

  const { isOverlayOpen, isDrawerOpen } = useAppContextState();

  /**
   * If Drawers or Overlays are open DateChangeSwipe should automatically be disabled.
   * If isDisabled prop is true then it should also be disabled for these cases.
   */
  const isDateChangeSwipeDisabled = useMemo(() => {
    return isOverlayOpen || isDrawerOpen || isDisabled;
  }, [isDisabled, isOverlayOpen, isDrawerOpen]);

  const handleSwipeLeft = useCallback(() => {
    if (!isDateChangeSwipeDisabled) {
      const value = changeDate('add', selectedDates[0]);
      if (checkIsInMinMaxRange(value)) {
        setSelected([value, value]);
        onSwipeLeft();
      }
    }
  }, [selectedDates, setSelected, onSwipeLeft, checkIsInMinMaxRange, isDateChangeSwipeDisabled]);

  const handleSwipeRight = useCallback(() => {
    if (!isDateChangeSwipeDisabled) {
      const value = changeDate('subtract', selectedDates[0]);
      if (checkIsInMinMaxRange(value)) {
        setSelected([value, value]);
        onSwipeRight();
      }
    }
  }, [setSelected, selectedDates, onSwipeRight, checkIsInMinMaxRange, isDateChangeSwipeDisabled]);

  const bindSwipe = useSwipeX({ onSwipeLeft: handleSwipeLeft, onSwipeRight: handleSwipeRight });
  return (
    <div {...bindSwipe()} className={className}>
      {children}
    </div>
  );
};

DateChangeSwipe.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onSwipeLeft: PropTypes.func,
  onSwipeRight: PropTypes.func,
  checkIsInMinMaxRange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

DateChangeSwipe.defaultProps = {
  children: null,
  className: null,
  onSwipeLeft: () => {},
  onSwipeRight: () => {},
  checkIsInMinMaxRange: () => {},
  isDisabled: false,
};

export default DateChangeSwipe;
