import { createSelector } from '@reduxjs/toolkit';
import _property from 'lodash/property';

import { isLoading } from '@showtime/cea-dep-utility';

import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { getParametersFactory } from '@modules/FilterTypes';

import { STATE_KEY, REPORT_CIRCUITS_FETCH_KEY, REPORT_SUMMARY_FETCH_KEY } from './constants';

export const getReportingState = _property(STATE_KEY);

export const getReportingSummary = createSelector([getReportingState], _property('summary'));
export const getReportingCircuits = createSelector([getReportingState], _property('circuits'));
export const getReportingPagination = createSelector([getReportingState], _property('pagination'));
export const getReportingSort = createSelector([getReportingState], _property('sort'));

export const isLoadingReportingSummary = (state) => isLoading(state, REPORT_SUMMARY_FETCH_KEY);
export const isLoadingReportingCircuits = (state) => isLoading(state, REPORT_CIRCUITS_FETCH_KEY);

export const getReportingLastUpdated = createSelector([getReportingSummary], _property('lastUpdated'));
export const getReportingReportedSites = createSelector([getReportingSummary], _property('reportedSites'));
export const getReportingReportedSitesYesterday = createSelector(
  [getReportingSummary],
  _property('reportedSitesYesterday')
);
export const getReportingRealTimeReportingSites = createSelector(
  [getReportingSummary],
  _property('realTimeSitesReporting')
);

export const getIsReportingShowtime = createSelector([getReportingSummary], _property('showtime'));
export const getIsReportingComscore = createSelector([getReportingSummary], _property('comscore'));

export const getReportingFeedType = createSelector(
  [getIsReportingShowtime, getIsReportingComscore],
  (showtime, comscore) => {
    if (showtime) {
      return 'showtime';
    }

    if (comscore) {
      return 'comscore';
    }

    return null;
  }
);

export const getParametersForReportingSummary = getParametersFactory([dates, territory]);
export const getParametersForReportingSites = getParametersFactory([dates, territory]);

export const getCurrentPage = createSelector([getReportingPagination], _property('current'));
export const getNumPages = createSelector([getReportingPagination], _property('numPages'));
export const hasPages = createSelector([getNumPages], (numPages) => numPages > 1);

export const getSortField = createSelector([getReportingSort], _property('field'));
export const getSortDir = createSelector([getReportingSort], _property('dir'));
