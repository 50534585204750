import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useChart, useIsToday } from '@modules/Core';
import { useSelectedMetric, useCompColorPairs, getBaseEventId, BASE_COLOR } from '@modules/Comps';

import { baseClass } from '../constants';
import { useEmbeddedChartData, useChartValueAxisFormatter, useChartCategoryAxisFormatter } from '../hooks';
import {
  getFormattedField,
  getEmbeddedAdvancedSalesConfig,
  getAdvancedSalesEmbeddedSeriesConfig,
  getDataField,
} from '../helpers';

function EmbeddedAdvancedSalesCompsChart() {
  const [data] = useEmbeddedChartData();
  const baseId = useSelector(getBaseEventId);
  const [selectedMetric] = useSelectedMetric();
  const compColorPairs = useCompColorPairs();
  const isToday = useIsToday();

  const seriesConfig = useMemo(() => {
    const baseConfig = {
      dataField: getDataField(baseId, selectedMetric),
      color: BASE_COLOR,
    };
    const compsDataFields = compColorPairs.map(({ id, color }) => ({
      dataField: getDataField(id, selectedMetric),
      color: color,
    }));

    return getAdvancedSalesEmbeddedSeriesConfig(baseConfig, compsDataFields, isToday);
  }, [baseId, compColorPairs, selectedMetric, isToday]);

  const compsChartConfig = useMemo(() => {
    const conf = getEmbeddedAdvancedSalesConfig(isToday);
    conf.xAxes[0].dataFields.category = 'id';
    conf.xAxes[0].dataFields.value = getFormattedField(`${baseId}_${selectedMetric}`);
    return { ...conf };
  }, [isToday]);

  const { chartRef, setChartData, updateChartSeriesConfig, chart } = useChart(compsChartConfig);

  useEffect(() => {
    seriesConfig[0].dataFields.categoryX = 'id';
    seriesConfig[0].dataFields.valueY = getFormattedField(`${baseId}_${selectedMetric}`);
    updateChartSeriesConfig(seriesConfig);
  }, [selectedMetric, seriesConfig, updateChartSeriesConfig]);

  useEffect(() => {
    setChartData(data);
  }, [data, setChartData]);

  useChartValueAxisFormatter(chart, selectedMetric);

  return <div ref={chartRef} className={`${baseClass}__embedded`} />;
}

export default EmbeddedAdvancedSalesCompsChart;
