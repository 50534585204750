import { KEY } from './constants';
import { createFilterReducer } from '@modules/Filters';

const initialState = {
  selected: [],
};

const datesReducer = createFilterReducer(KEY, initialState);

export default {
  [KEY]: datesReducer,
};
