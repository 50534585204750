import { isPassword } from '@modules/Core';
import _get from 'lodash/get';

export const getError = (password, confirmPassword) => {
  let status, message;
  if (password === '' || confirmPassword === '') {
    status = 'error';
    message = 'required_field';
  } else if (!isPassword(password)) {
    status = 'error';
    message = 'incorrect_password';
  } else if (password && confirmPassword && password !== confirmPassword) {
    status = 'error';
    message = 'error_match_passwords';
  }

  return { status, message };
};

export const getHttpError = (httpError) => {
  const httpStatus = _get(httpError, 'error.status');
  let status, message;
  if (httpStatus) {
    switch (httpStatus) {
      case 404:
        status = 'error';
        message = 'restart_invalid_token';
        break;
      case 500:
      case 504:
        status = 'error';
        message = 'server_error';
    }
  }

  return { status, message };
};
