import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

import { useTabsContext } from './Context';
import { TAB_TRANSITION_PROP_TYPE } from './transitions';

const propTypes = {
  tabTo: PropTypes.string,
  // Sets tab transitions for tabs.
  // Use to modify transitions when navigating, e.g. to simulate changin directions.
  tabTranistionType: TAB_TRANSITION_PROP_TYPE,
  // allow navigating to an undefined tab.
  tabAllowEmpty: PropTypes.bool,
  component: PropTypes.elementType,
  onClick: PropTypes.func,
};

const defaultProps = {
  component: 'div',
  onClick: _noop,
};

const TabLink = ({ tabTo, tabAllowEmpty, tabTranistionType, component: Component, onClick, ...props }) => {
  const { goToTab, tabs } = useTabsContext();

  const onLinkClick = useCallback(
    (...args) => {
      if (tabs.has(tabTo) || tabAllowEmpty) {
        goToTab(tabTo, tabTranistionType);
      }
      onClick(...args);
    },
    [tabAllowEmpty, tabTranistionType, tabs, tabTo, onClick, goToTab]
  );

  return <Component {...props} onClick={onLinkClick} />;
};

TabLink.propTypes = propTypes;
TabLink.defaultProps = defaultProps;

export default TabLink;
