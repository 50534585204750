import React from 'react';
import PropTypes from 'prop-types';

import { COMPONENT_BASE_CLASS } from '../constants';
import { AccordianProvider } from './AccordianContext';

/**
 * Accordian
 * @param {auto} autoCollapseItems - If enabled onOpen of one accordian item will close all other items.
 */
const Accordian = ({ children, className, autoCollapseItems }) => {
  return (
    <AccordianProvider autoCollapseItems={autoCollapseItems}>
      <div className={`${COMPONENT_BASE_CLASS} ${className}`}>{children}</div>
    </AccordianProvider>
  );
};

Accordian.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  autoCollapseItems: PropTypes.bool,
};

Accordian.defaultProps = {
  children: null,
  className: '',
  autoCollapseItems: false,
};

export default Accordian;
