import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import _uniq from 'lodash/uniq';
import _get from 'lodash/get';

import { asyncAction } from '@showtime/cea-dep-utility';

import { API_ROOT_PATH, getDefaultParams } from '@modules/Core';
import { STATE_KEY, COMPS_LIST_FETCH_KEY } from './constants';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_BASE_EVENT_ID = 'SET_BASE_EVENT_ID';
export const setBaseEventId = createAction(createActionType(SET_BASE_EVENT_ID));

const SET_BASE_EVENT_METADATA = 'SET_BASE_EVENT_METADATA';
export const setBaseEventMetadata = createAction(createActionType(SET_BASE_EVENT_METADATA));

const SET_COMPS_EVENTS_METADATA = 'SET_COMPS_EVENTS_METADATA';
export const setCompsEventsMetadata = createAction(createActionType(SET_COMPS_EVENTS_METADATA));

const REMOVE_COMPS_ITEM = 'REMOVE_COMPS_ITEM';
export const removeCompsItem = createAction(createActionType(REMOVE_COMPS_ITEM));

export const fetchCompEvents = (baseEventId, params, metrics, eventParamKey, subSectionPath = '', dataPath) => {
  if (params[eventParamKey]) {
    params[eventParamKey] = _uniq([baseEventId, ...params[eventParamKey]]);
  } else {
    params[eventParamKey] = _uniq([baseEventId]);
  }

  return asyncAction({
    key: COMPS_LIST_FETCH_KEY,
    url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/comps?_query=json`,
    body: {
      mets: [...metrics, 'runWeek'].join(','),
      ...params,
      ...getDefaultParams(),
    },
    method: 'POST',
    useDefaultErrorHandler: false,
    success(response) {
      const [baseEvent, ...compsEvents] = response.map((comp) => {
        const format = 'YYYY-MM-DD';
        const runWeek = _get(comp, 'runWeek', comp.day / 7);

        return {
          ...comp,
          runWeek,
          dayDate: comp.date
            ? moment(comp.date, format)
                .add(comp.day - 1, 'days')
                .format(format)
            : null,
        };
      });

      this.dispatch(setBaseEventMetadata(baseEvent));
      this.dispatch(setCompsEventsMetadata(compsEvents));
    },
  });
};

const ASSIGN_COLORS = 'ASSIGN_COLORS';
export const assignColors = createAction(createActionType(ASSIGN_COLORS));

const CLEAN_STORE = 'CLEAN_STORE';
export const cleanUpComps = createAction(createActionType(CLEAN_STORE));
