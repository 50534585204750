import React, { useEffect, useState, useCallback, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useKeenSlider } from 'keen-slider/react';
import classnames from 'classnames';

import 'keen-slider/keen-slider.min.css';
import './_slider.scss';

import Slide from './Slide';

const propTypes = {
  className: PropTypes.string,
  initialActiveValue: PropTypes.node.isRequired,
  initialActiveIndex: PropTypes.number,
  minimumValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  maximumValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  slidesPerView: PropTypes.number.isRequired,
  slidesInDOM: PropTypes.number.isRequired,
  onActiveChange: PropTypes.func.isRequired,
  onMoveChange: PropTypes.func.isRequired,
  setSlideValue: PropTypes.func.isRequired,
  checkIsInMinMaxRange: PropTypes.func.isRequired,
};
const defaultProps = {
  className: '',
  initialActiveIndex: 0,
};

/**
 * Slider
 *
 * @param {string} initialActiveValue -expected format: yyyy-mm-dd
 * @param {number} initialActiveIndex - Index of initialActiveValue. This will be used to center the active value initially.
 * @param {number||string} minimumValue - Minimum available value in the slider. This will become the first slide (index 0)
 * @param {number||string} maximumValue - Maximym available value in the slider. This will become the last slide (index x)
 * @param {number} slidesPerView - Number of slides to be displayed in view at any one time.
 * @param {number} slidesInDOM - Number of slides in DOM at any one time. Dictated by slidesPerView
 * @param {func}  onActiveChange - Callback fired only when the last active value is selected after a swipe anim
 * @param {func} onMoveChange - Callback fired when any slide becomes active during a swipe anim.
 * @param {func} setSlideValue  - Callback fired when slides are created or  within slider.slideChanged & slider.afterChange.
 *                                used to determine the actual value of the current slide.
 * @param {func} checkIsInMinMaxRange - Callback fired to check if current value is in the correct range.
 */
const SliderComponent = React.forwardRef(function Slider(
  {
    className,
    initialActiveValue,
    initialActiveIndex,
    minimumValue,
    maximumValue,
    slidesPerView,
    slidesInDOM,
    onActiveChange,
    onMoveChange,
    setSlideValue,
    checkIsInMinMaxRange,
  },
  ref
) {
  // Used to add highlight styling of the active values mid swipe / animation.
  const [highlightedValue, setHighlightedValue] = useState(initialActiveValue);

  // Actual active value after the swipe animation has ended.
  const [selectedValue, setSelectedValue] = useState(initialActiveValue);

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView,
    mode: 'free-snap',
    centered: true,
    loop: false,
    vertical: false,
    dragSpeed: 2,
    duration: 200,
    initial: initialActiveIndex,
    slideChanged: (slider) => {
      // Is fired when the currently visible slide has changed
      const highlightedValue = setSlideValue(slider.details().absoluteSlide, minimumValue);

      setHighlightedValue(highlightedValue);
      onMoveChange(highlightedValue);
    },
    afterChange: (slider) => {
      // Is fired after an animation is finished or the slider position is changed externally or internally
      // NOTE: From debugging on mobile this fires more than it should - When the mobile browser toolbars disappear,
      // this causes the slider position to update which fires this afterChange.

      // onActiveChange is fired when selectedValue changes.
      // external onActiveChange monitors if selectedValue is different to filters selectedDates
      // and only fires subsequent code if it is different.
      const updatedSelectedVal = setSlideValue(slider.details().absoluteSlide, minimumValue);

      if (checkIsInMinMaxRange(updatedSelectedVal, minimumValue, maximumValue)) {
        setSelectedValue(updatedSelectedVal);
      }
    },
  });

  useEffect(() => {
    // onActiveChange inside a useEffect as it needs to be updated because it checks the latest selecedDates.
    onActiveChange(selectedValue);

    // onActiveChange is deliberately emmitted from the deps as this effect
    // should only ever trigger when the selected value changes
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const moveToNextSlide = useCallback(() => slider.next(), [slider]);

  const moveToPrevSlide = useCallback(() => slider.prev(), [slider]);

  useImperativeHandle(
    ref,
    () => ({
      moveToNextSlide,
      moveToPrevSlide,
    }),
    [moveToNextSlide, moveToPrevSlide]
  );

  return (
    <div className={classnames('slider keen-slider', className)} ref={sliderRef}>
      {Array.from(Array(slidesInDOM).keys()).map((slideNumber) => {
        const slideValue = setSlideValue(slideNumber, minimumValue);
        return (
          <Slide
            key={slideNumber}
            value={slideValue}
            isActive={slideValue === highlightedValue}
            onClick={() => slider.moveToSlide(slideNumber)}
          />
        );
      })}
    </div>
  );
});

SliderComponent.propTypes = propTypes;
SliderComponent.defaultProps = defaultProps;

export default SliderComponent;
