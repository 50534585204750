import React from 'react';
import { useMetric } from '@modules/FilterTypes/metric';
import { MetricTitle } from '@modules/Metrics';

function MetricLabel() {
  const [metric] = useMetric();
  return <MetricTitle metricKey={metric} />;
}

export default MetricLabel;
