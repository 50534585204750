import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from '@components';
import InputField from './InputField';

const componentName = 'password-field';

const propTypes = {
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
};

const defaultProps = {
  hasIcon: false,
};

export const PasswordField = ({ className, hasIcon, ...rest }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setIsPasswordVisible((visible) => !visible);
  }, []);

  return (
    <InputField
      {...rest}
      className={classnames(componentName, className)}
      type={isPasswordVisible ? 'text' : 'password'}
    >
      {hasIcon && rest.value && (
        <Button
          onClick={toggleVisibility}
          className={`${componentName}__toggle-password`}
          icon={isPasswordVisible ? 'hide-password' : 'show-password'}
        />
      )}
    </InputField>
  );
};

PasswordField.propTypes = propTypes;
PasswordField.defaultProps = defaultProps;

export default PasswordField;
