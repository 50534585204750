import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './_card.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  className: '',
  children: null,
};

const componentName = 'card';

const CardComponent = React.forwardRef(function Card({ children, className, ...props }, ref) {
  return (
    <div ref={ref} className={classnames(`${componentName} ${className}`)} {...props}>
      {children}
    </div>
  );
});

CardComponent.propTypes = propTypes;
CardComponent.defaultProps = defaultProps;

export default CardComponent;
