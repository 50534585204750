import { METRICS } from './constants';
import _uniqBy from 'lodash/uniqBy';

/**
 * @description
 * Just get metric object by key name
 *
 * @param key - string for metric key name
 * @return object - metric object defined at metrics.js config
 * */
export const getMetric = (key) => {
  return METRICS.find((metric) => metric.key === key);
};

/**
 * @description
 * From a list of metrics key names we return every single object
 *
 * @param keys - string[] metrics key names
 * @return objects[] - list of object according to the list input given
 * */
export const getMetricsByKeys = (keys) => {
  const metrics = [];
  keys = _uniqBy(keys);
  keys.forEach((key) => {
    const metricObject = getMetric(key);
    if (metricObject) {
      metrics.push(metricObject);
    }
  });
  return metrics;
};

/**
 * Returns the label key to be used in the i18n key
 *
 * @param {string} key Metric Key
 */
export const getMetricI18nKey = (key) => `metric.${key}`;

/**
 * Returns the Key for MovementValues returned from the API
 *
 * @param key metricKey
 */
export const getMovementKey = (key) => `${key}Movement`;

/**
 * Returns the Key for Change returned from the API
 *
 * @param key metricKey
 */
export const getChangeKey = (key) => `${key}Change`;
