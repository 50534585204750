import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { Text, Heading, Accordian, Spinner } from '@components';

import { componentName } from '../constants';
import { useSuggestedTitles } from '../hooks';
import CompCheckbox from './CompCheckbox';

function SuggestedTitles() {
  const { formatMessage } = useIntl();
  const { isLoading, data } = useSuggestedTitles();

  return (
    <Accordian.Item isOpen={true} className={classnames(`${componentName}__accordian-item`)}>
      <Accordian.ItemHeader className={classnames(`${componentName}__accordian-item-header`)}>
        <Heading size="xs" level="6" uppercase className={classnames(`${componentName}__section-heading`)}>
          {formatMessage({ id: 'suggested_titles' })}
        </Heading>
      </Accordian.ItemHeader>
      <Accordian.ItemContent className={classnames(`${componentName}__accordian-item-content`)}>
        {isLoading && <Spinner />}
        {!isLoading && data.length === 0 && (
          <Text className={`${componentName}__empty-text`}>{formatMessage({ id: 'comps_no_suggested_titles' })}</Text>
        )}
        {data.map((title) => (
          <CompCheckbox key={title.id} comp={title} />
        ))}
      </Accordian.ItemContent>
    </Accordian.Item>
  );
}

export default SuggestedTitles;
