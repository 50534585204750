import { useSelector } from 'react-redux';

import _get from 'lodash/get';

import { getUserProfile, getUserCircuit, getCircuitAttributes, getUserTimeFormat } from '../selectors';

export const useUserModel = () => {
  return useSelector(getUserProfile);
};

export const useUserField = (path, defaultValue) => {
  const userModel = useUserModel();

  return _get(userModel, path, defaultValue);
};

export const useUserCircuit = () => {
  return useSelector(getUserCircuit);
};

export const useCircuitAttributes = () => {
  return useSelector(getCircuitAttributes);
};

export const useCircuitAttribute = (attribute, defaultValue) => {
  const circuitAttributes = useCircuitAttributes();

  return _get(circuitAttributes, attribute, defaultValue);
};

export const useUserTimeFormat = () => {
  return useSelector(getUserTimeFormat);
};
