import React, { useRef, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '../Typography';
import { Button } from '../Button';

import './_toggle-switch.scss';

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  active: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
};
const defaultProps = {
  active: 0,
  options: [],
  onChange: () => {},
  className: '',
};

const componentName = 'toggle-switch';

const BackgroundSlide = ({ activeElement }) => {
  let style = { display: 'none' };

  if (activeElement) {
    style = {
      left: `${activeElement.offsetLeft}px`,
      width: `${activeElement.clientWidth}px`,
    };
  }

  return (
    <div className="toggle-switch__bg-slide">
      <span className="toggle-switch__bg-slide__indicator" style={style} />
    </div>
  );
};

const SwitchItem = ({ isActive, label, onChange, id }) => {
  const buttonRef = useRef(null);

  const handleChange = useCallback(() => {
    onChange(id, buttonRef.current);
  }, [onChange, buttonRef, id]);

  useEffect(() => {
    if (isActive) {
      handleChange();
    }
  }, [handleChange, isActive]);

  return (
    <div
      className={classnames(`${componentName}__list__item`, {
        [`${componentName}__list__item--active`]: isActive,
      })}
      ref={buttonRef}
    >
      <Button className="item__button" onClick={handleChange}>
        <Text tag="span" size="s" bold>
          {label}
        </Text>
      </Button>
    </div>
  );
};

const ToggleSwitch = ({ active, onChange, options, className }) => {
  const [activeElement, setActiveElement] = useState(null);

  const handleChange = useCallback(
    (id, selectedElement) => {
      setActiveElement(selectedElement);
      onChange(id);
    },
    [onChange, setActiveElement]
  );

  return (
    <nav className={classnames(componentName, className)}>
      <div className={classnames(`${componentName}__inner`)}>
        <BackgroundSlide activeElement={activeElement} />
        <div className={classnames(`${componentName}__list`)}>
          {options.map((option, index) => (
            <SwitchItem
              key={option.key}
              id={option.key}
              isActive={active === index}
              label={option.label}
              onChange={handleChange}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};

ToggleSwitch.propTypes = propTypes;
ToggleSwitch.defaultProps = defaultProps;

export default ToggleSwitch;
