import React, { Fragment, useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import _debounce from 'lodash/debounce';
import classnames from 'classnames';

import { Button, SearchInputField } from '@components';
import {
  useAppContextDispatch,
  useAppContextState,
  HEADER_SEARCH_ENABLED_PAGES,
  HEADER_SEARCH_PLACEHOLDERS,
} from '@modules';

import './_search-input-header.scss';

const componentClass = 'search-input-header';

/**
 * SearchInputHeader
 * Note: This currently only updates SET_PAGE_SEARCH but can be altered to change more.
 */
const SearchInputHeader = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppContextDispatch();
  const { pageName } = useAppContextState();
  const input = useRef();

  const [showSearch, setShowSearch] = useState();

  const isDisabled = useMemo(() => !HEADER_SEARCH_ENABLED_PAGES.includes(pageName), [pageName]);
  const placeholder = useMemo(() => {
    const intlId = HEADER_SEARCH_PLACEHOLDERS[pageName];

    return intlId ? formatMessage({ id: intlId }) : '';
  }, [pageName, formatMessage]);
  const open = useCallback(() => setShowSearch(true), []);
  const close = useCallback(() => setShowSearch(false), []);

  const setValue = useMemo(
    () =>
      _debounce((value) => {
        dispatch({ type: 'SET_PAGE_SEARCH', pageSearch: value });
      }, 100),
    [dispatch]
  );

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    if (input.current) {
      input.current.blur();
    }
  }, []);

  const onBlur = useCallback(() => {
    if (input.current) {
      setValue(input.current.value);
    }
  }, [setValue]);

  useEffect(() => {
    close();
  }, [close, pageName]);

  useEffect(() => {
    if (showSearch && input.current) {
      showSearch && input.current.focus();
    } else if (!showSearch) {
      setValue('');
    }
  }, [setValue, showSearch]);

  return (
    <Fragment>
      <Button
        icon="search"
        type="button"
        disabled={isDisabled}
        onClick={open}
        className={classnames('search-toggle', {
          'search-toggle--is-disabled': isDisabled,
        })}
      />
      {showSearch ? (
        <form onSubmit={onSubmit} onBlur={onBlur} className={componentClass}>
          <SearchInputField inputRef={input} placeholder={placeholder} />
          <Button type="button" onClick={close}>
            {formatMessage({ id: 'cancel' })}
          </Button>
        </form>
      ) : null}
    </Fragment>
  );
};

export default SearchInputHeader;
