import { asyncAction, isLoading } from '@showtime/cea-dep-utility';

import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import { API_ROOT_PATH } from '@modules/Core';

import { SITE_ATTRIBUTE_GROUPS_FETCH_KEY } from './constants';
import { SET_SITE_ATTRIBUTE_GROUPS, CLEAR_SITE_ATTRIBUTE_GROUPS } from './actionTypes';
import { getSiteAttributeGroupsRaw } from './selectors';

const setSiteAttributeGroups = (groups) => ({
  type: SET_SITE_ATTRIBUTE_GROUPS,
  groups,
});

export const clearSiteAttributeGroups = () => ({
  type: CLEAR_SITE_ATTRIBUTE_GROUPS,
});

let paramsCache = {};

export const loadSiteAttributeGroups = (params, subSectionPath) => (dispatch, getState) => {
  const groups = getSiteAttributeGroupsRaw(getState());
  const isLoadingAttribute = isLoading(getState(), SITE_ATTRIBUTE_GROUPS_FETCH_KEY);
  const hasUpdated = !_isEqual(params, paramsCache);

  if ((!isLoadingAttribute && !groups) || hasUpdated) {
    // only reloading if there are no groups, or if the params have been updated
    // This means that one request can be made per set of unique parameters
    paramsCache = _cloneDeep(params);

    dispatch(
      asyncAction({
        key: SITE_ATTRIBUTE_GROUPS_FETCH_KEY,
        url: `${API_ROOT_PATH}${subSectionPath}/filters/siteGroupsAndAttributes?_query=json`,
        body: {
          ...params,
        },
        method: 'POST',
        success: (groups) => dispatch(setSiteAttributeGroups(groups)),
      })
    );
  }
};

export const reloadSiteAttributeGroups = (params, subSectionPath = '') => (dispatch) => {
  dispatch(clearSiteAttributeGroups());
  dispatch(loadSiteAttributeGroups(params, subSectionPath));
};
