import { useSelector } from 'react-redux';
import { useAction } from '@modules/Core';
import { setSortField, setSortDir, resetSort } from '../actions';
import { getSortField, getSortDir } from '../selectors';

export function useSort() {
  return {
    sortDir: useSelector(getSortDir),
    sortField: useSelector(getSortField),
    setSortDir: useAction(setSortDir),
    setSortField: useAction(setSortField),
    resetSort: useAction(resetSort),
  };
}
