import { seriesCommon, getDashLineRange } from '@components/Chart';
import data from '../../mock/chartData';

const CATEGORY_FIELD = 'time';
const COLORS = ['#fd7e14', '#045c88'];

export default {
  type: 'LineSeries',
  strokeWidth: 3,
  data: data.map((item) => {
    let date = new Date();
    const time = item.time.split(':')[0];

    date.setHours(parseInt(time));
    date.setMinutes(0);
    return {
      ...item,
      time: date,
    };
  }),
  dataFields: {
    category: CATEGORY_FIELD,
  },
  series: [
    {
      ...seriesCommon,
      stroke: COLORS[0],
      id: 'baseSeries',
      dataFields: {
        valueY: 'value',
        dateX: CATEGORY_FIELD,
      },
    },
    {
      ...seriesCommon,
      stroke: COLORS[1],
      dataFields: {
        valueY: 'value2',
        dateX: CATEGORY_FIELD,
      },
    },
  ],
  xAxes: [
    {
      type: 'DateAxis',
      axisRanges: [getDashLineRange('baseSeries')],
      renderer: {
        grid: {
          disabled: true,
        },
        labels: {
          disabled: true,
        },
      },
      cursorTooltipEnabled: false,
    },
  ],
  yAxes: [
    {
      type: 'ValueAxis',
      renderer: {
        baseGrid: {
          disabled: true,
        },
        grid: {
          disabled: true,
        },
        labels: {
          disabled: true,
        },
      },
      cursorTooltipEnabled: false,
    },
  ],

  properties: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  },
};
