import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';

import { useAction, useAPIPath } from '@modules/Core';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';

import { getParametersForReportingSites } from '../selectors';
import { fetchReportingCircuits } from '../actions';
import { usePagination, useSort } from '../hooks';

/**
 * useCircuitsDataFetch
 * Fires to get latest reporting sites.
 */
export const useCircuitsDataFetch = (globalParams) => {
  const DEBOUNCE_VALUE = 300;

  const fetchCircuits = useAction(fetchReportingCircuits);
  const params = useSelector(getParametersForReportingSites);
  const [search, setSearch] = useState('');
  const { currentPage } = usePagination();
  const { sortField, sortDir } = useSort();
  const [subSectionPath] = useAPIPath(false);
  const [eventParamKey] = useEventParamKey();

  const fetchCircuitsDebounce = useMemo(() => _debounce(fetchCircuits, DEBOUNCE_VALUE), [fetchCircuits]);

  useEffect(() => {
    fetchCircuitsDebounce({
      ...params,
      ...globalParams,
      page: currentPage,
      search,
      sortField,
      sortDir,
      subSectionPath,
      eventParamKey,
    });
    return () => fetchCircuitsDebounce.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, fetchCircuits, currentPage, search, sortField, sortDir, eventParamKey, subSectionPath]);

  return {
    search,
    setSearch,
  };
};
