import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Section, TabsNav } from '@components';
import { BASE_CLASS, TABS_OPTIONS } from '../constants';
import BookmarksHeader from './BookmarksHeader';
import BookmarksFooter from './BookmarksFooter';
import { useBookmarksContext } from '../hooks';
import { getActiveTabIndex } from '../helpers';
import BookmarksList from './BookmarksList';

const propTypes = {
  className: PropTypes.string,
};

const BookmarksMain = forwardRef(function UserSettingsList(props, ref) {
  const { formatMessage } = useIntl();
  const { privacyTabActive, setPrivacyTabActive } = useBookmarksContext();
  const [activeTab, setActiveTab] = useState(getActiveTabIndex(privacyTabActive));

  const privacyLocal = useMemo(() => TABS_OPTIONS[activeTab].key, [activeTab]);

  useEffect(() => setPrivacyTabActive(privacyLocal), [privacyLocal, setPrivacyTabActive]);

  return (
    <Section.Container {...props} ref={ref} bordered tag="section" className={classnames(props.className)}>
      <BookmarksHeader title={formatMessage({ id: 'bookmarks' })} />
      <TabsNav
        size={'s'}
        type="material"
        active={activeTab}
        onChangeTab={setActiveTab}
        className={`${BASE_CLASS}__list__tabs-nav`}
        tabs={[...TABS_OPTIONS.map(({ key, label }) => ({ key, label: formatMessage({ id: label }) }))]}
      />
      <BookmarksList />
      <BookmarksFooter />
    </Section.Container>
  );
});

BookmarksMain.propTypes = propTypes;

export default BookmarksMain;
