import { createAction } from '@reduxjs/toolkit';
import moment from 'moment';
import _isNull from 'lodash/isNull';

import { asyncAction } from '@showtime/cea-dep-utility';

import { API_ROOT_PATH, getDefaultParams } from '@modules/Core';
import { getSelected } from '@modules/FilterTypes/dates';
import {
  STATE_KEY,
  EMBEDDED_FETCH_KEY,
  FULL_SCREEN_FETCH_KEY,
  GAP_EMBEDDED,
  GAP_FULLSCREEN,
  BASE_TIME_RANGE,
} from './constants';
import { maxDateByField, minDateByField, buildMomentTime } from './helpers';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_DATE_AXIS_MIN = 'SET_DATE_AXIS_MIN';
const SET_AXIS_MAX = 'SET_AXIS_MAX';

export const setDateAxisMin = createAction(createActionType(SET_DATE_AXIS_MIN));
export const setDateAxisMax = createAction(createActionType(SET_AXIS_MAX));

const SET_EMBEDDED_CHART_DATA = 'SET_EMBEDDED_CHART_DATA';
const SET_FULLSCREEN_CHART_DATA = 'SET_FULLSCREEN_CHART_DATA';

export const setEmbeddedChartData = createAction(createActionType(SET_EMBEDDED_CHART_DATA));
export const setFullscreenChartData = createAction(createActionType(SET_FULLSCREEN_CHART_DATA));

const updateTimeRanges = (dispatch, date, compsData) => {
  const { start, end } = BASE_TIME_RANGE;

  const validEarliestValues = compsData.filter(({ firstPerformanceTime }) => {
    return !_isNull(firstPerformanceTime);
  });

  const defaultMin = () => {
    dispatch(setDateAxisMin(buildMomentTime(date, start).toDate()));
  };

  // Only using non null values to set valid performance times
  if (validEarliestValues.length) {
    const earliestPerformanceTime = minDateByField(validEarliestValues, 'firstPerformanceTime');

    if (moment(earliestPerformanceTime).isBefore(buildMomentTime(date, start))) {
      dispatch(setDateAxisMin(earliestPerformanceTime));
    } else {
      defaultMin();
    }
  } else {
    defaultMin();
  }

  const validLatestValues = compsData.filter(({ lastPerformanceTime }) => {
    return !_isNull(lastPerformanceTime);
  });
  const defaultMax = () => {
    dispatch(setDateAxisMax(buildMomentTime(date, end).toDate()));
  };

  // Only using non null values to set valid performance times
  if (validLatestValues.length) {
    const latestPerformanceTime = maxDateByField(validLatestValues, 'lastPerformanceTime');

    if (moment(latestPerformanceTime).isAfter(buildMomentTime(date, end))) {
      dispatch(setDateAxisMax(latestPerformanceTime));
    } else {
      defaultMax();
    }
  } else {
    defaultMax();
  }
};

export const fetchEmbeddedChartData = (params, metrics, subSectionPath, dataPath) => {
  params.mets = metrics.join(',');

  return fetchCompChartData(EMBEDDED_FETCH_KEY, GAP_EMBEDDED, params, setEmbeddedChartData, subSectionPath, dataPath);
};

export const fetchFullScreenChartData = (params, metrics, subSectionPath, dataPath) => {
  params.mets = metrics.join(',');
  params.delta = metrics.join(',');

  return fetchCompChartData(FULL_SCREEN_FETCH_KEY, GAP_FULLSCREEN, params, setFullscreenChartData, subSectionPath, dataPath);
};

export const fetchCompChartData = (key, gap, params, onSuccessAction, subSectionPath = '', dataPath) => {
  return (dispatch, getState) => {
    const dates = getSelected(getState());

    dispatch(
      asyncAction({
        key,
        url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/groupBy/masterEventId/rangeBy/perfDate?_query=json`,
        body: {
          gap,
          ...params,
          ...getDefaultParams(),
        },
        method: 'POST',
        success(response) {
          if (response.length) {
            updateTimeRanges(this.dispatch, dates[0], response);
          }

          this.dispatch(onSuccessAction(response));
        },
      })
    );
  };
};
