import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const DRAWER_TRANSITION_EASE = 'power1.easeInOut';

export const useDrawerAnimations = (isOpen) => {
  let drawerRef = useRef(null);
  let drawerOverlayRef = useRef(null);
  let drawerTimeline = useRef(null);

  useEffect(() => {
    drawerTimeline.current = gsap.timeline({ paused: true });
    drawerTimeline.current.fromTo(
      [drawerRef],
      {
        autoAlpha: 1,
        duration: 0,
        top: '100%',
      },
      {
        duration: 0.4,
        top: 'auto',
        ease: DRAWER_TRANSITION_EASE,
      }
    );
    drawerTimeline.current.fromTo(
      [drawerOverlayRef],
      {
        duration: 0.2,
        backgroundColor: 'rgba(42, 48, 62, 0)',
        ease: DRAWER_TRANSITION_EASE,
        display: 'none',
      },
      {
        duration: 0.2,
        display: 'initial',
        backgroundColor: 'rgba(42, 48, 62, 0.4)',
        ease: DRAWER_TRANSITION_EASE,
      }
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      drawerTimeline.current.play();
    } else {
      drawerTimeline.current.reverse();
    }
  }, [isOpen, drawerTimeline]);

  const drawerBinder = (el) => (drawerRef = el);
  const drawerOverlayBinder = (el) => (drawerOverlayRef = el);

  return [drawerBinder, drawerOverlayBinder];
};
