import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hasStudioAdvancedSalesPerspective } from './helpers';
import { Route, Switch } from 'react-router-dom';

import { URL_PATHS } from '@modules/Core';
import { setSelected as setSelectedSites } from '@modules/FilterTypes/sites';
import { setSelected as setSelectedSiteAttributeGroup } from '@modules/FilterTypes/siteAttributeGroup';

import { FilmAnalysis } from './FilmAnalysis/';
import { FilmMetadata } from './FilmMetadata/';

function Routes() {
  const dispatch = useDispatch();

  useEffect(() => {
    const resetFilters = () => {
      dispatch(setSelectedSites(null));
      dispatch(setSelectedSiteAttributeGroup(null));
    };
    resetFilters();
    return () => {
      resetFilters();
    };
  }, [dispatch]);

  return (
    hasStudioAdvancedSalesPerspective() && (
      <Switch>
        <Route path={`/${URL_PATHS.ADVANCED_SALES}`} exact component={FilmAnalysis} />
        <Route
          path={[
            `/${URL_PATHS.ADVANCED_SALES}/:id/:screen/:site_attribute_group_id/:site_attribute_id`,
            `/${URL_PATHS.ADVANCED_SALES}/:id/:screen`,
          ]}
          component={FilmMetadata}
        />
      </Switch>
    )
  );
}

export default Routes;
