import { useRouteMatch } from 'react-router-dom';
import { URL_PATHS } from '@modules/Core';

export default function useIsAdvancedSales() {
  const match = useRouteMatch('/:basePath');
  const basePath = match.params.basePath;

  const isAdvancedSales = basePath === URL_PATHS.ADVANCED_SALES;

  return isAdvancedSales;
}
