import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Spinner } from '@components';
import List from './List';

const propTypes = {
  isInitiallyLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  className: PropTypes.string,
};
const defaultProps = {
  isInitiallyLoading: false,
  isLazyLoading: false,
  className: '',
};

/**
 * TitleList Container
 * @param {bool} isInitiallyLoading True when first page of results are loading.
 * @param {bool} isLazyLoading - True when any pages of results after the firts is loading.
 */
const ListContainer = ({ className, isInitiallyLoading, isLazyLoading, ...props }) => {
  return (
    <div className={`title-list-ctn ${className}`}>
      {isInitiallyLoading && <Spinner />}
      {!isInitiallyLoading && (
        <>
          <List {...props} />
          <div
            className={classnames('lazy-load', {
              'lazy-load--is-visible': isLazyLoading,
            })}
          >
            <Spinner size={'s'} />
          </div>
        </>
      )}
    </div>
  );
};

ListContainer.propTypes = propTypes;
ListContainer.defaultProps = defaultProps;

export default React.memo(ListContainer);
