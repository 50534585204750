import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import MetricNumber from './MetricNumber';

/**
 * @returns Outputs the capacity number and capacityRemaining beside one another.
 * e.g
 * 5.33k(100K)
 */
function CapacityNumberInline({ capacity, capacityRemaining }) {
  const { formatMessage } = useIntl();

  return formatMessage(
    {
      id: 'capacityFormat',
    },
    {
      capacityRemaining: (
        <MetricNumber key={'capacityRemaining'} metricKey={'capacityRemaining'} number={capacityRemaining} />
      ),
      capacity: <MetricNumber key={'capacity'} metricKey={'capacity'} number={capacity} />,
    }
  );
}

CapacityNumberInline.propTypes = {
  capacity: PropTypes.number,
  capacityRemaining: PropTypes.number,
};

export default CapacityNumberInline;
