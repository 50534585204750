import React from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@components';
import { useIsToday } from '@modules/Core';
import { useStudioHelpers } from '@pages/studio/hooks';

import popcorn from '@images/popcorn.svg';

export default function EmptyState({ noResultsComponent }) {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id: 'market_data_not_available' });
  const isToday = useIsToday();
  const { isSitesReported, isIntegrated } = useStudioHelpers();

  return !isSitesReported && !isIntegrated && isToday ? (
    <div className="empty-state empty-state--film-list">
      <img src={popcorn} alt={message} />
      <Text className="empty-state__text">{message}</Text>
    </div>
  ) : (
    noResultsComponent
  );
}
