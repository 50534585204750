import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import { errorByPattern } from '@showtime/cea-dep-utility';
import PropTypes from 'prop-types';
import { BASE_CLASS, FETCH_UPDATE_PASSWORD, SETTINGS_PAGE } from '../../constants';
import { Alert, Button, PasswordField, Spinner, Section } from '@components';
import { updatePassword, isUpdatingPassword } from '@modules/Auth';
import UserSettingsHeader from './../UserSettingsHeader';
import { useAlert } from './hooks';
import classnames from 'classnames';
import './_user-settings-pasword.scss';

const propTypes = {
  className: PropTypes.string,
};

const componentClass = `${BASE_CLASS}-password`;

const UserSettingsPassword = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [submitForm, setSubmitForm] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const isUpdatingUserPassword = useSelector(isUpdatingPassword);

  const alert = useAlert(props.httpError, currentPassword, newPassword, newConfirmPassword, submitForm);

  const onSetPassword = (action) => (ev) => action(ev.target.value);

  const onUpdatePassword = useCallback(() => {
    if (alert.status) return;
    dispatch(updatePassword(currentPassword, newPassword));
    setSubmitForm(false);
  }, [submitForm, alert, currentPassword, newPassword]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setSubmitForm(true);
    },
    [alert]
  );

  useEffect(() => {
    // Prevent to send request
    if (submitForm) onUpdatePassword();
  }, [submitForm]);

  useEffect(() => {
    // Reset Submitted form
    if (!alert.status) setSubmitForm(false);
  }, [alert.status]);

  useEffect(() => {
    return () => {
      setNewPassword('');
      setCurrentPassword('');
      setNewConfirmPassword('');
    };
  }, []);

  return (
    <form autoComplete={'off'} onSubmit={(e) => onSubmit(e)} className={componentClass}>
      <Section.Container
        bordered
        tag="section"
        ref={props.forwardedRef}
        className={classnames(props.className, { ['updating-password']: isUpdatingUserPassword })}
      >
        <UserSettingsHeader
          prevIcon="back"
          prevPage={SETTINGS_PAGE.SETTINGS}
          title={formatMessage({ id: 'user_settings.password' })}
        />
        {alert.message && <Alert type={alert.status} alertMessage={<FormattedMessage id={alert.message} />} />}
        <Section className={`${componentClass}__container`}>
          <PasswordField
            value={currentPassword}
            label={formatMessage({ id: 'current_password' })}
            hasError={alert.status && alert.formField.includes('current_password')}
            onChange={onSetPassword(setCurrentPassword)}
            hasIcon
          />
        </Section>
        <Section.Container className={`${componentClass}__container`}>
          <PasswordField
            label={formatMessage({ id: 'new_password' })}
            value={newPassword}
            hasError={alert.status && alert.formField.includes('new_password')}
            onChange={onSetPassword(setNewPassword)}
            hasIcon
          />
          <br />
          <PasswordField
            label={formatMessage({ id: 'confirm_new_password' })}
            value={newConfirmPassword}
            hasError={alert.status && alert.formField.includes('confirm_new_password')}
            onChange={onSetPassword(setNewConfirmPassword)}
            subtext={formatMessage({ id: 'confirm_password_subtext' })}
            hasIcon
          />
        </Section.Container>
        <Section className={`${componentClass}__footer`} tag="footer">
          <Button primary type={'submit'} className={`${componentClass}__footer--submit-btn`}>
            {formatMessage({ id: 'change_password' })}
          </Button>
        </Section>
      </Section.Container>
      {isUpdatingUserPassword && <Spinner />}
    </form>
  );
};

UserSettingsPassword.propTypes = propTypes;

const UserSettingsPasswordComponent = connect((state) => ({
  httpError: errorByPattern(state, FETCH_UPDATE_PASSWORD) || {},
}))(UserSettingsPassword);

export default forwardRef((props, ref) => <UserSettingsPasswordComponent {...props} forwardedRef={ref} />);
