import { createSelector } from '@reduxjs/toolkit';
import _property from 'lodash/property';

import { isLoading } from '@showtime/cea-dep-utility';

import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { KEY as metric } from '@modules/FilterTypes/metric';
import { getParametersFactory } from '@modules/FilterTypes';
import { STATE_KEY, THEATRE_METADATA_FETCH_KEY } from './constants';

export const getTheatreMetadataState = _property(STATE_KEY);

export const getInfoList = createSelector([getTheatreMetadataState], _property('info'));

export const getPreviousUrl = createSelector([getTheatreMetadataState], _property('previousUrl'));

export const getParametersForTheatresMetadataInfo = getParametersFactory([dates, territory, currency, sites, metric]);

export const getTheatreMetadataName = createSelector([getInfoList], _property('name'));

export const isLoadingInfo = (state) => isLoading(state, THEATRE_METADATA_FETCH_KEY);
