import { useEffect } from 'react';
import { useAppContextDispatch } from '@modules';

const REFRESH_METRICS = ['admissions', 'eventRevenue', 'eventRevenueBO'];
const setRefreshMetrics = (metrics) => {
  let refreshMetrics = [];
  REFRESH_METRICS.forEach((metric) => {
    if (metrics.includes(metric)) {
      refreshMetrics.push(metric);
    }
  });
  return refreshMetrics.join(',');
};
/**
 * useSetRefreshMetrics
 * @param {array} metrics array of allowed metrics from screenrules
 * Sets refreshMetrics as a comma spearated string of metrics from screenrules.
 */
export const useSetRefreshMetrics = (metrics) => {
  const dispatch = useAppContextDispatch();
  useEffect(() => {
    dispatch({ type: 'SET_REFRESH_METRICS', refreshMetrics: setRefreshMetrics(metrics) });
  }, [dispatch, metrics]);
};
