import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOccupancy, DEFAULT_FROM_OCCUPANCY, DEFAULT_TO_OCCUPANCY } from '@modules/FilterTypes/occupancy';
import { ORDER_OPTION_ASC, ORDER_OPTION_DESC, DEFAULT_ORDER_OPTION, useSort } from '@modules/FilterTypes/sort';
import { SelectorButton, Drawer, Heading, Button, Radio, RangeSlider } from '@components';
import './_sort-by-selector.scss';

const componentName = 'sort-by-selector';

const SORT_OPTIONS = {
  [ORDER_OPTION_DESC]: 'sortBySelector_metric_high_low',
  [ORDER_OPTION_ASC]: 'sortBySelector_metric_low_high',
};

/**
 * SortBySelector
 * @param {string} theme - displays the current theme
 * @param {string} buttonClassName - gives a custom class to toggle button
 * @param {node} buttonLabel - FormatMessage node
 * @param {string} showOccupancySlider
 * @param {boolean} hideSort
 *
 * @note
 * Before you load this component, setup the desired metric using: setSortMetricSelected() (useSort hook).
 * We will use the value as param to emit requests.
 */
const SortBySelector = ({ theme, buttonClassName, buttonLabel, showOccupancySlider, hideSort }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState();
  const [sortSelected, setSortSelected] = useSort();
  const [occupancySelected, setOccupancySelected] = useOccupancy();
  const [orderValue, setOrderValue] = useState(sortSelected || DEFAULT_ORDER_OPTION);
  const [fromValue, setFromValue] = useState(occupancySelected[0] || DEFAULT_FROM_OCCUPANCY);
  const [toValue, setToValue] = useState(occupancySelected[1] || DEFAULT_TO_OCCUPANCY);

  const onOrderChange = useCallback((value) => setOrderValue(value), [setOrderValue]);

  const onSliderChange = useCallback(
    (value) => {
      setFromValue(value[0]);
      setToValue(value[1]);
    },
    [setFromValue, setToValue]
  );

  const setSortLocal = () => {
    if (sortSelected) setOrderValue(sortSelected);
    else setOrderValue(DEFAULT_ORDER_OPTION);
  };

  const setOccupancyLocal = () => {
    if (occupancySelected.length === 2) {
      setFromValue(occupancySelected[0]);
      setToValue(occupancySelected[1]);
    } else {
      setFromValue(DEFAULT_FROM_OCCUPANCY);
      setToValue(DEFAULT_TO_OCCUPANCY);
    }
  };

  const onApply = useCallback(() => {
    setSortSelected(orderValue);
    setOccupancySelected([fromValue, toValue]);
    setIsOpen(false);
  }, [orderValue, fromValue, toValue]);

  const onCancel = useCallback(() => {
    setSortLocal();
    setOccupancyLocal();
    setIsOpen(false);
  }, [occupancySelected, sortSelected]);

  useEffect(() => {
    setSortLocal();
    setOccupancyLocal();
  }, [isOpen, occupancySelected, sortSelected]);

  return (
    <>
      <SelectorButton className={buttonClassName} theme={theme} onClick={() => setIsOpen(true)}>
        {buttonLabel ? buttonLabel : <FormattedMessage id={'sortBySelector_button'} />}
      </SelectorButton>
      <Drawer className={componentName} isOpen={isOpen} hasClosed={() => setIsOpen(false)}>
        <Drawer.Header isVisibleOnScreen={false}>
          <Heading size="m" className={classnames(`${componentName}__heading`)}>
            <FormattedMessage id={'sortBySelector_heading'} />
          </Heading>
        </Drawer.Header>
        <Drawer.Content>
          {!hideSort && (
            <Radio.Group name="sort-by-selector" value={orderValue} onChange={onOrderChange}>
              {Object.entries(SORT_OPTIONS).map(([value, label]) => (
                <Radio.Button
                  key={value}
                  className={classnames(`${componentName}__radio`)}
                  label={formatMessage({ id: label })}
                  value={value}
                />
              ))}
            </Radio.Group>
          )}
          {showOccupancySlider && (
            <RangeSlider
              className={classnames(`${componentName}__slider`)}
              label={formatMessage({ id: 'occupancy' })}
              value={[fromValue, toValue]}
              min={0}
              max={100}
              onChange={onSliderChange}
            />
          )}
        </Drawer.Content>
        <Drawer.Footer align="right">
          <Button onClick={onCancel} secondary>
            {formatMessage({ id: 'cancel' })}
          </Button>
          <Button onClick={onApply} primary>
            {formatMessage({ id: 'apply' })}
          </Button>
        </Drawer.Footer>
      </Drawer>
    </>
  );
};

SortBySelector.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  buttonClassName: PropTypes.string,
  buttonLabel: PropTypes.node,
  showOccupancySlider: PropTypes.bool,
  hideSort: PropTypes.bool,
};

SortBySelector.defaultProps = {
  theme: 'light',
  buttonClassName: '',
  buttonLabel: null,
  showOccupancySlider: false,
  hideSort: false,
};

export default SortBySelector;
