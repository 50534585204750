export const CHART_COLORS = ['#fd7e14', '#20c997', '#ea78b7'];
export const BASE_SERIES_ID = 'baseSeries';

export const STATE_KEY = 'compsChartsStore';

export const BASE_SERIES_DATAFIELD = 'baseSeries';
export const COMPS_SERIES_DATAFIELDS = ['firstComp', 'secondComp', 'thirdComp'];

export const EMBEDDED_FETCH_KEY = 'fetchData.embeddedChart';
export const FULL_SCREEN_FETCH_KEY = 'fetchData.overlayChart';

export const GAP_EMBEDDED = '1HOUR';
export const GAP_FULLSCREEN = '15MIN';

export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const baseClass = 'comps-chart';

export const BASE_TIME_RANGE = {
  start: '09:00',
  end: '23:59',
};

export const AVAILABLE_METRICS = ['admissions', 'eventRevenue', 'eventRevenueBO', 'eventRevenueBOStudio'];
