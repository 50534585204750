import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Overlay, useOverlay, Button, Spinner } from '@components';
import { compsMetricsPropType } from '@modules/Comps';
import { useIsAdvancedSales } from '@modules/Core';

import { FullScreenOverlay, EmbeddedCompsChart, EmbeddedAdvancedSalesCompsChart, CompsBarChart } from './components';
import { isLoadingEmbeddedData } from './selectors';
import { useEmbeddedChartDataFetcher } from './hooks';
import './_comps-chart.scss';

const baseClass = 'comps-chart';

const propTypes = {
  metrics: PropTypes.arrayOf(compsMetricsPropType),
};
const defaultProps = {
  metrics: [],
};

function CompsLineChartContent({ metrics = [] }) {
  const isAdvancedSales = useIsAdvancedSales();
  const fullScreenOverlay = useOverlay();
  const isLoading = useSelector(isLoadingEmbeddedData);

  useEmbeddedChartDataFetcher(metrics);

  return (
    <>
      <Button onClick={fullScreenOverlay.open} icon="expand" className={`${baseClass}__expand-button`} />
      {isLoading ? (
        <Spinner className={`${baseClass}__loading`} />
      ) : (
        isAdvancedSales ? 
        <EmbeddedAdvancedSalesCompsChart metrics={metrics} />
        :
        <EmbeddedCompsChart metrics={metrics} />
      )}
      {fullScreenOverlay.render(
        <FullScreenOverlay overlayContext={fullScreenOverlay} metrics={metrics} />
      )}
    </>
  );
}

CompsLineChartContent.propTypes = propTypes;
CompsLineChartContent.defaultProps = defaultProps;

function CompsLineChart(props) {
  return (
    <Overlay.Provider
      component={CompsLineChartContent}
      type="full"
      className={`${baseClass}__overlay-container`}
      {...props}
    />
  );
}

export { CompsLineChart, CompsBarChart };
