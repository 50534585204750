import React, { forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { BASE_CLASS, SETTINGS_PAGE } from '../constants';
import { Section, Spinner } from '@components';
import UserSettingsHeader from './UserSettingsHeader';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedTerritoryCurrencyIsoCode } from '@modules/TerritorySelect';
import { updateLocalCurrency } from '../actions';
import { getUserProfile } from '@modules/Auth';
import SelectableList from './SelectableList';
import { useCurrency } from '@modules/FilterTypes/currency';
import { DEFAULT_CURRENCY_ISO_CODE } from '@modules/Core';
import { isUpdatingUserSettings, getLocalCurrency } from '../selectors';

const propTypes = {
  className: PropTypes.string,
};

const componentClass = `${BASE_CLASS}-currency`;

const UserSettingsCurrency = forwardRef(function UserSettingsPassword(props, ref) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const userProfile = useSelector(getUserProfile);
  const isLocalCurrency = useSelector(getLocalCurrency);
  const isUpdating = useSelector(isUpdatingUserSettings);
  const [, setSelectedCurrency] = useCurrency();
  const selectedTerritoryIsoCode = useSelector(getSelectedTerritoryCurrencyIsoCode);

  const setIsLocalCurrency = useCallback(
    (isLocalCurrency) => {
      const { email } = userProfile;
      const currencyIsoCode = isLocalCurrency ? selectedTerritoryIsoCode : DEFAULT_CURRENCY_ISO_CODE;
      dispatch(updateLocalCurrency(email, isLocalCurrency), setSelectedCurrency(currencyIsoCode));
    },
    [setSelectedCurrency, userProfile, dispatch, selectedTerritoryIsoCode]
  );

  const options = [
    { id: 1, name: 'user_currency_preference.default', value: false },
    { id: 0, name: 'user_currency_preference.local', value: true },
  ];

  return (
    <Section.Container
      {...props}
      className={classnames(props.className, componentClass, { [`${BASE_CLASS}-main--loading`]: isUpdating })}
      ref={ref}
      bordered
      tag="section"
    >
      <UserSettingsHeader
        prevIcon="back"
        prevPage={SETTINGS_PAGE.SETTINGS}
        title={formatMessage({ id: 'user_settings.currency' })}
      />
      <Section.Container>
        <SelectableList
          data={options}
          valueKey={'value'}
          labelKey={'name'}
          icon={'check'}
          value={!!isLocalCurrency}
          onChange={setIsLocalCurrency}
        />
      </Section.Container>
      {isUpdating && <Spinner />}
    </Section.Container>
  );
});

UserSettingsCurrency.propTypes = propTypes;

export default UserSettingsCurrency;
