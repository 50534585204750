import React, { useMemo, useState, useEffect, createContext, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _debounce from 'lodash/debounce';
import propTypes from 'prop-types';

import { useAPIPath } from '@modules/Core';

import { searchForTitles, setSearchResults, cleanNoResults } from '../actions';
import { getSearchResults, isSearchLoading, getParameters, getNoResults } from '../selectors';

const DEBOUNCE_VALUE = 600;

const SearchContext = createContext(['', () => {}]);

export const SearchProvider = ({ children }) => {
  const searchState = useState('');

  return <SearchContext.Provider value={searchState}>{children}</SearchContext.Provider>;
};

SearchProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export const useSearchState = () => useContext(SearchContext);

export const useSearch = () => {
  const dispatch = useDispatch();
  const params = useSelector(getParameters);
  const isLoading = useSelector(isSearchLoading);
  const data = useSelector(getSearchResults);
  const noResults = useSelector(getNoResults);
  const [searchValue, setSearchValue] = useSearchState();
  const [subSectionPath] = useAPIPath();

  const fetchData = useMemo(
    () =>
      _debounce((searchValue) => {
        if (searchValue.length > 1) {
          dispatch(searchForTitles(searchValue, params, subSectionPath));
        } else {
          dispatch(cleanNoResults());
          dispatch(setSearchResults([]));
        }
      }, DEBOUNCE_VALUE),
    [dispatch, params]
  );

  useEffect(() => {
    fetchData(searchValue);
    return () => fetchData.cancel();
  }, [searchValue, fetchData]);

  return {
    isLoading,
    data,
    searchValue,
    setSearchValue,
    noResults,
  };
};
