import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Section, Tabs, Tab } from '@components';
import { BASE_CLASS, BOOKMARKS_PAGES } from '../constants';
import BookmarksMain from './BookmarksMain';
import CreateBookmarkForm from './CreateBookmarkForm';
import { BookmarksProvider } from './BookmarksContext';
import { clearBookmarksData } from '../actions';
import DeleteBookmarkConfirm from './DeleteBookmarkConfirm';

const componentClass = `${BASE_CLASS}-wrapper`;

const BookmarksWrapper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearBookmarksData());
  }, []);

  return (
    <BookmarksProvider>
      <Section.Container tag={Tabs} homeTab={BOOKMARKS_PAGES.MAIN} component="article" className={componentClass}>
        <Tab tabKey={BOOKMARKS_PAGES.MAIN} component={BookmarksMain} className={`${componentClass}__page`} />
        <Tab tabKey={BOOKMARKS_PAGES.FORM} component={CreateBookmarkForm} className={`${componentClass}__page`} />
      </Section.Container>
      <DeleteBookmarkConfirm />
    </BookmarksProvider>
  );
};

export default BookmarksWrapper;
