import _debounce from 'lodash/debounce';
import { createAction } from '@reduxjs/toolkit';

import { asyncAction } from '@showtime/cea-dep-utility';

import { API_ROOT_PATH, getDefaultParams } from '@modules/Core';
import { TITLES_FETCH_KEY, TITLES_LAZY_LOAD_FETCH_KEY, STATE_KEY, PAGE_SIZE } from './constants';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_TITLE_LIST = 'SET_TITLE_LIST';
export const setTitleList = createAction(createActionType(SET_TITLE_LIST));

const APPEND_TITLE_LIST = 'APPEND_TITLE_LIST';
export const appendTitleList = createAction(createActionType(APPEND_TITLE_LIST));

const RESET_TITLE_LIST = 'RESET_TITLE_LIST';
export const resetTitleList = createAction(createActionType(RESET_TITLE_LIST));

const SET_MAX_PAGES_REACHED = 'SET_MAX_PAGES_REACHED';
export const setMaxPagesReached = createAction(createActionType(SET_MAX_PAGES_REACHED));

const RESET_MAX_PAGES_REACHED = 'RESET_MAX_PAGES_REACHED';
export const resetMaxPagesReached = createAction(createActionType(RESET_MAX_PAGES_REACHED));

// Putting a small debounce on the request so quick changing deps dont trigger multiple requests
const debouncedTitleRequest = _debounce((dispatch, config) => {
  const { dataPath, subSectionPath = '', globalParams, metrics, params, search = '', pageNumber = 1, excludeDeltas } = config;
  const mets = metrics.concat(['runWeek']).join(',');
  const isLazyLoading = pageNumber > 1;

  dispatch(
    asyncAction({
      key: isLazyLoading ? TITLES_LAZY_LOAD_FETCH_KEY : TITLES_FETCH_KEY,
      url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/groupBy/masterEventId?_query=json`,
      body: {
        sort: [`${metrics[0]},desc`], // Default sort by first metric
        ...getDefaultParams(),
        ...params,
        ...globalParams,
        ...(excludeDeltas ? {} : { delta: metrics.join(',') }), 
        mets,
        paginate: true,
        page: pageNumber,
        size: PAGE_SIZE,
        search,
      },
      method: 'POST',
      success(response) {
        if (isLazyLoading) {
          dispatch(appendTitleList(response));
        } else {
          dispatch(setTitleList(response));
        }

        const maxPagesReached = response.length < PAGE_SIZE;
        dispatch(setMaxPagesReached(maxPagesReached));
      },
      useDefaultErrorHandler: false,
    })
  );
}, 100);

/**
 * titleListRequest
 * @param {object} globalParams
 * e.g passed globalParams
 * {
 *  siteIds: [40478],
 * }
 */
export const titleListRequest = (config) => {
  return (dispatch) => {
    debouncedTitleRequest(dispatch, config);
  };
};
