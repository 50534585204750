import React from 'react';
import PropTypes from 'prop-types';

import { COMPONENT_BASE_CLASS } from '../constants';

function Section({ children }) {
  return <div className={`${COMPONENT_BASE_CLASS}__section`}>{children}</div>;
}

Section.propTypes = {
  children: PropTypes.node,
};

export default Section;
