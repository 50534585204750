import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import { errorByPattern } from '@showtime/cea-dep-utility';
import { getFullPathname } from '@modules/Core/helpers';
import { Section, InputField, Radio, Button, Alert } from '@components';
import { useTabsContext, TAB_TRANSITION } from '@components/Tabs';
import BookmarksHeader from './BookmarksHeader';
import { BASE_CLASS, BOOKMARK_PRIVACY, BOOKMARKS_FETCH_UPDATE_KEY, BOOKMARKS_PAGES, SHARED_KEY } from '../constants';
import { clearBookmarksData, createBookmark, updateBookmark } from '../actions';
import { useBookmarksContext, useAlertFormValidation } from '../hooks';
import { getFiltersObjectCompressed, isUpdatingBookmark } from '../selectors';

const componentClass = `${BASE_CLASS}__form`;

const propTypes = {
  className: PropTypes.string,
};

const CreateBookmarkForm = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { goToTab } = useTabsContext();
  const { privacyTabActive, editBookmark, setEditBookmark } = useBookmarksContext();
  const isUpdating = useSelector(isUpdatingBookmark);

  // Form fields
  const currentFilters = useSelector(getFiltersObjectCompressed);
  const [nameField, setNameField] = useState(editBookmark.name || '');
  const [privacyField, setPrivacyField] = useState(privacyTabActive);
  const [submitForm, setSubmitForm] = useState(false);

  const alert = useAlertFormValidation(props.httpError, nameField, submitForm);

  const isEdit = useMemo(() => !_isEmpty(editBookmark), [editBookmark]);

  const isShared = useMemo(() => privacyField === SHARED_KEY, [privacyField]);

  const clearData = () => dispatch(clearBookmarksData(privacyTabActive));

  const goBack = () => goToTab(BOOKMARKS_PAGES.MAIN, TAB_TRANSITION.TORIGHT_REMAIN);

  const onRequestDone = () => {
    // Clear Data and force to fetch again the list updated.
    clearData();
    goBack();
  };

  const saveBookmark = useCallback(() => {
    if (alert.status) return;
    const dataForm = {
      name: nameField,
      shared: isShared,
      pathname: editBookmark.pathname || getFullPathname(),
      filter: isEdit ? editBookmark.filter : currentFilters,
    };

    if (isEdit) {
      dispatch(updateBookmark(dataForm, editBookmark.id, onRequestDone));
    } else {
      dispatch(createBookmark(dataForm, onRequestDone));
    }
    setSubmitForm(false);
  }, [submitForm, alert, nameField, privacyField, isShared]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setSubmitForm(true);
    },
    [alert]
  );

  useEffect(() => {
    // Prevent to send request
    if (submitForm) saveBookmark();
  }, [submitForm]);

  useEffect(() => {
    // Reset Submitted form
    if (!alert.status) setSubmitForm(false);
  }, [alert.status]);

  useEffect(() => {
    return () => setEditBookmark({});
  }, []);

  return (
    <Section.Container tag="section" ref={props.forwardedRef} className={classnames(props.className)}>
      <form autoComplete={'off'} onSubmit={(e) => onSubmit(e)} className={componentClass}>
        <BookmarksHeader title={formatMessage({ id: 'bookmarks.header.save' })} prevPage={BOOKMARKS_PAGES.MAIN} />
        {alert.message && <Alert type={alert.status} alertMessage={alert.message} />}
        <Section className={`${componentClass}__fields`}>
          <InputField
            type="text"
            value={nameField}
            className={`${componentClass}--name`}
            label={<FormattedMessage id="bookmarks.form.name" />}
            onChange={(event) => setNameField(event.target.value)}
          />
          <Radio.Group
            defaultTheme
            name="bookmarks-privacy"
            value={privacyField}
            onChange={setPrivacyField}
            label={<FormattedMessage id="bookmarks.form.privacy" />}
          >
            {Object.entries(BOOKMARK_PRIVACY).map(([value, label]) => (
              <Radio.Button
                key={value}
                value={value}
                labelAlign={'right'}
                spaceBetween={false}
                label={formatMessage({ id: `bookmarks.form.${label}` })}
                className={classnames(`${componentClass}__radio`)}
              />
            ))}
          </Radio.Group>
        </Section>
        <Section className={`${componentClass}__footer`}>
          <Button secondary onClick={goBack} defaultTheme disabled={isUpdating}>
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button primary type={'submit'} defaultTheme disabled={isUpdating}>
            {isEdit ? <FormattedMessage id={'save'} /> : <FormattedMessage id={'bookmarks.form.create'} />}
          </Button>
        </Section>
      </form>
    </Section.Container>
  );
};

CreateBookmarkForm.propTypes = propTypes;

const CreateBookmarkComponent = connect((state) => ({
  httpError: errorByPattern(state, BOOKMARKS_FETCH_UPDATE_KEY) || {},
}))(CreateBookmarkForm);

export default forwardRef((props, ref) => <CreateBookmarkComponent {...props} forwardedRef={ref} />);
