import React, { createContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAccordianContext } from '../hooks';

const initialState = {
  isOpen: false,
  toggleAccordianItem: () => {},
  onOpen: () => {},
  onClose: () => {},
};

const ItemContext = createContext(initialState);

/**
 * Manages all the logic for Accordian Items state.
 *
 * @param {boolean} isOpenProp isOpen prop passed into component
 * @param {func} remainOpenProp if true, this item will remain open regardless of any actions
 */
const useIsOpenApi = ({ isOpenProp, remainOpenProp }) => {
  const { canItemAutoCollapse, currentAccordianIncrement, updateAccordianIncrement } = useAccordianContext();

  const [newIncrement, setNewIncrement] = useState(currentAccordianIncrement);
  const [isOpen, setIsOpen] = useState(remainOpenProp ? true : isOpenProp);

  const closeAccordianItem = useCallback(() => {
    if (!remainOpenProp && isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, remainOpenProp, setIsOpen]);

  const openAccordianItem = useCallback(() => {
    if (!remainOpenProp) {
      setIsOpen(true);

      if (canItemAutoCollapse) {
        const newIncrement = currentAccordianIncrement + 1;
        updateAccordianIncrement(newIncrement);
        setNewIncrement(newIncrement);
      }
    }
  }, [
    canItemAutoCollapse,
    remainOpenProp,
    updateAccordianIncrement,
    currentAccordianIncrement,
    setNewIncrement,
    setIsOpen,
  ]);

  const toggleAccordianItem = useCallback(() => {
    if (isOpen) {
      closeAccordianItem();
    } else {
      openAccordianItem();
    }
  }, [isOpen, openAccordianItem, closeAccordianItem]);

  useEffect(() => {
    if (currentAccordianIncrement !== newIncrement && canItemAutoCollapse) {
      closeAccordianItem();
    }
  }, [currentAccordianIncrement, newIncrement, canItemAutoCollapse]);

  return {
    remainOpen: remainOpenProp,
    isOpen,
    toggleAccordianItem,
  };
};

function ItemProvider({ children, isOpenProp, remainOpenProp, onOpen, onClose }) {
  const { isOpen, remainOpen, toggleAccordianItem } = useIsOpenApi({
    isOpenProp,
    remainOpenProp,
  });

  return (
    <ItemContext.Provider value={{ isOpen, remainOpen, toggleAccordianItem, onOpen, onClose }}>
      {children}
    </ItemContext.Provider>
  );
}

ItemProvider.propTypes = {
  children: PropTypes.node,
  isOpenProp: PropTypes.bool,
  remainOpenProp: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

ItemProvider.defaultProps = {
  children: null,
  isOpenProp: false,
  onOpen: () => {},
  onClose: () => {},
};

export { ItemContext, ItemProvider };
