import PropTypes from 'prop-types';

export const METRICS = [
  /*
   *
   * The all array is the metrics master list of all metrics available in the Insights Product.
   * A metric object is in the following format
   *
   * NOTE: Most of these attributes are not used in this application, just to keep consistent with Insights
   *
   * {
   *   key: 'string' // Unique metric key. This key is what is referenced in screen rules
   *   filter: 'string' // The display filter for the metric, will render it as a currency, number percentage etc.
   *   type: 'general' || 'retail' || 'tickets' // Needed to determine if a metric is a retail, ticket or other type (Mainly used for metric color logic)
   *   group: 'movie' || 'revenues' || 'kpi' || 'sales' // *Optional* The group a metric is categorised within a grouped metric dropdown. Required if metric will appear in the dropdown
   *   aggregate: boolean // If a metric is an aggregated metric, i.e. a metric that is not calculated as a 'percentage of' or 'average of'
   *   precision: // *Optional* The number of decimal places a metric will be fixed to. Will default to the precision outlined by the filter if not defined
   * }
   *
   */
  {
    key: 'admissions',
    filter: 'items',
    type: 'tickets',
    group: 'movie',
    aggregate: true,
  },
  {
    key: 'eventRevenue',
    filter: 'currency',
    type: 'tickets',
    group: 'revenues',
    aggregate: true,
  },
  {
    key: 'eventRevenueBO',
    filter: 'currency',
    type: 'tickets',
    group: 'revenues',
    aggregate: true,
  },
  {
    key: 'distributorReportedPrice',
    filter: 'currency',
    type: 'tickets',
    group: 'revenues',
    aggregate: true,
  },
  {
    key: 'occupancy',
    filter: 'percentage',
    type: 'tickets',
    group: 'kpi',
    precision: 2,
    aggregate: false,
  },
  {
    key: 'eventSph',
    filter: 'currency',
    type: 'tickets',
    group: 'kpi',
    precision: 2,
    aggregate: false,
  },
  {
    key: 'capacity',
    filter: 'items', // TODO
    aggregate: false,
  },
  {
    key: 'capacityRemaining',
    filter: 'items',
    aggregate: false,
  },
  {
    key: 'yearMarketShare',
    filter: 'percentage',
    aggregate: false,
  },
];

export const metricKeyPropType = PropTypes.oneOf(METRICS.map(({ key }) => key));
