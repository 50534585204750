import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDrag } from 'react-use-gesture';
import './_horizontal-swipe-buttons.scss';

const componentClass = 'horizontal-swipe-buttons';

const actionButtonWidth = 63; // Units in px as dependency of scss.

const propTypes = {
  children: PropTypes.node,
  leftButtons: PropTypes.arrayOf(PropTypes.node),
  rightButtons: PropTypes.arrayOf(PropTypes.node),
  hasMarginBottom: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  children: null,
  leftButtons: [],
  rightButtons: [],
  hasMarginBottom: false,
  className: null,
  onClick: () => {},
};

/**
 * HorizontalSwipeButtons
 * Allows you to swipe and show action buttons behind.
 * We can add buttons n both directions left and/or right.
 *
 * @params {node} children
 * @params {nodes} leftButtons    - These nodes are expected as Buttons element (max non handled yet)
 * @params {nodes} rightButtons   - These nodes are expected as Buttons element (max non handled yet)
 * @params {bool} hasMarginBottom
 * @params {node} className
 * @params {func} onClick          - Emitted when user clicks the main element and no buttons or swipe actions are in place.
 * */
const HorizontalSwipeButtons = forwardRef(
  ({ children, leftButtons = [], rightButtons = [], hasMarginBottom, className, onClick }, ref) => {
    const [xDelta, setDelta] = useState(0);
    const [{ x }, setX] = useSpring(() => ({ x: 0 }));
    const [heightContainer, setHeightContainer] = useState();
    const [swipeReachLimit, setSwipeReachLimit] = useState(false);
    const [isDragging, setDragging] = useState(false);

    const bounds = useMemo(
      () => ({
        left: -actionButtonWidth * rightButtons.length,
        right: actionButtonWidth * leftButtons.length,
      }),
      [leftButtons, rightButtons]
    );

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(
      ({ down, dragging, movement: [x] }) => {
        setDragging(dragging);

        // Stop swiping element at bounds.
        if (!dragging && swipeReachLimit) return;

        setDelta(down ? x : 0);
        setX({ x: down ? x : 0 });
        onSwipeReachLimit(down ? x : 0);
      },
      {
        axis: 'x',
        bounds,
      }
    );

    const onSwipeReachLimit = (xDelta) => {
      if (swipeReachLimit) return;

      if ((xDelta > 0 && leftButtons.length) || (xDelta < 0 && rightButtons.length)) {
        if (xDelta === bounds.left || xDelta === bounds.right) {
          setSwipeReachLimit(true);
        }
      }
    };

    const getTranslate3D = useCallback(
      (x) => {
        if ((x > 0 && leftButtons.length) || (x < 0 && rightButtons.length)) {
          return `translate3d(${x}px,0,0)`;
        }
        return 'none';
      },
      [x, leftButtons]
    );

    const backToInitPosition = useCallback(() => {
      if (swipeReachLimit) {
        setSwipeReachLimit(false);
        setDelta(0);
        setX({ x: 0 });
      }
    }, [swipeReachLimit, x, xDelta]);

    const onSelectOrSwipeBack = () => {
      if (!isDragging) {
        if (xDelta === 0) onClick();
        else backToInitPosition();
      }
    };

    useEffect(() => {
      // Assign child's height to the wrapper div
      if (ref.current) setHeightContainer(ref.current.clientHeight);
    }, [ref]);

    useEffect(() => {
      if (xDelta === 0) setSwipeReachLimit(false);
    }, [xDelta]);

    return (
      <div
        style={{ height: heightContainer }}
        className={classnames(className, `${componentClass}__main`, {
          [`${componentClass}__main--mb`]: hasMarginBottom,
        })}
      >
        <animated.div {...bind()} className={`${componentClass}__item`}>
          {leftButtons.length ? (
            <animated.div className={`${componentClass}__actions`}>{leftButtons}</animated.div>
          ) : null}
          <animated.div
            onClick={onSelectOrSwipeBack}
            className={`${componentClass}__content`}
            style={{ transform: x.value && x.interpolate((x) => getTranslate3D(x)) }}
          >
            {children}
          </animated.div>
          {rightButtons.length ? (
            <animated.div className={`${componentClass}__actions`} style={{ justifyContent: 'flex-end' }}>
              {rightButtons}
            </animated.div>
          ) : null}
        </animated.div>
      </div>
    );
  }
);

HorizontalSwipeButtons.propTypes = propTypes;
HorizontalSwipeButtons.defaultProps = defaultProps;

export default HorizontalSwipeButtons;
