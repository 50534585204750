import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDrag } from 'react-use-gesture';
import _isEmpty from 'lodash/isEmpty';
import './_horizontal-swipe.scss';

const componentClass = 'horizontal-swipe';

const propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  iconSize: PropTypes.oneOf(['xs', 'sm', 'md']),
  leftAction: PropTypes.bool,
  rightAction: PropTypes.bool,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
  onSwipeStarts: PropTypes.func,
  onSwipeEnds: PropTypes.func,
  bounds: PropTypes.shape({
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  hasMarginBottom: PropTypes.bool,
  className: PropTypes.string,
  onSwipe: PropTypes.func,
};

const defaultProps = {};

/**
 * HorizontalSwipe
 * Allows you to swipe and show and icon meaning the action to take.
 *
 * Important - Ref input ust be passed down to calculate its height based on their children heights.
 *
 * @params {node} children
 * @params {boolean}       leftAction - Enables and disables the swipe action
 * @params {string}        leftIcon - Enables and disables the swipe action
 * @params {string}        leftColor - HEX background color
 * @params {boolean}       rightAction - HEX background color
 * @params {string}        rightIcon - Name of the icon
 * @params {string}        rightColor - Name of the icon
 * @params {string}        iconSize - 'xs', 'sm', 'md' sizes
 * @params {{left, right}} bound - '+' and '-' numbers to fix a limit where the swiping effect reach the max allowed.
 * @params {func} onSwipeStarts   - callback when it emits swipe action
 * @params {func} onSwipeEnds     - callback when it has finished swiping action
 * @params {boolean}       hasMarginBottom - adds a space between components
 * @params {callback}      onSwipe - Callback Function that will return left or right when you reach the max swiping.
 * @params {string}        className
 * */
const HorizontalSwipe = forwardRef(
  (
    {
      children,
      leftAction,
      rightAction,
      iconSize,
      leftIcon,
      rightIcon,
      bounds,
      leftColor,
      rightColor,
      onSwipeStarts,
      onSwipeEnds,
      hasMarginBottom,
      className,
      onSwipe,
    },
    ref
  ) => {
    const [xDelta, setDelta] = useState(0);
    const [{ x }, set] = useSpring(() => ({ x: 0 }));
    const [widthContainer, setWidthContainer] = useState();
    const [heightContainer, setHeightContainer] = useState();
    const [swipeReachLimit, setSwipeReachLimit] = useState(false);
    const [isDragging, setIsDragging] = useState();

    // These bounds are going to limit the swipe action once they reach 70% of the screen.
    // It can also be customised in pixels by bounds props.
    const swipingBounds = useMemo(() => {
      if (_isEmpty(bounds) && widthContainer) {
        const limitInPixels = widthContainer * 0.7;
        return { left: -limitInPixels, right: limitInPixels };
      }
      return bounds;
    }, [widthContainer, bounds]);

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(
      ({ down, dragging, movement: [x] }) => {
        setIsDragging(dragging);
        setDelta(down ? x : 0);
        set({ x: down ? x : 0 });
        onSwipeReachLimit(down ? x : 0);
      },
      {
        axis: 'x',
        bounds: swipingBounds,
      }
    );

    const getTranslate3D = useCallback(
      (x) => {
        if ((x > 0 && leftAction) || (x < 0 && rightAction)) {
          return `translate3d(${x}px,0,0)`;
        }
        return 'none';
      },
      [leftAction, rightAction]
    );

    const onSwipeReachLimit = (xDelta) => {
      if (swipeReachLimit) return;

      if ((xDelta > 0 && leftAction) || (xDelta < 0 && rightAction)) {
        if (xDelta === swipingBounds.left) {
          setSwipeReachLimit(true);
          onSwipe('left');
        } else if (xDelta === swipingBounds.right) {
          setSwipeReachLimit(true);
          onSwipe('right');
        }
      }
    };

    useEffect(() => {
      // Assign child's height to the wrapper div
      if (ref.current) {
        setWidthContainer(ref.current.clientWidth);
        setHeightContainer(ref.current.clientHeight);
      }
    }, [ref.current]);

    useEffect(() => {
      if (xDelta === 0) setSwipeReachLimit(false);
    }, [xDelta]);

    useEffect(() => {
      if (isDragging) onSwipeStarts();
      else onSwipeEnds();
    }, [isDragging]);

    return (
      <div
        style={{ height: heightContainer }}
        className={classnames(className, `${componentClass}__main`, {
          [`${componentClass}__main--mb`]: hasMarginBottom,
        })}
      >
        <animated.div
          {...bind()}
          className={`${componentClass}__item`}
          style={{ backgroundColor: x.interpolate((x) => (x < 0 ? rightColor : leftColor)) }}
        >
          <animated.div
            className={classnames(`${componentClass}__icon`, `${componentClass}__icon--${iconSize}`)}
            style={{
              transform: x
                .interpolate({
                  map: Math.abs,
                  range: [60, 100],
                  output: [0.5, 0.8],
                  extrapolate: 'clamp',
                })
                .interpolate((x) => `scale(${x})`),
              justifySelf: xDelta < 0 ? 'end' : 'start',
            }}
          >
            {leftAction && xDelta > 0 && leftIcon}
            {rightAction && xDelta < 0 && rightIcon}
          </animated.div>
          <animated.div
            className={`${componentClass}__content`}
            style={{ transform: x.interpolate((x) => getTranslate3D(x)) }}
          >
            {children}
          </animated.div>
        </animated.div>
      </div>
    );
  }
);

HorizontalSwipe.propTypes = propTypes;
HorizontalSwipe.defaultProps = defaultProps;

export default HorizontalSwipe;
