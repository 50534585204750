import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useAppContextState } from '@modules';
import { Text } from '@components';

const propTypes = {
  text: PropTypes.node,
  routePath: PropTypes.string,
  routeUrl: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

function SidebarLink({ text, routePath, routeUrl, onClick, icon }) {
  const { path } = useAppContextState();

  return (
    <Link
      className={classnames('sidebar-menu__item', {
        'sidebar-menu__item--is-active': path === routePath,
      })}
      to={routeUrl}
      onClick={onClick}
    >
      <i
        className={classnames('icon', `icon--${icon}`, {
          [`icon icon--${icon}--active`]: path === routePath,
        })}
      ></i>
      <Text tag="span" size="l" bold>
        {text}
      </Text>
    </Link>
  );
}

SidebarLink.propTypes = propTypes;

export default SidebarLink;
