import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { Button } from '@components';
import { useAppContextDispatch } from '@modules';
import { URL_PATHS, useAction } from '@modules/Core';
import { useCompsTitles } from '@modules/FilterTypes/compsTitles';
import { useMetric } from '@modules/FilterTypes/metric';
import { cleanUpComps } from '@modules/Comps';

function BackArrow({ className, goToPath = `/${URL_PATHS.FILMS}`, resetMetricOnCleanUp = true }) {
  const history = useHistory();
  const cleanStore = useAction(cleanUpComps);
  const [, setSelected] = useCompsTitles();
  const [, setMetric] = useMetric();
  const appContextDispatch = useAppContextDispatch();

  const cleanUp = useCallback(() => {
    appContextDispatch({ type: 'SET_PAGE_SEARCH', pageSearch: '' });
    cleanStore();
    setSelected([]);
    if (resetMetricOnCleanUp) {
      setMetric(null);
    }
  }, [cleanStore, setMetric, setSelected, appContextDispatch]);

  const goToPreviousPage = useCallback(() => {
    cleanUp();
    history.push(goToPath);
  }, [history, cleanUp, goToPath]);

  useEffect(() => {
    return cleanUp;
  }, [cleanUp]);

  return <Button className={className} icon="back" onClick={goToPreviousPage} />;
}

BackArrow.propTypes = {
  className: PropTypes.string,
  goToPath: PropTypes.string,
};

export default BackArrow;

