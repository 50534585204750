import { createAction } from '@reduxjs/toolkit';
import _isNull from 'lodash/isNull';
import _find from 'lodash/find';
import { API_ROOT_PATH } from '@modules/Core';
import { asyncAction } from '@showtime/cea-dep-utility';
import { getSelected, setSelected as setSelectedTerritory } from '@modules/FilterTypes/territory';
import { fetchKeySetAsFavourite, fetchKeyRemoveAsFavourite } from './helpers';
import { getUserField, DEFAULT_CURRENCY_ISO_CODE } from '@modules/Core';
import { USER_SETTING_KEYS } from '@modules/UserSettings';
import { FETCH_KEY_SET_AS_DEFAULT_TERRITORY } from './constants';
import { errorBanner } from '../NotificationBanner';
import { setSelected as setSelectedCurrency } from '@modules/FilterTypes/currency';

const prefix = 'territories';

const SET_TERRITORIES_LIST = `${prefix}/SET_TERRITORIES_LIST`;
const SET_TERRITORY_AS_FAVOURITE = `${prefix}/SET_TERRITORY_AS_FAVOURITE`;
const REMOVE_TERRITORY_AS_FAVOURITE = `${prefix}/REMOVE_TERRITORY_AS_FAVOURITE`;

export const setTerritoriesList = createAction(SET_TERRITORIES_LIST);

export const initializeTerritoriesList = () => {
  return (dispatch, getState) => {
    const selectedTerritory = getSelected(getState());
    const isLocalCurrency = getUserField(USER_SETTING_KEYS.localCurrency);

    dispatch(
      asyncAction({
        key: 'territories.fetch',
        url: `${API_ROOT_PATH}/territories?_query=json`,
        body: {},
        method: 'POST',
        success(response) {
          // Setting the default selected territory
          if (_isNull(selectedTerritory)) {
            const defaultTerritory = _find(response, { default: true });

            if (defaultTerritory) {
              dispatch(setSelectedTerritory(defaultTerritory.id));
              dispatch(setSelectedCurrency(isLocalCurrency ? defaultTerritory.currency : DEFAULT_CURRENCY_ISO_CODE));
            } else if (response.length === 1) {
              dispatch(setSelectedTerritory(response[0].id));
              dispatch(setSelectedCurrency(isLocalCurrency ? response[0].currency : DEFAULT_CURRENCY_ISO_CODE));
            }
          }
          dispatch(setTerritoriesList(response));
        },
      })
    );
  };
};

export const setTerritoryAsFavourite = createAction(SET_TERRITORY_AS_FAVOURITE);
export const setTerritoryAsFavouriteRequest = (id) => {
  return asyncAction({
    key: fetchKeySetAsFavourite(id),
    url: `${API_ROOT_PATH}/territories/favourites/${id}`,
    body: {},
    method: 'PUT',
    success() {
      this.dispatch(setTerritoryAsFavourite(id));
    },
  });
};

export const setDefaultTerritoryRequest = (id) => {
  return asyncAction({
    key: FETCH_KEY_SET_AS_DEFAULT_TERRITORY,
    url: `${API_ROOT_PATH}/territories/default/${id}?_query=json&component=user-settings`,
    body: {},
    method: 'PUT',
    success() {
      this.dispatch(initializeTerritoriesList());
    },
    error() {
      this.dispatch(errorBanner('user_settings.error_update'));
    },
  });
};

export const removeTerritoryAsFavourite = createAction(REMOVE_TERRITORY_AS_FAVOURITE);
export const removeTerritoryAsFavouriteRequest = (id) => {
  return asyncAction({
    key: fetchKeyRemoveAsFavourite(id),
    url: `${API_ROOT_PATH}/territories/favourites/${id}`,
    body: {},
    method: 'DELETE',
    success() {
      this.dispatch(removeTerritoryAsFavourite(id));
    },
  });
};
