import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { Text } from '@components';
import { useIsToday } from '@modules/Core';
import { useStudioHelpers } from '@pages/studio/hooks';

import popcorn from '@images/popcorn.svg';

const componentClass = 'empty-state';

function EmptyState({ noResultsComponent }) {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id: 'market_data_not_available' });
  const isToday = useIsToday();
  const { isSitesReported, isIntegrated } = useStudioHelpers();

  return !isSitesReported && !isIntegrated && isToday ? (
    <div className={`${componentClass} ${componentClass}--film-list`}>
      <img src={popcorn} alt={message} />
      <Text className={`${componentClass}__text`}>{message}</Text>
    </div>
  ) : (
    noResultsComponent
  );
}

EmptyState.propTypes = {
  noResultsComponent: PropTypes.object,
};

export default EmptyState;
