import './_header.scss';
import './_main.scss';
import './_sidebar.scss';
import './_error-boundary.scss';

import Header from './Header';
import Main from './Main';
import Sidebar from './Sidebar';
import ThemeChanger from './ThemeChanger';
import Provider from './Provider';
import ErrorBoundary from './ErrorBoundary';

export * from './Provider';
export * from './hooks';
export * from './constants';

export default {
  Header,
  Main,
  Sidebar,
  ThemeChanger,
  Provider,
  ErrorBoundary,
};