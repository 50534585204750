/**
 * 24 hour e.g 20:30, 03:23
 */
export const PERFORMANCE_DISPLAY_FORMAT = 'HH:mm';

/**
 * Backend send performance dateTime strings back as `2020-08-17T13:50:00.000Z` isoStrings
 * For performances times we want to show this exact time, rather than it be converted
 * to the users systems timeZone. Hence why the Z has been ignored.
 */
export const PERFORMANCE_BACKEND_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export { default as CURRENCIES } from './currencies';

const { REACT_APP_APP_TYPE: APP_TYPE, REACT_APP_APP_TYPE_TITLE: APP_TYPE_TITLE } = process.env;

const APP_TYPE_STUDIO = 'studio';
const APP_TYPE_EXHIBITOR = 'exhibitor';

export const TWENTY_FOUR_HR_FORMAT = 'HH:mm';

export const TWELVE_HR_FORMAT = 'h:mma';

export const VALID_USER_TIME_FORMATS = [TWENTY_FOUR_HR_FORMAT, TWELVE_HR_FORMAT];

export const API_ROOT_PATH = `/api/comscore/${APP_TYPE}`;

export { APP_TYPE_STUDIO, APP_TYPE_EXHIBITOR, APP_TYPE, APP_TYPE_TITLE };

export const DEFAULT_METRIC_LIST_ORDER = [
  'eventRevenueBO',
  'eventRevenue',
  'admissions',
  'capacity',
  'occupancy',
  'eventSph',
  'yearMarketShare',
];

/**
 * Note this is outlined in the CSS if it is changed there it will need altered here too.
 */
export const APP_HEADER_HEIGHT = 50;

export const DEFAULT_CURRENCY_ISO_CODE = 'USD';

const URL_PATH_USER = 'user';
const URL_PATH_FILMS = 'films';
const URL_PATH_THEATRES = 'theatres';
const URL_PATH_MARKETS = 'markets';
const URL_PATH_ADVANCED_SALES = 'advancedSales';

export const URL_PATHS = {
  USER: URL_PATH_USER,
  FILMS: URL_PATH_FILMS,
  THEATRES: URL_PATH_THEATRES,
  MARKETS: URL_PATH_MARKETS,
  ADVANCED_SALES: URL_PATH_ADVANCED_SALES,
};
