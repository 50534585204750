import React from 'react';
import { COMPONENT_BASE_CLASS } from '../constants';
import { PlannerViewMain } from './index';
import PlannerViewActions from './PlannerViewActions';
import { usePerformancesFetcher } from '../hooks';

function EmbeddedPlannerView({ onExpand }) {
  usePerformancesFetcher();

  return (
    <div className={COMPONENT_BASE_CLASS}>
      <PlannerViewActions isFullscreen={false} onExpand={onExpand} />
      <PlannerViewMain />
    </div>
  );
}

export default EmbeddedPlannerView;
