import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import { useIntl } from 'react-intl';

import { metricKeyPropType } from '@modules/Metrics';
import { useIsElementInView } from '@modules/Core';

import ListItem from './ListItem';

const propTypes = {
  className: PropTypes.string,
  listData: PropTypes.arrayOf(PropTypes.shape({})),
  onSelectItem: PropTypes.func,
  metrics: PropTypes.arrayOf(metricKeyPropType),
  onScrollBottom: PropTypes.func,
  emptyStateRenderer: PropTypes.func,
};
const defaultProps = {
  className: '',
  listData: null,
  onSelectItem: () => {},
  onScrollBottom: () => {},
  metrics: null,
};

const componentName = 'title-list';

/**
 * Title List
 * @param {string} className - custom className for parent layout positioning.
 * @param {func}  onSelectItem - optional callback if item is selected.
 * @param {array} listData - array of list data to display.
 * @param {array} metrics list of metric strings to be displayed in this list.
 * @param {func} onScrollBottom callback func notifying when the last element in list comes into view.
 */
const List = ({
  className,
  onSelectItem,
  listData,
  metrics,
  onScrollBottom,
  emptyStateRenderer,
  hasSearch,
  highlightedMetrics,
}) => {
  const { formatMessage } = useIntl();
  const [isElementVisible, setElement] = useIsElementInView();

  useEffect(() => {
    if (isElementVisible) {
      onScrollBottom();
    }
  }, [isElementVisible, onScrollBottom]);

  const renderEmptyState = useCallback(() => {
    const noResults = <div className={`${componentName}__empty-ctn`}>{formatMessage({ id: 'no_results_found' })}</div>;

    // If there is a search value present then render no results found
    if (hasSearch) {
      return noResults;
    }

    if (_isFunction(emptyStateRenderer)) {
      return emptyStateRenderer({ noResultsComponent: noResults });
    }

    return noResults;
  }, [emptyStateRenderer, formatMessage, hasSearch]);

  return _isEmpty(listData) ? (
    renderEmptyState()
  ) : (
    <ul className={classnames(componentName, className)}>
      {listData.map((item, index) => {
        return (
          <ListItem
            ref={listData.length === index + 1 ? setElement : null}
            key={item.rank}
            componentClassName={`${componentName}__item`}
            onClick={onSelectItem}
            metrics={metrics}
            highlightedMetrics={highlightedMetrics}
            {...item}
          />
        );
      })}
    </ul>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default React.memo(List);
