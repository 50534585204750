import React from 'react';
import PropTypes from 'prop-types';

import Drawer from './Drawer';
import { useDrawerContext } from '../hooks';

function DrawerWrapper({ children, className, defaultTheme }) {
  const { isOpen, isHeaderVisible } = useDrawerContext();

  // Only rendering children if the header is visible or it is open
  // This prevents issue on Android where an invisible drawer appears when scrolling down on page
  return (
    <Drawer className={className} defaultTheme={defaultTheme}>
      {isOpen || isHeaderVisible ? children : <></>}
    </Drawer>
  );
}

DrawerWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultTheme: PropTypes.bool,
};

export default DrawerWrapper;
