import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { usePerformance } from '@modules/FilterTypes/performance';
import PerformanceCard from './PerformanceCard';
import { getPerformancesGroupedByScreen } from '../selectors';
import { SCREEN_SORTING_KEY } from '../constants';

const componentClass = 'performance';

const PerformanceContent = () => {
  const data = useSelector(getPerformancesGroupedByScreen);
  const [selected, , addPerformance, removePerformance] = usePerformance();

  const onClickCard = useCallback(
    (id) => {
      if (selected.includes(id)) {
        removePerformance(id);
      } else {
        addPerformance(id);
      }
    },
    [addPerformance, removePerformance, selected]
  );

  return (
    <div className={componentClass}>
      {data.map((screen, index) => (
        <div className={`${componentClass}__row`} key={`${screen[SCREEN_SORTING_KEY]}__${index}`}>
          {screen.values.map((title) => (
            <PerformanceCard
              key={title.id}
              {...title}
              onClickCard={onClickCard}
              isSelected={selected.includes(title.id)}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default PerformanceContent;
