import React from 'react';
import { Overlay } from '@components';
import { BASE_CLASS } from './constants';
import BookmarksWrapper from './components/BookmarksWrapper';
import BookmarksToggle from './components/BookmarksToggle';
import './_bookmarks.scss';
import PropTypes from 'prop-types';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const defaultProps = {
  isOpen: false,
  onClose: () => {},
};

const Bookmarks = ({ isOpen, onClose }) => (
  <Overlay.Provider type={'full'} isOpen={isOpen} onClose={onClose} className={BASE_CLASS}>
    {isOpen && <BookmarksWrapper />}
  </Overlay.Provider>
);

Bookmarks.propTypes = propTypes;
Bookmarks.defaultProps = defaultProps;

Bookmarks.Toggle = BookmarksToggle;

export default Bookmarks;
