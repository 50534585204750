import React from 'react';
import { useIntl } from 'react-intl';

import { metricKeyPropType } from '../constants';
import { getMetricI18nKey } from '../helpers';

const propTypes = {
  metricKey: metricKeyPropType.isRequired,
};

function MetricTitle({ metricKey }) {
  const { formatMessage } = useIntl();

  return formatMessage({ id: getMetricI18nKey(metricKey), defaultMessage: metricKey });
}

MetricTitle.propTypes = propTypes;

export default MetricTitle;
