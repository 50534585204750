import { combineReducers } from 'redux';
import { communicationReducer, COMMUNICATION_STATE_KEY } from '@showtime/cea-dep-utility';

import filtersReducer from '@modules/FilterTypes/reducer';
import territoriesReducer from '@modules/TerritorySelect/reducer';
import sitesReducer from '@modules/Sites/reducer';
import siteAttributeGroupsReducer from '@modules/SiteAttributeGroups/reducer';
import authReducer from '@modules/Auth/reducer';
import metricListReducer from '@modules/MetricList/reducer';
import compsReducer from '@modules/Comps/reducer';
import compsChartReducer from '@modules/CompsChart/reducer';
import compsSelectionDrawer from '@modules/CompsSelectionDrawer/reducer';
import dataRefresh from '@modules/DataRefresh/reducer';
import notificationBanner from '@modules/NotificationBanner/reducer';
import theatreList from '@modules/TheatreList/reducer';
import theatreMetadata from '@modules/TheatreMetadata/reducer';
import titleListReducer from '@modules/TitleList/reducer';
import plannerViewReducer from '@modules/PlannerView/reducer';
import bookmarksReducer from '@modules/Bookmarks/reducer';
import reportingIndicatorReducer from '@modules/ReportingIndicator/reducer';

export default combineReducers({
  [COMMUNICATION_STATE_KEY]: communicationReducer,
  ...territoriesReducer,
  ...sitesReducer,
  ...siteAttributeGroupsReducer,
  ...filtersReducer,
  ...authReducer,
  ...metricListReducer,
  ...titleListReducer,
  ...compsSelectionDrawer,
  ...compsReducer,
  ...dataRefresh,
  ...compsChartReducer,
  ...notificationBanner,
  ...theatreList,
  ...theatreMetadata,
  ...plannerViewReducer,
  ...bookmarksReducer,
  ...reportingIndicatorReducer,
});
