import { useEffect } from 'react';
import { useAppContextDispatch } from '@modules';

export default function (isOpen) {
  const dispatch = useAppContextDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch({ type: 'SET_OVERLAY_OPEN', isOverlayOpen: true });
    } else {
      dispatch({ type: 'SET_OVERLAY_OPEN', isOverlayOpen: false });
    }

    return () => dispatch({ type: 'SET_OVERLAY_OPEN', isOverlayOpen: false });
  }, [isOpen, dispatch]);
}
