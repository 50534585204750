import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { gsap } from 'gsap';

import { Text, Icon, IconCustom } from '@components';
import { UserSettingsModal } from '@modules';
import { URL_PATHS, useIsMounted, useIntercom, useLockBodyScroll } from '@modules/Core';

import PoweredByShowtimeImage from '@images/showtime-powered-logo-2x.png';

import TerritorySelect from '../TerritorySelect';
import SidebarLink from './SidebarLink';
import SidebarMenuButton from './SidebarMenuButton';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  hasFilms: PropTypes.bool,
  hasTheatres: PropTypes.bool,
  hasMarket: PropTypes.bool,
  hasAdvancedSales: PropTypes.bool,
};
const defaultProps = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  hasFilms: false,
  hasTheatres: false,
  hasMarket: false,
  hasAdvancedSales: false,
};

const componentName = 'app-sidebar';

const SIDEBAR_TRANSITION_EASE = 'power1.easeInOut';

const AppSidebar = ({
  isOpen,
  onOpen,
  onClose,
  hasAdvancedSales,
  hasFilms,
  hasTheatres,
  hasMarket
}) => {
  const { formatMessage } = useIntl();
  const [isOpenLocal, setIsOpenLocal] = useState(isOpen);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const isMounted = useIsMounted();

  let sidebarMenu = useRef(null);
  let menuTimeline = useRef(null);
  const { push } = useHistory();

  const logout = () => {
    setIsOpenLocal(false);
    push('/user/logout');
  };

  const { showIntercomPopup } = useIntercom();

  useLockBodyScroll(isMounted && isOpenLocal);

  useEffect(() => {
    menuTimeline.current = gsap.timeline({ paused: true });
    menuTimeline.current.fromTo(
      [sidebarMenu],
      {
        autoAlpha: 1,
        duration: 0,
        x: '-100%',
      },
      {
        duration: 0.4,
        x: '0%',
        ease: SIDEBAR_TRANSITION_EASE,
      }
    );
    menuTimeline.current.fromTo(
      [sidebarMenu],
      {
        duration: 0.2,
        backgroundColor: 'rgba(42, 48, 62, 0)',
        ease: SIDEBAR_TRANSITION_EASE,
      },
      {
        duration: 0.2,
        backgroundColor: 'rgba(42, 48, 62, 0.4)',
        ease: SIDEBAR_TRANSITION_EASE,
      }
    );
  }, []);

  useEffect(() => {
    if (isOpen !== isOpenLocal) {
      setIsOpenLocal(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isMounted) {
      if (isOpenLocal) {
        menuTimeline.current.play();
        onOpen();
      } else {
        menuTimeline.current.reverse();
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenLocal, menuTimeline]);

  return (
    <nav ref={(el) => (sidebarMenu = el)} className={classnames(componentName, {})}>
      <div className="app-sidebar__inner">
        <div className="app-sidebar__header">
          <div className="header__logos">
            <IconCustom icon="comscore-logo" className="header__logos__comscore" />
            <img className="header__logos__showtime" src={PoweredByShowtimeImage} alt="Powered by Showtime" />
          </div>

          <ul className="app-sidebar__menu">
            {hasFilms && (
              <li>
                <SidebarLink
                  routePath={URL_PATHS.FILMS}
                  text={formatMessage({ id: 'filmAnalysis' })}
                  routeUrl={`/${URL_PATHS.FILMS}`}
                  onClick={() => setIsOpenLocal(false)}
                  icon="films"
                />
              </li>
            )}
            {hasTheatres && (
              <li>
                <SidebarLink
                  routePath={URL_PATHS.THEATRES}
                  text={formatMessage({ id: 'theatreAnalysis' })}
                  routeUrl={`/${URL_PATHS.THEATRES}`}
                  onClick={() => setIsOpenLocal(false)}
                  icon="theatres"
                />
              </li>
            )}
            {hasMarket && (
              <li>
                <SidebarLink
                  routePath={URL_PATHS.MARKETS}
                  text={formatMessage({ id: 'marketAnalysis' })}
                  routeUrl={`/${URL_PATHS.MARKETS}`}
                  onClick={() => setIsOpenLocal(false)}
                  icon="market"
                />
              </li>
            )}
            {hasAdvancedSales && (
              <li>
                <SidebarLink
                  routePath={URL_PATHS.ADVANCED_SALES}
                  text={formatMessage({ id: 'advancedSales' })}
                  routeUrl={`/${URL_PATHS.ADVANCED_SALES}`}
                  onClick={() => setIsOpenLocal(false)}
                  icon="advanced-sales"
                />
              </li>
            )}
          </ul>
          <hr className="app-sidebar__divider" />
        </div>

        <div className="app-sidebar__body">
          <div className="app-sidebar__body__title sidebar-menu__item">
            <Icon icon="territories" />
            <Text tag="span" size="l" bold>
              {formatMessage({ id: 'territories' })}
            </Text>
          </div>

          <TerritorySelect className="app-sidebar__body__content" onSelectItem={() => setIsOpenLocal(false)} />
        </div>

        <div className="app-sidebar__footer">
          <hr className="app-sidebar__divider" />
          <SidebarMenuButton
            onClick={() => setIsSettingsOpen(true)}
            icon="settings"
            text={formatMessage({ id: 'settings' })}
          />

          <hr className="app-sidebar__divider" />
          <SidebarMenuButton onClick={showIntercomPopup} icon="intercom" text={formatMessage({ id: 'support' })} />

          <hr className="app-sidebar__divider" />
          <SidebarMenuButton onClick={logout} icon="log-out" text={formatMessage({ id: 'logout' })} />
        </div>
      </div>

      <div className="app-sidebar__overlay" role="button" onClick={() => setIsOpenLocal(false)} />
      <UserSettingsModal isOpen={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </nav>
  );
};

AppSidebar.propTypes = propTypes;
AppSidebar.defaultProps = defaultProps;

export default AppSidebar;
