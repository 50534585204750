import { useRouteMatch } from 'react-router-dom';
import { URL_PATHS } from '@modules/Core';

export default function useIsMarketAnalysis() {
  const match = useRouteMatch('/:basePath');
  const basePath = match.params.basePath;

  const isMarketAnalysis = basePath === URL_PATHS.MARKETS;

  return isMarketAnalysis;
}
