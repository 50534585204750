import React, { useContext } from 'react';

const initialValues = {
  metrics: [],
  baseSiteId: null,
};

const PlannerViewContext = React.createContext(initialValues);

PlannerViewContext.displayName = 'PlannerViewContext';

export const usePlannerViewContext = () => useContext(PlannerViewContext);

export default PlannerViewContext;
