import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useParametersListener } from '@modules/FilterTypes';
import { useSelectedSiteAttributeGroup } from '@modules/SiteAttributionSelector';
import { useLastRefresh, useAppContextState } from '@modules';

import './_theatre-list.scss';
import { ListContainer } from './components';
import { getParametersForTheatresList } from './selectors';
import TheatreListContext from './Context';
import { useDataFetchAndReset, useTheatreListData, useDataFetchAndAppend, usePagination } from './hooks';

const propTypes = {
  className: PropTypes.string,
  hasAside: PropTypes.bool,
  paginated: PropTypes.bool,
  onSelectItem: PropTypes.func,
  params: PropTypes.shape({
    [PropTypes.string]: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.number, PropTypes.string])
  }),
  ignoreSelectedAttributeGroup: PropTypes.bool,
  emptyStateRenderer: PropTypes.func,
};

const defaultProps = {
  paginated: false,
  hasAside: false,
};

const FETCH_START_PAGE = 1;

/**
 * TheatreList
 * @param {bool} hasAside
 * @param {object} params
 */
const TheatreList = (props) => {
  const { params, ignoreSelectedAttributeGroup, paginated, ...rest } = props;
  const { pageSearch } = useAppContextState();
  const [pageNumber, setPageNumber] = useState(FETCH_START_PAGE);

  const lastRefresh = useLastRefresh();
  const [, selectedAttribute] = useSelectedSiteAttributeGroup();
  const [, setCurrentPaginatedPage] = usePagination();

  const [data, isLoading, isLazyLoading, isMaxPagesReached] = useTheatreListData();

  /**
   * If a filter, search, selectedAttr etc change -
   * reset pageNumber back to start.
   */
  useParametersListener(
    getParametersForTheatresList,
    () => {
      // Reset page number for lazy loaded appended pages.
      setPageNumber(FETCH_START_PAGE);
      // Reset page number for Paginated pages.
      setCurrentPaginatedPage(FETCH_START_PAGE);
    },
    [lastRefresh, selectedAttribute, params, pageSearch]
  );

  // Fires to load next page
  useDataFetchAndAppend(params, ignoreSelectedAttributeGroup, pageNumber);

  // Fires after search, refresh, selected attr change, param change
  useDataFetchAndReset(params, ignoreSelectedAttributeGroup, paginated, pageNumber);

  const onScrollBottom = () => {
    if (!isLoading && !isMaxPagesReached && !paginated) {
      setPageNumber(pageNumber + 1);
    }
  };
  const onScrollBottomRef = useRef(onScrollBottom);
  useEffect(() => {
    onScrollBottomRef.current = onScrollBottom;
  });

  const onScrollBottomMemoized = useCallback(() => {
    onScrollBottomRef.current();
  }, []);
  return (
    <TheatreListContext.Provider value={params}>
      <ListContainer
        listData={data}
        onScrollBottom={onScrollBottomMemoized}
        isInitiallyLoading={isLoading}
        isLazyLoading={isLazyLoading}
        paginated={paginated}
        {...rest}
      />
    </TheatreListContext.Provider>
  );
};

TheatreList.propTypes = propTypes;
TheatreList.defaultProps = defaultProps;

export default TheatreList;
