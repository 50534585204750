import moment from 'moment';
import { GAP_HOUR_TIME_LINE, TIME_LINE_FORMAT } from './constants';
import { getUserCircuit } from '@modules/Core/helpers';
import { PERFORMANCE_BACKEND_TIME_FORMAT } from '@modules/Core';

// ------------ Performance Cards Helpers ------------ //

/**
 * Minutes to pixels
 * Converts minutes given to pixels applying formula by GAP_HOUR_TIME_LINE
 * @return {number}
 * */
const getMinutesToPixels = (minutes) => Math.abs(Math.round(minutes * (GAP_HOUR_TIME_LINE / 60)));

const getDurationAsMinutesPerPixels = (startTime, endTine) => {
  const duration = moment.duration(startTime.diff(endTine));
  return getMinutesToPixels(duration.asMinutes());
};

/**
 * This function return the moment object need to calculate if the datetime given should be today or tomorrow.
 * Time between 00am and circuitStartHour (6am) will be considered as next day in hour timeline's logic.
 * We just care about hours here, days are irrelevant. It always must be today or tomorrow.
 * Otherwise being other days our logic will push the day out of timeline component.
 *
 * From 06:00am to 23:59pm (circuitStartHour) is today.
 * From 00:00am to 06:00am (circuitStartHour) is tomorrow.
 *
 * @param {string} datetime PERFORMANCE_BACKEND_TIME_FORMAT.
 * @return {object} moment (current day or next day).
 * */
const getStartHourRightDay = (datetime) => {
  const hourFromNextDay = moment('00:00', TIME_LINE_FORMAT);
  const hourToNextDay = moment(getUserCircuit().startHour, 'hh').subtract(1, 'second');
  const time = moment(moment(datetime, PERFORMANCE_BACKEND_TIME_FORMAT).format(TIME_LINE_FORMAT), TIME_LINE_FORMAT);

  if (time.isBetween(hourFromNextDay, hourToNextDay)) {
    return moment(time).add(1, 'days'); // Tomorrow
  }
  return time; // Today
};

/**
 * Left Position from (timeLine) Start Hour
 * We can receive different days chosen previously by the app. Although cinema start hour is always today.
 * However this function makes sure we are on the same day for both values startTitleHour and startCinemaHour.
 * As we just need to calculate hours. Because calculating different days will multiply the left position by days.
 *
 * @return {number} - Pixels
 * */
export const leftPositionFromStartHour = (startTime) => {
  const startTitleHour = getStartHourRightDay(startTime);
  const startCinemaHour = moment(getUserCircuit().startHour, 'hh');
  return getDurationAsMinutesPerPixels(startTitleHour, startCinemaHour);
};

/**
 * Card's width by movies length and and runTime
 * @return {number} - Pixels
 * */
export const widthCardByMovieDuration = (runTime, movieLength) => {
  const _runTime = moment(runTime, PERFORMANCE_BACKEND_TIME_FORMAT);
  const endMovie = moment(runTime, PERFORMANCE_BACKEND_TIME_FORMAT).add(movieLength, 'minutes');
  return getDurationAsMinutesPerPixels(endMovie, _runTime);
};
