import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { useLockBodyScroll } from '@modules/Core';
import { useSetDrawerOpen } from '@modules/AppLayout';
import classnames from 'classnames';

import { useDrawerAnimations, useDrawerContext } from '../hooks';
import { COMPONENT_BASE_CLASS } from '../constants';

function Drawer({ children, className, defaultTheme }) {
  const { isOpen, openDrawer, closeDrawer } = useDrawerContext();

  const [drawerBinder, drawerOverlayBinder] = useDrawerAnimations(isOpen);
  const drawerParent = document.body;
  // const bindSwipe = useSwipe(openDrawer, closeDrawer);

  useSetDrawerOpen(isOpen);
  useLockBodyScroll(isOpen);

  return (
    <>
      {createPortal(
        <>
          <div
            ref={drawerBinder}
            className={classnames(`${COMPONENT_BASE_CLASS} ${className}`, {
              [`${COMPONENT_BASE_CLASS}--visible`]: isOpen,
              [`${COMPONENT_BASE_CLASS}--default-theme`]: defaultTheme,
            })}
          >
            {children}
          </div>
          <div
            role="button"
            onClick={closeDrawer}
            ref={drawerOverlayBinder}
            className={`${COMPONENT_BASE_CLASS}-overlay`}
          />
        </>,
        drawerParent
      )}
    </>
  );
}

Drawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultTheme: PropTypes.bool,
};

Drawer.defaultProps = {
  className: '',
  defaultTheme: false,
};

export default Drawer;
