import { useMemo } from 'react';
import _debounce from 'lodash/debounce';
import gsap from 'gsap';

import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useParametersListener } from '@modules/FilterTypes';
import { useSelectedSiteAttributeGroup } from '@modules/SiteAttributionSelector';
import { useLastRefresh, useAppContextState } from '@modules';

import { getParametersForTheatresList } from '../selectors';
import { fetchSitesList } from '../actions';
import { usePagination } from '../hooks';

/**
 * useDataFetchAndReset
 * This resets the list back to page one.
 * Will be fired if any of these change:
 * - selectedAttribute (site)
 * - metricList selected
 * - occupancy filter changes
 * - onRefresh
 * - pageSearch
 */
export const useDataFetchAndReset = (globalParams, ignoreSelectedAttributeGroup, paginated) => {
  const { pageSearch } = useAppContextState();

  const fetchSites = useAction(fetchSitesList);
  // Debouncing sites so only one request is fired at a time
  const fetchSitesDebounce = useMemo(() => _debounce(fetchSites, 100), [fetchSites]);
  const lastRefresh = useLastRefresh();
  const [, selectedAttribute] = useSelectedSiteAttributeGroup();
  const [currentPage] = usePagination();
  const [subSectionPath, dataPath] = useAPIPath();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getParametersForTheatresList,
    (params) => {
      const excludeDeltas = isAdvancedSales;
      const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
      if (params.territoryId && params.sort.length >= 1) {
        const attributeId = ignoreSelectedAttributeGroup ? null : selectedAttribute.id;

        // Reset Page scroll back to top when any of the filter, pageSearch or lastRefresh changes.
        gsap.to(window, {
          duration: 0.5,
          scrollTo: 0,
          onStart: () => {
            fetchSitesDebounce(
              attributeId,
              {
                ...params,
                ...timeParams,
                ...globalParams,
                search: pageSearch,
                page: paginated ? currentPage : 1,
              },
              undefined,
              subSectionPath,
              dataPath,
              excludeDeltas
            );
          },
        });
      }
    },
    [
      lastRefresh,
      globalParams,
      selectedAttribute.id,
      pageSearch,
      ignoreSelectedAttributeGroup,
      currentPage,
      subSectionPath,
      dataPath,
      isAdvancedSales,
      isToday,
    ]
  );
};
