import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

import SelectorItem from '../SelectorItem';

const propTypes = {
  value: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};
const defaultProps = {
  isActive: false,
  value: null,
  onClick: () => {},
};

const ITEM_TRANSITION_EASE = 'power1.easeInOut';

/**
 * Slide
 * @param {bool} isActive
 * @param {string} value expected format: yyyy-mm-dd
 */
const Slide = ({ isActive, value, onClick }) => {
  let sliderItemRef = useRef(null);
  let animTimeline = useRef(null);

  useEffect(() => {
    animTimeline.current = gsap.timeline({ paused: true });
    animTimeline.current.to([sliderItemRef], {
      duration: 0.4,
      y: -5,
      scale: 1.3,
      ease: ITEM_TRANSITION_EASE,
    });
  }, []);

  useEffect(() => {
    if (isActive) {
      animTimeline.current.play();
    } else {
      animTimeline.current.reverse();
    }
  }, [isActive]);

  return (
    <div className="keen-slider__slide" onClick={onClick}>
      <div className="slide__content" ref={(el) => (sliderItemRef = el)}>
        <SelectorItem active={isActive} value={value} />
      </div>
    </div>

  );
};

Slide.propTypes = propTypes;
Slide.defaultProps = defaultProps;

export default Slide;
