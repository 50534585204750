import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBookmarks } from '../actions';
import { FIRST_PAGE_FETCH, LIMIT_SIZE_FETCH, SHARED_KEY } from '../constants';
import { useBookmarksContext } from './useBookmarksContext';
import _debounce from 'lodash/debounce';

const delaySearching = 500;

export const useDataFetcher = (search, page) => {
  const dispatch = useDispatch();
  const { privacyTabActive } = useBookmarksContext();
  const [searchDebounce, setSearchDebounce] = useState(search);

  const setSearchValueDebounce = useMemo(
    () =>
      _debounce((value) => {
        setSearchDebounce(value);
      }, delaySearching),
    []
  );

  useEffect(() => {
    setSearchValueDebounce(search);
  }, [search]);

  useEffect(() => {
    const isLazyLoad = page > FIRST_PAGE_FETCH;
    const params = {
      page,
      size: LIMIT_SIZE_FETCH,
      shared: (privacyTabActive === SHARED_KEY).toString(),
    };

    if (searchDebounce) params.search = encodeURIComponent(searchDebounce);

    dispatch(fetchBookmarks(privacyTabActive, params, isLazyLoad));
  }, [privacyTabActive, searchDebounce, page]);
};
