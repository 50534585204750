import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Territories } from './components';
import { initializeTerritoriesList } from './actions';

import './_territory-select.scss';

export * from './selectors';
export * from './actions';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const TerritorySelect = ({ className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeTerritoriesList());
  }, []);

  return <Territories className={className}></Territories>;
};

TerritorySelect.propTypes = propTypes;
TerritorySelect.defaultProps = defaultProps;

export default TerritorySelect;
