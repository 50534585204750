import { useParametersListener } from '@modules/FilterTypes';
import { useAction, useAPIPath, useIsAdvancedSales, useIsToday, getTimeParams } from '@modules/Core';
import { getPerformanceModalDataParams } from '../selectors';
import { performanceModalMetricsRequest, performanceModalAttributesRequest } from '../actions';
import { usePlannerViewContext } from '../Context';

export default function usePerformanceModalDataFetcher(performanceId) {
  const { metrics, baseSiteId } = usePlannerViewContext();
  const dispatchFetch = useAction(performanceModalMetricsRequest);
  const dispatchFetchPerformanceAttributes = useAction(performanceModalAttributesRequest);
  const mets = metrics.join(',');
  const isAdvancedSales = useIsAdvancedSales();
  const isToday = useIsToday();
  const [subSectionPath, dataPath] = useAPIPath();

  useParametersListener(
    getPerformanceModalDataParams,
    (params) => {
      const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
      const excludeDeltas = isAdvancedSales;
      if (baseSiteId && performanceId && params.dt && params.df) {
        const localParams = { ...params, performanceId: [performanceId], siteIds: [baseSiteId] };
        dispatchFetch(mets, { ...localParams, ...timeParams }, subSectionPath, dataPath, excludeDeltas);
        dispatchFetchPerformanceAttributes(performanceId);
      }
    },
    [performanceId, metrics, baseSiteId, subSectionPath, dataPath, isAdvancedSales, isToday]
  );
}
