import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useOverlay } from '@components/Overlay';
import { Spinner, SearchInputField, Section } from '@components';
import { useTabsContext, TAB_TRANSITION } from '@components/Tabs';
import { useScrollBottom } from '@modules/Core/hooks';
import { useBookmarksContext, useDataFetcher } from '../hooks';
import { BASE_CLASS, BOOKMARKS_PAGES, FIRST_PAGE_FETCH } from '../constants';
import { getBookmarksPrivacy, isFetchingData, isLazyFetchingData } from '../selectors';
import { loadBookmarkFilters, clearBookmarksData, setSelected } from '../actions';
import BookmarksListItem from './BookmarksListItem';

const delayClosingModal = 650;

const propTypes = {
  className: PropTypes.string,
};

const BookmarksList = () => {
  const dispatch = useDispatch();
  const { close } = useOverlay();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { goToTab } = useTabsContext();
  const { privacyTabActive, setEditBookmark } = useBookmarksContext();
  const [search, setSearch] = useState();
  const [page, setPage] = useState(FIRST_PAGE_FETCH);
  const isLoading = useSelector(isFetchingData);
  const isLazyLoading = useSelector(isLazyFetchingData);
  const [isScrollBottom, scrollRef] = useScrollBottom();
  const [selectedBookmark, setSelectedBookmark] = useState();

  const data = useSelector(getBookmarksPrivacy(privacyTabActive));

  const hasNoDataAvailable = useMemo(() => !isLoading && !data.content.length, [isLoading, data]);

  const getBookmarkById = (id) => data.content.find((bookmark) => bookmark.id === id);

  const onBookmarkSelect = useCallback((id) => {
    // We navigate to the page and then we'll load the values thereafter by useEffect & selectBookmarkAfterNavigation.
    const bookmark = getBookmarkById(id);
    setSelectedBookmark(bookmark);
    history.push(bookmark.pathname);
  });

  const selectBookmarkAfterNavigation = () => {
    if (selectedBookmark) {
      dispatch(loadBookmarkFilters(selectedBookmark.filter));
      dispatch(setSelected(selectedBookmark));
      closeMainModal();
    }
  };

  const onBookmarkEdit = useCallback((id) => {
    goToTab(BOOKMARKS_PAGES.FORM, TAB_TRANSITION.REMAIN_FROMRIGHT);
    setEditBookmark(getBookmarkById(id));
  });

  const closeMainModal = () => setTimeout(() => close(), delayClosingModal);

  useEffect(() => {
    setPage(FIRST_PAGE_FETCH);
    dispatch(clearBookmarksData());
  }, [privacyTabActive, search]);

  useEffect(() => {
    if (isScrollBottom && page < data.totalPages) {
      setPage(page + 1);
    }
  }, [isScrollBottom, page, data]);

  useEffect(() => {
    selectBookmarkAfterNavigation();
  }, [history, selectedBookmark]);

  useDataFetcher(search, page);

  return (
    <Section.Container className={`${BASE_CLASS}__list`} ref={scrollRef} tag="section" scrollable bordered>
      <SearchInputField
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        placeholder={formatMessage({ id: 'search' })}
      />
      <ul className={`${BASE_CLASS}__list__items`}>
        {isLoading ? (
          <Spinner defaultTheme className={`${BASE_CLASS}__list__loading-main`} />
        ) : (
          data.content.map((item) => (
            <div key={`bookmark-${item.id}`}>
              <BookmarksListItem {...item} onClick={onBookmarkSelect} onEdit={onBookmarkEdit} />
              <div className={`${BASE_CLASS}__list__item-divider`} />
            </div>
          ))
        )}
        {isLazyLoading && <Spinner size={'s'} className={`${BASE_CLASS}__list__loading-lazy`} />}
        {hasNoDataAvailable && (
          <div className={`${BASE_CLASS}__list__no-result`}>
            <FormattedMessage id={'no_data_available'} />
          </div>
        )}
      </ul>
    </Section.Container>
  );
};

BookmarksList.propTypes = propTypes;

export default BookmarksList;
