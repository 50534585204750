import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import _isUndefined from 'lodash/isUndefined';

import { useAppContextState, SearchInputHeader, RefreshButton } from '@modules';
import HeadersMiscActions from '@modules/HeadersMiscActions';

import { Button, Heading, HeaderGroup, IconCustom } from '@components';

import { PAGE_TITLES_IDS } from './constants';

const propTypes = {
  onToggleSidebar: PropTypes.func,
  FilterComponent: PropTypes.elementType,
};

const defaultProps = {
  onToggleSidebar: () => {},
};

const PageTitle = () => {
  const { formatMessage } = useIntl();
  const { path } = useAppContextState();

  return (
    <Heading className="page-title" level={1} size="m">
      {!_isUndefined(PAGE_TITLES_IDS[path]) && formatMessage({ id: PAGE_TITLES_IDS[path] })}
    </Heading>
  );
};

/**
 * AppHeader
 * @param {string} onToggleSidebar - Callback for when the sidebar hamburger gets clicked
 * @param {Component} FilterComponent - Filter Component to be rendered for the app
 */
const AppHeader = ({ onToggleSidebar, FilterComponent }) => {

  return (
    <HeaderGroup className="app-header">
      <HeaderGroup.Left>
        <Button className="sidebar-toggle" onClick={onToggleSidebar}>
          <IconCustom icon="menu" />
        </Button>
        {FilterComponent && <FilterComponent />}
      </HeaderGroup.Left>

      <HeaderGroup.Center>
        <PageTitle />
      </HeaderGroup.Center>

      <HeaderGroup.Right>
        <RefreshButton />
        <HeadersMiscActions />
        <SearchInputHeader />
      </HeaderGroup.Right>
    </HeaderGroup>
  );
};

AppHeader.propTypes = propTypes;
AppHeader.defaultProps = defaultProps;

export default AppHeader;
