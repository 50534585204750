import { useEffect, useMemo, useState } from 'react';

const defaultBrowserConfig = { hidden: '', visibilityChange: '' };

/**
 * useIsAppActive
 *
 * This hook will detect if the user has app active by Page Visibility API for some web browsers.
 * Active - When the user is using this app and the tab is on.
 * NOT active - Either if the user locks the phone or is navigating through other tabs at his browser.
 *
 * @exception
 * If the browser does not support web 'Page Visibility API' we'll inform by a warning console message.
 * Therefore, this functionality of detecting when the app in active or not will be disabled.
 * It won't cause any damage but is nice to know it.
 *
 * @return {boolean}
 * */
export default function useIsAppActive() {
  const [isAppActive, setIsAppActive] = useState(false);
  const [visibleConfig, setVisibleConfig] = useState(defaultBrowserConfig);

  const handleVisibilityChange = () => setIsAppActive(!document[visibleConfig.hidden]);

  // Cross-browser compatibility setup.
  useEffect(() => {
    let hidden, visibilityChange;
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
    setVisibleConfig({ hidden, visibilityChange });
  }, []);

  useEffect(() => {
    // Non cross-browser compatibility (the app will be always on).
    if (visibleConfig.hidden === undefined) {
      console.warn('useIsAppActive (Hook): This browser does not support Page Visibility API.');
      setIsAppActive(true);
    } else {
      // Initial state
      handleVisibilityChange();
      document.addEventListener(visibleConfig.visibilityChange, handleVisibilityChange, false);
    }
    return () => document.removeEventListener(visibleConfig.visibilityChange, handleVisibilityChange);
  }, [visibleConfig]);

  return useMemo(() => isAppActive, [isAppActive, visibleConfig]);
}
