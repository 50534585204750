import _property from 'lodash/property';
import _isString from 'lodash/isString';
import _get from 'lodash/get';

import { createSelector } from '@reduxjs/toolkit';

import { TWENTY_FOUR_HR_FORMAT } from '@modules/Core';

import { getState } from './common';

export const getUserProfile = createSelector([getState], _property('userProfile'));

export const getUserCircuit = createSelector([getUserProfile], ({ circuits }) => {
  return circuits[0] || {};
});

export const getCircuitAttributes = createSelector([getUserCircuit], (circuit) => {
  const attributes = circuit.attributes;

  if (_isString(attributes)) {
    try {
      return JSON.parse(attributes);
    } catch (e) {
      return {};
    }
  } else {
    return attributes || {};
  }
});

export const getUserTimeFormat = createSelector([getCircuitAttributes, getUserProfile], (attributes, userProfile) => {
  const timeFormatCircuit = _get(attributes, 'timeFormat', TWENTY_FOUR_HR_FORMAT);
  let timeFormat = _get(userProfile, 'timeFormat', timeFormatCircuit);

  return timeFormat;
});
