import _get from 'lodash/get';
import { createSelector } from '@reduxjs/toolkit';
import { STATE_KEY } from './constants';

export const getFilters = (state) => state[STATE_KEY];

export const createAttributeSelector = (key, attribute, defaultValue) =>
  createSelector([getFilters], (filters) => _get(filters, `${key}.${attribute}`, defaultValue));

export const getSelectedSelector = (key, defaultValue = []) => createAttributeSelector(key, 'selected', defaultValue);
