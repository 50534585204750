import React, { useRef, useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
  DateSelector,
  DateComparisonPeriod,
  DateChangeSwipe,
  MetricList,
  Page,
  useSetPageName,
  useSetRefreshMetrics,
  PAGE_NAME_FILM_ANALYSIS,
  TitleList,
  ReportingIndicator,
} from '@modules';
import { URL_PATHS, useIsFuture } from '@modules/Core';
import { useSort } from '@modules/FilterTypes/sort';
import { useMetric } from '@modules/FilterTypes/metric';
import { BackToTop } from '@components';

import { useStudioHelpers } from '@pages/studio/hooks';

import { EmptyStateFilmList, NoPresalesIntegration } from './components';
import { useFilmPerspectiveMetrics } from '../hooks';

import './_film-analysis.scss';

const componentName = 'film-analysis';

/**
 * FilmAnalysis Page
 */
const FilmAnalysis = () => {
  const history = useHistory();
  const [metric] = useMetric();
  const metrics = useFilmPerspectiveMetrics();
  const isFuture = useIsFuture();
  const { isPresales } = useStudioHelpers();
  const shouldDisplayPresalesPlaceholder = useMemo(() => {
    return !isPresales && isFuture;
  }, [isFuture, isPresales]);

  const [, , setSortMetricSelected] = useSort();

  const renderEmptyState = useCallback(({ noResultsComponent }) => {
    return <EmptyStateFilmList noResultsComponent={noResultsComponent} />;
  }, []);

  useSetPageName(PAGE_NAME_FILM_ANALYSIS);
  useSetRefreshMetrics(metrics);

  const dateSelectorContainerRef = useRef(null);
  const dateSelectorRef = useRef(null);
  const onPageSwipeLeft = useCallback(() => dateSelectorRef.current.moveToNextSlide(), [dateSelectorRef]);
  const onPageSwipeRight = useCallback(() => dateSelectorRef.current.moveToPrevSlide(), [dateSelectorRef]);
  const checkIsInMinMaxRange = dateSelectorRef.current ? dateSelectorRef.current.checkIsInMinMaxRange : null;

  useEffect(() => {
    setSortMetricSelected(metric || metrics[0]);
  }, [metric, metrics, setSortMetricSelected]);

  return (
    <Page className={componentName}>
      <div className={classnames(`${componentName}__date-selector-ctn`)} ref={dateSelectorContainerRef}>
        <DateSelector className={classnames(`${componentName}__date-selector`)} ref={dateSelectorRef} daysAfter={1} />
        {!shouldDisplayPresalesPlaceholder && (
          <>
            <DateComparisonPeriod />
            <ReportingIndicator className={classnames(`${componentName}__report-indicator`)} />
          </>
        )}
      </div>
      {shouldDisplayPresalesPlaceholder && <NoPresalesIntegration />}
      {!shouldDisplayPresalesPlaceholder && (
        <>
          <MetricList
            pin
            metrics={metrics}
            monitorHeightRef={dateSelectorContainerRef}
            metricListContainerClassName={classnames(`${componentName}__metric-list-ctn`)}
          />

          <DateChangeSwipe
            className={classnames(`${componentName}__swipe-ctn`)}
            onSwipeLeft={onPageSwipeLeft}
            onSwipeRight={onPageSwipeRight}
            checkIsInMinMaxRange={checkIsInMinMaxRange}
          >
            <TitleList
              metrics={metrics}
              onSelectItem={(id) => history.push(`/${URL_PATHS.FILMS}/${id}/comps`)}
              emptyStateRenderer={renderEmptyState}
            />
            <BackToTop />
          </DateChangeSwipe>
        </>
      )}
    </Page>
  );
};

export default FilmAnalysis;
