import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BackNavHeader } from './components';
import classnames from 'classnames';

import './_page.scss';

const PageComponent = React.forwardRef(function Page({ className, ...props }, ref) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <section ref={ref} className={classnames(`page ${className}`)} {...props}></section>;
});

PageComponent.propTypes = {
  className: PropTypes.string,
};

PageComponent.defaultProps = {
  className: '',
};

PageComponent.BackNavHeader = BackNavHeader;

export default PageComponent;
