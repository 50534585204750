import { createSelector } from '@reduxjs/toolkit';
import { isLoading } from '@showtime/cea-dep-utility';
import _property from 'lodash/property';

import { STATE_KEY, SITE_ATTRIBUTE_GROUPS_FETCH_KEY, EMPTY_GROUPS } from './constants';

import { KEY as territory } from '@modules/FilterTypes/territory';

import { getParametersFactory } from '@modules/FilterTypes';

export const getSiteAttrGroupsParams = getParametersFactory([territory]);

export const getIsLoadingSiteAttributeGroups = (state) => isLoading(state, SITE_ATTRIBUTE_GROUPS_FETCH_KEY);

export const getSiteAttributeGroupsRaw = _property(STATE_KEY);

export const getSiteAttributeGroups = createSelector([getSiteAttributeGroupsRaw], (groups) => groups || EMPTY_GROUPS);

const getSiteAttributeGroupsNormalised = createSelector([getSiteAttributeGroups], (groups) => {
  const attributeMap = new Map();
  const groupMap = new Map();

  groups.forEach((group) => {
    const { id, attributes } = group;
    const attributeIds = [];

    (attributes || []).forEach((attribute) => {
      attributeIds.push(attribute.id);
      attributeMap.set(attribute.id, { ...attribute, groupId: id });
    });

    groupMap.set(id, { ...group, attributes: new Set(attributeIds) });
  });

  return { groupMap, attributeMap };
});

export const getSiteAttributeGroupsMap = createSelector([getSiteAttributeGroupsNormalised], _property('groupMap'));
export const getSiteAttributesMap = createSelector([getSiteAttributeGroupsNormalised], _property('attributeMap'));
