import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Binds action creator with dispatch.
 * @param {function} actionCreator
 * @param {any[]} partialArgs - Additional args are partially applied to the action creator.
 *   Additional args should not change in length between rerenders!
 * @return an action creator that gets dispatched on call.
 */
export default function useAction(actionCreator, ...partialArgs) {
  const dispatch = useDispatch();

  return useCallback(
    (...actionArgs) => {
      return dispatch(actionCreator(...partialArgs, ...actionArgs));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, actionCreator, ...partialArgs]
  );
}
