import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from '../Typography';
import { Button } from '../Button';

const propTypes = {
  type: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      label: PropTypes.node,
    })
  ).isRequired,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  active: PropTypes.number,
  onChangeTab: PropTypes.func,
  className: PropTypes.string,
  lightTheme: PropTypes.bool,
};
const defaultProps = {
  type: 'material',
  tabs: [],
  size: 'm',
  active: 0,
  onChangeTab: () => {},
  className: null,
  lightTheme: false,
};

const Underline = ({ activeTab, lightTheme }) => {
  let style = { display: 'none' };

  if (activeTab) {
    style = {
      left: `${activeTab.offsetLeft}px`,
      width: `${activeTab.clientWidth}px`,
    };
  }

  return (
    <div className="tabs__nav__underline">
      <span
        className={classnames('tabs__nav__underline__indicator', {
          ['tabs__nav__underline__indicator--light-theme']: lightTheme,
        })}
        style={style}
      />
    </div>
  );
};

const TabNavItem = ({ isActive, label, onChangeTab, size }) => {
  const tabRef = useRef(null);

  const handleChangeTab = useCallback(() => {
    onChangeTab(tabRef.current);
  }, [onChangeTab]);

  useEffect(() => {
    if (isActive) {
      handleChangeTab();
    }
  }, [isActive]);

  return (
    <div
      className={classnames('tabs__nav__list__item', {
        'tabs__nav--active-tab': isActive,
      })}
      ref={tabRef}
    >
      <Button className="item__button" onClick={handleChangeTab}>
        <Text tag="span" size={size} bold>
          {label}
        </Text>
      </Button>
    </div>
  );
};

const TabsNav = ({ active, onChangeTab, type, tabs, lightTheme, className, size }) => {
  const [activeTabElement, setActiveTabElement] = useState(null);

  return (
    <nav className={classnames(`tabs__nav tabs__nav--${type}`, className)}>
      <div className={classnames('tabs__nav__list', { ['tabs__nav--light-theme']: lightTheme })}>
        {tabs.map((tab, index) => (
          <TabNavItem
            size={size}
            key={`${tab.key}-nav`}
            isActive={active === index}
            label={tab.label}
            onChangeTab={(tabElement) => {
              onChangeTab(index);
              setActiveTabElement(tabElement);
            }}
          />
        ))}
      </div>

      {type === 'material' ? <Underline activeTab={activeTabElement} lightTheme={lightTheme} /> : null}
    </nav>
  );
};

TabsNav.propTypes = propTypes;
TabsNav.defaultProps = defaultProps;

export default TabsNav;
