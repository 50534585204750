import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { calculateCapacityRemaining } from '@modules/Core';
import { ORDER_OPTION_DESC, useSort } from '@modules/FilterTypes/sort';

import { getSortMetricSelected } from '@modules/FilterTypes/sort';

import { getTheatreList, isLoadingTheatreList, isLazyLoadingTheatreList, getIsMaxPagesReached } from '../selectors';

export const useTheatreListData = () => {
  const [sortDirection] = useSort();
  const sortMetric = useSelector(getSortMetricSelected);
  const theatreDataList = useSelector(getTheatreList);
  const isLoading = useSelector(isLoadingTheatreList);

  // We need to resort the list if metric is capacity, so that it is sorted by capacity remaining
  const resortedList = useMemo(
    () =>
      sortMetric !== 'capacity'
        ? theatreDataList
        : [...theatreDataList]
            .sort((a, b) => {
              const capacityRemainingA = calculateCapacityRemaining(a.admissions, a.capacity);
              const capacityRemainingB = calculateCapacityRemaining(b.admissions, b.capacity);
              return sortDirection === ORDER_OPTION_DESC
                ? capacityRemainingB - capacityRemainingA
                : capacityRemainingA - capacityRemainingB;
            })
            // Remap rankings after sorting
            .map((theatre, index) => ({ ...theatre, rank: index + 1 })),
    [sortDirection, sortMetric, theatreDataList]
  );

  return [resortedList, isLoading, useSelector(isLazyLoadingTheatreList), useSelector(getIsMaxPagesReached)];
};
