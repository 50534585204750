import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Text } from '@components';
import { componentName } from '../constants';

const ShareDrawerToggle = ({ onClick }) => {
  return (
    <div className={`${componentName}__toggle`} onClick={onClick}>
      <Button icon="share" />
      <Text>
        <FormattedMessage id={'share'} />
      </Text>
    </div>
  );
};

export default ShareDrawerToggle;
