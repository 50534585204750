import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { useLocation } from 'react-router-dom';

import { PAGE_NAME_FILM_ANALYSIS } from './constants';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

function appContextReducer(state, action) {
  switch (action.type) {
    case 'SET_PATH': {
      return update(state, {
        path: { $set: action.path },
      });
    }
    case 'SET_PAGE_SEARCH': {
      // TODO could be refactored down the line to take into consideration different pages.
      return update(state, {
        pageSearch: { $set: action.pageSearch },
      });
    }
    case 'SET_PAGE_NAME': {
      return update(state, {
        pageName: { $set: action.pageName },
      });
    }
    case 'SET_REFRESH_METRICS': {
      return update(state, {
        refreshMetrics: { $set: action.refreshMetrics },
      });
    }
    case 'SET_OVERLAY_OPEN': {
      return update(state, {
        isOverlayOpen: { $set: action.isOverlayOpen },
      });
    }
    case 'SET_DRAWER_OPEN': {
      return update(state, {
        isDrawerOpen: { $set: action.isDrawerOpen },
      });
    }
    default: {
      return state;
    }
  }
}

/**
 * AppProvider
 * https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
const AppProvider = ({ children }) => {
  const location = useLocation();

  const initialAppContextState = useRef({
    path: 'films',
    pageName: PAGE_NAME_FILM_ANALYSIS,
    titleId: 'filmAnalysis',
    pageSearch: '',
    isOverlayOpen: false,
    isDrawerOpen: false,
  });
  const [state, dispatch] = React.useReducer(appContextReducer, initialAppContextState.current);
  const setPath = useCallback((path) => dispatch({ type: 'SET_PATH', path }), [dispatch]);

  useEffect(() => {
    const matches = location.pathname.split('/');
    const currentUrlPath = matches[1] || 'films';
    if (currentUrlPath !== state.path) {
      setPath(currentUrlPath);
    }
  }, [location, state.path, setPath]);

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node,
};

AppProvider.defaultProps = {
  children: null,
};

/**
 * useAppContextState hook provided to access current app state
 */
function useAppContextState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppContextState must be used within the AppProvider');
  }
  return context;
}

/**
 * useAppContextDispatch dispatch hook provided to alter app state externallly.
 */
function useAppContextDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppContextDispatch must be used within the AppProvider');
  }
  return context;
}

export { useAppContextState, useAppContextDispatch };
export default AppProvider;
