import React from 'react';
import { hasExhibitorTheatrePerspective } from './helpers';
import { Route, Switch } from 'react-router-dom';

import { URL_PATHS } from '@modules/Core';

import { TheatreMetadata } from './TheatreMetadata/';
import { TheatreAnalysis, TheatreAnalysisGrouping } from './TheatreAnalysis/';

function Routes() {
  return (
    hasExhibitorTheatrePerspective() && (
      <Switch>
        <Route path={`/${URL_PATHS.THEATRES}/site-attribute/:id`} component={TheatreAnalysisGrouping} />
        <Route path={`/${URL_PATHS.THEATRES}/:id/:screen`} component={TheatreMetadata} />
        <Route path={`/${URL_PATHS.THEATRES}`} component={TheatreAnalysis} />
      </Switch>
    )
  );
}

export default Routes;
