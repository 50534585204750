import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import _get from 'lodash/get';

import { Heading, Text } from '@components';
import { useCompColor, useSelectedMetric, getFullCompsList } from '@modules/Comps';
import { MetricNumber } from '@modules/Metrics';

import LegendMarker from './LegendMarker';
import { isLoadingFullscreenData } from '../selectors';
import * as classnames from 'classnames';

const dataMap = {
  admissions: {
    dayValue: 'dayAdmissions',
  },
  eventRevenue: {
    dayValue: 'dayEventRevenue',
  },
  eventRevenueBO: {
    dayValue: 'dayEventRevenueBO',
  },
};

const getKey = (metric, value) => dataMap[metric][value];

const DATE_STORAGE_FORMAT = 'YYYY-MM-DD';
const formatDate = (date) => moment(date, DATE_STORAGE_FORMAT).format('ddd, MMM D');

function ChartLegendItem({ id, name, dayDate, ...data }) {
  const color = useCompColor(id);
  const [selectedMetric] = useSelectedMetric();
  const isLoading = useSelector(isLoadingFullscreenData);

  return (
    <tr>
      <td>
        <LegendMarker color={color}></LegendMarker>
      </td>
      <td className="comps-chart__legend__table__td--left">
        <Text className="comps-chart__legend__film-name" size="s" bold>
          {name}
        </Text>
      </td>
      <td className="comps-chart__legend__table__td--center">
        {dayDate && <Text size="s">{formatDate(dayDate)}</Text>}
      </td>
      <td className="comps-chart__legend__table__td--right">
        <Text size="s" className={classnames({ ['skeleton']: isLoading })}>
          {!isLoading && (
            <MetricNumber metricKey={selectedMetric} number={_get(data, getKey(selectedMetric, 'dayValue'), 0)} />
          )}
        </Text>
      </td>
    </tr>
  );
}

function FullScreenChartLegend() {
  const data = useSelector(getFullCompsList);
  const dayValue = useMemo(() => {
    return _get(data, '[0].day', 0);
  }, [data]);

  return (
    <div className="comps-chart__legend">
      <Heading className="comps-chart__legend__heading" level="5">
        <FormattedMessage id="comps_day_n_totals" values={{ n: dayValue }} />
      </Heading>

      <table className="comps-chart__legend__table">
        <tbody>
          {data.map((item) => {
            return <ChartLegendItem key={item.id} {...item} />;
          })}
        </tbody>
      </table>
    </div>
  );
}

export default FullScreenChartLegend;
