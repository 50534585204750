import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SiteAttributionSelector, SortBySelector } from '@modules';
import { useMetric } from '@modules/FilterTypes/metric';
import { useSort } from '@modules/FilterTypes/sort';
import './_theatre-selector-buttons.scss';

const OCCUPANCY_FILTER_METRICS = ['occupancy'];

const TheatreSelectorButtons = ({ theme, metrics, hideAttributeSelector = false }) => {
  const [metric] = useMetric();
  const [, , setSortMetricSelected] = useSort();
  const isMetricOccupancy = useMemo(() => OCCUPANCY_FILTER_METRICS.includes(metric), [metric]);

  useEffect(() => {
    setSortMetricSelected(metric || metrics[0]);
  }, [metric]);

  return (
    <div className={'theatres__selectors-ctn'}>
      {!hideAttributeSelector && <SiteAttributionSelector theme={theme} />}
      <SortBySelector theme={theme} showOccupancySlider={isMetricOccupancy} />
    </div>
  );
};

TheatreSelectorButtons.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  metrics: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideAttributeSelector: PropTypes.bool,
};

TheatreSelectorButtons.defaultProps = {
  theme: 'light',
};

export default TheatreSelectorButtons;
