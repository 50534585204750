import { createContext, useContext, useEffect, useReducer, useMemo, useCallback, useRef } from 'react';
import update from 'immutability-helper';
import _isUndefined from 'lodash/isUndefined';

const ADD_TAB = 'ADD_TAB';
const DELETE_TAB = 'DELETE_TAB';
const SET_ACTIVE = 'SET_ACTIVE';
const SET_TRANSITION_TYPE = 'SET_TRANSITION_TYPE';

const tabsReducer = (state, action) => {
  switch (action.type) {
    case ADD_TAB:
      return update(state, {
        tabs: { $add: [[action.tabKey, action.tabProps]] },
      });
    case DELETE_TAB:
      return update(state, {
        tabs: { $remove: [action.tabKey] },
      });
    case SET_ACTIVE:
      return update(state, {
        active: { $set: action.active },
      });
    case SET_TRANSITION_TYPE: {
      if (_isUndefined(action.transitionType)) {
        // if transitionType is unset, do nothing.
        return state;
      }

      return update(state, {
        transitionType: { $set: action.transitionType },
      });
    }
    default:
      return state;
  }
};

const initTabs = ({ homeTab, tabTranistionType }) => ({
  tabs: new Map(),
  active: homeTab,
  transitionType: tabTranistionType || null,
});

/**
 * Tabs state management. To be used with TabsContext.
 */
export const useTabs = (props) => {
  // activeTab - for controlled components
  // homeTab - initialState for uncontrolled components
  const { activeTab, onTabChange, tabTranistionType } = props;
  const mounted = useRef(false);
  const [{ active, tabs, transitionType }, dispatch] = useReducer(tabsReducer, props, initTabs);

  useEffect(() => {
    if (mounted.current) {
      dispatch({ type: SET_ACTIVE, active: activeTab });
    }
  }, [activeTab]);
  useEffect(() => {
    if (mounted.current) {
      // Prop changes should force transitionType change.
      dispatch({ type: SET_TRANSITION_TYPE, transitionType: tabTranistionType || null });
    }
  }, [tabTranistionType]);
  useEffect(() => {
    mounted.current = true;
  }, []);

  const addTab = useCallback(
    (tabKey, tabProps) => {
      dispatch({ type: ADD_TAB, tabKey, tabProps });
    },
    [dispatch]
  );

  const deleteTab = useCallback(
    (tabKey, tabProps) => {
      dispatch({ type: DELETE_TAB, tabKey });
    },
    [dispatch]
  );

  const goToTab = useCallback(
    (newTab, transitionType) => {
      dispatch({ type: SET_ACTIVE, active: newTab });
      dispatch({ type: SET_TRANSITION_TYPE, transitionType });
      onTabChange(newTab);
    },
    [onTabChange]
  );

  return useMemo(
    () => ({
      active,
      tabs,
      addTab,
      deleteTab,
      goToTab,
      transitionType,
    }),
    [active, addTab, deleteTab, goToTab, tabs, transitionType]
  );
};

export const TabsContext = createContext();

export const useTabsContext = () => useContext(TabsContext);

export const useTab = (tabKey, tabProps) => {
  const { tabs, active, addTab, deleteTab } = useTabsContext();
  const isRegistered = tabKey && tabs.has(tabKey);
  const isActive = isRegistered && tabKey === active;

  useEffect(() => {
    if (tabKey) {
      addTab(tabKey, tabProps);
    }
  }, [addTab, tabKey, tabProps]);

  useEffect(() => {
    return () => deleteTab(tabKey);
  }, [deleteTab, tabKey]);

  return [isActive, isRegistered];
};
