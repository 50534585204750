import { useEffect } from 'react';
import { useAppContextDispatch } from '@modules';
import { createPageName } from '@modules/Core';

export const useSetPageName = (pageName, pageView) => {
  const dispatch = useAppContextDispatch();
  useEffect(() => {
    dispatch({ type: 'SET_PAGE_NAME', pageName: createPageName(pageName, pageView) });
  }, [dispatch, pageName, pageView]);
};
