import { useMemo, useState, useEffect } from 'react';
import _orderBy from 'lodash/orderBy';
import _isUndefined from 'lodash/isUndefined';
import _find from 'lodash/find';
import { useSiteAttributeGroup } from '@modules/FilterTypes/siteAttributeGroup';
import { useSiteAttributeGroups } from '@modules/SiteAttributeGroups';

export const useSelectedSiteAttributeGroup = () => {
  const [selectedId, setSelectedId] = useState();
  const [siteAttributeGroups] = useSiteAttributeGroups();
  const [selected, setSelected] = useSiteAttributeGroup();

  const selectedValue = useMemo(() => (!_isUndefined(selected) ? selected : selectedId), [selected, selectedId]);

  useEffect(() => {
    if (!_isUndefined(selectedId)) {
      setSelected(selectedId);
    }
  }, [setSelected, selectedId]);

  const groupsWithSites = useMemo(() => {
    return _orderBy([{ id: null, name: 'site' }, ...siteAttributeGroups], 'name');
  }, [siteAttributeGroups]);

  const selectedAttribute = useMemo(
    () =>
      _find(groupsWithSites, { id: selectedValue && parseInt(selectedValue) }) || {
        id: selectedValue && parseInt(selectedValue),
        name: '',
      },
    [selectedValue, groupsWithSites]
  );

  return [groupsWithSites, selectedAttribute || {}, setSelectedId];
};
