const SET_FILTER = 'SET_FILTER';
const SET_SELECTED = 'SET_SELECTED';
const UPDATE_FILTER = 'UPDATE_FILTER';

export const createFilterActionType = (key, action) => {
  return `filters/${key}/${action}`;
};

export const createSetSelectedActionType = (key) => {
  return createFilterActionType(key, SET_SELECTED);
};

export const createSetFilterActionType = (key) => {
  return createFilterActionType(key, SET_FILTER);
};

export const createUpdateFilterActionType = (key) => {
  return createFilterActionType(key, UPDATE_FILTER);
};
