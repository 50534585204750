import { createSelector } from '@reduxjs/toolkit';

import _property from 'lodash/property';
import _isEqual from 'lodash/isEqual';

import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { getParametersFactory } from '@modules/FilterTypes';
import { STATE_KEY } from './constants';

export const getRefreshState = (state) => state[STATE_KEY];

export const getLastRefreshed = createSelector([getRefreshState], _property('lastRefreshed'));

export const getMetrics = createSelector([getRefreshState], _property('metrics'));
export const getPaused = createSelector([getRefreshState], _property('paused'));

export const getOldValues = createSelector([getRefreshState], _property('oldValues'));
export const getNewValues = createSelector([getRefreshState], _property('newValues'));

export const hasDataUpdatedSinceLastRefresh = createSelector([getOldValues, getNewValues], (oldValues, newValues) => {
  return !_isEqual(oldValues, newValues);
});

export const getRefreshParams = getParametersFactory([dates, currency, territory, sites]);
