import { createFilterReducer, createFilterActionType, attributeSetter } from '@modules/Filters';

import { KEY, SET_IS_EXCLUDED, SET_IS_DISABLED } from './constants';

const initialState = {
  selected: null,
  isExcluded: false,
  isDisabled: false,
};

const sitesReducer = createFilterReducer(KEY, initialState, {
  [createFilterActionType(KEY, SET_IS_EXCLUDED)]: attributeSetter('isExcluded'),
  [createFilterActionType(KEY, SET_IS_DISABLED)]: attributeSetter('isDisabled'),
});

export default {
  [KEY]: sitesReducer,
};
