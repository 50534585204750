import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BASE_CLASS } from '../constants';

const Indicator = ({ isActive }) => {
  return (
    <div className="indicator">
      <div
        className={classnames('indicator__item', {
          'indicator__item--is-active': isActive,
        })}
      />
    </div>
  );
};

/**
 * ScrollIndicator
 * @param {bool} isVisible determines whether to render this element or not.
 * ScrollIndicator should only be shown if the metricList width is larger
 * than that of the metricList component width.
 * @param {number} activeIndicator number determining which indicator should be active.
 * Only two options ever available, 0 = start of scrollCtn. 1 = end of scrollContainer
 */
const ScrollIndicator = ({ isVisible, activeIndicator }) => {
  return isVisible ? (
    <div className={`${BASE_CLASS}__scroll-indicator`}>
      <Indicator isActive={activeIndicator === 0} />
      <Indicator isActive={activeIndicator === 1} />
    </div>
  ) : null;
};

ScrollIndicator.propTypes = {
  isVisible: PropTypes.bool,
  activeIndicator: PropTypes.oneOf([0, 1]),
};

ScrollIndicator.defaultProps = {
  isVisible: false,
  activeIndicator: 0,
};

export default ScrollIndicator;
