const de = {
  "filmAnalysis": "Film Analyse",
  "apply": "Anwenden",
  "cancel": "Abbrechen",
  "capacity": "Kapazität",
  "clear": "Löschen",
  "copy_link": "Link kopieren",
  "custom": "Benutzerdefiniert",
  "download": "Download",
  "films": "Filme",
  "format": "Format",
  "link_copied": "Link in die Zwischenablage kopiert",
  "occupancy": "Belegung",
  "screen": "Leinwand",
  "select_all": "Alle auswählen",
  "site": "Kino",
  "theatres": "Kinos",
  "time": "Zeit",
  "title": "Title",
  "today": "Heute",
  "tomorrow": "Morgen",
  "yesterday": "Gesten",
  "next": "Weiter",
  "previous": "Zurück",
  "tmdb_credits": "Dieses Produkt verwendet die TMDb-API, ist jedoch nicht von TMDb empfohlen oder zertifiziert. ",
  "logout": "Ausloggen",
  "territories": "Regionen",
  "format_currency": "%v%s",
  "format_decimal": ",",
  "format_thousand": ".",
  "format_abbr_thousand": "tsd,",
  "format_abbr_million": "mio.",
  "format_abbr_billion": "bio.",
  "format_abbr_trillion": "trio.",
  "theatreAnalysis": "Kino Analyse",
  "sortBySelector_button": "Sortieren nach",
  "sortBySelector_heading": "Sortieren nach",
  "sortBySelector_metric_high_low": "Höchste bis niedrigste",
  "sortBySelector_metric_low_high": "Niedrigste bis höchste",
  "siteAttrSelector_heading": "Kinomerkmal",
  "shareDrawer_heading": "Diese Ansicht teilen",
  "compsSelectionDrawer_heading": "Vergleichstitel hinzufügen",
  "all_titles": "Alle Titel",
  "selected_titles": "Ausgewählte Titel",
  "suggested_titles": "Vorgeschlagene Titel",
  "search_films": "Filme suchen",
  "search_theatres": "Kino suchen",
  "metric.admissions": "Besucher",
  "metric.eventRevenue": "Umsatz",
  "metric.eventRevenueBO": "Umsatz",
  "metric.distributorReportedPrice": "Umsatz",
  "metric.occupancy": "Belegung",
  "metric.eventSph": "Dur. Eintrittspreis",
  "metric.capacity": "Verbleibende Kapazität",
  "metric.yearMarketShare": "52 Wochen-Marktanteil",
  "sites_filter.search": "Kinos suchen",
  "sites_filter.page1.title": "Nach Kinos filtern",
  "sites_filter.page1.go_to.page2": "Gruppierung Kinomerkmale",
  "sites_filter.page2.title": "Kinogruppierung auswählen",
  "sites_filter.page3.title": "Kino auswählen - {attribute}",
  "sites_filter.page3.title.min": "Kino auswählen",
  "sites_filter.alert": "Durch das Filtern nach Kinos oder Attributen werden nicht freigegebene Kinos aus den angezeigten Summen entfernt. ",
  "sites_filter.search_placeholder": "Verwenden Sie diese Suche, um ein Kino zu finden",
  "capacityFormat": "{capacityRemaining}({capacity})",
  "dow_monday": "Montag",
  "dow_monday_short": "Mo",
  "dow_tuesday": "Dienstag",
  "dow_tuesday_short": "Di",
  "dow_wednesday": "Mittwoch",
  "dow_wednesday_short": "Mi",
  "dow_thursday": "Donnerstag",
  "dow_thursday_short": "Do",
  "dow_friday": "Freitag",
  "dow_friday_short": "Fr",
  "dow_saturday": "Samstag",
  "dow_saturday_short": "Sa",
  "dow_sunday": "Sonntag",
  "dow_sunday_short": "So",
  "dow_weekdays": "Wochentage",
  "dow_weekends": "Wochenende",
  "month_january": "Januar",
  "month_january_short": "Jan",
  "month_february": "Februar",
  "month_february_short": "Feb",
  "month_march": "März",
  "month_march_short": "Mär",
  "month_april": "April",
  "month_april_short": "Apr",
  "month_may": "Mai",
  "month_may_short": "Mai",
  "month_june": "Juni",
  "month_june_short": "Jun",
  "month_july": "Juli",
  "month_july_short": "Jul",
  "month_august": "August",
  "month_august_short": "Aug",
  "month_september": "September",
  "month_september_short": "Sep",
  "month_october": "Oktober",
  "month_october_short": "Okt",
  "month_november": "November",
  "month_november_short": "Nov",
  "month_december": "Dezember",
  "month_december_short": "Dez",
  "future": "in %s",
  "past": "vor %s",
  "s": "vor wenigen Sekunden",
  "ss": "%d Sekunden",
  "m": "eine Minute",
  "mm": "%d Minuten",
  "h": "eine Stunde",
  "hh": "%d Stunden",
  "d": "ein Tag",
  "dd": "%d Tage",
  "w": "eine Woche",
  "ww": "%d Wochen",
  "M": "ein Monat",
  "MM": "%d Monate",
  "y": "ein Jahr",
  "yy": "%d Jahre",
  "vs_x": "vs {x}",
  "vs_x_until_y": "vs {x} (Until {y})",
  "titlesList_run_week": "Woche {num}",
  "no_results_found": "Keine Ergebnisse gefunden",
  "no_data_available": "Keine Daten verfügbar",
  "performance_data_not_available": "Performancedaten nicht verfügbar",
  "email": "E-Mail",
  "password": "Passwort",
  "login": "Login",
  "success": "Klasse!",
  "incorrect_password": "Falsches Passwort",
  "required_field": "Pflichtfeld",
  "email_required": "E-Mail ist notwendig",
  "login_error": "Die von Ihnen eingegebene E-Mail-Adresse oder das eingegebene Passwort ist falsch. Bitte versuche es erneut. ",
  "login_unexpected_error": "Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es erneut ",
  "forgot_password": "Passwort vergessen?",
  "reset_success": "Ihr Passwort wurde aktualisiert. Sie können Ihr neues Passwort nun verwenden, um sich anzumelden. ",
  "reset_your_password": "Ihr Passwort zurücksezten",
  "new_password": "Neues Passwort",
  "confirm_new_password": "Neues Passwort bestätigen",
  "confirm_password_subtext": "Ihr Passwort muss aus 8 oder mehr Zeichen bestehen, mindestens 1 Buchstaben, 1 Zahl und 1 Symbol ",
  "reset_password": "Passwort zurücksetzen",
  "error_match_passwords": "Die eingegebenen Passwörter stimmen nicht überein ",
  "error_incorrect_password": "Das eingegebene Passwort ist falsch. Bitte versuche es erneut. ",
  "forgot_password_title": "Passwort vergessen?",
  "forgot_password_subtext": "Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen Anweisungen zum Zurücksetzen des Passworts.",
  "send_email": "E-Mail senden",
  "back_sign_in": "Zurück zur Anmeldung ",
  "restart_invalid_token": "Ihr Link zum Zurücksetzen Ihres Passworts ist abgelaufen. Bitte versuche es erneut.",
  "forgot_password_success_p1": "Wir haben eine E-Mail mit Anweisungen zum Zurücksetzen des Passworts an {userEmail} gesendet. ",
  "forgot_password_success_p2": "Hinweis: Sollten Sie keine E-Mail erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner.",
  "forgot_password_success_p3": "Wenn es sich nicht in Ihrem Spam-Ordner befindet, wenden Sie sich an Ihren Systemadministrator, der Sie beim Zurücksetzen Ihres Kennworts unterstützen kann. ",
  "forgot_password_email_error": "Benutzer mit dieser E-Mail konnte nicht gefunden werden ",
  "server_error": "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch einmal. ",
  "same_day": "Gleicher Tag",
  "day_n": "Tag {n}",
  "comps_your_data": "Ihre Daten",
  "comps_market_data": "Marktdaten",
  "comps_real_time_data": "Echtzeitdaten",
  "comps_day_n_totals": "Tag {n} - Total",
  "comps_opening_day": "Starttag",
  "comps_opening_weekend": "Startwochenende",
  "comps_opening_total_run": "Gesamt",
  "comps_search_placeholder": "Verwenden Sie diese Suche, um einen bestimmten Titel zum Vergleichen zu finden ",
  "comps_no_selected_titles": "Keine Titel ausgewählt ",
  "comps_no_suggested_titles": "Keine vorgeschlagenen Titel ",
  "comps_total_market_data": "Gesamtmarkt",
  "comps_circuit_share": "({n} Betreibergruppenanteil)",
  "no_access_title": "Seite kann nicht angezeigt werden ",
  "no_access_subtext": "Diese Anwendung ist nur auf mobilen Endgeräten verfügbar ",
  "theatre_metadata_view_films": "Filme",
  "theatre_metadata_view_screen": "Leinwandplaner",
  "film_metadata_view_comps": "Vergleiche",
  "film_metadata_view_theatres": "Kinos",
  "film_metadata_view_circuits": "Betreibergruppe",
  "report_indicator_last_updated": "Zuletzt aktualisiert vor {time} ",
  "report_indicator_sites_reported": "{num} Kino(s) gemeldet",
  "report_indicator_sites_reported_yesterday": "{num} Kino(s) haben gestern gemeldet",
  "report_indicator_sites_reporting": "{num} Kinos(s) melden",
  "report_indicator_sites_reporting_real_time": "{num} Kino(s) melden in Echtzeit",
  "report_indicator_search_placeholder": "Nach Betreibergruppe suchen",
  "report_indicator_market_report_title": "Marktbericht - {today}",
  "report_indicator_th_data_feed": "Datenfeed",
  "report_indicator_th_circuit": "Betreibergruppe",
  "report_indicator_th_sites_reported": "Kinos haben gemeldet",
  "report_indicator_th_sites_reported_yesterday": "Kinos haben gestern gemeldet",
  "report_indicator_th_sites_reporting": "Kinos melden",
  "performanceModal.admissions": "Besucher",
  "performanceModal.eventRevenue": "Umsatz",
  "performanceModal.occupancy": "Belegung",
  "performanceModal.eventSph": "Durchschnittlicher Eintrittspreis",
  "performanceModal.capacity": "Verbleibende Kapazität",
  "runtime": "{num}min",
  "market_data_not_available": "Marktdaten sind noch nicht verfügbar. Bitte versuchen Sie es später erneut ",
  "presales_data_integration": "Für die Vorverkaufs-Datenintegration wenden Sie sich bitte an Ihren Comscore-Vertreter ",
  "circuits_view_not_available_real_time": "Die Ansicht für Betreibergruppen ist nicht für Echtzeitdaten verfügbar",
  "delete": "Löschen",
  "share": "Teilen",
  "save": "Speichern",
  "settings": "Einstellungen",
  "search": "Suche",
  "support": "Hilfe und Support",
  "user_settings.settings": "Einstellungen",
  "user_settings.password": "Passwort",
  "user_settings.language": "Sprache",
  "user_settings.currency": "Währung",
  "user_settings.time-format": "Zeitformat",
  "user_settings.24-Hour-time": "24-Stunden",
  "user_settings.default-territory": "Standardregion",
  "user_settings.error_update": "Beim Aktualisieren der Einstellungen ist ein Fehler aufgetreten. Aktualisieren Sie die Seite und versuchen Sie es erneut. ",
  "user_settings.current": "Aktuell",
  "user_settings.all_territories": "Alle Regionen",
  "user_settings.search_territories": "Regionen suchen",
  "user_settings.title-preference": "Titelpräferenz ",
  "user_settings.selected_territory": "Ausgewählte Gebiete",
  "user_settings.favourite_territories": "Favorisierte Gebiete",
  "user_settings.territories": "Gebiete",
  "invalid_email": "Ungültige E-Mail",
  "change_password": "Passwort ändern",
  "current_password": "Aktuelles Passwort",
  "error_incorrect_password_type": "Das eingegebene Passwort enthält nicht 8 oder mehr Zeichen, mindestens 1 Buchstaben, 1 Zahl und 1 Symbol ",
  "password_updated": "Passwort aktualisiert ",
  "comps_no_market_data": "(Keine Marktdaten)",
  "comps_market_data_not_tracked": "Marktdaten nicht erfasst",
  "bookmarks": "Lesezeichen",
  "bookmarks.header.save": "Ihr Lesezeichen speichern",
  "bookmarks.create": "Neues Lesezeichen erstellen",
  "bookmarks.form.name": "Name Lesezeichen",
  "bookmarks.form.privacy": "Privatsphäre",
  "bookmarks.form.private": "Nur für Sie verfügbar",
  "bookmarks.form.shared": "Mit Ihrer Organisation teilen",
  "bookmarks.form.create": "Lesezeichen erstellen",
  "bookmarks.shared": "Lesezeichen teilen",
  "bookmarks.privacy": "Privates Lesezeichen",
  "bookmarks.createdBy": "Erstellt von",
  "bookmarks.confirm.title": "Lesezeichen löschen",
  "bookmarks.confirm.sub-title": "Das kann nicht rückgängig gemacht werden",
  "bookmark.error.name_required": "Bitte legen Sie einen Namen für Ihr Lesezeichen fest",
  "bookmark.error.name_duplication": "Ein Lesezeichen mit diesem Namen existiert bereits",
  "bookmark.error.name_too_long": "Lesezeichen ist zu lang (Maximum {maxChars} characters)",
  "bookmark.error.unexpected": "Es ist etwas Unerwartetes passiert. Bitte versuchen Sie es erneut",
  "en": "Englisch (English)",
  "es": "Spanisch (Español)",
  "app_error_heading": "Hoppla! Etwas ist schief gelaufen. \n",
  "app_error_subtext": "Bitte aktualisieren Sie die Seite und versuchen Sie es erneut. ",
  "title_preference.us_title": "US Titel",
  "title_preference.local_title": "Lokaler Titel",
  "user_currency_preference.local": "Lokale Währung",
  "user_currency_preference.default": "US Dollar ($)",
  "marketAnalysis": "Marktanalyse",
  "pt": "Portugiesisch (Português)",
  "advancedSales": "Erweiterte Verkaufsdaten",
  "market_share": "Marktanteile",
  "advanced_sales_data_not_available": "Erweiterte Verkaufsdaten sind noch nicht verfügbar. Bitte versuchen Sie es später erneut"
};

export default de;
