import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

/**
 * useSelectableItemAnimation
 * Scales up selected Metric Item
 * Scales Down unselected mertics items.
 */
const METRIC_SELECT_EASE_IN = 'Power1.easeInOut';
const METRIC_SELECT_EASE_OUT = 'Power1.easeInOut';
const useSelectableItemAnimation = () => {
  let menuTimeline = useRef(null);
  let metricItemRef = useRef(null);

  useEffect(() => {
    menuTimeline.current = gsap.timeline({ paused: true });
    menuTimeline.current.fromTo(
      [metricItemRef.current],
      {
        duration: 0.3,
        scale: 0.7,
        opacity: 0.4,
        ease: METRIC_SELECT_EASE_OUT,
      },
      {
        duration: 0.3,
        scale: 1,
        opacity: 1,
        ease: METRIC_SELECT_EASE_IN,
      }
    );
  }, [menuTimeline]);

  const metricItemRefBinder = metricItemRef;

  return [metricItemRefBinder, menuTimeline];
};

export default useSelectableItemAnimation;
