import { asyncAction } from '@showtime/cea-dep-utility';

import { API_ROOT_PATH } from '@modules/Core';
import { SHARE_LINK_FETCH_KEY } from './constants';

export const fetchShareLink = (share) => (dispatch) => {
  const link = `${window.location.href}?share=${share}`;

  const response = dispatch(
    asyncAction({
      key: SHARE_LINK_FETCH_KEY,
      url: `${API_ROOT_PATH}/shorten-url?url=${link}`,
      method: 'POST',
      success(response) {
        return response.shortenedUrl;
      },
    })
  );

  // On fail respond with long link
  return response.catch(() => link);
};
