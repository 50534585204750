import React from 'react';
import PropTypes from 'prop-types';

import { metricFormatter, metricFormatterShort } from '@modules/Formatters';

import { metricKeyPropType } from '../constants';

const propTypes = {
  metricKey: metricKeyPropType.isRequired,
  number: PropTypes.number,
  isLongFormat: PropTypes.bool,
};

const defaultProps = {
  isLongFormat: false,
};

function MetricNumber({ metricKey, number, isLongFormat }) {
  if (isLongFormat) {
    return metricFormatter(number, metricKey);
  } else {
    return metricFormatterShort(number, metricKey);
  }
}

MetricNumber.propTypes = propTypes;
MetricNumber.defaultProps = defaultProps;

export default MetricNumber;
