import useIsMarketAnalysis from './useIsMarketAnalysis';
import useIsAdvancedSales from './useIsAdvancedSales';

const DEFAULT_DATA_PATH = '/data';

export default function useAPIPath(ignoreAdvancedSalesSubSection = true, defaultDataPath = DEFAULT_DATA_PATH) {
  const TXNDATA_PATH = '/txndata';
  const MARKET_PATH = '/market';
  const isAdvancedSales = useIsAdvancedSales();
  const isMarketAnalysis = useIsMarketAnalysis();

  const subSectionPath = isMarketAnalysis
    ? MARKET_PATH 
    : ignoreAdvancedSalesSubSection || !isAdvancedSales
    ? ''
    : TXNDATA_PATH;
  const dataPath = isAdvancedSales ? TXNDATA_PATH : defaultDataPath;

  return [subSectionPath, dataPath];
}
