import React, { useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { metricKeyPropType } from '@modules/Metrics';
import { useIsMounted, useResizeHeightChanged, APP_HEADER_HEIGHT } from '@modules/Core';

import { BASE_CLASS, SCROLL_TRIGGER_ID } from '../constants';
import List from './List';
import { useMetricListHeroAnimation } from '../hooks';

/**
 * ListWithHero
 * @param {string} metricListContainerClassName - optional className to style the container added to metricLists with heros.
 * @param {element||func} monitorHeightRef - If specified, MetricList will monitor the height of this element.
 * If height changes MetricList will re-calculate it's scrollTrigger top position to get the correct animStartPos for the listHeroanimation.
 */
const ListWithHero = ({ metricListContainerClassName, metricListClassName, metrics, monitorHeightRef, ...props }) => {
  const isMounted = useIsMounted();

  const isOnlyHero = useMemo(() => metrics.length === 1, [metrics.length]);
  const hasNoHero = useMemo(() => metrics.length > 1 && metrics.length <= 3, [metrics.length]);
  const hasHero = useMemo(() => metrics.length > 3, [metrics.length]);

  let listHeroTopPosition = useRef(null);
  let listWithHeroRef = useRef(null);

  const onContainerHeightChange = useCallback((height) => {
    listHeroTopPosition.current = height + APP_HEADER_HEIGHT;
  }, []);

  useResizeHeightChanged(monitorHeightRef, onContainerHeightChange);

  const [heroContainerRefBinder] = useMetricListHeroAnimation(hasHero, isMounted, listHeroTopPosition.current);

  useEffect(() => {
    return () => {
      // When ListWithHero is being unmounted ensure that current SCROLL_TRIGGER_ID ScrollTrigger instance is killed completely.
      const currentScrollTrigger = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (currentScrollTrigger) {
        currentScrollTrigger.kill(true);
      }
    };
  }, []);

  return (
    <div ref={listWithHeroRef}>
      <div className={`${BASE_CLASS}-hero-container ${metricListContainerClassName}`} ref={heroContainerRefBinder}>
        <div className={`${BASE_CLASS} ${metricListClassName}`}>
          {(isOnlyHero || hasHero) && <List metrics={[metrics[0]]} {...props} layout="inline" />}
          {(hasNoHero || hasHero) && <List metrics={metrics} {...props} />}
        </div>
      </div>
    </div>
  );
};

ListWithHero.propTypes = {
  metrics: PropTypes.arrayOf(metricKeyPropType).isRequired,
  metricListContainerClassName: PropTypes.string,
  metricListClassName: PropTypes.string,
  monitorHeightRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
};

export default ListWithHero;
