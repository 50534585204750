import React from 'react';

import ViewTransition from './ViewTransition';

import { Text } from '@components';

export default function ViewNotAvailableState({ message }) {
  return (
    <ViewTransition className="film-metadata__view film-metadata__view--not-available">
      <div className="not-available-state">
        <Text className="not-available-state__text">{message}</Text>
      </div>
    </ViewTransition>
  );
}
