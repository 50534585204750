import React from 'react';

import { Button, useOverlay, Overlay } from '@components';

import { BASE_CLASS } from '../constants';
import OverlayContent from './OverlayContent';
import { DisplayReportedSitesInButton } from './DisplayReportedSites';

const ReportingOverlayComponent = () => {
  const reportingOverlay = useOverlay();
  return (
    <>
      <Button className={`${BASE_CLASS}__overlay-toggle`} onClick={reportingOverlay.open}>
        <DisplayReportedSitesInButton />
      </Button>
      {reportingOverlay.render(<Overlay.Content component={OverlayContent} />)}
    </>
  );
};

const ReportingOverlay = () => (
  <Overlay.Provider component={ReportingOverlayComponent} className={`${BASE_CLASS}__overlay`} />
);

export default ReportingOverlay;
