import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import _isFunction from 'lodash/isFunction';

import { SortToggle } from '@components';
import { BASE_CLASS, COLUMNS_SHAPE } from './constants';

const Head = ({ columns, onSortChange, sortBy, initialSort }) => {
  const [sortDir, setSortDir] = useState(initialSort ? initialSort.dir : 'desc');

  const handleSortChange = useCallback(
    (sortByKey) => {
      let dir = sortDir === 'desc' ? 'asc' : 'desc';
      if (sortByKey !== sortBy.id) {
        dir = initialSort.dir;
      }
      onSortChange({ id: sortByKey, dir });
      setSortDir(dir);
    },
    [onSortChange, sortDir, sortBy, initialSort]
  );

  return (
    <thead>
      <tr>
        {columns.map((column) => {
          return (
            <th
              onClick={column.sort ? () => handleSortChange(column.key) : _noop}
              key={column.key}
              className={classnames(`${BASE_CLASS}__th`, {
                [`th--${column.key}`]: column.key,
                [`${BASE_CLASS}__th--sortable`]: column.sort,
                [`${column.className}`]: column.className,
              })}
              style={{ width: column.width }}
            >
              {column.sort ? (
                <SortToggle
                  onChange={_noop}
                  value={column.key === sortBy.id ? sortDir : false}
                  label={_isFunction(column.renderHeaderCell) ? column.renderHeaderCell(column) : column.label}
                />
              ) : (
                <>{_isFunction(column.renderHeaderCell) ? column.renderHeaderCell(column) : column.label}</>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

Head.propTypes = {
  columns: COLUMNS_SHAPE,
  onSortChange: PropTypes.func,
  sortBy: PropTypes.shape({
    /**
     * key in data to sort by
     */
    id: PropTypes.string,
    dir: PropTypes.oneOf(['asc', 'desc']),
  }),
  initialSort: PropTypes.shape({
    /**
     * key in data to sort by
     */
    id: PropTypes.string,
    dir: PropTypes.oneOf(['asc', 'desc']),
  }),
};

export default Head;
