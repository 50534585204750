import moment from 'moment';

import { SELECTED_DATE_FORMAT, DEFAULT_CINEMA_WEEK_START_DAY } from './constants';
import { getDOWPosition } from '@modules/Core/helpers';

/**
 * formatDate
 * @param {momentDate} value
 */
export function formatDate(value) {
  return value.format(SELECTED_DATE_FORMAT);
}

const DEFAULT_MIN_DAYS_BEFORE = 7;
const DEFAULT_DAYS_AFTER = 28;
const standardWeek = [1, 2, 3, 4, 5, 6, 7];

/**
 * calculateDaysBefore
 * If dayInWeek is Day 1 of Cinema week, user should only be able to scroll through previous 7 days.
 * If dayInWeek is Day 7 of Cinema week, user should be able to scroll from Day 7, to the previous 6 days of the current Cinema week
 * @returns
 * If dayInWeek = 7 returns 13
 * If dayInWeek = 6 returns 12
 * If dayInWeek = 5 returns 11
 * If dayInWeek = 4 returns 10
 * If dayInWeek = 3 returns 9
 * If dayInWeek = 2 returns 8
 * If dayInWeek = 1 returns 7
 */
const calculateDaysBefore = (dayInWeek, minDaysBefore) => {
  const minimumNumDaysBefore = minDaysBefore || DEFAULT_MIN_DAYS_BEFORE;
  return dayInWeek - 1 + minimumNumDaysBefore;
};

/**
 * Returns array of weekday positions starting with this circuits startDay as the first day.
 * e.g [5, 6, 7, 1, 2, 3, 4];
 */
const getCinemaWeekDayPositions = (cinemaWeekStartDay) => {
  const a = standardWeek.slice(0, cinemaWeekStartDay - 1);
  const b = standardWeek.slice(cinemaWeekStartDay - 1);
  return b.concat(a);
};

/**
 * getMinDaysBefore
 */
const getMinDaysBefore = (minDaysBefore, cinemaWeekStartDay) => {
  const todayDOWPosition = getDOWPosition(formatDate(moment()));
  const cinemaWeekDayPositions = getCinemaWeekDayPositions(cinemaWeekStartDay);
  const dayInThisCinemaWeek = cinemaWeekDayPositions.indexOf(todayDOWPosition) + 1;

  return calculateDaysBefore(dayInThisCinemaWeek, minDaysBefore);
};

/**
 * getMinMaxNumDays
 * @returns {object}
 *  minimumNumDaysBefore - number of days to show in date-selector before currentActiveValue on init.
 *  maximumNumDaysAfter - number of days to show in date-selector after currentActiveValue on init.
 */
export function getMinMaxNumDays(cinemaWeekStartDay = DEFAULT_CINEMA_WEEK_START_DAY, minDaysBefore, daysAfter) {
  return {
    minimumNumDaysBefore: getMinDaysBefore(minDaysBefore, cinemaWeekStartDay),
    maximumNumDaysAfter: daysAfter !== null ? daysAfter : DEFAULT_DAYS_AFTER + (7 - cinemaWeekStartDay),
  };
}
