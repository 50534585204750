import { createSelector } from '@reduxjs/toolkit';
import { renderToString } from 'react-dom/server';
import React from 'react';

import _property from 'lodash/property';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _set from 'lodash/set';
import moment from 'moment';

import { isLoading, error } from '@showtime/cea-dep-utility';
import { KEY as dates, getSelected } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { KEY as compsTitles } from '@modules/FilterTypes/compsTitles';
import { getParametersFactory } from '@modules/FilterTypes';
import { metricFormatter } from '@modules/Formatters';
import { getUserTimeFormat } from '@modules/Auth';
import { isToday, getShare } from '@modules/Core';

import { DATE_FORMAT, TIME_FORMAT, STATE_KEY, EMBEDDED_FETCH_KEY, FULL_SCREEN_FETCH_KEY } from './constants';
import {
  getDateTimeValue,
  getDataField,
  getFormattedField,
  getMovementField,
  addCinemaStartHour,
  isTimeWithinRange,
} from './helpers';
import PercentageMovement from '@components/PercentageMovement';

export const getChartParams = getParametersFactory([compsTitles, currency, dates, territory, sites]);

export const getCompsChartData = _property(STATE_KEY);

export const getDateAxisRange = createSelector([getCompsChartData], _property('dateAxisRange'));
export const getDateAxisMin = createSelector([getDateAxisRange], _property('minimum'));
export const getDateAxisMax = createSelector([getDateAxisRange], _property('maximum'));

const AVAILABLE_METRICS = ['admissions', 'eventRevenue', 'eventRevenueBO'];

const dataProcesser = (data, axisRange, dates, timeFormat, showLabelCurrentDateTime = false, isAdvancedSales, formatMessage) => {
  let processedData = [];
  // Storing the basevalues for each metrics for comparison values
  let baseVals = {};

  data.forEach(({ id, values }, index) => {
    const isBaseValue = index === 0;
    let sumFields = {};

    AVAILABLE_METRICS.forEach((metric) => {
      sumFields[metric] = 0;
    });

    Object.keys(values).forEach((offsetTime) => {
      const momentDate = moment(offsetTime);
      const dateValue = getDateTimeValue(offsetTime, dates[0]);
      let formattedTime = moment(dateValue).format(timeFormat);
      let dataItemId = isAdvancedSales ? offsetTime : addCinemaStartHour(offsetTime);
      const runDay = isAdvancedSales ? formatMessage({ id: 'runday'}, {num: dataItemId }) : null;

      if (showLabelCurrentDateTime && isToday) {
        const diff = moment().diff(dateValue, 'minutes');
        if (diff < 0 && diff > -14) {
          formattedTime = moment().format(timeFormat);
        }
      }

      const value = values[offsetTime];
      const seriesDataField = id;

      AVAILABLE_METRICS.forEach((metric) => {
        const dataField = getDataField(seriesDataField, metric);
        const dataFieldFormatted = getFormattedField(dataField);
        const dataFieldMovement = getMovementField(dataField);
        const extractedValue = _get(value, metric, 0);
        const existingDataIndex = _findIndex(processedData, { id: dataItemId });
        const baseValPath = `${offsetTime}.${metric}`;
        let movement = null;

        sumFields[metric] += extractedValue;

        // If its the base value, store it, otherwise we calculate the percentage movement for that time
        if (isBaseValue) {
          _set(baseVals, baseValPath, sumFields[metric]);
        } else {
          const primary = _get(baseVals, baseValPath, 0);
          const comp = sumFields[metric];

          movement = getShare(primary, comp) * 100 - 100;
        }

        const renderedMovement = renderToString(<PercentageMovement size="xxs" value={movement} />);

        if (isAdvancedSales || isTimeWithinRange(dateValue, axisRange.minimum, axisRange.maximum)) {
          const dataValues = {
            [dataField]: sumFields[metric],
            [dataFieldFormatted]: metricFormatter(sumFields[metric], metric),
            [dataFieldMovement]: renderedMovement,
          };

          if (existingDataIndex === -1) {
            processedData.push({
              id: dataItemId,
              runDay,
              time: dateValue,
              formattedTime,
              ...dataValues,
            });
          } else {
            processedData[existingDataIndex] = {
              ...processedData[existingDataIndex],
              ...dataValues,
            };
          }
          const index = existingDataIndex > -1 ? existingDataIndex : 0;
          processedData[index].category = dataItemId;
        }
      });
    });
  });

  return processedData;
};

export const getEmbeddedChartData = (isAdvancedSales, formatMessage) =>
  createSelector(
    [getCompsChartData, getDateAxisRange, getSelected, getUserTimeFormat],
    ({ embeddedChartData }, axisRange, dates, timeFormat) => {
      return dataProcesser(embeddedChartData, axisRange, dates, timeFormat, false, isAdvancedSales, formatMessage);
    }
  );

export const getFullscreenChartData = (isAdvancedSales, formatMessage) =>
  createSelector(
    [getCompsChartData, getDateAxisRange, getSelected, getUserTimeFormat],
    ({ fullscreenChartData }, axisRange, dates, timeFormat) => {
      return dataProcesser(fullscreenChartData, axisRange, dates, timeFormat, true, isAdvancedSales, formatMessage);
    }
  );

export const isLoadingEmbeddedData = (state) => isLoading(state, EMBEDDED_FETCH_KEY);
export const hasEmbeddedDataErrored = (state) => error(state, EMBEDDED_FETCH_KEY);

export const isLoadingFullscreenData = (state) => isLoading(state, FULL_SCREEN_FETCH_KEY);
export const hasFullscreenDataErrored = (state) => error(state, FULL_SCREEN_FETCH_KEY);
