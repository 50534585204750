import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import _isNil from 'lodash/isNil';

import { RankLabel, Heading, Text } from '@components';
import { metricKeyPropType } from '@modules/Metrics';
import { calculateCapacityRemaining } from '@modules/Core';

import ItemMetric from './ItemMetric';
import ListItemBackground from './ListItemBackground';

const propTypes = {
  componentClassName: PropTypes.string,

  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.node.isRequired,
  rank: PropTypes.number.isRequired,
  imageUrl: PropTypes.string,
  /**
   * runWeek
   * 0 = no data available.
   */
  runWeek: PropTypes.number,
  onClick: PropTypes.func,
  metrics: PropTypes.arrayOf(metricKeyPropType),
  highlightedMetrics: PropTypes.arrayOf(metricKeyPropType),

  eventRevenue: PropTypes.number,
  eventRevenueBO: PropTypes.number,
  distributorReportedPrice: PropTypes.number,
  admissions: PropTypes.number,
  eventSph: PropTypes.number,
  occupancy: PropTypes.number,
  capacity: PropTypes.number,
  eventRevenueMovement: PropTypes.number,
  eventRevenueBOMovement: PropTypes.number,
  distributorReportedPriceMovement: PropTypes.number,
  admissionsMovement: PropTypes.number,
  eventSphMovement: PropTypes.number,
  occupancyMovement: PropTypes.number,
};
const defaultProps = {
  componentClassName: '',
  imageUrl: null,
  onClick: () => {},
  metrics: null,
  highlightedMetrics: [],

  eventRevenue: null,
  eventRevenueBO: null,
  distributorReportedPrice: null,
  admissions: null,
  eventSph: null,
  occupancy: null,
  capacity: null,
  eventRevenueMovement: undefined,
  eventRevenueBOMovement: undefined,
  distributorReportedPriceMovement: undefined,
  admissionsMovement: undefined,
  eventSphMovement: undefined,
  occupancyMovement: undefined,
};

/**
 * ListItem
 * If no imageUrl is specified - default bgColor will be used.
 * @param {array} metrics list of  metric name strings to be displayed by this item.
 */
const ListItemComponent = React.forwardRef(function ListItem(
  {
    componentClassName,
    id,
    name,
    rank,
    imageUrl,
    runWeek,
    metrics,
    onClick,
    eventRevenue,
    eventRevenueBO,
    distributorReportedPrice,
    admissions,
    eventSph,
    occupancy,
    capacity,
    eventRevenueMovement,
    eventRevenueBOMovement,
    admissionsMovement,
    eventSphMovement,
    occupancyMovement,
    highlightedMetrics,
  },
  ref
) {
  const metricProps = {
    eventRevenue,
    eventRevenueBO,
    admissions,
    eventSph,
    occupancy,
    capacity,
    eventRevenueMovement,
    eventRevenueBOMovement,
    distributorReportedPrice,
    admissionsMovement,
    eventSphMovement,
    occupancyMovement,
    capacityRemaining: calculateCapacityRemaining(admissions, capacity),
  };

  const { formatMessage } = useIntl();

  const onSelectTitle = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  return (
    <li ref={ref} onClick={onSelectTitle} className={classnames(`${componentClassName}`, {})}>
      <ListItemBackground image={imageUrl} />

      <div className="item-content">
        <div className="item-content__inner">
          <Text className="item__run-week" bold>
            {!_isNil(runWeek) ? formatMessage({ id: 'titlesList_run_week' }, { num: runWeek }) : '\u00A0'}
          </Text>

          <div className="item__title">
            <RankLabel className="title__rank" value={rank} />
            <Heading level={2} className="title__text">
              {name}
            </Heading>
          </div>

          <div className="item__metrics">
            {metrics.map((metric) => {
              return (
                <ItemMetric
                  key={metric}
                  metricKey={metric}
                  isHighlighted={highlightedMetrics.includes(metric)}
                  value={metricProps[metric]}
                  movement={metricProps[`${metric}Movement`]}
                  capacityRemaining={metricProps['capacityRemaining']}
                />
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
});

ListItemComponent.propTypes = propTypes;
ListItemComponent.defaultProps = defaultProps;

export default ListItemComponent;
