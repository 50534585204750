import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

/**
 * useItemContentAnimations
 * @param {bool} isOpen
 * @param {func} onAnimCloseComplete - callback fired when close animation ends.
*/
export const useItemContentAnimations = ({ isOpen, onAnimCloseComplete }) => {
  let itemContentRef = useRef(null);
  let itemContentTimeline = useRef(null);

  useEffect(() => {
    itemContentTimeline.current = gsap.timeline({
      paused: true,
      onReverseComplete: onAnimCloseComplete,
    });
    itemContentTimeline.current.to([itemContentRef.current], {
      display: 'block',
      height: 0,
      duration: 0.05,
    });
    itemContentTimeline.current.to([itemContentRef.current], {
      autoAlpha: 1,
      duration: 0.2,
      height: 'auto',
      ease: 'power1.easeInOut',
    });
  }, [itemContentRef, itemContentTimeline, onAnimCloseComplete]);

  useEffect(() => {
    if (isOpen) {
      itemContentTimeline.current.play();
    } else {
      itemContentTimeline.current.reverse();
    }
  }, [isOpen, itemContentTimeline]);

  const itemContentBinder = itemContentRef;

  return [itemContentBinder];
};
