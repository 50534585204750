import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { useTheatrePerspectiveMetrics } from '../../hooks';
import { BackToTop } from '@components';
import { TitleList } from '@modules';
import { useSortMetricsByOrder } from '@modules/Core';
import { KEY as SORT_KEY } from '@modules/FilterTypes/sort';
import ViewTransition from './ViewTransition';
import {
  useOccupancy,
  KEY as OCCUPANCY_KEY,
  getSelected as getOccupancySelected,
  PARAM_KEY_FROM as OCCUPANCY_FROM,
  PARAM_KEY_TO as OCCUPANCY_TO,
} from '@modules/FilterTypes/occupancy';

const propTypes = {
  baseSiteId: PropTypes.number.isRequired,
};

const ViewFilms = ({ baseSiteId }) => {
  const occupancySelected = useSelector(getOccupancySelected);
  let occupancyParams = {};
  if (occupancySelected) {
    occupancyParams = { [OCCUPANCY_FROM]: occupancySelected[0], [OCCUPANCY_TO]: occupancySelected[1] };
  }
  const metrics = useTheatrePerspectiveMetrics();
  const [, setSelected] = useOccupancy();

  const [sortedMetrics, highlightedMetrics] = useSortMetricsByOrder(metrics);

  useEffect(() => {
    return () => {
      setSelected([]);
    };
  }, [setSelected]);

  return (
    <ViewTransition className="theatre-metadata__view theatre-metadata__view--films">
      <TitleList
        className={classnames('theatre-metadata__view__title-list')}
        params={{ siteIds: [baseSiteId], ...occupancyParams }}
        metrics={sortedMetrics}
        highlightedMetrics={highlightedMetrics}
        additionalFilters={[SORT_KEY, OCCUPANCY_KEY]}
      />
      <BackToTop />
    </ViewTransition>
  );
};

ViewFilms.propTypes = propTypes;

export default ViewFilms;
