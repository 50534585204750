import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
const HEADING_SIZES = ['xxl', 'xl', 'l', 'm', 's', 'xs'];

/**
 * Outputs a heading element only
 * @param {} size if not specified it will look to the level to map the correct size.
 */
const Heading = ({ level, size, bold, className, uppercase, ...props }) => {
  const Tag = HEADINGS[level - 1];
  const headingSize = size || HEADING_SIZES[level - 1];

  return (
    <Tag
      className={classnames(`${className}`, `heading--${headingSize}`, {
        bold,
        'heading--uppercase': uppercase,
      })}
      {...props}
    />
  );
};

Heading.defaultProps = {
  className: '',
  level: 3,
  size: null,
  bold: true,
};

export default Heading;
