import React, { useEffect } from 'react';

/**
 * This hook emits a callback function when the user holds pressed the ref element.
 * @param {node} ref Dom node to watch for double clicks
 * @param {function} callback - A callback function for single click events
 * @param {number} [delay=1300] The amount of time (in milliseconds) to wait before differentiating a long press.
 * */
export default function useLongPress(ref, callback, delay = 1300) {
  useEffect(() => {
    let timer;
    const elementRef = ref.current;

    const startInterval = () => {
      timer = setTimeout(() => {
        cancelInterval();
        callback();
      }, delay);
    };

    const cancelInterval = () => clearTimeout(timer);

    elementRef.addEventListener('touchstart', startInterval);
    elementRef.addEventListener('touchend', cancelInterval);

    return () => {
      elementRef.removeEventListener('touchstart', startInterval);
      elementRef.removeEventListener('touchend', cancelInterval);
    };
  }, [ref.current]);
}
