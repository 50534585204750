import { combineReducers } from '@reduxjs/toolkit';
import { STATE_KEY } from '@modules/Filters';
import { reducer as datesReducer } from './dates';
import { reducer as territoryReducer } from './territory';
import { reducer as metricReducer } from './metric';
import { reducer as compsTitlesReducer } from './compsTitles';
import { reducer as sitesReducer } from './sites';
import { reducer as siteAttributeGroup } from './siteAttributeGroup';
import { reducer as occupancy } from './occupancy';
import { reducer as performance } from './performance';
import { reducer as sort } from './sort';
import { reducer as currencyReducer } from './currency';

export default {
  [STATE_KEY]: combineReducers({
    ...datesReducer,
    ...territoryReducer,
    ...metricReducer,
    ...compsTitlesReducer,
    ...sitesReducer,
    ...siteAttributeGroup,
    ...occupancy,
    ...performance,
    ...sort,
    ...currencyReducer,
  }),
};
