import React, { forwardRef, useState } from 'react';
import { Text } from '@components';
import { Overlay } from '@components';
import { ScreenModal } from './Modals';
import { useSelector } from 'react-redux';
import { getPerformancesGroupedByScreen } from '../selectors';
import { SCREEN_SORTING_KEY } from '../constants';

const componentClass = 'screens';

const Screens = ({ onClick }, ref) => {
  const screens = useSelector(getPerformancesGroupedByScreen);

  return (
    <div ref={ref} className={componentClass}>
      <div className={`${componentClass}__header`} />
      {screens.map((screen, index) => (
        <Text
          key={screen[SCREEN_SORTING_KEY]}
          tag="div"
          size="l"
          className={`${componentClass}__screen-card`}
          onClick={() => onClick(screen)}
        >
          {screen[SCREEN_SORTING_KEY]}
        </Text>
      ))}
    </div>
  );
};

const ScreensSidebar = forwardRef(Screens);

const ScreensCard = (_, ref) => {
  const [screenDataSelected, setScreenDataSelected] = useState(null);

  return (
    <>
      <ScreensSidebar onClick={setScreenDataSelected} ref={ref} />
      <Overlay.Provider type="modal" isOpen={!!screenDataSelected} onClose={() => setScreenDataSelected(null)}>
        {screenDataSelected && <ScreenModal data={screenDataSelected} />}
      </Overlay.Provider>
    </>
  );
};

export default forwardRef(ScreensCard);
