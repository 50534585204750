import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { errorByPattern } from '@showtime/cea-dep-utility';

import { InputField, Text, Button, Alert } from '@components';

import { AuthLayout, getIsEmailSent, forgotPassword } from '@modules/Auth';
import { isEmail } from '@modules/Core';

const componentName = 'auth-forgot';

const mapStateToProps = (state) => ({
  forgotPasswordError: errorByPattern(state, 'user.forgotPassword'),
});

const enhance = connect(mapStateToProps);

const Forgot = ({ forgotPasswordError }) => {
  const dispatch = useDispatch();
  const isEmailSent = useSelector(getIsEmailSent);
  const [userEmail, setUserEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const setEmail = (ev) => setUserEmail(ev.target.value);
  const sendEmailForgotPassword = () => {
    if (!isEmail(userEmail)) {
      setIsEmailValid(false);
    } else {
      setIsEmailValid(true);
      dispatch(forgotPassword(userEmail));
    }
  };

  const renderEmailSentMessage = () => (
    <div className={`${componentName}__text`}>
      <Text className={`${componentName}__title`} size="m" bold>
        <FormattedMessage id="success" />
      </Text>
      <Text className={`${componentName}__paragraph`} size="m">
        <FormattedMessage id={'forgot_password_success_p1'} values={{ userEmail: userEmail }} />
      </Text>
      <Text className={`${componentName}__paragraph`} size="m">
        <FormattedMessage id={'forgot_password_success_p2'} />
      </Text>
      <Text className={`${componentName}__paragraph`} size="m">
        <FormattedMessage id={'forgot_password_success_p3'} />
      </Text>
    </div>
  );

  return (
    <AuthLayout.Container hasTopImage>
      {isEmailSent ? (
        renderEmailSentMessage()
      ) : (
        <Fragment>
          {!isEmailValid && <Alert type="error" alertMessage={<FormattedMessage id="email_required" />} />}
          {!_isEmpty(forgotPasswordError) ? (
            <Alert type="error" alertMessage={<FormattedMessage id="forgot_password_email_error" />} />
          ) : (
            ''
          )}
          <div className={`${componentName}__text`}>
            <Text className={`${componentName}__title`} size="m" bold>
              <FormattedMessage id="forgot_password_title" />
            </Text>
            <Text size="m">
              <FormattedMessage id="forgot_password_subtext" />
            </Text>
          </div>
          <div className={`${componentName}__form-group`}>
            <InputField
              className={`${componentName}__form-field`}
              type="text"
              label={<FormattedMessage id="email" />}
              inputMode="email"
              value={userEmail}
              onChange={setEmail}
              hasError={!_isEmpty(forgotPasswordError) || !isEmailValid}
            />
            <Button primary defaultTheme className="auth-button" onClick={sendEmailForgotPassword}>
              <Text tag="span" size="m">
                <FormattedMessage id="send_email" />
              </Text>
            </Button>
          </div>
        </Fragment>
      )}
      <Link className="auth-link" to="/user/login">
        <Text size="m" bold>
          <FormattedMessage id="back_sign_in" />
        </Text>
      </Link>
    </AuthLayout.Container>
  );
};

export default enhance(Forgot);
