import React from 'react';
import _noop from 'lodash/noop';

import PoweredByShowtimeImage from '@images/showtime-powered-logo-2x.png';

const componentName = 'auth-footer';

export const Footer = () => {
  return (
    <div className={componentName}>
      <img className={`${componentName}__footer-logo`} src={PoweredByShowtimeImage} alt="Powered by Showtime" />
    </div>
  );
}

export default Footer;
