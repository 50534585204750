import { useLayoutEffect } from 'react';
import { useAppContextState } from '@modules';

import { THEME_CLASSNAME_MAP, THEME_CLASSNAMES, THEME_NAME_DEFAULT } from './constants';
/**
 * ThemeChanger
 * @param {object} location
 *
 * Monitors the url location.
 * Adds and removes theme classes from the HTML root element as the url path changes.
 * See themes.scss and variable.scss to see how these classes are used to map to different color schemes
 */
const AppThemeChanger = () => {
  const { path } = useAppContextState();
  // Always have some default theme active.
  const currentThemeClassName = THEME_CLASSNAME_MAP[path] || THEME_NAME_DEFAULT;

  useLayoutEffect(() => {
    const htmlElement = document.documentElement;

    // Target className changed, remove any other theme class.
    htmlElement.classList.remove(...THEME_CLASSNAMES);
    htmlElement.classList.add(currentThemeClassName);
  }, [currentThemeClassName]);

  return null;
};

export default AppThemeChanger;
