import Section from './Section';
import SectionContainer from './SectionContainer';
import SectionButton from './SectionButton';

import './section.scss';

/**
 * 3 block/utility components for content split into sections for use cases like Filters & Settings.
 * - Should work with any tag.
 * - Should allow for nesting sections.
 * - Should allow for borders between sections.
 */

Section.Container = SectionContainer;
Section.Button = SectionButton;

export default Section;
