import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@components';
import { getDOWKeyLabel, isWeekend, isToday, isTomorrow, isYesterday } from '@modules/Core/helpers';

const propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps = {
  active: false,
  className: '',
  value: moment(),
};

/**
 * SelectorItem
 * @param {string} value expected format: yyyy-mm-dd
 * @param {bool} active
 */
const componentName = 'date-selector__item';

const DayOfWeekLabel = ({ date }) => {
  const { formatMessage } = useIntl();

  if (isToday(date)) {
    return formatMessage({ id: 'today' });
  } else if (isYesterday(date)) {
    return formatMessage({ id: 'yesterday' });
  } else if (isTomorrow(date)) {
    return formatMessage({ id: 'tomorrow' });
  }
  return moment(date).format('MMM D');
};

const SelectorItem = ({ active, value }) => {
  if (!value) return null;

  const dayOfWeekLabel = getDOWKeyLabel(value, true);

  return (
    <div
      className={classnames(componentName, {
        [`${componentName}--active`]: active,
      })}
    >
      <Text size="l" bold className={classnames(`${componentName}__date`)}>
        <DayOfWeekLabel date={value} />
      </Text>
      <Text
        size="xs"
        bold
        className={classnames(`${componentName}__week-day`, {
          [`${componentName}__week-day--weekend`]: isWeekend(value),
        })}
      >
        {!active && <FormattedMessage id={dayOfWeekLabel} />}
      </Text>
    </div>
  );
};

SelectorItem.propTypes = propTypes;
SelectorItem.defaultProps = defaultProps;

export default SelectorItem;
