import React, { useCallback, useState, useRef } from 'react';
import { gsap } from 'gsap';
import { Transition } from 'react-transition-group';

import { Button, Arrow } from '@components';
import ListItemTable from './ListItemTable';

const ASIDE_TRANSITION_EASE = 'power1.easeInOut';

const ListItemAside = ({ id }) => {
  const [isAsideOpen, setIsAsideOpen] = useState(false);

  let asideRef = useRef(null);

  const onToggleAside = useCallback(
    (event) => {
      event.stopPropagation();
      setIsAsideOpen(!isAsideOpen);
    },
    [setIsAsideOpen, isAsideOpen]
  );

  const endListener = useCallback(
    (_, onComplete) => {
      const dir = isAsideOpen ? 'from' : 'to';
      gsap[dir]([asideRef.current], {
        duration: 0.3,
        height: 0,
        opacity: 0,
        ease: ASIDE_TRANSITION_EASE,
        onComplete,
      });
    },
    [asideRef, isAsideOpen]
  );

  return (
    <>
      <Button className="item-toggle-aside" onClick={onToggleAside}>
        <Arrow animate animPlay={isAsideOpen} />
      </Button>

      <Transition
        timeout={500}
        nodeRef={asideRef}
        in={isAsideOpen}
        appear
        mountOnEnter
        unmountOnExit
        addEndListener={endListener}
      >
        <aside className="item-aside" ref={asideRef}>
          <ListItemTable siteId={id} />
        </aside>
      </Transition>
    </>
  );
};

export default ListItemAside;
