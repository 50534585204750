import { useMemo } from 'react';
import _sortBy from 'lodash/sortBy';

import { DEFAULT_METRIC_LIST_ORDER } from '../constants';

/**
 * useSortMetricsByOrder
 * @param {array} metrics - List of metrics allowed to be displayed.
 * @param {array} metricOrder - Preferred order the metrics should be displayed in.
 */
export default function useSortMetricsByOrder(metrics, metricOrder) {

  const metricListOrder = metricOrder || DEFAULT_METRIC_LIST_ORDER;
  /**
   * Sorts metrics in the order they are found within metricListOrder.
   *
   * If metric is not within the metricListOrder, it should be appended to the end of the list.
   * FE responsibility to add new metricNames to DEFAULT_METRIC_LIST_ORDER.
   */
  const sortedMetrics = useMemo(() => {
    const notInMetricOrderList = [];

    const withinMetricOrderList = _sortBy(metrics, function (item) {
      const metricIndex = metricListOrder.indexOf(item);

      if (metricIndex === -1) {
        notInMetricOrderList.push(item);
      }
      return metricIndex;
    }).filter((item) => {
      return !notInMetricOrderList.includes(item);
    });

    return withinMetricOrderList.concat(notInMetricOrderList);
  }, [metrics, metricListOrder]);

  /**
   * Default is to highlight the first two metrics.
   */
  const highlightedMetrics = [sortedMetrics[0], sortedMetrics[1]];

  return [sortedMetrics, highlightedMetrics];
}
