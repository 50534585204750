import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useOccupancy } from '@modules/FilterTypes/occupancy';

import { useTheatrePerspectiveMetrics } from '../../hooks';

import ViewTransition from './ViewTransition';
import PlannerView from '@modules/PlannerView';

const propTypes = {
  baseSiteId: PropTypes.number.isRequired,
};

const ViewScreen = ({ baseSiteId }) => {
  const [, setSelected] = useOccupancy();
  const metrics = useTheatrePerspectiveMetrics();

  useEffect(() => {
    return () => {
      setSelected([]);
    };
  }, [setSelected]);

  return (
    <ViewTransition className="theatre-metadata__view theatre-metadata__view--screen">
      <PlannerView baseSiteId={baseSiteId} metrics={metrics} />
    </ViewTransition>
  );
};

ViewScreen.propTypes = propTypes;

export default ViewScreen;
