import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useAction } from '@modules/Core';
import { getSelected } from './selectors';
import { setSelected as setSelectedAction } from './actions';

export function usePerformance() {
  const setSelected = useAction(setSelectedAction);
  const selected = useSelector(getSelected);

  const removeItem = useCallback(
    (id) => {
      setSelected(selected.filter((selected) => id !== selected));
    },
    [selected, setSelected]
  );

  const addItem = useCallback(
    (id) => {
      if (!selected.includes(id)) {
        setSelected([...selected, id]);
      }
    },
    [selected, setSelected]
  );

  return [selected, setSelected, addItem, removeItem];
}
