import { KEY } from './constants';
import { createFilterReducer } from '@modules/Filters';

const initialState = {
  selected: [],
};

const occupancyReducer = createFilterReducer(KEY, initialState);

export default {
  [KEY]: occupancyReducer,
};
