import _cloneDeep from 'lodash/cloneDeep';

/**
 *
 * @param {DataReference} dataReference Data item in the forma
 *
 * @type DataReference:
 * @property {Date} time the time value used in the chart
 */

export const isNow = (dataReference) => {
  const now = new Date();
  const hours = now.getHours();
  const hourNumber = dataReference.time.getHours();

  return hourNumber === hours;
};
/**
 *
 * Generates the config for an amcharts4 axisRange
 * that spans from now to the end of the day.
 * This range will convert the line of the targetSeries
 * to a dash array
 *
 * @param {string} targetSeries id for series to be "dashed"
 */
export const getDashLineRange = (targetSeries) => {
  let dashLineStart = new Date();

  let dashLineEnd = new Date();
  dashLineEnd.setHours(24);
  dashLineEnd.setMinutes(0);

  return _cloneDeep({
    series: targetSeries,
    date: dashLineStart,
    endDate: dashLineEnd,
    grid: {
      visible: false,
    },
    contents: {
      strokeDasharray: '1,8',
      strokeLinecap: 'round',
    },
  });
};

//Common LineSeries config
export const seriesCommon = {
  type: 'LineSeries',
  strokeWidth: 3,
  tensionX: 1,
  bullets: [
    {
      type: 'CircleBullet',
      circle: {
        visible: false,
      },
    },
  ],
};
