import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import update from 'immutability-helper';

import { STATE_KEY } from './constants';
import {
  setList,
  appendList,
  setMaxPagesReached,
  setListFormats,
  clearListFormats,
  setNumPages,
  setCurrentPage,
} from './actions';

const setTheatres = (theatreArray) => theatreArray;

const appendTheatres = (state, theatreArray) => [...state, ...theatreArray];

const initialTheatreList = [];
const theatreListReducer = createReducer(initialTheatreList, {
  [setList.toString()]: (_, { payload }) => setTheatres(payload),
  [appendList.toString()]: (state, { payload }) => appendTheatres(state, payload),
});

const setMaxPages = (hasMaxPagesBeenReached) => hasMaxPagesBeenReached;

const maxPagesReached = createReducer(false, {
  [setMaxPagesReached.toString()]: (_, action) => setMaxPages(action.payload),
});

const initialFormatsList = {};

const setFormatsData = (state, { data, id }) => {
  const newState = { ...state };
  newState[id] = data;
  return newState;
};
const clearFormatsData = () => initialFormatsList;

const formatsReducer = createReducer(initialFormatsList, {
  [setListFormats.toString()]: (state, { payload }) => setFormatsData(state, payload),
  [clearListFormats.toString()]: () => clearFormatsData(),
});

const initialPagination = {
  current: 1,
  numPages: 0,
};
const setAttribute = (state, attribute, value) =>
  update(state, {
    [attribute]: {
      $set: value,
    },
  });

const paginationReducer = createReducer(initialPagination, {
  [setCurrentPage.toString()]: (state, { payload }) => setAttribute(state, 'current', payload),
  [setNumPages.toString()]: (state, { payload }) => setAttribute(state, 'numPages', payload),
});

export default {
  [STATE_KEY]: combineReducers({
    theatres: theatreListReducer,
    maxPagesReached: maxPagesReached,
    formatData: formatsReducer,
    pagination: paginationReducer,
  }),
};
