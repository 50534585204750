import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ProgressCircle } from '@components';
import { useMetric } from '@modules/FilterTypes/metric';

import { getValueByTheatreID, getCapacityRemainingByTheatreID } from '../selectors';

const propTypes = {};

const ChartCapacity = ({ id, value }) => {
  const capacityRemaining = useSelector(getCapacityRemainingByTheatreID(id));
  const progressValue = useMemo(() => Math.round((capacityRemaining / value) * 100), [capacityRemaining, value]);
  return <ProgressCircle className="RadarChart" size={50} progress={progressValue} strokeWidth={5} color={'#fd7e14'} />;
};

const ListItemChart = ({ id }) => {
  const [metric] = useMetric();
  const value = useSelector(getValueByTheatreID(id));

  return metric === 'capacity' ? <ChartCapacity id={id} value={value} /> : null;
};

ListItemChart.propTypes = propTypes;

export default ListItemChart;
