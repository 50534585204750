import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';

import { Text, Heading, Accordian, Drawer } from '@components';

import { componentName } from '../constants';
import { useSelected } from '../hooks';

import CompCheckbox from './CompCheckbox';

function SelectedTitles() {
  const { formatMessage } = useIntl();
  const { selectedComps } = useSelected();

  return (
    <>
      <Accordian.Item remainOpen className={classnames(`${componentName}__accordian-item`)}>
        <Accordian.ItemHeader className={classnames(`${componentName}__accordian-item-header`)}>
          <Heading size="xs" level="6" uppercase className={classnames(`${componentName}__section-heading`)}>
            {formatMessage({ id: 'selected_titles' })}
          </Heading>
        </Accordian.ItemHeader>
        <Accordian.ItemContent className={classnames(`${componentName}__accordian-item-content`)}>
          {selectedComps.length === 0 && (
            <Text className={`${componentName}__empty-text`}>{formatMessage({ id: 'comps_no_selected_titles' })}</Text>
          )}
          {selectedComps.map((selectedTitle) => {
            return <CompCheckbox key={selectedTitle.id} comp={selectedTitle} />;
          })}
        </Accordian.ItemContent>
      </Accordian.Item>

      <Drawer.Divider></Drawer.Divider>
    </>
  );
}

export default SelectedTitles;
