import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENT_PARAM_KEY, MA_EVENT_PARAM_KEY } from './';

import { useIsMarketAnalysis, useAction } from '@modules/Core';

import { getSelected } from './selectors';
import { setSelected as setSelectedAction } from './actions';

export function useCompsTitles() {
  const setSelected = useAction(setSelectedAction);
  const selected = useSelector(getSelected);

  const removeItem = useCallback(
    (id) => {
      setSelected(selected.filter((selected) => id !== selected.id));
    },
    [selected, setSelected]
  );

  return [selected, setSelected, removeItem];
}

/*
 * The EVENT_PARAM_KEY only changes between studio and exhibitor (using env vars)
 * We also need a specific key for Market Analysis
 * Since Market Analysis is contained within the Exhibitor app,
 * we need to manage the eventParamKey in a hook
 */
export function useEventParamKey() {
  const [eventParamKey, setEventParamKey] = useState(EVENT_PARAM_KEY);
  const isMarketAnalysis = useIsMarketAnalysis();
  useEffect(() => {
    setEventParamKey(isMarketAnalysis ? MA_EVENT_PARAM_KEY : EVENT_PARAM_KEY);
  }, [isMarketAnalysis]);

  return [eventParamKey];
}
