import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Arrow } from '@components';

import { COMPONENT_BASE_CLASS } from '../constants';
import { useItemContext } from '../hooks';

const componentName = `${COMPONENT_BASE_CLASS}__item-header`;

function ItemHeader({ children, className }) {
  const { toggleAccordianItem, isOpen, remainOpen } = useItemContext();

  return (
    <header role="button" className={classnames(componentName, className)}>
      <div className={`${componentName}__content`}>{children}</div>
      <div role="button" onClick={toggleAccordianItem} className={`${componentName}__arrow-ctn`}>
        {!remainOpen && <Arrow className={`${componentName}__arrow`} animate animPlay={isOpen} />}
      </div>
    </header>
  );
}

ItemHeader.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  remainOpen: PropTypes.bool,
  className: PropTypes.string,
};

ItemHeader.defaultProps = {
  children: '',
  isOpen: false,
  remainOpen: false,
  className: '',
};

export default ItemHeader;
