import React, { useEffect, useRef } from 'react';
import { addLicense, useTheme as assignTheme, createFromConfig, options as amChartsOptions } from '@amcharts/amcharts4/core';
import { XYChart } from '@amcharts/amcharts4/charts';
import animatedTheme from '@amcharts/amcharts4/themes/animated';
import PropTypes from 'prop-types';
import _cloneDeep from 'lodash/cloneDeep';
const { AMCHARTS4_LICENSE_KEY } = process.env;

/**
 * Chart component that instantsiates an amcharts4 chart in a div
 *
 * TODO:
 *  - Static chart config only, does not support new data/updating series etc
 */
const propTypes = {
  config: PropTypes.shape({}),
  className: PropTypes.string,
  amchartsChartObj: PropTypes.shape({}),
};

const Chart = ({ config, className, amchartsChartObj = XYChart }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    addLicense(AMCHARTS4_LICENSE_KEY);
    amChartsOptions.commercialLicense = true;
    amChartsOptions.autoSetClassName = true;
    assignTheme(animatedTheme);

    const chart = createFromConfig(_cloneDeep(config), chartRef.current, amchartsChartObj);

    return () => {
      chart.dispose();
    };
  }, []);

  return <div ref={chartRef} className={className} />;
};

Chart.propTypes = propTypes;

export default Chart;
