import React, { forwardRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { Section, Text, ToggleButton, Spinner } from '@components';
import { useAction, TWENTY_FOUR_HR_FORMAT, TWELVE_HR_FORMAT } from '@modules/Core';
import { useUserTimeFormat, useUserModel } from '@modules/Auth';

import { BASE_CLASS, SETTINGS_PAGE } from '../constants';

import { isUpdatingUserSettings } from '../selectors';
import { updateUserTimeFormat } from '../actions';

import UserSettingsHeader from './UserSettingsHeader';

const propTypes = {
  className: PropTypes.string,
};

const componentClass = `${BASE_CLASS}-time-format`;

const UserSettingsTimeFormat = forwardRef(function UserSettingsPassword(props, ref) {
  const { formatMessage } = useIntl();
  const { email } = useUserModel();
  const isUpdating = useSelector(isUpdatingUserSettings);
  const updateTimeFormatAction = useAction(updateUserTimeFormat);
  const timeFormat = useUserTimeFormat();

  const isToggled = useMemo(() => {
    return timeFormat === TWENTY_FOUR_HR_FORMAT;
  }, [timeFormat]);

  const updateTimeFormat = useCallback(
    (isSelected) => {
      if (!isUpdating) {
        const timeFormat = isSelected ? TWENTY_FOUR_HR_FORMAT : TWELVE_HR_FORMAT;

        updateTimeFormatAction(email, timeFormat);
      }
    },
    [isUpdating, updateTimeFormatAction, email]
  );

  return (
    <Section.Container
      {...props}
      className={classnames(props.className, componentClass, { [`${BASE_CLASS}-main--loading`]: isUpdating })}
      ref={ref}
      bordered
      tag="section"
    >
      <UserSettingsHeader
        prevIcon="back"
        prevPage={SETTINGS_PAGE.SETTINGS}
        title={formatMessage({ id: 'user_settings.time-format' })}
      />
      <Section className={`${componentClass}__section`}>
        <Text
          bold
          className={classnames(`${componentClass}__title`, {
            [`${componentClass}__title--disabled`]: isUpdating,
          })}
        >
          <FormattedMessage id={'user_settings.24-Hour-time'} />
        </Text>
        <ToggleButton isToggled={isToggled} onChange={updateTimeFormat} defaultTheme disabled={isUpdating} />
      </Section>
      {isUpdating && <Spinner />}
    </Section.Container>
  );
});

UserSettingsTimeFormat.propTypes = propTypes;

export default UserSettingsTimeFormat;
