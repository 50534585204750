import React from 'react';
import PropTypes from 'prop-types';

import './_title.scss';

import { Heading, Text } from '../Typography';

const propTypes = {
  title: PropTypes.node,
  subtext: PropTypes.node,
  className: PropTypes.string,
};
const defaultProps = {
  title: null,
  subtext: null,
  className: '',
};

const Title = ({ title, subtext, className }) => {
  return (
    <div className={`title ${className}`}>
      {title ? <Heading level={3}>{title}</Heading> : null}
      {subtext ? <Text tag="span">{subtext}</Text> : null}
    </div>
  );
};

Title.propTypes = propTypes;
Title.defaultProps = defaultProps;

export default Title;
