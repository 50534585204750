import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from '@components/Button';
import { BASE_CLASS } from './common';

/**
 * A button variant for section content.
 * Styled to appear like every other section, with defaults set to imply it is a button.
 */

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  iconAlign: 'right',
  icon: 'chevron-right',
};

const overrides = {
  primary: false,
  secondary: false,
};

const SectionButton = (props) => {
  const { className, ...rest } = props;

  return <Button {...rest} {...overrides} className={classnames(className, `${BASE_CLASS}-button`)} />;
};

SectionButton.propTypes = propTypes;
SectionButton.defaultProps = defaultProps;

export default SectionButton;
