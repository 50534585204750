import { URL_PATHS, createPageName } from '@modules/Core';

// Available CSS theme classnames
export const THEME_NAME_DEFAULT = 'theme--default';
const THEME_NAME_FILMS = 'theme--films';
const THEME_NAME_THEATRES = 'theme--theatres';
const THEME_NAME_MARKETS = 'theme--markets';
const THEME_NAME_ADVANCED_SALES = 'theme--advanced-sales';

// Maps available theme classnames to url paths.
export const THEME_CLASSNAME_MAP = {
  [URL_PATHS.USER]: THEME_NAME_DEFAULT,
  [URL_PATHS.FILMS]: THEME_NAME_FILMS,
  [URL_PATHS.THEATRES]: THEME_NAME_THEATRES,
  [URL_PATHS.MARKETS]: THEME_NAME_MARKETS,
  [URL_PATHS.ADVANCED_SALES]: THEME_NAME_ADVANCED_SALES,
};

export const THEME_CLASSNAMES = Object.values(THEME_CLASSNAME_MAP);

export const PAGE_TITLES_IDS = {
  [URL_PATHS.FILMS]: 'filmAnalysis',
  [URL_PATHS.THEATRES]: 'theatreAnalysis',
  [URL_PATHS.MARKETS]: 'marketAnalysis',
  [URL_PATHS.ADVANCED_SALES]: 'advancedSales',
};

export const PAGE_NAME_FILM_ANALYSIS = createPageName('filmAnalysis');
export const PAGE_NAME_FILM_METADATA = createPageName('filmMetaData');
export const PAGE_NAME_FILM_METADATA_THEATRES = createPageName(PAGE_NAME_FILM_METADATA, 'theatres');
export const PAGE_NAME_FILM_METADATA_CIRCUITS = createPageName(PAGE_NAME_FILM_METADATA, 'circuits');
export const PAGE_NAME_FILM_METADATA_COMPS = createPageName(PAGE_NAME_FILM_METADATA, 'comps');

export const PAGE_NAME_THEATRE_ANALYSIS = createPageName('theatreAnalysis');
export const PAGE_NAME_THEATRE_METADATA = createPageName('theatreMetaData');
export const PAGE_NAME_THEATRE_METADATA_BY_ATTRIBUTE = createPageName('theatreMetaDataByAttribute');
export const PAGE_NAME_THEATRE_METADATA_FILMS = createPageName(PAGE_NAME_THEATRE_METADATA, 'films');
export const PAGE_NAME_THEATRE_METADATA_SCREEN = createPageName(PAGE_NAME_THEATRE_METADATA, 'screens');

export const PAGE_NAME_MARKET_ANALYSIS = createPageName('marketAnalysis');
export const PAGE_NAME_MARKET_METADATA = createPageName('marketMetaData');
export const PAGE_NAME_MARKET_METADATA_THEATRES = createPageName(PAGE_NAME_MARKET_METADATA, 'theatres');
export const PAGE_NAME_MARKET_METADATA_CIRCUITS = createPageName(PAGE_NAME_MARKET_METADATA, 'circuits');
export const PAGE_NAME_MARKET_METADATA_COMPS = createPageName(PAGE_NAME_MARKET_METADATA, 'comps');

export const PAGE_NAME_ADVANCED_SALES_ANALYSIS = createPageName('advancedSales');
export const PAGE_NAME_ADVANCED_SALES_METADATA = createPageName('advancedSalesMetaData');
export const PAGE_NAME_ADVANCED_SALES_METADATA_THEATRES = createPageName(PAGE_NAME_ADVANCED_SALES_METADATA, 'theatres');
export const PAGE_NAME_ADVANCED_SALES_METADATA_CIRCUITS = createPageName(PAGE_NAME_ADVANCED_SALES_METADATA, 'circuits');
export const PAGE_NAME_ADVANCED_SALES_METADATA_COMPS = createPageName(PAGE_NAME_ADVANCED_SALES_METADATA, 'comps');

export const HEADER_SEARCH_PLACEHOLDERS = {
  [PAGE_NAME_FILM_ANALYSIS]: 'search_films',
  [PAGE_NAME_FILM_METADATA_THEATRES]: 'search_theatres',
  [PAGE_NAME_FILM_METADATA_CIRCUITS]: 'search_theatres',
  [PAGE_NAME_THEATRE_METADATA_BY_ATTRIBUTE]: 'search_theatres',
  [PAGE_NAME_THEATRE_ANALYSIS]: 'search_theatres',
  [PAGE_NAME_THEATRE_METADATA_FILMS]: 'search_films',
  [PAGE_NAME_MARKET_ANALYSIS]: 'search_films',
  [PAGE_NAME_MARKET_METADATA_THEATRES]: 'search_theatres',
  [PAGE_NAME_MARKET_METADATA_CIRCUITS]: 'search_theatres',
  [PAGE_NAME_ADVANCED_SALES_ANALYSIS]: 'search_films',
  [PAGE_NAME_ADVANCED_SALES_METADATA_THEATRES]: 'search_theatres',
  [PAGE_NAME_ADVANCED_SALES_METADATA_CIRCUITS]: 'search_theatres',
};

// List of pages that the header search input is enabled on.
export const HEADER_SEARCH_ENABLED_PAGES = Object.keys(HEADER_SEARCH_PLACEHOLDERS);
