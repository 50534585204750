import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';

import { TabsContext, useTabs } from './Context';
import { TAB_TRANSITION_PROP_TYPE } from './transitions';

const propTypes = {
  activeTab: PropTypes.string,
  homeTab: PropTypes.string,
  onTabChange: PropTypes.func,
  tabTranistionType: TAB_TRANSITION_PROP_TYPE,
  component: PropTypes.elementType,
  className: PropTypes.string,
};

const defaultProps = {
  onTabChange: _noop,
  component: 'div',
};

const Tabs = (props) => {
  const { activeTab, homeTab, onTabChange, tabTranistionType, component: Component, ...rest } = props;
  const ctx = useTabs({ activeTab, homeTab, onTabChange, tabTranistionType });

  return (
    <TabsContext.Provider value={ctx}>
      <Component {...rest} className={classnames(rest.className, 'tabs')} />
    </TabsContext.Provider>
  );
};

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default Tabs;
