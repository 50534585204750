const HIDE_OVERFLOW_CLASS = 'body--hide-overflow';

export const getBody = () => {
  // eslint-disable-next-line no-undef
  return document.querySelector('body');
};

export const lockBodyScroll = () => {
  const body = getBody();

  body.classList.add(HIDE_OVERFLOW_CLASS);
};

export const unlockBodyScroll = () => {
  const body = getBody();

  body.classList.remove(HIDE_OVERFLOW_CLASS);
};
