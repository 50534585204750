import { useSelector } from 'react-redux';
import { useAction } from '@modules/Core';
import { getSelected, getIsExcluded } from './selectors';
import { setSelected, setIsExcluded, setFilterState, disable, enable } from './actions';

export function useSelectedSites() {
  return [useSelector(getSelected), useAction(setSelected)];
}

export function useIsExcluded() {
  return [useSelector(getIsExcluded), useAction(setIsExcluded)];
}

export function useFilterState() {
  return [useSelector(getSelected), useSelector(getIsExcluded), useAction(setFilterState)];
}

export function useSitesDisabler() {
  return {
    enable: useAction(enable),
    disable: useAction(disable),
  };
}
