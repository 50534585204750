import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import { MetricNumber } from '@modules/Metrics';
import { useOverlay, Button, Text, Heading, PercentageMovement, Icon } from '@components';
import { COMPONENT_BASE_CLASS } from '../../constants';
import { getPerformanceModalData, getPerformanceAttributes } from '../../selectors';
import './_plannerViewModals.scss';
import { usePerformanceModalDataFetcher, useTimeFormatter } from '../../hooks';
import { usePlannerViewContext } from '../../Context';

const componentClass = 'performance';
const componentModalClass = `${COMPONENT_BASE_CLASS}-modal`;

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    event: PropTypes.string.isRequired,
    runtime: PropTypes.number.isRequired,
    datetime: PropTypes.string.isRequired,
    screenName: PropTypes.string.isRequired,
    distributorName: PropTypes.string.isRequired,
    runWeek: PropTypes.number.isRequired,
    imageUrl: PropTypes.string,
  }),
};

const PerformanceModal = ({ data }) => {
  const { metrics } = usePlannerViewContext();
  const fullScreenOverlay = useOverlay();
  const { formatMessage } = useIntl();
  const { id, event, runtime, datetime, screenName, imageUrl, distributorName, runWeek } = data;
  const timeFormatter = useTimeFormatter();

  const performanceData = useSelector(getPerformanceModalData); // metrics
  const performanceAttributes = useSelector(getPerformanceAttributes);

  usePerformanceModalDataFetcher(id);

  const LANG_FORMAT = 'langformat';
  const PRINT_FORMAT = 'printformat';
  const ATTRIBUTE_FORMAT = 'attribute';
  const PERF_ATTR_ICON_MAP = {
    [LANG_FORMAT]: 'audio-description',
    [PRINT_FORMAT]: 'subtitled-performance',
    [ATTRIBUTE_FORMAT]: 'special-needs-friendly',
  };

  return (
    <div className={componentModalClass}>
      <div className={`${componentModalClass}--container`}>
        <div className={`${componentClass}__header`}>
          {imageUrl && <img className={'screen-image'} src={imageUrl} alt="" />}
          <Button className={'close-button'} icon="close" onClick={fullScreenOverlay.close} />
        </div>
        <div className={`${componentClass}__title`}>
          <Heading className={`${componentClass}__name`} size={'xl'} bold>
            {event}
          </Heading>
          <div className={`${componentClass}__info`}>
            <Text size={'m'}>
              {distributorName}, {formatMessage({ id: 'runtime' }, { num: runtime })}
            </Text>
            {runWeek && (
              <Text size={'m'} bold>
                {formatMessage({ id: 'titlesList_run_week' }, { num: runWeek })}
              </Text>
            )}
          </div>
        </div>
        <div className={`${componentClass}__performance-data`}>
          <div className={`${componentClass}__screen-time`}>
            <div className={`${componentClass}__date-time`}>
              <Icon icon="time" />
              <Text className={`${componentClass}__time-text`} size={'m'} bold>
                {timeFormatter(datetime)}
              </Text>
            </div>
            <div className={`${componentClass}__screen-info`}>
              <Icon icon="screens" />
              <Text className={`${componentClass}__screen-text`} size={'m'} bold>
                {screenName}
              </Text>
            </div>
          </div>
          <div className={`${componentClass}__list-attributes`}>
            {performanceAttributes
              ? performanceAttributes.map((attr) => {
                  return (
                    <div className={`${componentClass}__attribute-item`} key={attr.type}>
                      <Icon icon={PERF_ATTR_ICON_MAP[attr.type.toLowerCase()]} className="icon-attribute" />
                      <Text tag="span" size="m">
                        {attr.name}
                      </Text>
                    </div>
                  );
                })
              : null}
          </div>
          <div className={`${componentClass}__list-metrics`}>
            {metrics.map((metricKey) => {
              return (
                <div className={`${componentClass}__metric-item`} key={metricKey}>
                  <Text className={`${componentClass}__metric-label`} tag="span" size="m">
                    {formatMessage({ id: `performanceModal.${metricKey}` })}
                  </Text>
                  <div className={`${componentClass}__metric-values`}>
                    {metricKey !== 'capacity' && (
                      <>
                        {performanceData[metricKey] ? (
                          <Text tag="span" size="m" bold>
                            <MetricNumber metricKey={metricKey} number={performanceData[metricKey]} />
                          </Text>
                        ) : (
                          '-'
                        )}
                        {performanceData[`${metricKey}Movement`] && (
                          <PercentageMovement
                            className={`${componentClass}__metric-percentage`}
                            value={`${performanceData[`${metricKey}Movement`]}`}
                            size="xxs"
                          />
                        )}
                      </>
                    )}
                    {metricKey == 'capacity' && (
                      <>
                        {performanceData['capacityRemaining'] ? (
                          <Text tag="span" size="m" bold>
                            {performanceData['capacityRemaining']}
                          </Text>
                        ) : (
                          '-'
                        )}
                        {performanceData['capacity'] ? (
                          <Text className={`${componentClass}__metric-capacity`} tag="span" size="s" bold>
                            ({performanceData['capacity']})
                          </Text>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

PerformanceModal.propTypes = propTypes;

export default PerformanceModal;
