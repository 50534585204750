import _get from 'lodash/get';
import _isString from 'lodash/isString';
import { loadState } from './localStorage';

/**
 * DEPRECATED!!!
 *
 * using this within components is not advised as they are static fns
 * read from local storage. They will not trigger component renders
 * and could lead to wierd side effects, see userHooks instead
 *
 * TODO remove dependencies
 */
export const getUserModel = () => loadState('comscore:user');
export const getUserField = (path, defaultValue) => _get(getUserModel(), path, defaultValue);

export const getUserCircuit = () => {
  return getUserField('circuits')[0];
};

export const getCircuitAttributes = () => {
  const circuit = getUserCircuit();
  const attributes = circuit.attributes;

  if (_isString(attributes)) {
    try {
      return JSON.parse(attributes);
    } catch (e) {
      return {};
    }
  } else {
    return attributes;
  }
};

export const getCircuitAttribute = (attribute, defaultVal) => {
  const attributes = getCircuitAttributes();

  return _get(attributes, attribute, defaultVal);
};

export const getCinemaStartHour = () => {
  var isUsingCinemaDate = getCircuitAttribute('useCinemaDateView', false);

  if (isUsingCinemaDate) {
    return getUserCircuit().startHour;
  } else {
    return 0;
  }
};
