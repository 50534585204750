import { useRef } from 'react';

import { useSelector } from 'react-redux';
import { getLastRefreshed, getRefreshParams } from './selectors';
import { useEffect } from 'react';
import { useParametersListener } from '@modules/FilterTypes';
import { useAction } from '@modules/Core';
import { reset } from './actions';

export const useLastRefresh = () => {
  return useSelector(getLastRefreshed);
};

export const useRefreshCallback = (callback, args = []) => {
  const lastRefreshed = useLastRefresh();
  useEffect(callback, [lastRefreshed, ...args]);
};

export const useLastUpdatedUpdate = () => {
  const resetAction = useAction(reset);
  const hasMountedRef = useRef(false);

  // Resetting the store when filters have updated so the icon doesnt show unnecesarily
  useParametersListener(getRefreshParams, () => {
    // Preventing reset on first mount
    if (hasMountedRef.current) {
      resetAction();
    } else {
      hasMountedRef.current = true;
    }
  });
};
