import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Section } from '@components';
import { TabLink, TAB_TRANSITION } from '@components/Tabs';
import { BASE_CLASS, SETTINGS_PAGE } from '../constants';
import UserSettingsHeader from './UserSettingsHeader';

const propTypes = {
  className: PropTypes.string,
};

const componentClass = `${BASE_CLASS}-list`;

const UserSettingsList = forwardRef(function UserSettingsList(props, ref) {
  const { formatMessage } = useIntl();

  return (
    <Section.Container
      {...props}
      ref={ref}
      bordered
      tag="section"
      className={classnames(props.className, componentClass)}
    >
      <UserSettingsHeader title={formatMessage({ id: 'user_settings.settings' })} />
      <TabLink
        component={Section.Button}
        tabTo={SETTINGS_PAGE.PASSWORD}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'user_settings.password' })}
      </TabLink>

      <TabLink
        component={Section.Button}
        tabTo={SETTINGS_PAGE.LANGUAGE}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'user_settings.language' })}
      </TabLink>

      <TabLink
        component={Section.Button}
        tabTo={SETTINGS_PAGE.CURRENCY}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'user_settings.currency' })}
      </TabLink>

      <TabLink
        component={Section.Button}
        tabTo={SETTINGS_PAGE.DEFAULT_TERRITORY}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'user_settings.default-territory' })}
      </TabLink>

      <TabLink
        component={Section.Button}
        tabTo={SETTINGS_PAGE.TITLE_PREFERENCE}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'user_settings.title-preference' })}
      </TabLink>

      <TabLink
        component={Section.Button}
        tabTo={SETTINGS_PAGE.TIME_FORMAT}
        tabTranistionType={TAB_TRANSITION.REMAIN_FROMRIGHT}
      >
        {formatMessage({ id: 'user_settings.time-format' })}
      </TabLink>
    </Section.Container>
  );
});

UserSettingsList.propTypes = propTypes;

export default UserSettingsList;
