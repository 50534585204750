import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUnavailableMetrics } from '@modules/TerritorySelect';

import { getFilmAnalysisMetrics } from './helpers';

export const useFilmPerspectiveMetrics = () => {
  const unavailableMetrics = useSelector(getUnavailableMetrics);

  return useMemo(() => {
    return getFilmAnalysisMetrics().filter((metric) => !unavailableMetrics.includes(metric));
  }, [unavailableMetrics]);
};
