import { formatNumber, formatMoney } from 'accounting-js';
import _identity from 'lodash/identity';
import { getMetric } from '@modules/Metrics';
import { CURRENCIES } from '@modules/Core';

const DEFAULT_PRECISION = 0;
const DEFAULT_CURRENCY_FORMAT = '%s%v';
const DEFAULT_DECIMAL_DELIMITER = '.';
const DEFAULT_THOUSAND_DELIMITER = ',';

const TRILLION = 1000000000000;
const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

let defaultSettings = {};
let numberAbbreviations = {
  thousand: 'k',
  million: 'm',
  billion: 'b',
  trillion: 't',
};

/**
 * Configures the default accounting-js settings to be used for
 * formatting numbers
 *
 * @type config
 * @property {string}  userCurrency currency for the user in ISO 3 digit format, e.g. USD
 * @property {string}  currencyFormat controls output: %s = symbol, %v = value/number
 * @property {string}  decimalDelimiter symbol used to separate decimal values
 * @property {string}  thousandDelimiter symbol used to separate the thousand limiter
 * @property {abbreviations} abreviation  abreviation symbols to be used
 *
 * @type abbreviations
 * @property {string} thousand string used to abbreviate thousand values, default k
 * @property {string} million string used to abbreviate million values, default m
 * @property {string} billion string used to abbreviate billlion values, default b
 * @property {string} trillion string used to abbreviate trillion values, default t
 *
 * @param {config}  config Configuration for formaters
 */
export const configureAccounting = (config) => {
  const {
    userCurrency,
    currencyFormat = DEFAULT_CURRENCY_FORMAT,
    decimalDelimiter = DEFAULT_DECIMAL_DELIMITER,
    thousandDelimiter = DEFAULT_THOUSAND_DELIMITER,
    abbreviations,
  } = config;
  const currencyMetadata = CURRENCIES[userCurrency] || { symbol_native: userCurrency };

  const symbol = currencyMetadata.symbol_native;

  defaultSettings = {
    thousand: thousandDelimiter,
    decimal: decimalDelimiter,
    format: currencyFormat,

    symbol: symbol, // default currency symbol is '$'
    precision: DEFAULT_PRECISION, //Default 0 precision unless specify otherwise
  };

  numberAbbreviations = {
    ...numberAbbreviations,
    ...abbreviations,
  };
};

/**
 * Formats a number to be readable
 *
 * @param {number} number number to be formatted
 * @param {number} precision the amount of decimal places which the number will be rendered to
 */
export const itemFormatter = (number, precision = DEFAULT_PRECISION) => {
  if (number === 0) {
    precision = 0;
  }
  return formatNumber(number, {
    ...defaultSettings,
    precision,
  });
};

/**
 * Formats a number to be readable and to have a post fix
 *
 * @param {number} number number to be formatted
 * @param {number} precision the amount of decimal places which the number will be rendered to
 */
export const itemFormatterShort = (number, precision = 2) => {
  let formattedValue = '';

  const appendAbbr = (value, abbr) => {
    return `${itemFormatter(value, precision)}${abbr}`.replace(/\.00/g, '');
  };

  if (number >= TRILLION) {
    // trillion
    formattedValue = appendAbbr(number / TRILLION, numberAbbreviations.trillion);
  } else if (number < TRILLION && number >= BILLION) {
    // billion
    formattedValue = appendAbbr(number / BILLION, numberAbbreviations.billion);
  } else if (number < BILLION && number >= MILLION) {
    // million
    formattedValue = appendAbbr(number / MILLION, numberAbbreviations.million);
  } else if (number < MILLION && number >= THOUSAND) {
    // thousand
    formattedValue = appendAbbr(number / THOUSAND, numberAbbreviations.thousand);
  } else {
    formattedValue = `${itemFormatter(number, precision)}`.replace(/\.00/g, '');
  }

  return formattedValue;
};

/**
 * Formats a percentage number to contain a % sign.
 * i.e. will format 49 to 49%
 *
 * @param {number} number number to be formatted
 * @param {number} precision the amount of decimal places which the number will be rendered to
 */
export const percentageFormatter = (number, precision) => {
  const value = itemFormatter(number, precision);

  return `${value}%`;
};

/**
 * Formats a number to be readable, and contain currency symbol
 *
 * @param {number} number number to be formatted
 * @param {number} precision the amount of decimal places which the number will be rendered to
 */
export const currencyFormatter = (number, precision) => {
  let currPrecision = 2;
  if (precision > -1) {
    currPrecision = precision;
  } else if (number >= 1000 || number <= -1000 || number === 0) {
    currPrecision = 0;
  }

  return formatMoney(number, {
    ...defaultSettings,
    precision: currPrecision,
  });
};

/**
 * Formats a number to contain post-fix and render the currency symbol
 *
 * @param {number} number value to be formatted
 * @param {number} precision decimal places that the number will be formatted to
 */
export const currencyFormatterShort = (number, precision = 2) => {
  const numAbbreviated = itemFormatterShort(number, precision);
  const currencyTemplate = defaultSettings.format;

  return currencyTemplate.replace('%s', defaultSettings.symbol).replace('%v', numAbbreviated);
};

/**
 * Returns the currency symbol for the current user
 */
export const getCurrencySymbol = () => {
  return defaultSettings.symbol;
};

/**
 * Formats a number based on a metric key
 *
 * @param {number} number number to be formatted
 * @param {string} metricKey formats a number based on the metric configuration
 */
export const metricFormatter = (number, metricKey) => {
  const { filter, precision } = getMetric(metricKey);
  const filterMap = {
    items: itemFormatter,
    currency: currencyFormatter,
    percentage: percentageFormatter,
    capacity: _identity,
  };
  const formatter = filterMap[filter] || _identity;

  if (!filterMap[filter]) {
    console.warn(`No formatter found for ${metricKey}, its formatter is configured as ${filter}`);
  }

  return formatter(number, precision);
};

/**
 * Formats a number based on a metric key
 *
 * @param {number} number number to be formatted
 * @param {string} metricKey formats a number based on the metric configuration
 */
export const metricFormatterShort = (number, metricKey) => {
  const { filter, precision } = getMetric(metricKey);
  const filterMap = {
    items: itemFormatterShort,
    currency: currencyFormatterShort,
    percentage: percentageFormatter,
  };
  const formatter = filterMap[filter] || _identity;

  if (!filterMap[filter]) {
    console.warn(`No formatter found for ${metricKey}, its formatter is configured as ${filter}`);
  }

  return formatter(number, precision);
};
