import { createReducer } from '@reduxjs/toolkit';
import {
  APPEND_BOOKMARKS_DATA,
  CLEAR_BOOKMARKS_DATA,
  DELETE_BOOKMARK_ID,
  SET_BOOKMARK_SELECTED,
  SET_BOOKMARKS_DATA,
} from './actionTypes';
import { STATE_KEY } from './constants';
import _filter from 'lodash/filter';

const defaultPrivacyData = {
  content: [],
};

const initialState = {
  data: {
    private: defaultPrivacyData,
    shared: defaultPrivacyData,
  },
  selected: null,
};

const bookmarksReducer = createReducer(initialState, {
  [SET_BOOKMARKS_DATA]: (state, { key, value }) => ({ ...state, data: { ...state.data, [key]: value } }),
  [APPEND_BOOKMARKS_DATA]: (state, { key, value }) => {
    const currentData = state.data[key].content;
    state.data[key].content = currentData.concat(value.content);
    return state;
  },
  [SET_BOOKMARK_SELECTED]: (state, { key, value }) => ({ ...state, [key]: value || null }),
  [CLEAR_BOOKMARKS_DATA]: (state) => ({ ...state, data: initialState.data }),
  [DELETE_BOOKMARK_ID]: (state, { key, value }) => {
    state.data[key].content = _filter(state.data[key].content, ({ id }) => id !== value);
    return state;
  },
});

export default { [STATE_KEY]: bookmarksReducer };
