import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import _noop from 'lodash/noop';
import _isNull from 'lodash/isNull';

import { TheatreList, TheatreSelectorButtons } from '@modules';
import { useIsHistorical } from '@modules/Core';
import { useSiteAttributeGroup } from '@modules/FilterTypes/siteAttributeGroup';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';
import { useStudioHelpers } from '@pages/studio/hooks';

import ViewTransition from './ViewTransition';
import ViewNotAvailableState from './ViewNotAvailableState';
import { useMarketPerspectiveMetrics } from '../../hooks';

const propTypes = {
  baseEventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelectItem: PropTypes.func.isRequired,
};

/**
 * Circuit is the default selected attribute group for the studio app.
 * It's ID is 1 which is a hardcoded value on the BE. There is an effect in
 * this component that will set this as the selected value on component mount
 */
const CIRCUIT_ID = 1;

const ViewTheatres = ({ baseEventId, onSelectItem }) => {
  const { formatMessage } = useIntl();
  const [eventParamKey] = useEventParamKey();
  const [selectedAttributeGroupId, setSelectedAttributeGroupId] = useSiteAttributeGroup();
  const metrics = useMarketPerspectiveMetrics();
  const isHistorical = useIsHistorical();
  const { isIntegrated } = useStudioHelpers();
  const isSitesSelected = selectedAttributeGroupId === null;

  const isCircuitViewVisible = useMemo(() => {
    return !isIntegrated || (isHistorical && isIntegrated);
  }, [isHistorical, isIntegrated]);

  useEffect(() => {
    if (_isNull(selectedAttributeGroupId)) {
      setSelectedAttributeGroupId(CIRCUIT_ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isCircuitViewVisible ? (
    <ViewTransition className="film-metadata__view film-metadata__view--theatres">
      <TheatreSelectorButtons metrics={metrics} />
      <TheatreList
        className="film-metadata__theatres-list"
        hasAside={selectedAttributeGroupId === null}
        onSelectItem={isSitesSelected ? _noop : onSelectItem} 
        params={{ [eventParamKey]: [baseEventId] }}
      />
    </ViewTransition>
  ) : (
    <ViewNotAvailableState message={formatMessage({ id: 'circuits_view_not_available_real_time' })} />
  );
};

ViewTheatres.propTypes = propTypes;

export default ViewTheatres;
