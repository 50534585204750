import { useEffect, useCallback } from 'react';

import { metricFormatterShort } from '@modules/Formatters';

export default function useChartValueAxisFormatter(chart, selectedMetric) {
  const formatLabel = useCallback((value) => metricFormatterShort(value, selectedMetric), [selectedMetric]);

  useEffect(() => {
    if (chart !== null) {
      const yAxis = chart.yAxes.getIndex(0);
      yAxis.formatLabel = formatLabel;
    }
  }, [chart, formatLabel]);
}
