import moment from 'moment';

export const isTimeWithinRange = (time, minimum, maximum) => {
  return moment(time).isBetween(moment(minimum), moment(maximum), 'hour', '[]');
};

export const mapTimesToUTCTimestampByField = (array, field) => {
  return array.map((item) => {
    const date = new Date(item[field]);

    return date.getTime();
  });
};

export const maxDateByField = (array, field) => {
  const times = mapTimesToUTCTimestampByField(array, field);

  const max = Math.max(times);

  return new Date(max);
};

export const minDateByField = (array, field) => {
  const times = mapTimesToUTCTimestampByField(array, field);

  const max = Math.min(times);

  return new Date(max);
};
