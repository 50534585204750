import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';

import { LeagueTableChart } from '@components';
import { getFullCompsList, useSelectedMetric, useCompColorPairs, BASE_COLOR } from '@modules/Comps';
import { metricFormatterShort } from '@modules/Formatters';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useCallback } from 'react';

const dataMap = {
  admissions: 'dayAdmissions',
  eventRevenue: 'dayEventRevenue',
  eventRevenueBO: 'dayEventRevenueBO',
};

const getValueField = (metric) => dataMap[metric];

function CompsBarChart(props) {
  const data = useSelector(getFullCompsList);
  const [selectedMetric] = useSelectedMetric();
  const valueField = useMemo(() => getValueField(selectedMetric), [selectedMetric]);
  const valueRenderer = useCallback(
    (dataItem) => {
      const value = dataItem[getValueField(selectedMetric)];

      return value ? metricFormatterShort(value, selectedMetric) : null;
    },
    [selectedMetric]
  );
  const colorPairs = useCompColorPairs();
  const colorRenderer = useCallback(
    ({ id }) => {
      const colorPair = _find(colorPairs, { id });

      return colorPair ? colorPair.color : BASE_COLOR;
    },
    [colorPairs]
  );

  return (
    <LeagueTableChart
      idField="id"
      labelField="name"
      data={data}
      valueField={valueField}
      valueRenderer={valueRenderer}
      colorRenderer={colorRenderer}
    />
  );
}

CompsBarChart.propTypes = {};

export default CompsBarChart;
