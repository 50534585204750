import React from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';

import { useOverlay, Button, Text, Heading, Icon } from '@components';
import ScreenImage from '@images/screen-sites.png';
import { useTimeFormatter } from '../../hooks';
import { calculateCapacityRemaining } from '@modules/Core/helpers';

import './_plannerViewModals.scss';
import { COMPONENT_BASE_CLASS } from '../../constants';

const componentClass = 'screen';
const componentModalClass = `${COMPONENT_BASE_CLASS}-modal`;

const TimesTable = ({ titles = [] }) => {
  const timeFormatter = useTimeFormatter();

  return (
    <table>
      <thead>
        <tr>
          <th />
          <th>
            <Text size={'s'} bold>
              <FormattedMessage id={'time'} />
            </Text>
          </th>
          <th>
            <Text size={'s'} bold>
              <FormattedMessage id={'title'} />
            </Text>
          </th>
          <th>
            <Text size={'s'} bold>
              <FormattedMessage id={'metric.capacity'} />
            </Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {titles.map(({ id, datetime, event, capacity, admissions }) => (
          <tr key={id}>
            <td>
              <Icon icon="time" />
            </td>
            <td>
              <Text size={'s'}>{timeFormatter(datetime)}</Text>
            </td>
            <td>
              <Text size={'s'} bold>
                {event}
              </Text>
            </td>
            <td>
              <Text size={'s'} bold>
                {calculateCapacityRemaining(admissions, capacity)}
              </Text>
            </td>
          </tr>
        ))}
        {!titles.length && (
          <tr>
            <td colSpan={4} className={`${componentClass}__timetable__no-result`}>
              <Text bold>
                <FormattedMessage id={'no_data_available'} />
              </Text>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const ScreenModal = ({ data }) => {
  const fullScreenOverlay = useOverlay();

  const capacity = _get(data, 'values[0].capacity');
  const screenName = _get(data, 'values[0].screenName');

  return (
    <div className={componentModalClass}>
      <div className={`${componentModalClass}--container`}>
        <div className={`${componentClass}__header`}>
          <img className={'screen-image'} src={ScreenImage} alt="Screen site" />
          <Button className={'close-button'} icon="close" onClick={fullScreenOverlay.close} />
        </div>
        <div className={`${componentClass}__title`}>
          <Heading size={'xl'} bold>
            {screenName}
          </Heading>
          <Text size={'m'}>
            {capacity} <FormattedMessage id={'capacity'} />
          </Text>
        </div>
        <div className={`${componentClass}__timetable`}>
          <TimesTable titles={data.values} />
        </div>
      </div>
    </div>
  );
};

export default ScreenModal;
