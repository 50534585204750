import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { COMPONENT_BASE_CLASS } from '../constants';

const componentClass = `${COMPONENT_BASE_CLASS}__footer`;

function Footer({ children, align }) {
  return <div className={classnames(componentClass, align && `${componentClass}--align-${align}`)}>{children}</div>;
}

Footer.propTypes = {
  align: PropTypes.oneOf(['right', 'center', 'left']),
  children: PropTypes.node,
};

export default Footer;
