import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { NoAccessWrapper } from '@modules';
import Pages from '@pages';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Draggable } from 'gsap/Draggable';

require('intersection-observer');
/**
 * Register GSAP Plugins available as part of our Club GreenSock membership
 * Forces ScrollTrigger etc to not get dropped during build
 */
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(Draggable);

const App = () => {
  return (
    <Router>
      <NoAccessWrapper>
        <Pages />
      </NoAccessWrapper>
    </Router>
  );
};

export default App;
