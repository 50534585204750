import React from 'react';
import { init as SentryInit } from '@sentry/browser';
import ReactDOM from 'react-dom';
import '@modules/Styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LocaleProvider, StoreProvider } from '@modules';

const { REACT_APP_SENTRY_DSN: SENTRY_DSN } = process.env;
if (SENTRY_DSN) {
  SentryInit({ dsn: SENTRY_DSN });
}

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <LocaleProvider>
        <App />
      </LocaleProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('app-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
