import { createAction } from '@reduxjs/toolkit';

import { asyncAction } from '@showtime/cea-dep-utility';
import { API_ROOT_PATH, getDefaultParams } from '@modules/Core';

import { THEATRE_METADATA_FETCH_KEY, STATE_KEY } from './constants';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_INFO_LIST = 'SET_INFO_LIST';
export const setInfoList = createAction(createActionType(SET_INFO_LIST));

const SET_PREV_URL = 'SET_PREV_URL';
export const setPrevUrl = createAction(createActionType(SET_PREV_URL));

/**
 * fetchMetadataInfo
 * Load the data for the currently selected site
 */
export function fetchMetadataInfo(siteId, params, subSectionPath, dataPath) {
  return asyncAction({
    key: THEATRE_METADATA_FETCH_KEY,
    url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/groupBy/stanSiteId?_query=json`,
    body: {
      ...getDefaultParams(),
      ...params,
      siteIds: [siteId],
    },
    method: 'POST',
    success(response) {
      this.dispatch(setInfoList(response[0]));
    },
    useDefaultErrorHandler: false,
  });
}
