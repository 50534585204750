import { createSelector } from '@reduxjs/toolkit';
import _property from 'lodash/property';

import { isLoading } from '@showtime/cea-dep-utility';

import { KEY as territory } from '@modules/FilterTypes/territory';
import { getParametersFactory } from '@modules/FilterTypes';

import { STATE_KEY, SEARCH_FETCH_KEY, SUGGESTED_FETCH_KEY } from './constants';

const getCompsStore = (state) => state[STATE_KEY];

export const getParameters = getParametersFactory([territory]);

export const isSearchLoading = (state) => isLoading(state, SEARCH_FETCH_KEY);
export const getSearchResults = createSelector([getCompsStore], _property('searchResults'));

export const isSuggestedTitlesLoading = (state) => isLoading(state, SUGGESTED_FETCH_KEY);
export const getSuggestedTitles = createSelector([getCompsStore], _property('suggestedTitles'));

export const getSelectedComps = createSelector([getCompsStore], _property('selectedComps'));

export const getNoResults = createSelector([getCompsStore], _property('noResults'));
