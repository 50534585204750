import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { getIsAuthenticated } from '@modules/Auth';

import { Reset, Forgot, Login, Logout } from './Auth';

function Routes({ redirectTo }) {
  const isAuthenticated = useSelector(getIsAuthenticated);

  return !isAuthenticated ? (
    <Switch>
      <Route path="/user/reset-password/:token" component={Reset} />
      <Route path="/user/forgot" component={Forgot} />
      <Route path="/user/login" component={Login} />
      <Route path="/user/logout" component={Logout} />
      <Redirect to="/user/login" />
    </Switch>
  ) : (
    <Switch>
      <Route path="/user/logout" component={Logout} />
      <Redirect to={redirectTo} />
    </Switch>
  );
}

export default Routes;
