import React, { useMemo } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useTab } from './Context';
import useTabTransition from './transitions';

const propTypes = {
  tabKey: PropTypes.string.isRequired,
  // Note: If it's a function component it has to forwardRef to the element that is animated.
  component: PropTypes.elementType,
  className: PropTypes.string,
};

const defaultProps = {
  component: 'div',
};

/**
 * useTab offers passing additional props to the context (e.g. 'disabled' so that Nav could read it.)
 * When such features become required, they could be defined here - just make sure to memoize them.
 */
const TabComponent = React.forwardRef(function Tab(props, ref) {
  const { tabKey, component: Component, ...rest } = props;
  const tabProps = useMemo(() => ({}), []);
  const [isTabActive, isTabRegistered] = useTab(tabKey, tabProps);
  const transitionProps = useTabTransition(isTabActive, { elementRef: ref });

  // Render nothing until fully registered
  // Otherwise tabs may animate on very first render which is undesired
  return isTabRegistered ? (
    <Transition {...transitionProps} mountOnEnter unmountOnExit>
      <Component {...rest} className={classnames(rest.className, 'tabs-tab')} ref={transitionProps.nodeRef} />
    </Transition>
  ) : null;
});

TabComponent.propTypes = propTypes;
TabComponent.defaultProps = defaultProps;

export default TabComponent;
