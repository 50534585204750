import React, { useCallback, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, Icon } from '@components';
import './_back-to-top.scss';

const SCROLL_TRIGGER_ID = 'backToTopScrollTrigger';

const BackToTop = () => {
  const scrollToTop = useCallback(() => {
    gsap.to(window, { duration: 1, scrollTo: 0 });
  }, []);

  let backToTopRef = useRef(null);

  useEffect(() => {
    let animTimeline;

    if (backToTopRef) {
      animTimeline = gsap.timeline();
      animTimeline.fromTo(
        [backToTopRef.current],
        {
          opacity: 0,
          duration: 0.3,
        },
        {
          autoAlpha: 1,
          opacity: 1,
          duration: 0.3,
        }
      );

      ScrollTrigger.create({
        id: SCROLL_TRIGGER_ID,
        animation: animTimeline,
        toggleActions: 'play none none reverse',
        start: '400px top',
        ease: 'power1.easeInOut',
      });
    }

    return () => {
      // Cleanup ScrollTrigger instances when leaving view
      const currentScrollTrigger = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (currentScrollTrigger) {
        if (animTimeline) {
          animTimeline.pause(0).kill(true);
        }
        currentScrollTrigger.kill(true);
      }
    };
  }, [backToTopRef]);

  return (
    <div className="back-to-top" ref={backToTopRef}>
      <Button className="back-to-top__btn" onClick={scrollToTop}>
        <Icon icon="back-to-top" />
      </Button>
    </div>
  );
};

export default BackToTop;
