import { useCallback, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * useResizeObserver
 * @param {RefObject} ref optional, if nothing is passed resizeObserver will default to the body element.
 * @param {func} callback returns DOMRect ReadOnly
 *
 * Listens for resize changes on provided element reference.
 * Width and height are returned.
 * or optional callback can be used to make changes on resize.
 * 
 * useResizeWidthChanged(elementRef, callbackFunc);
 * useResizeWidthChanged(null, callbackFunc); - will default to body element
 */
export default function useResizeObserver(ref, callback) {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const handleResize = useCallback(
    (entries) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback]
  );

  const setObserver = useCallback(
    (element) => {
      let observer = new ResizeObserver((entries) => handleResize(entries));
      observer.observe(element);

      return () => {
        observer.disconnect();
        observer = null;
      };
    },
    [handleResize]
  );

  useEffect(() => {
    const bodyEl = document.body;

    if (!ref) {
      return setObserver(bodyEl);
    } else if (ref.current) {
      return setObserver(ref.current);
    }

    return;
  }, [ref, setObserver]);

  return [width, height];
}
