import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Section, Spinner } from '@components';
import UserSettingsHeader from './../UserSettingsHeader';
import CurrentDefaultTerritory from './CurrentDefaultTerritory';
import ContentDefaultTerritory from './ContentDefaultTerritory';
import { BASE_CLASS, SETTINGS_PAGE } from '../../constants';
import { isSettingDefaultTerritory } from '@modules/TerritorySelect';
import { useSelector } from 'react-redux';
import './_default-territory.scss';

const propTypes = {
  className: PropTypes.string,
};

const componentClass = `${BASE_CLASS}-default-territory`;

const UserSettingsDefaultTerritory = forwardRef(function UserSettingsDefaultTerritory(props, ref) {
  const { formatMessage } = useIntl();
  const isLoading = useSelector(isSettingDefaultTerritory);

  return (
    <>
      <Section.Container
        {...props}
        className={classnames(props.className, componentClass, { [`${componentClass}__loading`]: isLoading })}
        ref={ref}
        bordered
        tag="section"
      >
        <UserSettingsHeader
          prevIcon="back"
          prevPage={SETTINGS_PAGE.SETTINGS}
          title={formatMessage({ id: 'user_settings.default-territory' })}
        />
        <CurrentDefaultTerritory />
        <ContentDefaultTerritory />
      </Section.Container>
      {isLoading && <Spinner />}
    </>
  );
});

UserSettingsDefaultTerritory.propTypes = propTypes;

export default UserSettingsDefaultTerritory;
