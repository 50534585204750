import { useCallback } from 'react';
import moment from 'moment';

import { useUserTimeFormat } from '@modules/Auth';
import { PERFORMANCE_BACKEND_TIME_FORMAT } from '@modules/Core';

export const useTimeFormatter = () => {
  const timeFormat = useUserTimeFormat();

  return useCallback(
    (dateString) => {
      return moment(dateString, PERFORMANCE_BACKEND_TIME_FORMAT).format(timeFormat);
    },
    [timeFormat]
  );
};
