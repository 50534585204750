import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import kawaiiPopcornSrc from '@images/kawaii-popcorn.svg';

const componentName = 'item-background';

/**
 * @description
 * List Item Background
 *
 * @param {string} image - Url of title image
 * @param {boolean} showPlaceholderImage - Whether or not to show placeholder image when no image is available
 * */
const ListItemBackground = ({ image, showPlaceholderImage }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoaded = useCallback(() => {
    setImageLoaded(true);
  }, [setImageLoaded]);

  return (
    <div
      className={classnames(`${componentName}__ctn`, {
        [`${componentName}__ctn--image`]: image,
        [`${componentName}__ctn--placeholder`]: !image,
      })}
    >
      {image ? (
        <img
          className={classnames(`${componentName}__image`, {
            [`${componentName}__image--is-loaded`]: imageLoaded,
          })}
          src={image}
          onLoad={handleImageLoaded}
          alt=""
        />
      ) : (
        showPlaceholderImage && (
          <img className={classnames(`${componentName}__placeholder`)} src={kawaiiPopcornSrc} alt="" />
        )
      )}
    </div>
  );
};

ListItemBackground.propTypes = {
  image: PropTypes.string,
  showPlaceholderImage: PropTypes.bool,
};

ListItemBackground.defaultProps = {
  image: null,
  showPlaceholderImage: false,
};

export default ListItemBackground;
