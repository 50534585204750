import React, { useRef, useCallback } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import {
  DateSelector,
  DateComparisonPeriod,
  DateChangeSwipe,
  MetricList,
  Page,
  useSetPageName,
  useSetRefreshMetrics,
  PAGE_NAME_FILM_ANALYSIS,
  TitleList,
} from '@modules';
import { URL_PATHS, useSortMetricsByOrder } from '@modules/Core';
import { BackToTop } from '@components';
import { useFilmPerspectiveMetrics } from '../hooks';

import './_film-analysis.scss';

const componentName = 'film-analysis';

/**
 * FilmAnalysis Page
 */
const FilmAnalysis = () => {
  const history = useHistory();
  const metrics = useFilmPerspectiveMetrics();

  useSetPageName(PAGE_NAME_FILM_ANALYSIS);
  useSetRefreshMetrics(metrics);

  const dateSelectorContainerRef = useRef(null);
  const dateSelectorRef = useRef(null);
  const onPageSwipeLeft = useCallback(() => dateSelectorRef.current.moveToNextSlide(), [dateSelectorRef]);
  const onPageSwipeRight = useCallback(() => dateSelectorRef.current.moveToPrevSlide(), [dateSelectorRef]);
  const checkIsInMinMaxRange = dateSelectorRef.current ? dateSelectorRef.current.checkIsInMinMaxRange : null;

  const [sortedMetrics, highlightedMetrics] = useSortMetricsByOrder(metrics);

  return (
    <Page className={componentName}>
      <div className={classnames(`${componentName}__date-selector-ctn`)} ref={dateSelectorContainerRef}>
        <DateSelector className={classnames(`${componentName}__date-selector`)} ref={dateSelectorRef} />
        <DateComparisonPeriod />
      </div>

      <MetricList
        pin
        metrics={metrics}
        monitorHeightRef={dateSelectorContainerRef}
        metricListContainerClassName={classnames(`${componentName}__metric-list-ctn`)}
      />

      <DateChangeSwipe
        className={classnames(`${componentName}__swipe-ctn`)}
        onSwipeLeft={onPageSwipeLeft}
        onSwipeRight={onPageSwipeRight}
        checkIsInMinMaxRange={checkIsInMinMaxRange}
      >
        <TitleList
          metrics={sortedMetrics}
          highlightedMetrics={highlightedMetrics}
          defaultSortMetric={metrics[0]}
          onSelectItem={(id) => history.push(`/${URL_PATHS.FILMS}/${id}/comps`)}
        />

        <BackToTop />
      </DateChangeSwipe>
    </Page>
  );
};

export default FilmAnalysis;
