import { useDispatch } from 'react-redux';

import { useParametersListener } from '@modules/FilterTypes';

import { getParamsForMetrics } from '../selectors';
import { metricRequest } from '../actions';
import { useLastRefresh } from '@modules/DataRefresh';
import { getTimeParams, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';

const useData = (metrics, additionalParams) => {
  const dispatch = useDispatch();
  const lastRefresh = useLastRefresh();
  const [subSectionPath, dataPath] = useAPIPath();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getParamsForMetrics,
    (params) => {
      if (metrics && metrics.length && params.territoryId && params.df) {
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        const excludeDeltas = isAdvancedSales;
        dispatch(metricRequest(metrics, { ...params, ...timeParams, ...additionalParams }, subSectionPath, dataPath, excludeDeltas));
      }
    },
    [metrics, additionalParams, lastRefresh, subSectionPath, dataPath, isAdvancedSales, isToday]
  );
};

export default useData;
