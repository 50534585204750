import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BASE_CLASS } from '../../constants';
import { Section, Text, SearchInputField } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { useSearch } from '@modules/Core';
import {
  getSelectedTerritoryCurrencyIsoCode,
  setDefaultTerritoryRequest,
  getNonCurrentTerritories,
} from '@modules/TerritorySelect';
import './_default-territory.scss';
import { getLocalCurrency } from '../../selectors';
import { useCurrency } from '@modules/FilterTypes/currency';

const componentClass = `${BASE_CLASS}-default-territory`;

const ContentDefaultTerritory = () => {
  const didMountRef = useRef(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [search, setSearch, matchesSearch] = useSearch('');
  const territories = useSelector(getNonCurrentTerritories);
  const isLocalCurrency = useSelector(getLocalCurrency);
  const [, setSelectedCurrency] = useCurrency();

  const selectedTerritoryCurrencyIsoCode = useSelector(getSelectedTerritoryCurrencyIsoCode);
  const setDefaultTerritory = useCallback(
    (id) => {
      dispatch(setDefaultTerritoryRequest(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (didMountRef.current) {
      isLocalCurrency && setSelectedCurrency(selectedTerritoryCurrencyIsoCode);
    } else {
      didMountRef.current = true;
    }
  }, [isLocalCurrency, selectedTerritoryCurrencyIsoCode, setSelectedCurrency]);

  const territoriesFiltered = useMemo(() => territories.filter(({ name }) => matchesSearch(name)), [
    territories,
    matchesSearch,
  ]);

  return (
    <Section.Container>
      <Text bold size={'s'} className={`${componentClass}__title`}>
        {formatMessage({ id: 'user_settings.all_territories' })}
      </Text>
      <SearchInputField
        className={`${componentClass}__search`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder={formatMessage({ id: 'user_settings.search_territories' })}
      />
      <div className={`${componentClass}__list`}>
        {territoriesFiltered.map(({ id, name }) => (
          <Text key={id} className={'item-territory'} onClick={() => setDefaultTerritory(id)}>
            {name}
          </Text>
        ))}
        {territories.length >= 1 && territoriesFiltered.length === 0 && search !== '' && (
          <Text className={`${componentClass}__list--no-data`}>
            <FormattedMessage id={'no_results_found'} />
          </Text>
        )}
        {territories.length === 0 && (
          <Text className={`${componentClass}__list--no-data`}>
            <FormattedMessage id={'no_data_available'} />
          </Text>
        )}
      </div>
    </Section.Container>
  );
};

export default ContentDefaultTerritory;
