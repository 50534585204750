import React from 'react';
import PropTypes from 'prop-types';
import * as classnames from 'classnames';
import './_spinner.scss';

/**
 * Spinner
 *
 * @param {string} - classNames
 * @param {string} size - Only allows XS, S and M
 * @param {boolean} defaultTheme - By this property we force this spinner to use default color instead of auto theme colours.
 * */
const Spinner = ({ className, size, defaultTheme }) => {
  return (
    <div className={classnames('loading--spinner', className)}>
      <div className={classnames('spinner', `spinner--size-${size}`, { ['spinner--default-theme']: defaultTheme })} />
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  defaultTheme: PropTypes.bool,
};

Spinner.defaultProps = {
  className: '',
  size: 'm',
  defaultTheme: false,
};

export default Spinner;
