import lzw from 'node-lzw';
import { pack, unpack } from 'jsonpack/main';
import { encode, decode } from 'url-safe-base64';

export const compressJSON = (obj) => {
  // Pack the json object to reduce its size using jsonpack
  // https://github.com/rgcl/jsonpack
  const packed = pack(obj);

  // node-lzw is a node implementation of the LZW compression algorithm
  // https://github.com/hormander/node-lzw
  const compressed = lzw.encode(packed);

  //We base64 encode the value as a first step of making the compressed value URL safe
  const base64Value = btoa(unescape(encodeURIComponent(compressed)));

  // Base64 can contain certain chars that URLs will parse differently (e.g. + sign);
  // using the small lib, url-safe-base64, we can make sure that this can be sent safely
  // https://github.com/commenthol/url-safe-base64
  const encoded = encode(base64Value);

  return encoded;
};

const decodeFilter = (str) =>
  decodeURIComponent(
    Array.prototype.map
      .call(atob(decodeURIComponent(str)), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

export const decompressJSON = (compressedData) => {
  // Catching the error so that the application doesnt catch if it fails
  try {
    const safeBase64Decode = decode(compressedData);

    const decoded = decodeFilter(safeBase64Decode);

    const uncompressed = lzw.decode(decoded);

    const unpacked = unpack(uncompressed);

    return unpacked;
  } catch (e) {
    console.error('error thrown decoding', e);
    return {};
  }
};
