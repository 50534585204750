import _property from 'lodash/property';
import _find from 'lodash/find';
import { createSelector } from '@reduxjs/toolkit';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { isLoading } from '@showtime/cea-dep-utility';

import { getSelected } from '@modules/FilterTypes/territory';

import { STATE_KEY, FETCH_KEY_SET_AS_DEFAULT_TERRITORY } from './constants';
import { fetchKeySetAsFavourite, fetchKeyRemoveAsFavourite } from './helpers';

const getTerritories = _property(STATE_KEY);

export const getTerritoriesList = createSelector([getTerritories], (territories) => {
  return Object.keys(territories).map((territoryId) => {
    return territories[territoryId];
  });
});

export const getSelectedTerritoryCurrencyIsoCode = createSelector(
  [getTerritoriesList, getSelected],
  (territoriesList, selectedTerritory) => {
    return _get(_find(territoriesList, { id: selectedTerritory }), 'currency', null);
  }
);

export const getNonFavouriteTerritores = createSelector([getTerritoriesList], (territories) =>
  _orderBy(
    territories.filter((territory) => !territory.favorite),
    ['default', 'name'],
    'desc'
  )
);

export const getFavouriteTerritores = createSelector([getTerritoriesList], (territories) =>
  _orderBy(
    territories.filter(({ favorite }) => favorite),
    'name'
  )
);

export const getCurrentTerritory = createSelector(
  [getTerritoriesList],
  (territories) => territories.find((territory) => territory.default) || {}
);

export const getNonCurrentTerritories = createSelector([getTerritoriesList], (territories) =>
  territories.filter((territory) => !territory.default)
);

export const getSelectedTerritory = createSelector([getTerritories, getSelected], (list, selected) => {
  return list[selected] || {};
});

export const getUnavailableMetrics = createSelector([getSelectedTerritory], (selected) => {
  return _get(selected, 'unavailableMetrics', []);
});

export const getUnavailableMarketMetrics = createSelector([getSelectedTerritory], (selected) => {
  return _get(selected, 'unavailableMarketMetrics', []);
});

export const isSettingFavourite = (id) => (state) =>
  isLoading(state, fetchKeySetAsFavourite(id)) || isLoading(state, fetchKeyRemoveAsFavourite(id));

export const isSettingDefaultTerritory = (state) => isLoading(state, FETCH_KEY_SET_AS_DEFAULT_TERRITORY);

export const getStartOfCinemaWeekForSelectedTerritory = createSelector(
  [getSelectedTerritory],
  _property('startOfCinemaWeek')
);
