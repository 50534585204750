import { useEffect, useState } from 'react';

import useResizeObserver from './useResizeObserver';
/**
 * useResizeWidthChanged
 * @param {RefObject} ref
 * @param {func} callback returns DOMRect ReadOnly
 * Listens to width changes only of given elementRef.
 * Will fire callback when width only changes.
 * 
 * Examples of use:
 * 
 * useResizeWidthChanged(elementRed, callbackFunc); - specific element ref passed
 *
 * useResizeWidthChanged(null, callbackFunc); - no ref passed - will default to the body element
 */
export default function useResizeWidthChanged(ref, callback) {
  const [currentWidth, setCurrentWidth] = useState();

  const [width] = useResizeObserver(ref);

  useEffect(() => {
    if (callback && currentWidth !== width) {
      callback(width);
      setCurrentWidth(width);
    }
  }, [width, callback, currentWidth]);
}
