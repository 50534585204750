import { useCompsTitles } from '@modules/FilterTypes/compsTitles';
import { usePrevious } from '@modules/Core';
import { useEffect } from 'react';
import { useAction } from '@modules/Core';
import { removeCompsItem } from '../actions';

/**
 * Removes comps events that are no longer selected instantly
 */
export const useCompsEventsRemover = () => {
  const [compsTitles] = useCompsTitles();
  const previousComps = usePrevious(compsTitles);
  const removeItem = useAction(removeCompsItem);

  useEffect(() => {
    const compsFiltersIds = compsTitles.map(({ id }) => id);
    if (previousComps && previousComps.length > compsFiltersIds) {
      previousComps.forEach(({ id }) => {
        if (!compsFiltersIds.includes(id)) {
          removeItem(id);
        }
      });
    }
  }, [compsTitles, previousComps, removeItem]);
};
