import { useCallback, useState } from 'react';
import { useCompsTitles } from '@modules/FilterTypes/compsTitles';
import { useAction } from '@modules/Core';

import { setSelectedComps } from '../actions';
import { useSearchState } from './useSearch';
import { useSelected } from './useSelected';

export const useDrawerSession = () => {
  const [selectedCompsFilters, setSelectedCompsFilters] = useCompsTitles();
  const setSelectedCompsAction = useAction(setSelectedComps);
  const { selectedComps } = useSelected();
  const [cachedVersion, setCachedVersion] = useState(selectedComps);
  const [, setSearchValue] = useSearchState();

  const initSession = useCallback(() => {
    setSelectedCompsAction(selectedCompsFilters);
    setCachedVersion(selectedCompsFilters);
  }, [setSelectedCompsAction, setCachedVersion, selectedCompsFilters]);

  const commitToStore = useCallback(() => {
    setSearchValue('');
    setSelectedCompsFilters(selectedComps);
    setCachedVersion([]);
  }, [setSelectedCompsFilters, selectedComps, setSearchValue]);

  const restoreToOriginal = useCallback(() => {
    setSelectedCompsAction(cachedVersion);
    setSearchValue('');
  }, [setSelectedCompsAction, cachedVersion, setSearchValue]);

  return { initSession, restoreToOriginal, commitToStore };
};
