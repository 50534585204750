import { KEY } from './constants';
import { createFilterReducer } from '@modules/Filters';

const initialState = {
  selected: null,
};

const territoryReducer = createFilterReducer(KEY, initialState);

export default {
  [KEY]: territoryReducer,
};
