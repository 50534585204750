import { useState, useMemo, useCallback, useEffect } from 'react';
import _isFunction from 'lodash/isFunction';

export const usePaginationApi = (propValue = 1, onCurrentChange, max) => {
  const [current, setCurrentState] = useState(propValue);

  const canGoBack = useMemo(() => current - 1 > 0, [current]);
  const canGoForward = useMemo(() => current + 1 <= max, [current, max]);

  const setCurrent = useCallback(
    (newCurrent) => {
      if (newCurrent > 0 && newCurrent <= max) {
        setCurrentState(newCurrent);
      }
    },
    [max]
  );

  const next = useCallback(() => {
    if (canGoForward) {
      setCurrent(current + 1);
    }
  }, [canGoForward, current, setCurrent]);

  const previous = useCallback(() => {
    if (canGoBack) {
      setCurrent(current - 1);
    }
  }, [canGoBack, current, setCurrent]);

  useEffect(() => {
    if (_isFunction(onCurrentChange)) {
      onCurrentChange(current);
    }
  }, [current]);

  useEffect(() => {
    if (propValue !== current) {
      setCurrent(propValue);
    }
  }, [propValue]);

  return {
    current,
    canGoBack,
    setCurrent,
    canGoForward,
    next,
    previous,
  };
};
