import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelected } from './selectors';
import { setSelected as setSelectedAction } from './actions';

export function useOccupancy() {
  const dispatch = useDispatch();
  const selected = useSelector(getSelected);

  const setSelected = useCallback((values) => {
    dispatch(setSelectedAction(values));
  }, []);

  return [selected, setSelected];
}

export function useResetOccupancy() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(setSelectedAction());
  }, []);
}
