import React from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '@components';
import UserSettingsMain from './UserSettingsMain';
import { BASE_CLASS } from './../constants';
import './_user-settings.scss';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const defaultProps = {
  isOpen: false,
  onClose: () => {},
};

const UserSettings = ({ isOpen, onClose }) => {
  return (
    <Overlay.Provider type={'full'} isOpen={isOpen} onClose={onClose} className={`${BASE_CLASS}-overlay`}>
      <UserSettingsMain />
    </Overlay.Provider>
  );
};

UserSettings.propTypes = propTypes;
UserSettings.defaultProps = defaultProps;

export default UserSettings;
