export default {
  apply: 'Apply',
  cancel: 'Cancel',
  capacity: 'Capacity',
  clear: 'Clear',
  copy_link: 'Copy Link',
  custom: 'Custom',
  download: 'Download',
  delete: 'Delete',
  films: 'Films',
  format: 'Format',
  link_copied: 'Link copied to clipboard',
  occupancy: 'Occupancy',
  share: 'Share',
  screen: 'Screen',
  save: 'Save',
  select_all: 'Select All',
  settings: 'Settings',
  search: 'Search',
  site: 'Site',
  theatres: 'Theatres',
  time: 'Time',
  title: 'Title',
  today: 'Today',
  tomorrow: 'Tomorrow',
  yesterday: 'Yesterday',
  next: 'Next',
  previous: 'Previous',

  // Sidebar
  logout: 'Log out',
  support: 'Help and Support',
  territories: 'Territories',

  // User Settings
  'user_settings.settings': 'Settings',
  'user_settings.password': 'Password',
  'user_settings.language': 'Language',
  'user_settings.currency': 'Currency',
  'user_settings.time-format': 'Time Format',
  'user_settings.title-preference': 'Title Preference',
  'user_settings.24-Hour-time': '24-Hour Time',
  'user_settings.default-territory': 'Default Territory',
  'user_settings.error_update': 'An error occurred while updating settings, please refresh the page and try again.',
  'user_settings.current': 'Current',
  'user_settings.all_territories': 'All Territories',
  'user_settings.search_territories': 'Search territories',
  'user_settings.selected_territory': 'Selected territory',
  'user_settings.favourite_territories': 'Favourite territories',
  'user_settings.territories': 'Territories',

  // Formatters
  format_currency: '%s%v',
  format_decimal: '.',
  format_thousand: ',',
  format_abbr_thousand: 'k',
  format_abbr_million: 'm',
  format_abbr_billion: 'b',
  format_abbr_trillion: 't',
  filmAnalysis: 'Film Analysis',
  theatreAnalysis: 'Theatre Analysis',
  marketAnalysis: 'Market Analysis',
  advancedSales: 'Advanced Sales',

  // Site Attributes
  'site_attribute_group.branch': 'Branch',
  'site_attribute_group.circuit': 'Circuit',
  'site_attribute_group.dma:': 'DMA',
  'site_attribute_group.state': 'State',
  'site_attribute_group.site': 'Site',
  'site_attribute_group.zone': 'Zone',

  // Sort by Selector
  sortBySelector_button: 'Sort By',
  sortBySelector_heading: 'Sort by',
  sortBySelector_metric_high_low: 'Metric High to Low',
  sortBySelector_metric_low_high: 'Metric Low to High',

  siteAttrSelector_heading: 'Site Attribution',
  shareDrawer_heading: 'Share this view',
  compsSelectionDrawer_heading: 'Add Comp Titles',

  all_titles: 'All Titles',
  selected_titles: 'Selected Titles',
  suggested_titles: 'Suggested Titles',

  search_films: 'Search films',
  search_theatres: 'Search theatres',

  market_share: 'Market Share',

  // Metric dictionary
  'metric.admissions': 'Admissions',
  'metric.eventRevenue': 'Ticket Revenue',
  'metric.eventRevenueBO': 'Box Office',
  'metric.distributorReportedPrice': 'Box Office',
  'metric.occupancy': 'Occupancy',
  'metric.eventSph': 'ATP',
  'metric.capacity': 'Capacity Remaining',
  'metric.yearMarketShare': '52 Week Market Share',

  // Sites filter overlay
  'sites_filter.search': 'Search sites',
  'sites_filter.page1.title': 'Filter by Sites',
  'sites_filter.page1.go_to.page2': 'Site Attribution Grouping',
  'sites_filter.page2.title': 'Choose Site Grouping',
  'sites_filter.page3.title': 'Choose Site - {attribute}',
  'sites_filter.page3.title.min': 'Choose Site',
  'sites_filter.alert':
    'Filtering by Sites or Attributes will remove Non Sharing sites from the displayed metric totals.',
  'sites_filter.search_placeholder': 'Use this search to find a site',

  capacityFormat: '{capacityRemaining}({capacity})',

  // DOW
  dow_monday: 'Monday',
  dow_monday_short: 'Mon',
  dow_tuesday: 'Tuesday',
  dow_tuesday_short: 'Tue',
  dow_wednesday: 'Wednesday',
  dow_wednesday_short: 'Wed',
  dow_thursday: 'Thursday',
  dow_thursday_short: 'Thu',
  dow_friday: 'Friday',
  dow_friday_short: 'Fri',
  dow_saturday: 'Saturday',
  dow_saturday_short: 'Sat',
  dow_sunday: 'Sunday',
  dow_sunday_short: 'Sun',
  dow_weekdays: 'Weekdays',
  dow_weekends: 'Weekends',

  //MONTH
  month_january: 'January',
  month_january_short: 'Jan',
  month_february: 'February',
  month_february_short: 'Feb',
  month_march: 'March',
  month_march_short: 'Mar',
  month_april: 'April',
  month_april_short: 'Apr',
  month_may: 'May',
  month_may_short: 'May',
  month_june: 'June',
  month_june_short: 'Jun',
  month_july: 'July',
  month_july_short: 'Jul',
  month_august: 'August',
  month_august_short: 'Aug',
  month_september: 'September',
  month_september_short: 'Sep',
  month_october: 'October',
  month_october_short: 'Oct',
  month_november: 'November',
  month_november_short: 'Nov',
  month_december: 'December',
  month_december_short: 'Dec',

  //RELATIVE TIME
  future: 'in %s',
  past: '%s ago',
  s: 'a few seconds',
  ss: '%d seconds',
  m: 'a minute',
  mm: '%d minutes',
  h: 'an hour',
  hh: '%d hours',
  d: 'a day',
  dd: '%d days',
  w: 'a week',
  ww: '%d weeks',
  M: 'a month',
  MM: '%d months',
  y: 'a year',
  yy: '%d years',

  vs_x: 'vs {x}',
  vs_x_until_y: 'vs {x} (Until {y})',

  titlesList_run_week: 'Run Week {num}',

  no_results_found: 'No results found',
  no_data_available: 'No data available',

  performance_data_not_available: 'Performance data not available',

  // Auth pages
  // common
  email: 'Email',
  password: 'Password',

  // login
  login: 'Login',
  success: 'Success!',
  incorrect_password: 'Incorrect Password',

  // login
  invalid_email: 'Invalid Email',
  required_field: 'Required field',
  email_required: 'Email required',

  login_error: 'The email or password you have entered is incorrect. Please try again.',
  login_unexpected_error: 'Unexpected error, please try again',

  // forgot - send email
  forgot_password: 'Forgot password?',
  reset_success: 'Your password is updated. You can use your new password to login.',

  // reset password
  change_password: 'Change Password',
  reset_your_password: 'Reset your password',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  confirm_password_subtext: 'Your password must contain 8 or more characters, at least 1 letter, 1 number and 1 symbol',
  reset_password: 'Reset Password',
  error_match_passwords: 'The passwords you have entered do not match',
  error_incorrect_password: 'The password you have entered is incorrect. Please try again.',
  error_incorrect_password_type:
    'The password you have entered does not contain 8 or more characters, at least 1 letter, 1 number and 1 symbol',
  forgot_password_title: 'Forgot your password?',
  forgot_password_subtext: 'Enter your email address below and we’ll send you password reset instructions.',
  send_email: 'Send Email',
  back_sign_in: 'Back to sign in',
  restart_invalid_token: 'Your password reset link has expired. Please try again.',
  password_updated: 'Password Updated',

  // forgot - sent email success
  forgot_password_success_p1: 'We’ve sent an email to {userEmail} with password reset instructions.',
  forgot_password_success_p2:
    'Note: If you don’t receive an email soon, please check your “Spam” folder as sometimes it gets filtered as spam!',
  forgot_password_success_p3:
    'If it’s not in your spam folder, please contact your System Administrator, who can assist with resetting your password.',
  forgot_password_email_error: 'Could not find user with that email',

  // Errors - common
  server_error: 'An error occurred. Please try again later.',

  same_day: 'Same Day',
  day_n: 'Day {n}',
  comps_your_data: 'Your data',
  comps_market_data: 'Market Data',
  comps_real_time_data: 'Real Time Data',
  comps_day_n_totals: 'Day {n} - Totals',
  comps_opening_day: 'Opening Day',
  comps_opening_weekend: 'Opening Weekend',
  comps_opening_total_run: 'Total Run',
  comps_no_market_data: '(No Market Data)',

  comps_search_placeholder: 'Use this search to find a specific title to compare against',
  comps_no_selected_titles: 'No titles selected',
  comps_no_suggested_titles: 'No suggested titles',

  comps_total_market_data: 'Total Market Data',
  comps_circuit_share: '({n} Circuit Share)',
  comps_market_data_not_tracked: 'Market Data Not Yet Tracked',

  // No access page
  no_access_title: 'Page cannot be viewed',
  no_access_subtext: 'This application is only available on mobile devices',

  theatre_metadata_view_films: 'Films',
  theatre_metadata_view_screen: 'Screen Planner',
  film_metadata_view_comps: 'Comps',
  film_metadata_view_theatres: 'Theatres',
  film_metadata_view_circuits: 'Circuits',

  report_indicator_last_updated: 'Last updated {time} ago',
  report_indicator_sites_reported: '{num} site(s) reported',
  report_indicator_sites_reported_yesterday: '{num} site(s) reported yesterday',
  report_indicator_sites_reporting: '{num} site(s) reporting',
  report_indicator_sites_reporting_real_time: '{num} site(s) Real time reporting',
  report_indicator_search_placeholder: 'Search for Circuit',
  report_indicator_market_report_title: 'Market Reporting - {today}',
  report_indicator_th_data_feed: 'Data Feed',
  report_indicator_th_circuit: 'Circuit',
  report_indicator_th_sites_reported: 'Sites Reported',
  report_indicator_th_sites_reported_yesterday: 'Sites Reported Yesterday',
  report_indicator_th_sites_reporting: 'Sites Reporting',
  // performance modal - metrics
  'performanceModal.admissions': 'Admissions',
  'performanceModal.eventRevenue': 'Ticket Revenue',
  'performanceModal.occupancy': 'Occupancy',
  'performanceModal.eventSph': 'Average Ticket Price',
  'performanceModal.capacity': 'Capacity Remaining',

  // Bookmarks
  bookmarks: 'Bookmarks',
  'bookmarks.header.save': 'Save Your Bookmark',
  'bookmarks.create': 'Create new bookmark',
  'bookmarks.form.name': 'Bookmark Name',
  'bookmarks.form.privacy': 'Privacy',
  'bookmarks.form.private': 'Available only to you',
  'bookmarks.form.shared': 'Share with your organisation',
  'bookmarks.form.create': 'Create bookmark',
  'bookmarks.shared': 'Share Bookmarks',
  'bookmarks.privacy': 'Private Bookmarks',
  'bookmarks.createdBy': 'Created by',
  'bookmarks.confirm.title': 'Delete Bookmark',
  'bookmarks.confirm.sub-title': 'This cannot be undone',
  'bookmark.error.name_required': 'A name is required for your bookmark',
  'bookmark.error.name_duplication': 'A bookmark with this name exists',
  'bookmark.error.name_too_long': 'Bookmark name is too long (Maximum {maxChars} characters)',
  'bookmark.error.unexpected': 'Something unexpected happened, please try again',

  runtime: '{num}mins',
  runday: 'Day {num}',

  // Languages
  en: 'English (English)',
  es: 'Spanish (Español)',
  de: 'German (Deutsch)',
  pt: 'Portuguese (Português)',

  // Titles
  'title_preference.us_title': 'US Title',
  'title_preference.local_title': 'Local Title',

  // Currency settings
  'user_currency_preference.local': 'Local Currency',
  'user_currency_preference.default': 'US Dollar ($)',

  market_data_not_available: 'Market Data is not yet available, please check back later',
  advanced_sales_data_not_available: 'Advanced Sales data is not yet available, please check back later',
  presales_data_integration: 'For PreSales data integration, please contact your Comscore representative',
  circuits_view_not_available_real_time: 'Circuits view is not available for Real Time Data',
  circuits_view_not_available_advanced_sales: 'Circuits view is not available for Advanced Sales Data',

  app_error_heading: 'Oops! Something went wrong.',
  app_error_subtext: 'Please refresh the page and try again.',
};
