import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import _findIndex from 'lodash/findIndex';

import { deselectCompTitle, selectCompTitle } from '../actions';
import { getSelectedComps } from '../selectors';

export const useSelected = () => {
  const dispatch = useDispatch();
  const selectedComps = useSelector(getSelectedComps);

  const selectComp = useCallback(
    (comp) => {
      dispatch(selectCompTitle(comp));
    },
    [dispatch]
  );

  const deselectComp = useCallback(
    (comp) => {
      dispatch(deselectCompTitle(comp));
    },
    [dispatch]
  );

  const isCompSelected = useCallback(
    (comp) => {
      return _findIndex(selectedComps, { id: comp.id }) > -1;
    },
    [selectedComps]
  );

  return { selectedComps, selectComp, deselectComp, isCompSelected };
};
