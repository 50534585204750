import React from 'react';
import { useIntl } from 'react-intl';

import { Icon } from '@components';

const componentClass = 'empty-state';

export default function NoPresalesIntegration() {
  const { formatMessage } = useIntl();
  const message = formatMessage({ id: 'presales_data_integration' });

  return (
    <div className={`${componentClass} ${componentClass}--no-presales`}>
      <Icon icon="contact" />
      <div className={`${componentClass}__text`}>{message}</div>
    </div>
  );
}
