import moment from 'moment';
import { getCinemaStartHour } from '@modules/Core';

import { DATE_FORMAT, TIME_FORMAT } from '../constants';

export const addCinemaStartHour = (time) => {
  const cinemaStartHour = getCinemaStartHour();

  return moment(time, TIME_FORMAT).add(cinemaStartHour, 'h').format(TIME_FORMAT);
};

export const buildMomentTime = (date, time) => {
  return moment(`${date}${time}`, `${DATE_FORMAT}${TIME_FORMAT}`);
};

export const getDateTimeValue = (time, date) => {
  const cinemaStartHour = getCinemaStartHour();

  return buildMomentTime(date, time).add(cinemaStartHour, 'h').toDate();
};
