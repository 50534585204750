import { KEY as DATES_KEY } from '@modules/FilterTypes/dates';
import { KEY as METRIC_KEY } from '@modules/FilterTypes/metric';
import { KEY as SORT_KEY, KEY_METRIC as SORT_KEY_METRIC } from '@modules/FilterTypes/sort';

export const BASE_CLASS = 'bookmarks';

export const STATE_KEY = 'bookmarks';

export const PRIVATE_KEY = 'private';

export const SHARED_KEY = 'shared';

export const BOOKMARKS_PAGES = {
  MAIN: 'main',
  FORM: 'form',
};

export const BOOKMARK_PRIVACY = {
  private: PRIVATE_KEY,
  shared: SHARED_KEY,
};

export const TABS_OPTIONS = [
  { key: PRIVATE_KEY, label: 'bookmarks.privacy' },
  { key: SHARED_KEY, label: 'bookmarks.shared' },
];

export const BOOKMARKS_FETCH_KEY = `${STATE_KEY}.fetchData`;
export const BOOKMARKS_FETCH_KEY_LAZY = `${BOOKMARKS_FETCH_KEY}LazyLoad`;
export const BOOKMARKS_FETCH_UPDATE_KEY = `${BOOKMARKS_FETCH_KEY}Update`;
export const BOOKMARKS_FETCH_DELETE_KEY = `${BOOKMARKS_FETCH_KEY}Delete`;

export const MIN_NAME_FIELD_CHARS = 1;
export const MAX_NAME_FIELD_CHARS = 30;

export const FIRST_PAGE_FETCH = 1;
export const LIMIT_SIZE_FETCH = 20;

export const mockedBookmarks = [
  { id: 1, name: 'Testing Swipe', createdBy: 'Ivan Sanchez' },
  { id: 2, name: 'Testing Swipe 2', createdBy: 'Ivan Sanchez' },
];

export const EXCLUDED_FILTERS = [DATES_KEY, METRIC_KEY];

export const EXCLUDED_FILTER_KEYS = {
  [SORT_KEY]: [SORT_KEY_METRIC],
};
