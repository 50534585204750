import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _flatMap from 'lodash/flatMap';

import { Button, BackToTop } from '@components';
import { useSiteAttributeGroups } from '@modules/SiteAttributeGroups';
import { useOccupancy } from '@modules/FilterTypes/occupancy';
import {
  useSetPageName,
  useSetRefreshMetrics,
  PAGE_NAME_THEATRE_METADATA_BY_ATTRIBUTE,
  DateSelector,
  MetricList,
  TheatreList,
  Page,
  useTheatreMetadata,
  DateComparisonPeriod,
  DateChangeSwipe,
} from '@modules';

import { useTheatrePerspectiveMetrics } from '../hooks';
import { PAGE_PATH, DEFAULT_PAGE_SCREEN_PATH } from '../constants';
import { useActionCtnAnimation } from './hooks';

import './_theatre-analysis.scss';

const componentName = 'theatre-analysis';

/**
 * TheatreAnalysisGrouping
 * Intermediate Extra Flow step where user has selected a site attribution Group
 * This page will render sites within seleded site attribution group.
 * On click of site will go to normal theatre/films page.
 */
const TheatreAnalysisGrouping = ({ match }) => {
  const siteAttributeGroupId = parseInt(match.params.id);

  const history = useHistory();
  const [, setOccupancy] = useOccupancy();
  const { setPreviousUrl } = useTheatreMetadata();
  const [groups, isLoading] = useSiteAttributeGroups();
  const metrics = useTheatrePerspectiveMetrics();
  const [actionsContainerRefBinder] = useActionCtnAnimation();

  useSetPageName(PAGE_NAME_THEATRE_METADATA_BY_ATTRIBUTE);
  useSetRefreshMetrics(metrics);

  const attrGroupTitle = useMemo(() => {
    let title = '';

    if (!_isEmpty(groups)) {
      const attributes = _flatMap(groups, ({ attributes }) => attributes);
      const attrGroupData = _find(attributes, ['id', siteAttributeGroupId]);

      title = attrGroupData ? attrGroupData.name : ' - ';
    }

    return title;
  }, [groups, siteAttributeGroupId]);

  /**
   * Set Previous URL so theatres/40478/films pages know to come back to here if backArrow is selected.
   * Otherwise it should default to /theatres page
   */
  useEffect(() => {
    if (match && match.url) {
      setPreviousUrl(match.url);
    }
  }, [setPreviousUrl, match]);

  useEffect(() => {
    return () => {
      // Clearing within unMount so Browser Back button can avail of the clearing too.
      setOccupancy([]);
    };
  }, [setOccupancy]);

  const goToPreviousPage = useCallback(() => {
    setPreviousUrl(null);
    history.push('/theatres');
  }, [history, setPreviousUrl]);

  const onSelectItem = useCallback(
    (id) => {
      history.push(`/${PAGE_PATH}/${id}/${DEFAULT_PAGE_SCREEN_PATH}`);
    },
    [history]
  );

  const dateSelectorRef = useRef(null);
  const onPageSwipeLeft = useCallback(() => dateSelectorRef.current.moveToNextSlide(), [dateSelectorRef]);
  const onPageSwipeRight = useCallback(() => dateSelectorRef.current.moveToPrevSlide(), [dateSelectorRef]);
  const checkIsInMinMaxRange = dateSelectorRef.current ? dateSelectorRef.current.checkIsInMinMaxRange : null;

  return (
    <Page className={componentName}>
      <div className={classnames(`${componentName}__actions-ctn`)} ref={actionsContainerRefBinder}>
        <DateSelector className={classnames(`${componentName}__date-selector`)} ref={dateSelectorRef} />
        <DateComparisonPeriod />
        <Page.BackNavHeader
          className="theatre-analysis__nav-header"
          title={isLoading ? '-' : attrGroupTitle}
          backArrow={<Button className={`${componentName}__back`} icon="back" onClick={goToPreviousPage} />}
        />
        <MetricList
          metrics={metrics}
          selectable
          params={{
            siteGroupAttributeId: [siteAttributeGroupId],
          }}
        />
      </div>
      <DateChangeSwipe
        className={classnames(`${componentName}__swipe-ctn`)}
        onSwipeLeft={onPageSwipeLeft}
        onSwipeRight={onPageSwipeRight}
        checkIsInMinMaxRange={checkIsInMinMaxRange}
      >
        <TheatreList
          className={classnames(`${componentName}__site-list`)}
          onSelectItem={onSelectItem}
          params={{
            siteGroupAttributeId: [siteAttributeGroupId],
          }}
          ignoreSelectedAttributeGroup
        />
        <BackToTop />
      </DateChangeSwipe>
    </Page>
  );
};

export default TheatreAnalysisGrouping;
