import { createSelector } from '@reduxjs/toolkit';
import _property from 'lodash/property';
import _find from 'lodash/find';

import { isLoading } from '@showtime/cea-dep-utility';

import { calculateCapacityRemaining } from '@modules/Core';
import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { KEY as sort } from '@modules/FilterTypes/sort';
import { KEY as occupancy } from '@modules/FilterTypes/occupancy';
import { KEY as metric, getSelected as getSelectedMetric } from '@modules/FilterTypes/metric';
import { getParametersFactory } from '@modules/FilterTypes';

import { STATE_KEY, SITE_LIST_FETCH_KEY, SITE_LAZY_LOAD_FETCH_KEY, SITE_FORMATS_FETCH_KEY } from './constants';

export const getTheatreState = _property(STATE_KEY);

export const getTheatreList = createSelector([getTheatreState], _property('theatres'));

export const getIsMaxPagesReached = createSelector([getTheatreState], _property('maxPagesReached'));

export const getTheatreListFormatData = createSelector([getTheatreState], _property('formatData'));

export const getPaginationState = createSelector([getTheatreState], _property('pagination'));

export const getParametersForTheatresList = getParametersFactory([
  dates,
  territory,
  currency,
  sites,
  metric,
  sort,
  occupancy,
]);

export const getParametersForTheatresFormats = getParametersFactory([dates, territory, currency, sites, metric]);

export const isLoadingTheatreList = (state) => isLoading(state, SITE_LIST_FETCH_KEY);

export const isLazyLoadingTheatreList = (state) => isLoading(state, SITE_LAZY_LOAD_FETCH_KEY);

const getTheatreById = (id) =>
  createSelector([getTheatreList], (list) => {
    return _find(list, { id: id }) || {};
  });

export const getValueByTheatreID = (id, metric = null) =>
  createSelector([getTheatreById(id), getSelectedMetric], (theatre, selectedMetric) => metric ? theatre[metric] : theatre[selectedMetric]);

export const getMovementValueByTheatreID = (id) =>
  createSelector([getTheatreById(id), getSelectedMetric], (theatre, metric) => theatre[`${metric}Movement`]);

export const getCapacityRemainingByTheatreID = (id) =>
  createSelector([getTheatreById(id), getSelectedMetric], (theatre, metric) => {
    return calculateCapacityRemaining(theatre['admissions'], theatre[metric]);
  });

export const getFormatDataByTheatreID = (id) => createSelector([getTheatreListFormatData], (list) => list[id]);

export const isLoadingListItemFormats = (siteId) => (state) => isLoading(state, `${SITE_FORMATS_FETCH_KEY}-${siteId}`);

export const getCurrentPage = createSelector([getPaginationState], _property('current'));
export const getNumPages = createSelector([getPaginationState], _property('numPages'));
export const hasPages = createSelector([getNumPages], (numPages) => numPages > 1);
