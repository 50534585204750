import React, { createContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  canItemAutoCollapse: false,
  currentAccordianIncrement: 0,
  updateAccordianIncrement: () => {},
};

const AccordianContext = createContext(initialState);

/**
 * Manages all the logic for Accordian Items state.
 *
 * @param {boolean} isOpenProp isOpen prop passed into component
 * @param {func} remainOpenProp callback called when drawer has opened
 */
const useAccordianApi = ({ autoCollapseItems }) => {
  const [currentAccordianIncrement, setCurrentAccordianIncrement] = useState(0);

  /**
   * updateAccordianIncrement
   * If autoCollapseItems is enabled:
   * When an accordian item is opened this will fire to increase the increment.
   * Accordian Items are watching for increment changes and will close their contents if it changes.
   */
  const updateAccordianIncrement = useCallback(
    (incrementedValue) => {
      if (autoCollapseItems) {
        setCurrentAccordianIncrement(incrementedValue);
      }
    },
    [setCurrentAccordianIncrement, currentAccordianIncrement, autoCollapseItems]
  );

  return {
    canItemAutoCollapse: autoCollapseItems,
    currentAccordianIncrement,
    updateAccordianIncrement,
  };
};

/**
 * AccordianProvider
 * @param {auto} autoCollapseItems - If enabled onOpen of one accordian item will close all other items.
 */
function AccordianProvider({ children, autoCollapseItems }) {
  const { currentAccordianIncrement, updateAccordianIncrement, canItemAutoCollapse } = useAccordianApi({ autoCollapseItems });

  return (
    <AccordianContext.Provider value={{ currentAccordianIncrement, updateAccordianIncrement, canItemAutoCollapse }}>
      {children}
    </AccordianContext.Provider>
  );
}

AccordianProvider.propTypes = {
  children: PropTypes.node.isRequired,
  autoCollapseItems: PropTypes.bool.isRequired,
};

export { AccordianContext, AccordianProvider };
