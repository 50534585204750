const es = {
  "filmAnalysis": "Análisis de películas",
  "apply": "Aplicar",
  "cancel": "Cancelar",
  "capacity": "Capacidad",
  "clear": "Borrar",
  "copy_link": "Copiar enlace",
  "custom": "Personalizar",
  "download": "Descargar",
  "films": "Películas",
  "format": "Formato",
  "link_copied": "Enlace copiado al portapapeles",
  "occupancy": "Ocupación",
  "screen": "Pantalla",
  "select_all": "Seleccionar todos",
  "site": "Cine",
  "theatres": "Cines",
  "time": "Hora",
  "title": "Título",
  "today": "Hoy",
  "tomorrow": "Mañana",
  "yesterday": "Ayer",
  "next": "Siguiente",
  "previous": "Anterior",
  "tmdb_credits": "Este producto utiliza la API de TMDb pero no está respaldado ni certificado por TMDb.",
  "logout": "Cerrar sesión",
  "territories": "Territorios",
  "format_currency": "%s%v",
  "format_decimal": ".",
  "format_thousand": ",",
  "format_abbr_thousand": "k",
  "format_abbr_million": "m",
  "format_abbr_billion": "b",
  "format_abbr_trillion": "t",
  "theatreAnalysis": "Análisis de Cine",
  "sortBySelector_button": "Ordenar por",
  "sortBySelector_heading": "Ordenar por",
  "sortBySelector_metric_high_low": "Métricas de mayor a menor",
  "sortBySelector_metric_low_high": "Métricas de menor a mayor",
  "siteAttrSelector_heading": "Atributos de Cine",
  "shareDrawer_heading": "Compartir esta vista",
  "compsSelectionDrawer_heading": "Añadir Títulos a Comparar",
  "all_titles": "Todos los títulos",
  "selected_titles": "Títulos seleccionados",
  "suggested_titles": "Títulos sugeridos",
  "search_films": "Buscar película",
  "search_theatres": "Buscar Cines",
  "metric.admissions": "Asistencia",
  "metric.eventRevenue": "Venta de boletos",
  "metric.eventRevenueBO": "Ingresos",
  "metric.distributorReportedPrice": "Ingresos",
  "metric.occupancy": "Ocupación",
  "metric.eventSph": "PPB",
  "metric.capacity": "Capacidad Restante",
  "metric.yearMarketShare": "Participación de mercado en las últimas 52 semanas",
  "sites_filter.search": "Buscar Cines",
  "sites_filter.page1.title": "Filtrar por cine",
  "sites_filter.page1.go_to.page2": "Agrupar por atributos de Cines",
  "sites_filter.page2.title": "Elegir agrupación de cines",
  "sites_filter.page3.title": "Seleccionar cine - {attribute}",
  "sites_filter.page3.title.min": "Elegir cine",
  "sites_filter.alert": "El filtrado por cine o atributos eliminará los cines que no comparten los totales de las métrica mostradas.",
  "sites_filter.search_placeholder": "Usa este buscador para encontrar un cine",
  "capacityFormat": "{capacityRemaining}({capacity})",
  "dow_monday": "Lunes",
  "dow_monday_short": "Lun",
  "dow_tuesday": "Martes",
  "dow_tuesday_short": "Mar",
  "dow_wednesday": "Miércoles",
  "dow_wednesday_short": "Mié",
  "dow_thursday": "Jueves",
  "dow_thursday_short": "Jue",
  "dow_friday": "Viernes",
  "dow_friday_short": "Vie",
  "dow_saturday": "Sábado",
  "dow_saturday_short": "Sáb",
  "dow_sunday": "Domingo",
  "dow_sunday_short": "Dom",
  "dow_weekdays": "Días de la Semana",
  "dow_weekends": "Fin de semana",
  "month_january": "Enero",
  "month_january_short": "Ene",
  "month_february": "Febrero",
  "month_february_short": "Feb",
  "month_march": "Marzo",
  "month_march_short": "Mar",
  "month_april": "Abril",
  "month_april_short": "Abr",
  "month_may": "Mayo",
  "month_may_short": "May",
  "month_june": "Junio",
  "month_june_short": "Jun",
  "month_july": "Julio",
  "month_july_short": "Jul",
  "month_august": "Agosto",
  "month_august_short": "Ago",
  "month_september": "Septiembre",
  "month_september_short": "Sep",
  "month_october": "Octubre",
  "month_october_short": "Oct",
  "month_november": "Noviembre",
  "month_november_short": "Nov",
  "month_december": "Diciembre",
  "month_december_short": "Dic",
  "future": "en %s",
  "past": "%s atrás",
  "s": "hace unos segundos",
  "ss": "%d segundos",
  "m": "un minuto",
  "mm": "%d minutos",
  "h": "por hora",
  "hh": "%d horas",
  "d": "por día",
  "dd": "%d días",
  "w": "por semana",
  "ww": "%d semanas",
  "M": "por mes",
  "MM": "%d meses",
  "y": "por año",
  "yy": "%d años",
  "vs_x": "contra {x}",
  "vs_x_until_y": "contra {x} (Hasta {y})",
  "titlesList_run_week": "Semana actual {num}",
  "no_results_found": "No se encontraron resultados",
  "no_data_available": "No hay datos disponibles",
  "performance_data_not_available": "Datos de rendimiento no disponibles",
  "email": "Correo electrónico",
  "password": "Contraseña",
  "login": "Iniciar sesión",
  "success": "¡Éxito!",
  "incorrect_password": "Contraseña incorrecta",
  "required_field": "Campo requerido",
  "email_required": "Correo electrónico requerido",
  "login_error": "El correo electrónico o la contraseña que ingresaste son incorrectos. Inténtalo de nuevo.",
  "login_unexpected_error": "Ocurrió un error inesperado, por favor inténtalo de nuevo.",
  "forgot_password": "Olvidé mi contraseña",
  "reset_success": "Tu contraseña está actualizada. Puede utilizar su nueva contraseña para iniciar sesión.",
  "reset_your_password": "Restablecer contraseña",
  "new_password": "Nueva contraseña",
  "confirm_new_password": "Confirmar nueva contraseña",
  "confirm_password_subtext": "Su contraseña debe contener 8 o más caracteres, al menos 1 letra, 1 número y 1 símbolo",
  "reset_password": "Restablecer contraseña",
  "error_match_passwords": "Las contraseñas no coinciden",
  "error_incorrect_password": "La contraseña que ha introducido es incorrecta. Por favor inténtalo de nuevo.",
  "forgot_password_title": "Olvidé mi contraseña",
  "forgot_password_subtext": "Ingrese su dirección de correo electrónico a continuación y le enviaremos instrucciones para restablecer la contraseña.",
  "send_email": "Enviar correo",
  "back_sign_in": "Regresar a inicio de sesión",
  "restart_invalid_token": "Su enlace de restablecimiento de contraseña ha caducado. Inténtalo de nuevo.",
  "forgot_password_success_p1": "Enviamos un correo electrónico a {userEmail} con instrucciones para restablecer la contraseña.",
  "forgot_password_success_p2": "Nota: Si no recibe un correo electrónico pronto, verifique su carpeta \"Spam\".",
  "forgot_password_success_p3": "Si no está en su carpeta de correo no deseado, comuníquese con el administrador del sistema, quien puede ayudarlo a restablecer su contraseña.",
  "forgot_password_email_error": "No se encontró ningún usuario con ese correo electrónico",
  "server_error": "Ocurrió un error. Por favor inténtalo de nuevo más tarde.",
  "same_day": "Mismo día",
  "day_n": "Día {n}",
  "comps_your_data": "Tus datos",
  "comps_market_data": "Datos de mercado",
  "comps_real_time_data": "Datos en tiempo real",
  "comps_day_n_totals": "Días {n} - Totales",
  "comps_opening_day": "Día de estreno",
  "comps_opening_weekend": "FS de estreno",
  "comps_opening_total_run": "Total Acumulado",
  "comps_search_placeholder": "Usa este buscador para encontrar un título específico contra el cual comparar",
  "comps_no_selected_titles": "Ningún título seleccionado",
  "comps_no_suggested_titles": "Sin sugerencia de título(s)",
  "comps_total_market_data": "Datos totales de mercado",
  "comps_circuit_share": "({n} cuota de mercado del circuito)",
  "no_access_title": "La página no se puede mostrar",
  "no_access_subtext": "Esta aplicación está disponible sólo en dispositivos móviles",
  "theatre_metadata_view_films": "Películas",
  "theatre_metadata_view_screen": "Planificador de pantalla",
  "film_metadata_view_comps": "Comparativos",
  "film_metadata_view_theatres": "Complejos",
  "film_metadata_view_circuits": "Circuitos",
  "report_indicator_last_updated": "Última actualización hace {time}",
  "report_indicator_sites_reported": "{num} cine(s) reportados",
  "report_indicator_sites_reported_yesterday": "{num} cine(s) reportados ayer",
  "report_indicator_sites_reporting": "{num} cine(s) reportando",
  "report_indicator_sites_reporting_real_time": "{num} cine(s) reportando en tiempo real",
  "report_indicator_search_placeholder": "Buscar por circuito",
  "report_indicator_market_report_title": "Reporte de mercado - {today}",
  "report_indicator_th_data_feed": "Fuente de datos",
  "report_indicator_th_circuit": "Circuito",
  "report_indicator_th_sites_reported": "Cines reportados",
  "report_indicator_th_sites_reported_yesterday": "Cines reportados ayer",
  "report_indicator_th_sites_reporting": "Cines reportando",
  "performanceModal.admissions": "Admisiones",
  "performanceModal.eventRevenue": "Venta de boletos",
  "performanceModal.occupancy": "Ocupación",
  "performanceModal.eventSph": "Precio promedio del boleto",
  "performanceModal.capacity": "Capacidad restante",
  "runtime": "{num}mins",
  "market_data_not_available": "Datos de mercado aún no disponibles, por favor revise más tarde",
  "presales_data_integration": "Para integración de preventas por favor contacte a su representante local de Comscore",
  "circuits_view_not_available_real_time": "Vista de circuitos no está disponible para datos en tiempo real",
  "delete": "Borrar",
  "share": "Compartir",
  "save": "Guardar",
  "settings": "Configuración",
  "search": "Buscar",
  "support": "Ayuda & Soporte",
  "user_settings.settings": "Configuración",
  "user_settings.password": "Contraseña",
  "user_settings.language": "Idioma",
  "user_settings.currency": "Moneda",
  "user_settings.time-format": "Formato de hora",
  "user_settings.24-Hour-time": "Formato de 24 hrs.",
  "user_settings.default-territory": "Territorio predeterminado",
  "user_settings.error_update": "Se produjo un error al actualizar la configuración, actualice la página e intente nuevamente.",
  "user_settings.current": "Actual",
  "user_settings.all_territories": "Todos los terriotorios",
  "user_settings.search_territories": "Buscar por territorio",
  "user_settings.title-preference": "Preferencia de título",
  "user_settings.selected_territory": "Territorio seleccionado",
  "user_settings.favourite_territories": "Territorios favoritos",
  "user_settings.territories": "Territorios",
  "invalid_email": "Correo electrónico inválido",
  "change_password": "Cambiar contraseña",
  "current_password": "Contraseña actual",
  "error_incorrect_password_type": "La contraseña que ha introducido no contiene 8 o más caracteres, al menos 1 letra, 1 número y 1 símbolo",
  "password_updated": "Contraseña actualizada",
  "comps_no_market_data": "(Sin datos de mercado)",
  "comps_market_data_not_tracked": "Datos de mercado no rastreados",
  "bookmarks": "Marcadores",
  "bookmarks.header.save": "Guardar marcador",
  "bookmarks.create": "Crear un marcador nuevo",
  "bookmarks.form.name": "Nombre del marcador",
  "bookmarks.form.privacy": "Privacidad",
  "bookmarks.form.private": "Disponible solo para ti",
  "bookmarks.form.shared": "Compartir con toda la organización",
  "bookmarks.form.create": "Crear marcador",
  "bookmarks.shared": "Compartir marcador",
  "bookmarks.privacy": "Marcadores privados",
  "bookmarks.createdBy": "Creado por",
  "bookmarks.confirm.title": "Borrar marcador",
  "bookmarks.confirm.sub-title": "Esta acción no se puede deshacer",
  "bookmark.error.name_required": "Se requiere un nombre para el marcador",
  "bookmark.error.name_duplication": "Ya existe un marcador con ese nombre",
  "bookmark.error.name_too_long": "El nombre del marcador es demasiado largo (Máximo {maxChars} caracteres)",
  "bookmark.error.unexpected": "Ocurrió algo inesperado, por favor inténtalo de nuevo",
  "en": "Inglés (English)",
  "es": "Español (Español)",
  "app_error_heading": "¡Oops! Algo salió mal",
  "app_error_subtext": "Por favor actualiza la página e inténtalo de nuevo",
  "title_preference.us_title": "Título EUA",
  "title_preference.local_title": "Título local",
  "user_currency_preference.local": "Moneda local",
  "user_currency_preference.default": "Dólar estadounidense ($)",
  "marketAnalysis": "Análisis de mercado",
  "pt": "Portugués (Português)",
  "advancedSales": "Pre Ventas",
  "market_share": "Participación de Mercado",
  "advanced_sales_data_not_available": "Datos de pre venta no disponibles aún. Por favor intenta más tarde."
};

export default es;
