import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppContextState } from '@modules';
import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useSelectedSiteAttributeGroup } from '@modules/SiteAttributionSelector';

import { getParametersForTheatresList } from '../selectors';
import { fetchSitesList } from '../actions';

/**
 * useDataFetchAndAppend
 * - Pagination
 * - This will keep the current parameters and load the next page, appending to the list.
 * - Should only be fired if the page number changes.
 */
export const useDataFetchAndAppend = (globalParams, ignoreSelectedAttributeGroup, newPageNumber) => {
  const fetchSites = useAction(fetchSitesList);
  /**
   * Note: globalParams, selectedAttribute, pageSearch - These were purposely not added to the useEffect depandancy list.
   *
   * If pageNumber changes it should just load next page with the exact same parameters.
   * If anything else changes the other useDataFetcher should take over.
   */
  const [, selectedAttribute] = useSelectedSiteAttributeGroup();
  const { pageSearch } = useAppContextState();
  const params = useSelector(getParametersForTheatresList);
  const [subSectionPath, dataPath] = useAPIPath();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useEffect(() => {
    if (newPageNumber > 1) {
      const attributeId = ignoreSelectedAttributeGroup ? null : selectedAttribute.id;
      const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
      const excludeDeltas = isAdvancedSales;

      fetchSites(
        attributeId,
        {
          ...params,
          ...timeParams,
          ...globalParams,
          search: pageSearch,
          page: newPageNumber,
        },
        true,
        subSectionPath,
        dataPath,
        excludeDeltas
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPageNumber, fetchSites, subSectionPath, dataPath, isAdvancedSales, isToday]);
};
