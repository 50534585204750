import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer, Content, DrawerProvider, DrawerWrapper, Section, Divider } from './components';

import './_drawer.scss';

/**
 * DrawerComponent
 * @param {boolean} defaultTheme - By this property we force this spinner to use default color instead of auto theme colours.
 */
function DrawerComponent({ children, isOpen, hasOpened, hasClosed, className, defaultTheme }) {
  return (
    <DrawerProvider isOpenProp={isOpen} hasOpened={hasOpened} hasClosed={hasClosed}>
      <DrawerWrapper className={className} defaultTheme={defaultTheme}>
        {children}
      </DrawerWrapper>
    </DrawerProvider>
  );
}

DrawerComponent.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  hasOpened: PropTypes.func,
  hasClosed: PropTypes.func,
  className: PropTypes.string,
  defaultTheme: PropTypes.bool,
};

DrawerComponent.Header = Header;
DrawerComponent.Footer = Footer;
DrawerComponent.Section = Section;
DrawerComponent.Divider = Divider;
DrawerComponent.Content = Content;

export default DrawerComponent;
