import React, { useState } from 'react';
import { Drawer, Heading, SelectorButton } from '@components';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { isStudio } from '@modules/Core';
import { Icon } from '@components';
import { useSelectedSiteAttributeGroup } from './hooks';

import './_site-attribution-selector.scss';

export * from './hooks';

const SiteAttributionSelector = ({ theme }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [groups, selected, setSelected] = useSelectedSiteAttributeGroup();
  const isStudioApp = isStudio();

  return (
    <>
      <SelectorButton theme={theme} onClick={() => setIsOpen(true)}>
        {isStudioApp
          ? selected.name && (
              <FormattedMessage id={`site_attribute_group.${selected.name}`} defaultMessage={selected.name} />
            )
          : selected.name}
      </SelectorButton>
      <Drawer isOpen={isOpen} hasClosed={() => setIsOpen(false)}>
        <Drawer.Header isVisibleOnScreen={false}>
          <Heading size="m" className="site-attribution-selector__heading">
            <FormattedMessage id="siteAttrSelector_heading" />
          </Heading>
        </Drawer.Header>
        <Drawer.Content>
          <Drawer.Section>
            <ul className="site-attribution-selector__list">
              {groups.map(({ id, name }) => (
                <li
                  key={id}
                  role="button"
                  onClick={() => {
                    setSelected(id);
                    setIsOpen(false);
                  }}
                  className={classnames('site-attribution-selector__list-item', {
                    'site-attribution-selector__list-item--selected': id === selected.id,
                  })}
                >
                  {isStudioApp ? <FormattedMessage id={`site_attribute_group.${name}`} defaultMessage={name} /> : name}
                  {id === selected.id && <Icon icon="check" />}
                </li>
              ))}
            </ul>
          </Drawer.Section>
        </Drawer.Content>
      </Drawer>
    </>
  );
};

SiteAttributionSelector.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

SiteAttributionSelector.defaultProps = {
  theme: 'light',
};

export default SiteAttributionSelector;
