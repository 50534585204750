import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { Text } from '../Typography';

const componentName = 'range-slider';

const Handle = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <circle
        className={classnames(`${componentName}__handle__circle`)}
        cx="84"
        cy="15"
        r="10.313"
        transform="translate(-69)"
        strokeWidth="9.375"
        fillRule="evenodd"
      />
    </svg>
  );
};

const propTypes = {
  className: PropTypes.string,
  /**
   * Array of the from and to boundary range e.g [30, 50]
   */
  value: PropTypes.arrayOf(PropTypes.number),
  /**
   * Minimum value that can be selected from the range slider
   */
  min: PropTypes.number,
  /**
   * Maximum value that can be selected from the range slider
   */
  max: PropTypes.number,
  /**
   * Callback fired everytime the from or to values change.
   */
  onChange: PropTypes.func,
  /**
   * Optional Label to give to the slider. Default positioned above the slider.
   */
  label: PropTypes.node,
};
const defaultProps = {
  value: [],
  className: '',
  min: 0,
  max: 100,
  onChange: () => {},
  label: null,
};

const RangeSlider = ({ value, className, min, max, onChange, label }) => {
  const totalSteps = max - min;

  const sliderInnerRef = useRef(null);
  const railRef = useRef(null);
  const leftHandleRef = useRef(null);
  const rightHandleRef = useRef(null);

  const initialFrom = useRef(value[0] || min);
  const initialTo = useRef(value[1] || max);

  const [from, setFrom] = useState(initialFrom.current);
  const [to, setTo] = useState(initialTo.current);
  const [sliderRailWidth, setSliderRailWidth] = useState(null);
  const [sliderIncrement, setSliderIncrement] = useState(null);

  /**
   * Convert percentage value to track increment number.
   */
  const initialLeftPos = useMemo(
    () => Math.round((sliderRailWidth * initialFrom.current) / 100),
    [initialFrom, sliderRailWidth]
  );
  const initialRightPos = useMemo(
    () => Math.round((sliderRailWidth * initialTo.current) / 100 - sliderRailWidth),
    [initialTo, sliderRailWidth]
  );

  const getNewValues = useCallback(
    (value) => {
      const val = Math.round((value / sliderIncrement) * sliderIncrement);
      const valInPercent = Math.round((val / sliderRailWidth) * 100);

      return [val, valInPercent];
    },
    [sliderIncrement, sliderRailWidth]
  );

  useEffect(() => {
    onChange([from, to]);
  }, [from, onChange, to]);

  useEffect(() => {
    if (sliderInnerRef && sliderInnerRef.current) {
      const width = sliderInnerRef.current.offsetWidth;
      const increment = width / totalSteps;
      setSliderRailWidth(width);
      setSliderIncrement(increment);
    }
  }, [totalSteps, sliderInnerRef]);

  /**
   * Left Handle Drag
   */
  useEffect(() => {
    if (leftHandleRef) {
      Draggable.create(leftHandleRef.current, {
        type: 'x',
        lockAxis: true,
        bounds: railRef.current,
        minimumMovement: 1,
        liveSnap: function (value) {
          const [val, valInPercent] = getNewValues(value);
          if (min <= valInPercent && valInPercent < to) {
            setFrom(valInPercent);
            return val;
          }
        },
      });
    }
  }, [leftHandleRef, railRef, to, min, getNewValues, initialLeftPos]);

  /**
   * Right Handle Drag
   */
  useEffect(() => {
    if (rightHandleRef) {
      Draggable.create(rightHandleRef.current, {
        type: 'x',
        lockAxis: true,
        bounds: railRef.current,
        minimumMovement: 1,
        liveSnap: function (value) {
          const [val, valInPercent] = getNewValues(value);
          //e.g -61 + 100 = 39%;
          const reversedValInPercent = valInPercent + 100;

          if (from < reversedValInPercent && reversedValInPercent <= max) {
            setTo(reversedValInPercent);
            return val;
          }
        },
      });
    }
  }, [rightHandleRef, railRef, from, max, getNewValues]);

  useEffect(() => {
    if (rightHandleRef && rightHandleRef.current) {
      gsap.set(rightHandleRef.current, { x: initialRightPos });
    }
  }, [initialRightPos]);

  useEffect(() => {
    if (leftHandleRef && leftHandleRef.current) {
      gsap.set(leftHandleRef.current, { x: initialLeftPos });
    }
  }, [initialLeftPos]);

  return (
    <div className={classnames(componentName, className)}>
      {label && (
        <Text tag="span" size="m" className={classnames(`${componentName}__label`)}>
          {label}
        </Text>
      )}

      <div className={classnames(`${componentName}__slider`)}>
        <div className={classnames(`${componentName}__slider-inner`)} ref={sliderInnerRef}>
          <div className={classnames(`${componentName}__rail`)} ref={railRef}></div>
          <div
            className={classnames(`${componentName}__track`)}
            style={{
              left: `${from}%`,
              width: `${max - from - (max - to)}%`,
            }}
          ></div>
          <div
            ref={leftHandleRef}
            className={classnames(`${componentName}__handle`)}
            style={{
              transform: `translate(-50%, 0%) translate3d(${initialLeftPos}px, 0px, 0px)`,
            }}
          >
            <Handle />
            <Text tag="span" bold className={classnames(`${componentName}__handle__label`)}>
              {from}%
            </Text>
          </div>
          <div
            ref={rightHandleRef}
            className={classnames(`${componentName}__handle`)}
            style={{
              left: '100%',
              transform: `translate(-50%, 0%) translate3d(${initialRightPos}px, 0px, 0px)`,
            }}
          >
            <Handle />
            <Text tag="span" bold className={classnames(`${componentName}__handle__label`)}>
              {to}%
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

RangeSlider.propTypes = propTypes;
RangeSlider.defaultProps = defaultProps;

export default RangeSlider;
