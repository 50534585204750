import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { BackToTop } from '@components';
import {
  useSetPageName,
  useSetRefreshMetrics,
  PAGE_NAME_THEATRE_ANALYSIS,
  DateSelector,
  MetricList,
  TheatreList,
  Page,
  TheatreSelectorButtons,
  DateComparisonPeriod,
  DateChangeSwipe,
} from '@modules';
import { useSelectedSiteAttributeGroup } from '@modules/SiteAttributionSelector';
import { useOccupancy } from '@modules/FilterTypes/occupancy';

import { useTheatrePerspectiveMetrics } from '../hooks';

import './_theatre-analysis.scss';
import { useActionCtnAnimation } from './hooks';
import { PAGE_PATH, DEFAULT_PAGE_SCREEN_PATH } from '../constants';

const componentName = 'theatre-analysis';

/**
 * TheatreAnalysis
 * @param {}
 */
const TheatreAnalysis = () => {
  const history = useHistory();
  const metrics = useTheatrePerspectiveMetrics();
  const [actionsContainerRefBinder] = useActionCtnAnimation();
  const [, selectedAttribute] = useSelectedSiteAttributeGroup();
  const [, setOccupancy] = useOccupancy();

  useSetPageName(PAGE_NAME_THEATRE_ANALYSIS);
  useSetRefreshMetrics(metrics);

  const onSelectItem = useCallback(
    (id) => {
      let path =
        selectedAttribute.id === null
          ? `/${PAGE_PATH}/${id}/${DEFAULT_PAGE_SCREEN_PATH}`
          : `/${PAGE_PATH}/site-attribute/${id}`;

      history.push(path);
    },
    [history, selectedAttribute.id]
  );

  const dateSelectorRef = useRef(null);
  const onPageSwipeLeft = useCallback(() => dateSelectorRef.current.moveToNextSlide(), [dateSelectorRef]);
  const onPageSwipeRight = useCallback(() => dateSelectorRef.current.moveToPrevSlide(), [dateSelectorRef]);
  const checkIsInMinMaxRange = dateSelectorRef.current ? dateSelectorRef.current.checkIsInMinMaxRange : null;

  useEffect(() => {
    return () => {
      setOccupancy([]);
    };
  }, [setOccupancy]);

  return (
    <Page className={componentName}>
      <div className={classnames(`${componentName}__actions-ctn`)} ref={actionsContainerRefBinder}>
        <DateSelector className={classnames(`${componentName}__date-selector`)} ref={dateSelectorRef} />
        <DateComparisonPeriod />
        <MetricList metrics={metrics} selectable />
      </div>
      <DateChangeSwipe
        className={classnames(`${componentName}__swipe-ctn`)}
        onSwipeLeft={onPageSwipeLeft}
        onSwipeRight={onPageSwipeRight}
        checkIsInMinMaxRange={checkIsInMinMaxRange}
      >
        <TheatreSelectorButtons theme="dark" metrics={metrics} />
        <TheatreList className={classnames(`${componentName}__site-list`)} onSelectItem={onSelectItem} />
        <BackToTop />
      </DateChangeSwipe>
    </Page>
  );
};

TheatreAnalysis.propTypes = {
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isAttributeView: PropTypes.bool,
};

TheatreAnalysis.defaultProps = {
  selectorButtons: true,
  onSelectAttributeItem: null,
  isAttributeView: false,
};

export default TheatreAnalysis;
