import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _uniq from 'lodash/uniq';

import { Spinner, Table } from '@components';
import { useEventParamKey } from '@modules/FilterTypes/compsTitles';

import { useFormatDataFetch, useListItemFormatData } from '../hooks';
import { useTheatreListContext } from '../Context';
import { useTableColumns } from '../hooks';

const propTypes = {
  siteId: PropTypes.number.isRequired,
};
/**
 * ListItemTable
 * Displays list of
 * @param {number} siteId - of this current listItem card.
 */
const ListItemTable = ({ siteId }) => {
  const theatreListContext = useTheatreListContext();
  const [eventParamKey] = useEventParamKey();

  const { formatMessage } = useIntl();

  const [itemData, isLoadingItemFormats] = useListItemFormatData(siteId);

  const hasTime = useMemo(() => {
    const dates = _uniq(itemData && itemData.map(({ datetime }) => datetime));

    return !dates.every(_isUndefined);
  }, [itemData]);

  /**
   * If data has already loaded for this siteId -> this will be used.
   * If data weren't initialised yet by 'isNullData' the endpoint request will be made.
   */
  useFormatDataFetch({ siteId, [eventParamKey]: theatreListContext[eventParamKey], isNullData: _isNil(itemData) });

  const tableColumns = useTableColumns(hasTime);

  return (
    <div className="item-table-container">
      {isLoadingItemFormats ? (
        <Spinner />
      ) : (
        <div className="item-table-container__scroll">
          {_isEmpty(itemData) ? (
            <div className="item-table-container__empty">{formatMessage({ id: 'no_results_found' })}</div>
          ) : (
            <Table>
              <Table.Head columns={tableColumns} />
              <Table.Body columns={tableColumns} data={itemData} dataUniqKey={'id'} />
            </Table>
          )}
        </div>
      )}
    </div>
  );
};

ListItemTable.propTypes = propTypes;

export default ListItemTable;
