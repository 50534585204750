import { useRef, useEffect, useState } from 'react';

/**
 * @Description Hooks to detect when scroll reaches the bottom of an DOM element.
 *
 * @Usage Import 'useScrollBottom' function to your component.
 *  1. const [isScrollBottom, scrollRef] = useScrollBottom();
 *  2. Include 'ref={scrollRef}' in your html element that has the scroll functionality.
 *
 * @Return [isScrollBottom, scrollRef];
 *  isScrollBottom {boolean} - State of your hook.
 *  scrollRef {DOM} - React ref element.
 */
export default function useScrollBottom() {
  const scrollRef = useRef();
  const [isBottom, setIsBottom] = useState(false);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      setIsBottom(scrollTop >= scrollHeight - clientHeight);
    }
  };

  useEffect(() => {
    scrollRef.current.addEventListener('scroll', onScroll);

    return () => {
      scrollRef.current.removeEventListener('scroll', onScroll);
    };
  }, [scrollRef.current]);

  return [isBottom, scrollRef];
}
