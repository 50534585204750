import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BASE_CLASS } from './common';

const propTypes = {
  tag: PropTypes.elementType,
  className: PropTypes.string,
};

const defaultProps = {
  tag: 'div',
};

const SectionComponent = React.forwardRef(function Section(props, ref) {
  const { tag: Tag, className, ...rest } = props;

  return <Tag {...rest} ref={ref} className={classnames(className, BASE_CLASS)} />;
});

SectionComponent.propTypes = propTypes;
SectionComponent.defaultProps = defaultProps;

export default SectionComponent;
