import { getUserField } from './user';
import { getUserDateParam } from './userDateParam';

export const getDefaultParams = () => {
  return {
    metricType: 'gross',
    lang: getUserField('language'),
    ...getUserDateParam(),
  };
};
