import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useMetric } from '@modules/FilterTypes/metric';
import { MetricNumber } from '@modules/Metrics';
import { Heading, PercentageMovement } from '@components';

import { getValueByTheatreID, getMovementValueByTheatreID } from '../selectors';

function Value({ id }) {
  const [metric] = useMetric();
  const value = useSelector(getValueByTheatreID(id));
  const valueMovement = useSelector(getMovementValueByTheatreID(id));

  return value ? (
    <>
      <Heading>
        <MetricNumber metricKey={metric} number={value} />
      </Heading>
      <PercentageMovement className="metric__percentage" value={valueMovement} size="xs" />
    </>
  ) : (
      ' - '
    );
}

Value.propTyoes = { id: PropTypes.number };

export default Value;
