import React from 'react';
import { Text } from '@components';
import { getFormattedField, getMovementField } from '../helpers';
import LegendMarker from './LegendMarker';

const TIME = '{formattedTime}';
const componentClass = 'comps-chart__tooltip';
const buildValueField = (field) => `{${getFormattedField(field)}}`;
const buildMovementPercentageComponent = (field) => `{${getMovementField(field)}}`;

function Tooltip({ valueFields, colorMap, title = TIME }) {
  return (
    <div className={componentClass}>
      <Text>{title}</Text>

      <ul>
        {valueFields.map((field, index) => (
          <li key={field}>
            <LegendMarker color={colorMap[field]} />
            <Text className={`${componentClass}--value`} style={{ color: colorMap[field] }}>
              {buildValueField(field)}
            </Text>
            {index !== 0 && buildMovementPercentageComponent(field)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tooltip;
