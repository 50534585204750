import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { COMPONENT_BASE_CLASS } from '../constants';
import { useItemContext, useItemContentAnimations } from '../hooks';

function ItemContent({ children, className }) {
  const { isOpen, onOpen, onClose } = useItemContext();
  const [isContentsOpen, setIsContentsOpen] = useState(isOpen);
  const [renderContents, setRenderContents] = useState(isOpen);

  /**
   * When the content is closed again, remove children elements from DOM.
   */
  const onAnimCloseComplete = useCallback(() => {
    setRenderContents(false);
  }, [setRenderContents]);

  const [itemContentBinder] = useItemContentAnimations({ isOpen: isContentsOpen, onAnimCloseComplete });

  useEffect(() => {
    if (isOpen) {
      setRenderContents(true);
      onOpen();
    } else {
      onClose();
    }
    setIsContentsOpen(isOpen);
  }, [isOpen, onOpen, onClose]);

  return (
    <div ref={itemContentBinder} className={`${COMPONENT_BASE_CLASS}__item-content ${className}`}>
      {renderContents && children}
    </div>
  );
}

ItemContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

ItemContent.defaultProps = {
  children: null,
  className: '',
};

export default ItemContent;
