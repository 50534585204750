import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import _isNull from 'lodash/isNull';

import { Button } from '@components';
import { useAppContextState } from '@modules';
import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAppActive, useIsAdvancedSales } from '@modules/Core';
import { refreshPoll, cleanUpRefreshPoll, setLastRefreshed, pausePoll, setMetrics } from './actions';
import { hasDataUpdatedSinceLastRefresh } from './selectors';
import { useLastUpdatedUpdate } from './hooks';

const componentName = 'refresh-button';

function RefreshButton() {
  const kickOffRefreshPoll = useAction(refreshPoll);
  const hasRefreshed = useAction(setLastRefreshed);
  const pause = useAction(pausePoll);
  const hasUpdated = useSelector(hasDataUpdatedSinceLastRefresh);
  const [shouldRotate, setShouldRotate] = useState(false);
  const setRefreshMetrics = useAction(setMetrics);
  const isAppActive = useIsAppActive();
  const { refreshMetrics } = useAppContextState();
  const [subSectionPath, dataPath] = useAPIPath();

  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  const onButtonClick = useCallback(() => {
    setShouldRotate(true);
    hasRefreshed();
  }, [hasRefreshed]);

  useLastUpdatedUpdate();

  // Tidying up the rotate transition class
  useEffect(() => {
    let removeRotateTimeout = null;

    if (shouldRotate) {
      removeRotateTimeout = setTimeout(() => {
        setShouldRotate(false);
      }, 600);
    }

    return () => {
      if (!_isNull(removeRotateTimeout)) {
        clearTimeout(removeRotateTimeout);
      }
    };
  }, [shouldRotate]);

  useEffect(() => {
    setRefreshMetrics(refreshMetrics);
  }, [refreshMetrics, setRefreshMetrics]);

  /**
   * This effect listens on the thie isAppActive boolean
   * If the app is active, then it kicks off a refreshpoll
   * cycle, otherwise it cleans up any active ones. It also
   * sets the pause flag on the store as an extra fallback
   */
  useEffect(() => {
    if (isAppActive) {
      const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
      kickOffRefreshPoll(subSectionPath, dataPath, timeParams);
    } else {
      cleanUpRefreshPoll();
    }

    // Pausing/unpausing based on app activity
    pause(!isAppActive);

    return cleanUpRefreshPoll();
  }, [isAppActive, pause, kickOffRefreshPoll, subSectionPath, dataPath, isAdvancedSales, isToday]);

  return (
    <>
      <Button
        className={classnames(componentName, {
          [`${componentName}--transition`]: shouldRotate,
          [`${componentName}--rotated`]: shouldRotate,
          [`${componentName}--active`]: hasUpdated,
        })}
        icon="refresh"
        onClick={onButtonClick}
      />
      <span className={`${componentName}__marker`}></span>
    </>
  );
}

export default RefreshButton;
