import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Heading } from '@components';
import { OPTION_SHAPE } from '../constants';
import ListItem from './ListItem';

const listComponentName = 'territory-list';

const propTypes = {
  title: PropTypes.node,
  options: PropTypes.arrayOf(OPTION_SHAPE),
  onSelectItem: PropTypes.func,
  filterNameBy: PropTypes.string,
  disabled: PropTypes.bool,
};

const List = ({ title, options, filterNameBy = '', onSelectItem, disabled = false }) => {
  const processedOptions = useMemo(() => {
    const isSearching = filterNameBy.length > 0;
    const list = isSearching
      ? options.filter((item) => item.name.toLowerCase().includes(filterNameBy.toLowerCase()))
      : options;

    return list;
  }, [options, filterNameBy]);

  return (
    <div className={classnames(listComponentName)}>
      {title ? (
        <Heading className={classnames(`${listComponentName}__title`)} level={6}>
          {title}
        </Heading>
      ) : null}
      <ul className={classnames(`${listComponentName}__list`)}>
        {processedOptions.map((option) => (
          <ListItem disabled={disabled} key={option.id} option={option} onSelectItem={onSelectItem} />
        ))}
      </ul>
    </div>
  );
};

List.propTypes = propTypes;

export default List;
