import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@components';
import InputField from './InputField';

const componentClass = 'input-field__search';

function SearchInputField({ className, ...props }) {
  return (
    <InputField {...props} border={false} className={classnames(className, componentClass)} type="search">
      <Icon icon="search" className={`${componentClass}__icon`} />
    </InputField>
  );
}

SearchInputField.propTypes = {
  className: PropTypes.string,
};

export default SearchInputField;
