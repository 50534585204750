import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';
import _get from 'lodash/get';

import { componentName } from './constants';

const DEFAULT_COLOR = 'white';

const propTypes = {
  dataItem: PropTypes.shape({}),
  valueField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  labelRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  color: PropTypes.string,
  colorRenderer: PropTypes.func,
  max: PropTypes.number,
};

function Row(props) {
  const { dataItem, valueField, labelRenderer, labelField, valueRenderer, color, colorRenderer, max } = props;
  const calculatedColor = useMemo(() => {
    if (_isFunction(colorRenderer)) {
      return colorRenderer(dataItem);
    } else if (color) {
      return color;
    } else {
      return DEFAULT_COLOR;
    }
  }, [dataItem, color, colorRenderer]);

  const calculatedWidth = useMemo(() => {
    const value = dataItem[valueField] || 0.1;

    return `${(value / max) * 100}%`;
  }, [max, valueField, dataItem]);

  return (
    <tr className={`${componentName}__row`}>
      <td className={`${componentName}__label`}>
        <div className={`${componentName}__label__text`}>
          {_get(dataItem, labelField, _isFunction(labelRenderer) ? labelRenderer(dataItem) : '')}
        </div>
      </td>
      <td className={`${componentName}__bar`}>
        <div style={{ height: 16, width: calculatedWidth, backgroundColor: calculatedColor }}></div>
      </td>
      <td className={`${componentName}__value`}>
        {_isFunction(valueRenderer) ? valueRenderer(dataItem) : _get(dataItem, valueField)}
      </td>
    </tr>
  );
}

Row.propTypes = propTypes;

export default Row;
