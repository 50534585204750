import React, { useMemo } from 'react';
import moment from 'moment';

import { Text } from '@components';

import { useUserCircuit, useUserTimeFormat } from '@modules/Auth';

const componentClass = 'time-line';

function TimeLine() {
  const circuit = useUserCircuit();
  const timeFormat = useUserTimeFormat();
  const hoursPerCinemaDay = useMemo(() => {
    let hours = [];
    let cinemaStartHour = moment({ hour: circuit.startHour });

    for (let i = 0; i < 24; i++) {
      hours.push({
        key: cinemaStartHour.clone().utc().valueOf(),
        time: cinemaStartHour.clone().format(timeFormat),
      });
      cinemaStartHour = cinemaStartHour.add(1, 'hour');
    }

    return hours;
  }, [timeFormat, circuit]);

  return (
    <div className={componentClass}>
      {hoursPerCinemaDay.map(({ key, time }) => (
        <Text key={key} tag="span" size="s" className={`${componentClass}--hour`}>
          {time}
        </Text>
      ))}
    </div>
  );
}

TimeLine.propTypes = {};

export default TimeLine;
