import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BASE_CLASS } from './constants';

const Table = ({ className, children, colBordersVisible }) => {
  return (
    <table
      className={classNames(`${BASE_CLASS} ${className}`, {
        [`${BASE_CLASS}--col-borders`]: colBordersVisible,
      })}
    >
      {children}
    </table>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  colBordersVisible: PropTypes.bool,
};

Table.defaultProps = {
  className: '',
  children: null,
  colBordersVisible: false,
};
export default Table;
