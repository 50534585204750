import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Spinner } from '@components';

import { AuthLayout, logoutRequest, getIsAuthenticated } from '@modules/Auth';
import { useAction } from '@modules/Core';

export default function Logout() {
  const logout = useAction(logoutRequest);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    logout();
  }, [logout]);

  return isAuthenticated ? (
    <AuthLayout.Container hasTopImage>
      <AuthLayout.Content>
        <Spinner />
      </AuthLayout.Content>
    </AuthLayout.Container>
  ) : (
    <Redirect to="/user/login" />
  );
}
