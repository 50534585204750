import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Heading, Text } from '../Typography';

import './_rank-label.scss';

const propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
const defaultProps = {
  className: '',
  value: 0,
};

const RankLabel = ({ className, value }) => {
  return (
    <div className={classnames('rank-label', className)}>
      <Text tag="span" size="m" bold>
        {value}
      </Text>
    </div>
  );
};

RankLabel.propTypes = propTypes;
RankLabel.defaultProps = defaultProps;

export default RankLabel;
