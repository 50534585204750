import React from 'react';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import { BASE_CLASS } from '../../constants';
import { Text, Section, Icon } from '@components';
import { useSelector } from 'react-redux';
import { getCurrentTerritory } from '@modules/TerritorySelect';
import './_default-territory.scss';

const componentClass = `${BASE_CLASS}-default-territory`;

const CurrentDefaultTerritory = () => {
  const defaultTerritory = useSelector(getCurrentTerritory);

  return (
    <Section>
      <Text bold size={'s'} className={`${componentClass}__title`}>
        <FormattedMessage id={'user_settings.current'} />
      </Text>
      <div className={`${componentClass}__current`}>
        {!_isEmpty(defaultTerritory) ? (
          <>
            <Text bold>{defaultTerritory.name}</Text>
            <Icon icon="check" />
          </>
        ) : (
          ' - '
        )}
      </div>
    </Section>
  );
};

export default CurrentDefaultTerritory;
