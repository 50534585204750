import { useSelector, useDispatch } from 'react-redux';
import { fetchSuggestedTitles } from '../actions';
import { getSuggestedTitles, isSuggestedTitlesLoading, getParameters } from '../selectors';
import { useParametersListener } from '@modules/FilterTypes';
import { getBaseEventId } from '@modules/Comps';
import { useAPIPath } from '@modules/Core';

export const useSuggestedTitles = () => {
  const dispatch = useDispatch();
  const baseEventId = useSelector(getBaseEventId);
  const [subSectionPath] = useAPIPath();

  useParametersListener(
    getParameters,
    (params) => {
      if (params.territoryId && baseEventId) {
        dispatch(fetchSuggestedTitles(params, baseEventId, subSectionPath));
      }
    },
    [baseEventId, subSectionPath]
  );

  const isLoading = useSelector(isSuggestedTitlesLoading);
  const data = useSelector(getSuggestedTitles);

  return {
    isLoading,
    data,
  };
};
