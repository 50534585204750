import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { percentageFormatter } from '@modules';
import { Text } from '@components';
import './_occupancy-percentage.scss';

const propTypes = {
  value: PropTypes.number,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  precision: PropTypes.number,
  className: PropTypes.string,
};

const defaultProps = {
  value: null,
  size: 's',
  precision: 0,
  className: '',
};

const MIN_OCCUPANCY_THRESHOLD = 39;
const MED_OCCUPANCY_THRESHOLD = 69;

const componentClass = 'occupancy-percentage';

/**
 * Occupancy Percentage Indicator
 * @param {number} value
 * @param {number} size
 * @param {number} precision Number of decimal places to show
 * @param {string}
 */
const OccupancyPercentage = ({ value, size = 's', precision = 0, className }) => {
  return (
    <div
      className={classnames(componentClass, 'text-number', `text-number--${size}`, className, {
        [`${componentClass}__text--danger`]: value > MED_OCCUPANCY_THRESHOLD,
        [`${componentClass}__text--warning`]: value <= MED_OCCUPANCY_THRESHOLD && value > MIN_OCCUPANCY_THRESHOLD,
        [`${componentClass}__text--success`]: value <= MIN_OCCUPANCY_THRESHOLD,
      })}
    >
      <Text tag="span" size={size} bold>
        {percentageFormatter(Math.abs(value), precision)}
      </Text>
    </div>
  )
};

OccupancyPercentage.propTypes = propTypes;
OccupancyPercentage.defaultProps = defaultProps;

export default OccupancyPercentage;
