import moment from 'moment';
import { getUserCircuit } from './user';

const DOW_SUNDAY_CIRCUIT = 7;
const DOW_SUNDAY_MOMENT = 0;

export const isYesterday = (value) => moment(value).isSame(moment().subtract(1, 'days'), 'day');

export const isTomorrow = (value) => moment(value).isSame(moment().add(1, 'days'), 'day');

export const isToday = (value) => moment().isSame(moment(value), 'day');

/**
 * Day of week number position
 * Important Note -
 * This function returns From 1 (Monday) to 7 (Sunday). As circuit config.
 *
 * {string} date - yyyy/mm/dd
 * @return number - 1-7
 * */
export const getDOWPosition = (date) => {
  const dow = moment(date).day();
  return dow === DOW_SUNDAY_MOMENT ? DOW_SUNDAY_CIRCUIT : dow;
};

/**
 * Checks if the date given is weekend based on circuit configuration.
 * {string} date - format yyyy/mm/dd
 * @return boolean
 * */
export const isWeekend = (date) => {
  let dowPosition = getDOWPosition(date);
  const weekendDaysCircuit = getUserCircuit().weekend.split('-').map(Number);
  return weekendDaysCircuit.includes(dowPosition);
};

/**
 * Day of week label to map internationalization.
 * Day of wee starts on Monday as 1 (moment config adapted to circuits).
 *
 * {string} date - format yyyy/mm/dd
 * {bool} short? - gives back the sorter label
 * @return i18n key
 * */
export const getDOWKeyLabel = (date, short) => {
  // This array has 100% dependency of i18n keys declaration.
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  const dayOfWeek = days[getDOWPosition(date) - 1]; // from 0 to 6 in array above.
  let i18key = 'dow_' + dayOfWeek;

  if (short) i18key += '_short';

  return i18key;
};

/**
 * getMomentFromNowFormatted
 * @param {string} dateString - e.g '2020-08-16T11:28:55Z'
 * @param {bool} noSuffix - if false -> 'ago' suffix text will be added to string.
 * @returns {string} Returns how long ago this date is from today. E.g 1 hour ago, 5 days ago
 */
export const getMomentFromNowFormatted = (dateString, noSuffix = false) => {
  return moment(dateString).fromNow(noSuffix);
};
