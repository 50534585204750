import { useEffect, useCallback } from 'react';
import { initIntercom, intercomApi } from '../helpers';

export const useIntercom = () => {
  useEffect(() => {
    initIntercom();
  }, []);

  const showIntercomPopup = useCallback(() => {
    intercomApi('show');
  }, []);

  const hideIntercomPopup = useCallback(() => {
    intercomApi('hide');
  }, []);

  return {
    showIntercomPopup,
    hideIntercomPopup,
  };
};
