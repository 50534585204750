import { useAction } from '@modules/Core';
import { successBanner, errorBanner } from './actions';

export const useNotificationBanner = () => {
  const success = useAction(successBanner);
  const error = useAction(errorBanner);

  return {
    success,
    error,
  };
};
