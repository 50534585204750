import { isLoading } from '@showtime/cea-dep-utility';
import { createSelector } from '@reduxjs/toolkit';
import { getFilters } from '@modules/Filters';
import { compressJSON } from '@modules/Core/helpers';
import {
  BOOKMARKS_FETCH_DELETE_KEY,
  BOOKMARKS_FETCH_KEY,
  BOOKMARKS_FETCH_KEY_LAZY,
  BOOKMARKS_FETCH_UPDATE_KEY,
  EXCLUDED_FILTER_KEYS,
  EXCLUDED_FILTERS,
  STATE_KEY,
} from './constants';
import _omit from 'lodash/omit';

export const getBookmarkSelected = (state) => state[STATE_KEY].selected;

export const getBookmarksData = (state) => state[STATE_KEY].data;

export const getBookmarksPrivacy = (privacy) => createSelector([getBookmarksData], (data) => data[privacy]);

export const isFetchingData = (state) => isLoading(state, BOOKMARKS_FETCH_KEY);

export const isLazyFetchingData = (state) => isLoading(state, BOOKMARKS_FETCH_KEY_LAZY);

export const isUpdatingBookmark = (state) => isLoading(state, BOOKMARKS_FETCH_UPDATE_KEY);

export const isDeletingBookmark = (state) => isLoading(state, BOOKMARKS_FETCH_DELETE_KEY);

export const getFiltersObjectCompressed = createSelector([getFilters], (filters) => {
  let filtersToCompress = {};

  // Excluding filters and some of their keys too by constants.
  Object.entries(filters).map(([filterKey, values]) => {
    if (!EXCLUDED_FILTERS.includes(filterKey)) {
      if (EXCLUDED_FILTER_KEYS[filterKey]) {
        filtersToCompress[filterKey] = _omit(values, EXCLUDED_FILTER_KEYS[filterKey]);
      } else {
        filtersToCompress[filterKey] = values;
      }
    }
  });

  return compressJSON(filtersToCompress);
});
