import PropTypes from 'prop-types';

export const STATE_KEY = 'compsSelectionDrawer';

export const SUGGESTED_FETCH_KEY = 'compsSelectionDrawer.fetchSuggestedTitles';
export const SEARCH_FETCH_KEY = 'compsSelectionDrawer.fetchSearchTitles';

export const componentName = 'comps-titles';

export const MAX_SELECTIONS = 3;

export const titleShape = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
});
