import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '@components';
import { APP_TYPE_TITLE } from '@modules/Core';

import LogoImage from '../../../images/logo.svg';

const componentName = 'auth-header';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

export const Header = ({ className }) => {
  return (
    <div className={classnames(componentName, className)}>
      <div className={`${componentName}__header-logo-container`}>
        <img className={`${componentName}__header-logo`} src={LogoImage} alt="Comscore" />
        <Text className={`${componentName}__header-title`} size="m" bold>
          {APP_TYPE_TITLE}
        </Text>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
