import { useEffect, useRef, useState, useCallback } from 'react';

import { addLicense, useTheme as assignTheme, createFromConfig, options as amChartsOptions } from '@amcharts/amcharts4/core';
import { XYChart, LineSeries } from '@amcharts/amcharts4/charts';
import animatedTheme from '@amcharts/amcharts4/themes/animated';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _find from 'lodash/find';

const { AMCHARTS4_LICENSE_KEY } = process.env;

export default (config, amchartsObj = XYChart) => {
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    addLicense(AMCHARTS4_LICENSE_KEY);
    amChartsOptions.commercialLicense = true;
    amChartsOptions.autoSetClassName = true;
    assignTheme(animatedTheme);
  }, []);

  useEffect(() => {
    if (chart === null) {
      setChart(createFromConfig(_cloneDeep(config), chartRef.current, amchartsObj));
    }

    return () => {
      if (chart !== null) {
        chart.dispose();
      }
    };
  }, [config, chart, amchartsObj]);

  const setChartData = useCallback(
    (data) => {
      if (chart !== null) {
        chart.data = data;
      }
    },
    [chart]
  );

  const updateChartSeriesConfig = useCallback(
    (seriesConfig) => {
      if (chart !== null) {
        // Clearing all existing in case other ones have been added/removed
        chart.series.each((series, index) => {
          series.dispose();
        });

        chart.series.clear();

        const axisRangesConfig = _get(config, 'xAxes[0].axisRanges', []);

        seriesConfig.forEach((seriesConfig, index) => {
          const newSeries = new LineSeries();

          newSeries.config = _cloneDeep(seriesConfig);

          chart.series.push(newSeries);

          /*
           * amcharts does not pick up series axis ranges when series
           * are updated. So we must manually update them when the series
           * changes
           */
          const newSeriesId = seriesConfig.id;

          const range = _find(axisRangesConfig, { series: newSeriesId });
          if (range) {
            const xAxis = chart.xAxes.getIndex(0);
            let seriesRange = xAxis.createSeriesRange(newSeries);

            seriesRange.config = range;
          }
        });

        chart.invalidateData();
      }
    },
    [chart, config]
  );

  return { chartRef, setChartData, updateChartSeriesConfig, chart };
};
