import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Text } from '@components';
import { useSelected } from '../hooks';
import { titleShape, MAX_SELECTIONS } from '../constants';

const propTypes = {
  comp: titleShape,
  hasMarketData: PropTypes.bool,
};

function CompCheckbox({ comp, hasMarketData = true }) {
  const { selectComp, deselectComp, isCompSelected, selectedComps } = useSelected();
  const onChange = useCallback(
    (value) => {
      if (value) {
        selectComp(comp);
      } else {
        deselectComp(comp);
      }
    },
    [selectComp, deselectComp, comp]
  );
  const isSelected = isCompSelected(comp);

  return (
    <Checkbox
      disabled={!isSelected && selectedComps.length >= MAX_SELECTIONS}
      value={isSelected}
      label={
        <>
          {comp.name}
          {!hasMarketData && (
            <FormattedMessage id="comps_no_market_data">
              {(msg) => (
                <Text size="m" tag="span" className="comps-titles__no-market-text">
                  {msg}
                </Text>
              )}
            </FormattedMessage>
          )}
        </>
      }
      onChange={onChange}
    ></Checkbox>
  );
}

CompCheckbox.propTypes = propTypes;

export default CompCheckbox;
