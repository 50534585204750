import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import _isNil from 'lodash/isNil';

import { Heading, Text, Card, RankLabel } from '@components';
import { useMetric } from '@modules/FilterTypes/metric';

import { getValueByTheatreID } from '../selectors';
import ListItemAside from './ListItemAside';
import ListItemChart from './ListItemChart';
import MetricLabel from './MetricLabel';
import Value from './Value';
import CapacityValue from './CapacityValue';

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  hasAside: PropTypes.bool,
  logo: PropTypes.string,
  rank: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  circuitName: PropTypes.string,
};
const defaultProps = {
  hasAside: false,
  className: '',
  onClick: () => {},
};

const ListItemComponent = React.forwardRef(function ListItem(props, ref) {
  const { id, className, circuitName, rank, label, logo, hasAside, onClick } = props;

  const [metric] = useMetric();
  const marketShare = useSelector(getValueByTheatreID(id, 'marketShare'));

  return (
    <Card ref={ref} className={className} onClick={onClick}>
      <div className="item-header-wrapper">
        <div className="item-header">
          <RankLabel value={rank} />
          {logo && <img className="item-header__logo" src={logo} alt={label} />}
          <Text className="item-header__label" tag="span" size="l" bold>
            {label}
          </Text>
        </div>
        {circuitName && (
          <Text className="item-header-subheader" tag="span" size="s">
            {circuitName}
          </Text>
        )}
      </div>

      <div className="item-content">
        <div className="item-body">
          <div
            className={classnames('item-body__metric', {
              'item-body__metric--inline': metric !== 'capacity',
            })}
          >
            {metric === 'capacity' ? (
              <>
                <CapacityValue id={id} />
                <Text className="metric__label">
                  <MetricLabel />
                </Text>
              </>
            ) : (
              <>
                <Text className="metric__label">
                  <MetricLabel />
                </Text>
                <Value id={id} />
              </>
            )}
          </div>
          <ListItemChart id={id} />
        </div>
        {!_isNil(marketShare) && (
          <div className="item-body">
            <div className={classnames('item-body__metric', 'item-body__metric--inline')}>
              <Text className="metric__label">
                <FormattedMessage id={'market_share'} />
              </Text>
              <Heading>{marketShare}%</Heading>
            </div>
          </div>
        )}

        {hasAside && <ListItemAside id={id} />}
      </div>
    </Card>
  );
});

ListItemComponent.propTypes = propTypes;
ListItemComponent.defaultProps = defaultProps;

export default ListItemComponent;
