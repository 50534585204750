const pt = {
  "filmAnalysis": "Analise de Filmes",
  "apply": "Aplicar",
  "cancel": "Cancelar",
  "capacity": "Capacidade",
  "clear": "Limpar",
  "copy_link": "Copiar Link",
  "custom": "Customizado",
  "download": "Download",
  "films": "Filmes",
  "format": "Formato",
  "link_copied": "Link copiado para a área de transferência",
  "occupancy": "Ocupação",
  "screen": "Sala",
  "select_all": "Selecionar todos",
  "site": "Cinema",
  "theatres": "Cinemas",
  "time": "Horário",
  "title": "Titulo",
  "today": "Hoje",
  "tomorrow": "Amanhã",
  "yesterday": "Ontem",
  "next": "Próximo",
  "previous": "Anterior",
  "tmdb_credits": "Este produto usa a API TMDb, mas não é endossado ou certificado por TMDb.",
  "logout": "Log out",
  "territories": "Territórios",
  "format_currency": "%s%v",
  "format_decimal": ".",
  "format_thousand": ",",
  "format_abbr_thousand": "k",
  "format_abbr_million": "m",
  "format_abbr_billion": "b",
  "format_abbr_trillion": "t",
  "theatreAnalysis": "Análise de Cinemas",
  "sortBySelector_button": "Ordenar por",
  "sortBySelector_heading": "Ordenar por",
  "sortBySelector_metric_high_low": "Métrica Alto para Baixo",
  "sortBySelector_metric_low_high": "Metrica Baixo para Alto",
  "siteAttrSelector_heading": "Atributos do Cinema",
  "shareDrawer_heading": "Compartilhe esta página",
  "compsSelectionDrawer_heading": "Adicionar Titulos Comparativos",
  "all_titles": "Todos Titulos",
  "selected_titles": "Titulos Selecionados",
  "suggested_titles": "Titulos Sugeridos",
  "search_films": "Procurar Filmes",
  "search_theatres": "Procurar Cinemas",
  "metric.admissions": "Publico",
  "metric.eventRevenue": "Renda",
  "metric.eventRevenueBO": "Bilheteria",
  "metric.distributorReportedPrice": "Bilheteria",
  "metric.occupancy": "Ocupação",
  "metric.eventSph": "TM",
  "metric.capacity": "Capacidade Restante",
  "metric.yearMarketShare": "Part Merc 52 Semanas",
  "sites_filter.search": "Procurar cinemas",
  "sites_filter.page1.title": "Filtrar por Cinema",
  "sites_filter.page1.go_to.page2": "Agrupar por Atributos do Cinema",
  "sites_filter.page2.title": "Escolher Grupo de Cinemas",
  "sites_filter.page3.title": "Escolher Cinema - {attribute}",
  "sites_filter.page3.title.min": "Escolher Cinema",
  "sites_filter.alert": "Filtrar por cinemas ou atributos removerá os cinemas não compartilhados dos totais de métricas exibidas.",
  "sites_filter.search_placeholder": "Utilize esta busca para encontrar um cinema",
  "capacityFormat": "{capacityRemaining}({capacity})",
  "dow_monday": "Segunda",
  "dow_monday_short": "Seg",
  "dow_tuesday": "Terça",
  "dow_tuesday_short": "Ter",
  "dow_wednesday": "Quarta",
  "dow_wednesday_short": "Qua",
  "dow_thursday": "Quinta",
  "dow_thursday_short": "Qui",
  "dow_friday": "Sexta",
  "dow_friday_short": "Sex",
  "dow_saturday": "Sabado",
  "dow_saturday_short": "Sab",
  "dow_sunday": "Domingo",
  "dow_sunday_short": "Dom",
  "dow_weekdays": "Dias da Semana",
  "dow_weekends": "Final de Semana",
  "month_january": "Janeiro",
  "month_january_short": "Jan",
  "month_february": "Fevereiro",
  "month_february_short": "Fev",
  "month_march": "Março",
  "month_march_short": "Mar",
  "month_april": "Abril",
  "month_april_short": "Abr",
  "month_may": "Maio",
  "month_may_short": "Mai",
  "month_june": "Junho",
  "month_june_short": "Jun",
  "month_july": "Julho",
  "month_july_short": "Jul",
  "month_august": "Agosto",
  "month_august_short": "Ago",
  "month_september": "Setembro",
  "month_september_short": "Set",
  "month_october": "Outubro",
  "month_october_short": "Out",
  "month_november": "Novembro",
  "month_november_short": "Nov",
  "month_december": "Dezembro",
  "month_december_short": "Dez",
  "future": "em %s",
  "past": "%s ant",
  "s": "em alguns segundos",
  "ss": "%d segundos",
  "m": "um minuto",
  "mm": "%d minutos",
  "h": "uma hora",
  "hh": "%d horas",
  "d": "um dia",
  "dd": "%d dias",
  "w": "uma semana",
  "ww": "%d weeks",
  "M": "um mes",
  "MM": "%d meses",
  "y": "um ano",
  "yy": "%d anos",
  "vs_x": "vs {x}",
  "vs_x_until_y": "vs {x} (Até {y})",
  "titlesList_run_week": "Semana {num}",
  "no_results_found": "Nenhum resultado encontrado ",
  "no_data_available": "Nenhum dado disponivel",
  "performance_data_not_available": "Nenhum dado de performance disponivel",
  "email": "Email",
  "password": "Senha",
  "login": "Login",
  "success": "Sucesso!",
  "incorrect_password": "Senha Incorreta",
  "required_field": "Campo obrigatório",
  "email_required": "Email obrigatório",
  "login_error": "O email ou senha inserida esta incorreta. Por favor tente novamente",
  "login_unexpected_error": "Erro inesperado, por favor tente novamente",
  "forgot_password": "Esqueceu a senha? ",
  "reset_success": "Sua senha foi atualizada. Você pode utilizar sua nova senha para acessar.",
  "reset_your_password": "Resetar sua senha",
  "new_password": "Nova senha",
  "confirm_new_password": "Confirmar nova senha",
  "confirm_password_subtext": "Sua senha deve conter 8 ou mais caracteres, ao menos 1 letra, 1 numero e 1 simbolo",
  "reset_password": "Resetar Senha",
  "error_match_passwords": "A senha que você utilizou não combina",
  "error_incorrect_password": "A senha que você utilizou não contem 8 ou mais caracteres, ao menos 1 letra, 1 numero e 1 simbolo",
  "forgot_password_title": "Esqueceu a sua senha?",
  "forgot_password_subtext": "Por favor insira abaixo seu endereço de email para receber instruções para redefinir a sua senha",
  "send_email": "Enviar email",
  "back_sign_in": "Voltar ao acesso",
  "restart_invalid_token": "Seu link de redefinição de senha expirou. Por favor, tente novamente.",
  "forgot_password_success_p1": "Enviamos um e-mail para {userEmail} com instruções de redefinição de senha.",
  "forgot_password_success_p2": "Atenção: se você não receber sua senha provisória em breve, verifique sua pasta de SPAM",
  "forgot_password_success_p3": "Se você não encontrar sua senha na pasta de SPAM, entre em contato com o administrador",
  "forgot_password_email_error": "Não foi possível encontrar nenhum usuário com este e-mail",
  "server_error": "Ocorreum um erro. Por favor",
  "same_day": "Mesmo dia",
  "day_n": "Dia {n}",
  "comps_your_data": "Seus dados",
  "comps_market_data": "Dados do Mercado ",
  "comps_real_time_data": "Dados em Tempo Real",
  "comps_day_n_totals": "Dias {n} - Totais",
  "comps_opening_day": "Dia Estréia",
  "comps_opening_weekend": "Finde Estréia",
  "comps_opening_total_run": "Acum",
  "comps_search_placeholder": "Utilize esta busca para encontrar um titulo especifico para comparardvc567",
  "comps_no_selected_titles": "Nenhum titulo selecionado",
  "comps_no_suggested_titles": "Nenhum titulo sugerido",
  "comps_total_market_data": "Dados Totais Mercado",
  "comps_circuit_share": "({n} Part Circuito)",
  "no_access_title": "Pagina não pode ser visualizada",
  "no_access_subtext": "Esta aplicação está disponível apenas para dispositivos celulares",
  "theatre_metadata_view_films": "Filmes",
  "theatre_metadata_view_screen": "Planejador de Salas",
  "film_metadata_view_comps": "Comparar Filmes",
  "film_metadata_view_theatres": "Cinemas",
  "film_metadata_view_circuits": "Circuitos",
  "report_indicator_last_updated": "Atualizado {time} atrás",
  "report_indicator_sites_reported": "{num} cine(s) reportados",
  "report_indicator_sites_reported_yesterday": "{num} cine(s) reportados ontem",
  "report_indicator_sites_reporting": "{num} cine(s) reportando",
  "report_indicator_sites_reporting_real_time": "{num} cines(s) reportando em tempo real",
  "report_indicator_search_placeholder": "Pesquisar por Circuito",
  "report_indicator_market_report_title": "Relatório do Mercado - {today}",
  "report_indicator_th_data_feed": "Data Feed",
  "report_indicator_th_circuit": "Circuito",
  "report_indicator_th_sites_reported": "Cines Reportando",
  "report_indicator_th_sites_reported_yesterday": "Cines Reportaram Ontem",
  "report_indicator_th_sites_reporting": "Cines Reportando",
  "performanceModal.admissions": "Publico",
  "performanceModal.eventRevenue": "Ingressos Vendidos",
  "performanceModal.occupancy": "Ocupação",
  "performanceModal.eventSph": "Ticket Médio",
  "performanceModal.capacity": "Capacidade Disponivel",
  "runtime": "{num}mins",
  "market_data_not_available": "Os dados de mercado ainda não estão disponíveis, verifique novamente mais tarde",
  "presales_data_integration": "Para integração de pré-venda, entre em contato com seu representante Comscore",
  "circuits_view_not_available_real_time": "A visualização de circuitos não está disponível para dados em tempo real",
  "delete": "Excluir",
  "share": "Compartilhar",
  "save": "Salvar",
  "settings": "Definições",
  "search": "Procurar",
  "support": "Ajuda e Suporte",
  "user_settings.settings": "Definições",
  "user_settings.password": "Preferencia Usuário . Senha",
  "user_settings.language": "Idioma",
  "user_settings.currency": "Taxa Cambio",
  "user_settings.time-format": "Formato Hora",
  "user_settings.24-Hour-time": "Formato 24 -Horas",
  "user_settings.default-territory": "Território Padrão",
  "user_settings.error_update": "Erro",
  "user_settings.current": "Atual",
  "user_settings.all_territories": "Todos territórios",
  "user_settings.search_territories": "Procurar territórios",
  "user_settings.title-preference": "Preferencia Usuários - Titulos",
  "user_settings.selected_territory": "Território Selecionado",
  "user_settings.favourite_territories": "Território Favorito",
  "user_settings.territories": "Preferencia Usuário Territórios",
  "invalid_email": "E-mail invalido",
  "change_password": "Mudar senha",
  "current_password": "Senha atual",
  "error_incorrect_password_type": "Senha Incorreta",
  "password_updated": "Senha atualizada",
  "comps_no_market_data": "(Sem dados do mercado)",
  "comps_market_data_not_tracked": "Dados não coletados",
  "bookmarks": "Favoritos",
  "bookmarks.header.save": "Salvar seu Favorito",
  "bookmarks.create": "Criar um novo Favorito",
  "bookmarks.form.name": "Nome do Favorito",
  "bookmarks.form.privacy": "Privacidade",
  "bookmarks.form.private": "Disponivel apenas para você",
  "bookmarks.form.shared": "Compartilhe dentro da sua organização",
  "bookmarks.form.create": "Criar Favoritos",
  "bookmarks.shared": "Compartilhar Favoritos",
  "bookmarks.privacy": "Favoritos Privados",
  "bookmarks.createdBy": "Criado por",
  "bookmarks.confirm.title": "Excluir Favoritos",
  "bookmarks.confirm.sub-title": "Isto não pode ser desfeito",
  "bookmark.error.name_required": "Um nome é necessário para criar seu Favorito",
  "bookmark.error.name_duplication": "Um Favorito com este nome já existe",
  "bookmark.error.name_too_long": "Nome do Favorito é muito longo (Maximo {maxChars} caracteres)",
  "bookmark.error.unexpected": "Algo inesperado ocorreu",
  "en": "Ingles (English)",
  "es": "Espanhol (Español)",
  "app_error_heading": "Ops! Algo de errado ocorreu",
  "app_error_subtext": "Por favor atualize sua página e tente novamente",
  "title_preference.us_title": "Preferencia Usuário - Titulo EUA",
  "title_preference.local_title": "Preferencia Usuário - Titulo Local",
  "user_currency_preference.local": "Preferencia Usuário . Moeda Local",
  "user_currency_preference.default": "Preferencia Usuário  Padrão",
  "marketAnalysis": "Análise de Mercado",
  "pt": "PT",
  "advancedSales": "Vendas Antecipadas",
  "market_share": "Participação de Mercado",
  "advanced_sales_data_not_available": "Vendas Antecipadas Sem Datas"
};

export default pt;
