import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { PRIVATE_KEY } from '../constants';

const initialContext = {
  editBookmark: {},
  privacyTabActive: PRIVATE_KEY,
  deleteConfirmId: null,
  setEditBookmark: () => {},
  setPrivacyTabActive: () => {},
  setDeleteConfirmId: () => {},
};

const BookmarksContext = createContext(initialContext);

function BookmarksProvider({ children }) {
  const [editBookmark, setEditBookmark] = useState(initialContext.editBookmark);
  const [deleteConfirmId, setDeleteConfirmId] = useState(initialContext.deleteConfirmId);
  const [privacyTabActive, setPrivacyTabActive] = useState(initialContext.privacyTabActive);

  return (
    <BookmarksContext.Provider
      value={{
        privacyTabActive,
        setPrivacyTabActive,
        editBookmark,
        setEditBookmark,
        deleteConfirmId,
        setDeleteConfirmId,
      }}
    >
      {children}
    </BookmarksContext.Provider>
  );
}

BookmarksProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { BookmarksContext, BookmarksProvider };
