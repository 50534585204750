import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Text } from '@components';
import { getMomentFromNowFormatted } from '@modules/Core';

import './_reporting-indicator.scss';
import { BASE_CLASS } from './constants';
import { ReportingOverlay } from './components';
import { useSummaryDataFetch, useSummaryData } from './hooks';
import ReportIndicatorContext from './Context';

export * from './selectors';

/**
 * ReportingIndicator
 * NOTE: This is a STUDIO only module.
 * This should not be used in EXHIBITOR.
 */
const ReportingIndicator = ({ className, params }) => {
  const { formatMessage } = useIntl();

  useSummaryDataFetch(params);

  const { lastUpdated } = useSummaryData();

  return (
    <div className={classnames(`${BASE_CLASS} ${className}`)}>
      <ReportIndicatorContext.Provider value={params}>
        <div className="zone">
          {lastUpdated && (
            <Text className={`${BASE_CLASS}__last-updated`} size="s">
              {formatMessage(
                { id: 'report_indicator_last_updated' },
                { time: getMomentFromNowFormatted(lastUpdated, true) }
              )}
            </Text>
          )}
        </div>
        <div className="zone">
          <ReportingOverlay />
        </div>
      </ReportIndicatorContext.Provider>
    </div>
  );
};

ReportingIndicator.propTypes = {
  params: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
  }),
  className: PropTypes.string,
};

ReportingIndicator.defaultProps = {
  params: {},
  className: '',
};

export default ReportingIndicator;
