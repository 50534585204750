import React from 'react';
import moment from 'moment';

import { renderToStaticMarkup } from 'react-dom/server';
import _cloneDeep from 'lodash/cloneDeep';

import { Tooltip } from '../components';
import { BASE_SERIES_ID } from '../constants';

import { getCoreConfig } from './getCoreConfig';
import { getEmbeddedSeriesConfig, getAdvancedSalesEmbeddedSeriesConfig } from './getEmbeddedConfig';

const DARK_BLUE = '#045c88';
const OFF_WHITE = '#dce7ef';
const TOOLTIP_BACKGROUND = '#034b6f';
const TOOLTIP_OPACITY = 1;
const TEXT_COLOR = '#E0E4E8';
const WHITE = '#fff';

const gripStyles = {
  stroke: OFF_WHITE,
  strokeWidth: 4,
  height: 25,
  width: 25,
  background: {
    fill: DARK_BLUE,
  },
  icon: {
    disabled: true,
  },
};

const tooltipConfig = {
  getFillFromObject: false,
  tooltipPosition: 'pointer',
  background: {
    fill: TOOLTIP_BACKGROUND,
    stroke: TOOLTIP_BACKGROUND,
    strokeOpacity: TOOLTIP_OPACITY,
    opacity: TOOLTIP_OPACITY,
    cornerRadius: 4,
  },
  fill: TEXT_COLOR,
};

const isNow = (dataReference) => {
  const now = new Date();
  if (now.getMinutes() % 15 === 0) {
    return dataReference.id === `${now.getHours()}:${now.getMinutes()}`;
  }

  const fifteenMinutesFromNow = moment().add(15, 'minutes');
  const minsToNearest15 = Math.floor(fifteenMinutesFromNow.minute() / 15) * 15;
  const hours = fifteenMinutesFromNow.hour();

  return dataReference.id === `${hours}:${minsToNearest15}`;
};

export const getOverlaySeriesConfig = (baseSeriesConfig, compsSeriesConfig = [], isToday, isAdvancedSales) => {
  const embeddedSeries = isAdvancedSales
    ? _cloneDeep(getAdvancedSalesEmbeddedSeriesConfig(baseSeriesConfig, compsSeriesConfig))
    : _cloneDeep(getEmbeddedSeriesConfig(baseSeriesConfig, compsSeriesConfig));
  let colorMap = {
    [baseSeriesConfig.dataField]: baseSeriesConfig.color,
  };
  compsSeriesConfig.forEach(({ dataField, color }) => {
    colorMap[dataField] = color;
  });

  const tooltipMarkup = renderToStaticMarkup(
    <Tooltip
      valueFields={[baseSeriesConfig.dataField, ...compsSeriesConfig.map(({ dataField }) => dataField)]}
      colorMap={colorMap}
      title={isAdvancedSales ? '{runDay}' : '{formattedTime}'}
    />
  );

  return embeddedSeries.map((series, index) => {
    if (index === 0) {
      series.tooltip = tooltipConfig;
      series.tooltipHTML = tooltipMarkup;
    }

    series.bullets = [
      {
        type: 'CircleBullet',
        circle: {
          radius: 3,
          fill: WHITE,
        },
        scale: 0,
        states: {
          hover: {
            properties: {
              scale: 1.8,
            },
          },
        },
      },
    ];

    if (series.id === BASE_SERIES_ID) {
      series.bullets[0].states.now = {
        properties: {
          scale: 1,
        },
      };

      // Setting "now" state on base series for the point
      // that corresponds to now.
      series.bullets[0].events = {
        ready: ({ target }) => {
          const data = target.dataItem.dataContext;

          if (isToday && isNow(data)) {
            target.setState('now');
          }
        },
        out: ({ target }) => {
          const data = target.dataItem.dataContext;

          if (isToday && isNow(data)) {
            setTimeout(() => {
              target.setState('now');
            }, 300);
          }
        },
      };
    }

    series.fill = {
      type: 'LinearGradient',
      rotation: 90,
      stops: [
        {
          color: series.stroke,
        },
        {
          color: 'transparent',
        },
      ],
    };
    series.fillOpacity = 0.4;

    return series;
  });
};

let commonFullScreenConfig = {
  ...getCoreConfig(),
  cursor: {
    type: 'XYCursor',
    behavior: 'panX',
    inert: true,

    lineX: {
      stroke: TOOLTIP_BACKGROUND,
      strokeOpacity: 1,
      strokeDasharray: '0',
      strokeWidth: 2,
    },
    lineY: {
      disabled: true,
    },
  },
  scrollbarX: {
    type: 'Scrollbar',

    background: {
      fill: OFF_WHITE,
      height: 8,
    },

    startGrip: {
      ...gripStyles,
      cursorDownStyle: {
        ...gripStyles,
      },
    },

    endGrip: {
      ...gripStyles,
      cursorDownStyle: {
        ...gripStyles,
      },
    },

    thumb: {
      strokeWidth: 0,
      background: {
        fill: DARK_BLUE,
      },
    },
  },
  zoomOutButton: {
    disabled: true,
  },
  properties: {
    paddingLeft: 0,
  },

  events: {
    // Moving scrollbar to bottom of chart
    beforevalidated: ({ target }) => {
      let chart = target;

      chart.scrollbarX.parent = chart.bottomAxesContainer;
    },
    // Zooming into 3 hours either side of current time
    // Keeping commented deliberately in case we need it
    // ready: ({ target }) => {
    //   const dateAxis = target.xAxes.getIndex(0);
    //   const currentHours = new Date().getHours();
    //   const startHours = currentHours - 3;
    //   const endHours = currentHours + 3;

    //   const start = new Date();
    //   start.setMinutes(0);
    //   start.setHours(startHours);

    //   const end = new Date();
    //   end.setMinutes(0);
    //   end.setHours(endHours);

    //   dateAxis.zoomToDates(start, end);
    // },
  },
};

// Adding axis lines to yAxis
commonFullScreenConfig.yAxes[0].renderer = {
  ...commonFullScreenConfig.yAxes[0].renderer,
  baseGrid: {
    stroke: OFF_WHITE,
  },
  grid: {
    stroke: OFF_WHITE,
  },
};

export const getOverlayConfig = (isToday) => {
  const now = new Date();
  if (now.getMinutes() % 15 !== 0) {
    const fifteenMinutesFromNow = moment().add(15, 'minutes');
    const minsToNearest15 = Math.floor(fifteenMinutesFromNow.minute() / 15) * 15;

    if (minsToNearest15 !== 0) {
      now.setMinutes(minsToNearest15);
      now.setHours(fifteenMinutesFromNow.hour());
    }
  }

  let fullScreenConfig = {
    ...commonFullScreenConfig,
    xAxes: commonFullScreenConfig.xAxes.map((axis) => ({
      ...axis,
      type: 'DateAxis',
      dataFields: {
        ...axis.dataFields,
        category: 'time',
      },
      renderer: {
        ...axis.renderer,
        location: 0.0001,
        minGridDistance: 35,
      },
      gridIntervals: [
        { timeUnit: 'hour', count: 1 },
        { timeUnit: 'hour', count: 2 },
      ],
    })),
  };

  return _cloneDeep(fullScreenConfig);
};
export const getAdvancedSalesOverlayConfig = () => {
  let fullScreenAdvancedSalesConfig = {
    ...commonFullScreenConfig,
    xAxes: commonFullScreenConfig.xAxes.map((axis) => ({
      ...axis,
      type: 'CategoryAxis',
      dataFields: {
        ...axis.dataFields,
        value: 'value',
        category: 'id',
      },
      renderer: {
        ...axis.renderer,
        minGridDistance: 25,
      },
    })),
  };

  return _cloneDeep(fullScreenAdvancedSalesConfig);
};
