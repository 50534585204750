import React, { useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUserLocale } from '@modules/UserSettings';
import setupMoment from './setupMoment';
import en from '../../i18n/en';
import es from '../../i18n/es';
import de from '../../i18n/de';
import pt from '../../i18n/pt';

const DEFAULT_LOCALE = 'en';

const getMessages = (locale) => {
  let messages = {};

  switch (locale) {
    case 'es':
      messages = es;
      break;
    case 'de':
      messages = de;
      break;
    case 'pt':
      messages = pt;
      break;
    default:
      messages = en;
      break;
  }

  return { ...en, ...messages };
};

/**
 * Creates IntlProvider locale and messages props.
 * Sets up moment locale.
 * Should only be run on app start.
 */
function createLocale(locale) {
  const messages = getMessages(locale);

  setupMoment(locale, messages);

  return { locale, messages };
}

const LocaleProvider = ({ children }) => {
  const userLocale = useSelector(getUserLocale) || DEFAULT_LOCALE;
  const localeProps = useMemo(() => createLocale(userLocale), [userLocale]);

  return <IntlProvider {...localeProps}>{children}</IntlProvider>;
};

export default LocaleProvider;
