import React from 'react';
import { useSelector } from 'react-redux';
import _isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { CapacityNumberBlock, MetricNumber, MetricTitle, metricKeyPropType } from '@modules/Metrics';
import { PercentageMovement, Heading, Text } from '@components';
import { isMetricValueEmpty } from '@modules/Core';

import { isFetchingMetrics } from '../selectors';
import { useMetricValueMovement, useMetricValue } from '../hooks';

const componentName = 'metric-option';

const MetricValue = ({ metricKey }) => {
  const value = useMetricValue(metricKey);
  return <MetricNumber metricKey={metricKey} number={value} />;
};

const CapacityValue = ({ size }) => {
  const capacity = useMetricValue('capacity');
  const capacityRemaining = useMetricValue('capacityRemaining');
  return <CapacityNumberBlock size={size} capacity={capacity} capacityRemaining={capacityRemaining} />;
};

/*
 * Metric Value Number
 */
const MetricOptionValue = ({ metricKey, size }) => {
  const isLoading = useSelector(isFetchingMetrics);
  return (
    <div className={`${componentName}__value`}>
      {isLoading ? (
        ' - '
      ) : (
        <Text size={size} bold>
          {metricKey === 'capacity' ? <CapacityValue size={size} /> : <MetricValue metricKey={metricKey} />}
        </Text>
      )}
    </div>
  );
};

/**
 * Metric Name
 */
const MetricOptionTitle = ({ metricKey, size }) => {
  return (
    <Heading level={3} size={size} bold={false} className={`${componentName}__title`}>
      <MetricTitle metricKey={metricKey} />
    </Heading>
  );
};

/**
 * Percentage Movement Indicator
 */
const MetricOptionMovement = ({ metricKey, size }) => {
  const isLoading = useSelector(isFetchingMetrics);
  const [valueMovement] = useMetricValueMovement(metricKey);

  // We never render Movement values for capacity metric.
  return metricKey !== 'capacity' && !_isUndefined(valueMovement) ? (
    <div className={`${componentName}__movement`}>
      {!isLoading && !isMetricValueEmpty(valueMovement) ? (
        <PercentageMovement value={valueMovement} size={size} />
      ) : (
        ' - '
      )}{' '}
    </div>
  ) : null;
};

/**
 * MetricBlockOption
 * Stacks value, title & movement one below each other.
 */
const MetricBlockOption = ({ metricKey }) => {
  return (
    <>
      <MetricOptionValue metricKey={metricKey} size="l" />
      <MetricOptionTitle metricKey={metricKey} size="xs" />
      <MetricOptionMovement metricKey={metricKey} size="xxs" />
    </>
  );
};

/**
 * MetricInlineOption
 * Value, title & movement will all sit inline together.
 */
const MetricInlineOption = ({ metricKey }) => {
  return (
    <>
      <MetricOptionTitle metricKey={metricKey} size="s" />
      <MetricOptionValue metricKey={metricKey} size="xxl" />
      <MetricOptionMovement metricKey={metricKey} size="xs" />
    </>
  );
};

const MetricOptionComponent = React.forwardRef(function MetricOption({ inline, metricKey }, ref) {
  return (
    <div
      ref={ref}
      className={classnames(`${componentName}`, {
        [`${componentName}--inline`]: inline,
        [`${componentName}--block`]: !inline,
      })}
    >
      {inline ? <MetricInlineOption metricKey={metricKey} /> : <MetricBlockOption metricKey={metricKey} />}
    </div>
  );
});

MetricOptionComponent.propTypes = {
  metricKey: metricKeyPropType.isRequired,
  inline: PropTypes.bool,
};

MetricOptionComponent.defaultProps = {
  inline: false,
};

export default MetricOptionComponent;
