import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Text } from '@components';
import { BASE_CLASS } from '../constants';

const BookmarksToggle = ({ onClick }) => (
  <div className={`${BASE_CLASS}__toggle`} onClick={onClick}>
    <Button icon="bookmark" />
    <Text>
      <FormattedMessage id={'bookmarks'} />
    </Text>
  </div>
);

export default BookmarksToggle;
