import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { Text } from '@components';
import moment from 'moment';
import { useDates } from '@modules/FilterTypes/dates';
import { useLastRefresh } from '@modules/DataRefresh';
import { useUserTimeFormat } from '@modules/Auth';

const DATE_DISPLAY_FORMAT = 'ddd, MMM D, YYYY';
const DATE_STORAGE_FORMAT = 'YYYY-MM-DD';

function DateComparisonPeriod() {
  const [dates] = useDates();
  const { formatMessage } = useIntl();
  const lastRefresh = useLastRefresh();
  const timeFormat = useUserTimeFormat();
  const isToday = useMemo(() => {
    return dates[0] === moment().format(DATE_STORAGE_FORMAT);
  }, [dates]);
  const comparisonDate = useMemo(() => {
    return moment(dates[0], DATE_STORAGE_FORMAT).subtract(1, 'week').format(DATE_DISPLAY_FORMAT);

    // We want lastRefresh to update the time rendered here so using it
    // as an additional unwanted dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, lastRefresh]);

  return (
    <div className="date-comparison-period">
      <Text size="xs" bold>
        {isToday
          ? formatMessage({ id: 'vs_x_until_y' }, { x: comparisonDate, y: moment().format(timeFormat) })
          : formatMessage({ id: 'vs_x' }, { x: comparisonDate })}
      </Text>
    </div>
  );
}

export default DateComparisonPeriod;
