import { asyncAction } from '@showtime/cea-dep-utility';

import { API_ROOT_PATH } from '@modules/Core';
import {
  PERFORMANCES_FETCH_KEY,
  PERFORMANCE_MODAL_DATA_FETCH_KEY,
  PERFORMANCE_ATTRIBUTES_FETCH_KEY,
} from './constants';
import { SET_PERFORMANCES, SET_PERFORMANCE_ATTRIBUTES, SET_PERFORMANCE_MODAL_DATA } from './actionTypes';
import { getDefaultParams, calculateCapacityRemaining } from '@modules/Core';

const setPerformances = (performances) => ({
  type: SET_PERFORMANCES,
  performances,
});

export const loadPerformances =
  (params, dataPath = '', subSectionPath = '') =>
  (dispatch) => {
    dispatch(
      asyncAction({
        key: PERFORMANCES_FETCH_KEY,
        url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/occupancy/performances?_query=json`,
        body: {
          ...params,
          ...getDefaultParams(),
        },
        method: 'POST',
        success: ({ results }) => dispatch(setPerformances(results)),
      })
    );
  };

const setPerformanceAttributes = (performanceAttributes) => ({
  type: SET_PERFORMANCE_ATTRIBUTES,
  performanceAttributes,
});

export function performanceModalAttributesRequest(performanceId) {
  return asyncAction({
    key: PERFORMANCE_ATTRIBUTES_FETCH_KEY,
    url: `${API_ROOT_PATH}/performanceAttributes/${performanceId}`,
    success(response) {
      this.dispatch(setPerformanceAttributes(response));
    },
  });
}

const setPerformanceModalData = (performanceModalData) => ({
  type: SET_PERFORMANCE_MODAL_DATA,
  performanceModalData,
});

export function performanceModalMetricsRequest(metrics, params, subSectionPath = '', dataPath, excludeDeltas) {
  return asyncAction({
    key: PERFORMANCE_MODAL_DATA_FETCH_KEY,
    url: `${API_ROOT_PATH}${subSectionPath}${dataPath}?_query=json`,
    body: {
      ...params,
      ...getDefaultParams(),
      mets: metrics,
      ...(excludeDeltas ? {} : { delta: metrics }),
    },
    method: 'POST',
    success(response) {
      if (metrics.includes('capacity')) {
        response.capacityRemaining = calculateCapacityRemaining(response.admissions, response.capacity);
      }

      this.dispatch(setPerformanceModalData(response));
    },
  });
}
