import { useEffect, useRef, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useResizeWidthChanged } from '@modules/Core';

import { SCROLL_TRIGGER_ID } from '../constants';

const METRIC_TIMELINE_EASE_IN = 'power1.in';
const METRIC_TIMELINE_EASE_OUT = 'power1.out';

// Height of hero item + some extra pixels for padding.
const TOP_POSITION_OFFSET = 36;

/**
 * useMetricListHeroAnimation Hook.
 *
 * This hook will trigger an animation on scroll to pin (position:fix) the metricList to the page
 * after a certain point.
 * If shouldHideFirstMetric it will also fire animation to slide in the first item in the list after a certain point.
 *
 * @param {bool} shouldHideFirstMetric determines whether to trigger the animation which hides the first item in the block list or not.
 * @param {topPosition} topPosition - Current top position of the metric list container relative to the viewport top.
 * @param {bool} isMounted - Extra check to only create a ScrollTrigger instance if ListWithHero has already been mounted.
 */
const useMetricListHeroAnimation = (shouldHideFirstMetric, isMounted, topPosition) => {
  let heroContainerRef = useRef(null);

  // When width changes, scroll body back to top so metricList can re-position correctly
  const handleWidthChange = useCallback((width) => {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: 0,
    });
    ScrollTrigger.refresh();
  }, []);

  useResizeWidthChanged(heroContainerRef.current, handleWidthChange);

  useEffect(() => {
    let animTimeline = gsap.timeline().pause();
    const pinPositionClass = 'pin-position';

    if (shouldHideFirstMetric && heroContainerRef.current) {
      const listEl = heroContainerRef.current.querySelector('.metric-list-layout--block .metric-list__list');
      const listElWidth = listEl ? listEl.clientWidth : null;
      const listElScrollWidth = listEl ? listEl.scrollWidth : null;

      const listItemEl = heroContainerRef.current.querySelector('.metric-list-layout--block .list__item--0');
      const listItemElWidth = listItemEl ? listItemEl.clientWidth : null;

      // Scroll width minue one listItem width.
      const scrollWidthMinusOne = listElScrollWidth - listItemElWidth;

      /**
       * For the animation start position,
       * A. Center listItems if:
       *    If total scrollWidth - (listItemWidth * 1) is less than current allowed listContainerWidth.
       *
       * B. Move the listItems over to the left by (listItemWidth * 1) if:
       *    If total scrollWidth - (listItemWidth * 1) is more than current allowed listContainerWidth.
       */
      const xFromPosition =
        scrollWidthMinusOne < listElWidth
          ? -((listElScrollWidth - listElWidth) / 2 + listItemElWidth / 2)
          : -listItemElWidth;

      animTimeline
        .set(listItemEl, {
          opacity: 0,
        })
        .set(listEl, {
          x: xFromPosition,
        })
        .fromTo(
          listEl,
          {
            x: xFromPosition,
            duration: 0.1,
            ease: METRIC_TIMELINE_EASE_OUT,
          },
          {
            x: 0,
            duration: 0.2,
            ease: METRIC_TIMELINE_EASE_IN,
          }
        )
        .fromTo(
          listItemEl,
          {
            opacity: 0,
            duration: 0.1,
            ease: METRIC_TIMELINE_EASE_OUT,
          },
          {
            opacity: 1,
            duration: 0.1,
            ease: METRIC_TIMELINE_EASE_IN,
          }
        );
    }

    if (heroContainerRef.current && isMounted && topPosition) {
      const currentScrollTrigger = ScrollTrigger.getById(SCROLL_TRIGGER_ID);

      // Depending on where the metricList is rendered in the Layout, it's starting animation
      // positions may need to be altered. This is because the scrollTrigger trigger is calculated from the viewport top.
      const animStartPos = shouldHideFirstMetric ? topPosition - TOP_POSITION_OFFSET : topPosition;

      if (!currentScrollTrigger) {
        // Only create a new instance if one doesn't already exist.
        ScrollTrigger.create({
          id: SCROLL_TRIGGER_ID,
          animation: animTimeline,
          trigger: heroContainerRef.current,
          start: `-${animStartPos}px top`,
          pin: true,
          pinSpacing: false,
          anticipatePin: 1,
          toggleActions: 'restart none none reverse',
          onLeave: () => {
            if (heroContainerRef.current) {
              const parentRect = heroContainerRef.current.getBoundingClientRect();
              // Imitating ScrollTrigger Pin functionality inlined styles here to keep element in place.
              heroContainerRef.current.style.left = `${parentRect.left}px`;
              heroContainerRef.current.style.transform = `translate3d(0px, ${animStartPos}px, 0px)`;
              heroContainerRef.current.classList.add(pinPositionClass);
            }
          },
          onEnterBack: () => {
            if (heroContainerRef.current) {
              heroContainerRef.current.classList.remove(pinPositionClass);
              heroContainerRef.current.style.transform = 'translate3d(0px, 0px, 0px)';
            }
          },
        });
      }
    }

    return () => {
      animTimeline.pause(0).kill(true);
      animTimeline = null;
    };
  }, [heroContainerRef, shouldHideFirstMetric, isMounted, topPosition]);

  useEffect(() => {
    return () => {
      // If the topPosition has changed, Kill the current scrollTrigger so it can create a new one with the correct new topPosition.
      const currentScrollTrigger = ScrollTrigger.getById(SCROLL_TRIGGER_ID);
      if (currentScrollTrigger) {
        currentScrollTrigger.kill(true);
      }
    };
  }, [topPosition]);

  const heroContainerRefBinder = heroContainerRef;

  return [heroContainerRefBinder];
};

export default useMetricListHeroAnimation;
