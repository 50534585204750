import PropTypes from 'prop-types';

export const STATE_KEY = 'filmAnalysisComps';
export const COMPS_LIST_FETCH_KEY = 'fetchCompsList';
export const BASE_COLOR = '#fd7e14';
export const COMPS_COLORS = ['#20c997', '#ea78b7', '#f5af25'];
export const COMPS_COLOR_CLASSES = ['bg-color--a', 'bg-color--b', 'bg-color--c'];

export const COMPS_DATA_TYPE_REAL = 'realTime';
export const COMPS_DATA_TYPE_MARKET = 'market';
export const COMPS_DATA_TYPE_YOUR = 'your';
export const COMPS_DATA_TYPES = [COMPS_DATA_TYPE_REAL, COMPS_DATA_TYPE_MARKET, COMPS_DATA_TYPE_YOUR];

export const compsPropType = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.oneOf(COMPS_COLORS),
  colorClass: PropTypes.oneOf(COMPS_COLOR_CLASSES),
  admissionsOpeningDay: PropTypes.number,
  admissionsOpeningWknd: PropTypes.number,
  admissionsTotalRun: PropTypes.number,
  eventRevenueOpeningDay: PropTypes.number,
  eventRevenueOpeningWknd: PropTypes.number,
  eventRevenueTotalRun: PropTypes.number,
  day: PropTypes.number,
  date: PropTypes.string,
  dayAdmissions: PropTypes.number,
  dayEventRevenue: PropTypes.number,
  circuitAdmissionsTotalRun: PropTypes.number,
  circuitEventRevenueTotalRun: PropTypes.number,
};

export const COMPS_AVAILABLE_METRICS = ['eventRevenueBO', 'eventRevenue', 'distributorReportedPrice', 'admissions'];

export const compsMetricsPropType = PropTypes.oneOf(COMPS_AVAILABLE_METRICS);
