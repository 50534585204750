import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from '@components';
import { useSelectedMetric } from '@modules/Comps';
import { MetricNumber } from '@modules/Metrics';
import { useSelector } from 'react-redux';
import { isLoadingBaseEvent } from '../../Comps';

export const Stat = ({ label, value, shareValue }) => {
  const [selectedMetric] = useSelectedMetric();
  const isLoading = useSelector(isLoadingBaseEvent);

  return (
    <div className={classnames('stat', { 'stat--skeleton': isLoading })}>
      <div className={'stat__metric-ctn'}>
        <Text className="stat__label" size="s">
          {!isLoading && label}
        </Text>
        <Text size="s" bold className="stat__value">
          {!isLoading && <MetricNumber metricKey={selectedMetric} number={value} />}
        </Text>
        {shareValue && (
          <Text className="stat__share" size="s" bold>
            {!isLoading && shareValue}
          </Text>
        )}
      </div>
    </div>
  );
};

Stat.propTypes = {
  logo: PropTypes.node,
  label: PropTypes.node,
  dayDate: PropTypes.string,
  value: PropTypes.node,
  shareValue: PropTypes.node,
};

export const StatGroup = ({ header, children, marketDataNotTracked }) => {
  return (
    <>
      <div className="stat__logo-ctn">{header}</div>
      {marketDataNotTracked ? (
        <div className="stat__no-market-data">
          <FormattedMessage id={'comps_market_data_not_tracked'} />
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

StatGroup.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  marketDataNotTracked: PropTypes.bool,
};
