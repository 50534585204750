import { getSearchParameterByName, decompressJSON } from '@modules/Core';

// TODO: this removes all query params - will become a pain if we required any in future
export const getSharedState = () => {
  const filtersLink = getSearchParameterByName('share');

  if (filtersLink !== null) {
    // Removing the hash url from the browser window
    window.history.pushState(null, null, window.location.pathname);

    return decompressJSON(filtersLink);
  }

  return null;
};
