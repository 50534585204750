import { useParametersListener } from '@modules/FilterTypes';
import { useLastRefresh } from '@modules';
import { useAction, useAPIPath, useIsMarketAnalysis } from '@modules/Core';
import { useEventParamKey, MA_EVENT_PARAM_KEY } from '@modules/FilterTypes/compsTitles';

import { getParametersForReportingSummary } from '../selectors';
import { fetchReportingSummary } from '../actions';

/**
 * useSummaryDataFetch
 * Fires to get latest reporting summary stats.
 * Will re-fire if any of the filters added to getParametersForReportingSummary update
 */
export const useSummaryDataFetch = (globalParams) => {
  const fetchReportingSummaryInfo = useAction(fetchReportingSummary);
  const lastRefresh = useLastRefresh();
  const isMarketAnalysis = useIsMarketAnalysis();
  const [subSectionPath] = useAPIPath(false);
  const [eventParamKey] = useEventParamKey();

  useParametersListener(
    getParametersForReportingSummary,
    (params) => {
      // If isMarketAnalysis is true, then eventParamKey should be MA_EVENT_PARAM or else we get a 400 error
      if (params.territoryId && (!isMarketAnalysis || eventParamKey === MA_EVENT_PARAM_KEY)) {
        fetchReportingSummaryInfo(
          {
            ...params,
            ...globalParams,
          },
          eventParamKey,
          subSectionPath
        );
      }
    },
    [lastRefresh, globalParams, eventParamKey, isMarketAnalysis, subSectionPath]
  );
};
