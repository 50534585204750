import Container from './Container';
import Content from './Content';
import Header from './Header';
import Footer from './Footer';

import './_auth-container.scss';
import './_auth-header.scss';
import './_auth-footer.scss';

export default {
  Container,
  Content,
  Header,
  Footer,
};
