import React, { useEffect, useMemo } from 'react';
import { arrayOf } from 'prop-types';
import { CompsList, CompsSelectionDrawer } from '@modules';
import { useIsTodayOrFuture, useIsHistorical, useAction } from '@modules/Core';
import { CompsColors, CompsMetricToggle, COMPS_AVAILABLE_METRICS, useSelectedMetric } from '@modules/Comps';
import { CompsBarChart, CompsLineChart } from '@modules/CompsChart';
import { metricKeyPropType } from '@modules/Metrics';
import { setSelected } from '@modules/FilterTypes/metric';
import { useStudioHelpers } from '@pages/studio/hooks';

import ViewTransition from './ViewTransition';

const propTypes = {
  metrics: arrayOf(metricKeyPropType),
};

const ViewComps = ({ metrics }) => {
  const setSelectedMetric = useAction(setSelected);
  const [selectedMetric] = useSelectedMetric();

  const { isIntegrated, isPresales } = useStudioHelpers();
  const isTodayOrFuture = useIsTodayOrFuture();
  const isHistorical = useIsHistorical();

  // Initializing selected metric as the first compsMetric
  useEffect(() => {
    setSelectedMetric(metrics[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics]);

  const isCompsBarGraphVisible = useMemo(() => {
    return !isIntegrated || (isIntegrated && isHistorical) || (isIntegrated && isTodayOrFuture && !isPresales);
  }, [isIntegrated, isHistorical, isTodayOrFuture, isPresales]);

  const isCompsCumulativeTrendChartVisible = useMemo(() => isIntegrated && isTodayOrFuture && isPresales, [
    isIntegrated,
    isTodayOrFuture,
    isPresales,
  ]);

  return COMPS_AVAILABLE_METRICS.includes(selectedMetric) ? (
    <ViewTransition className="film-metadata__view film-metadata__view--comps">
      <div className="comps__toggle">
        <CompsMetricToggle metrics={metrics} />
      </div>
      <div className="comps__chart">
        {isCompsBarGraphVisible && <CompsBarChart metrics={metrics} />}
        {isCompsCumulativeTrendChartVisible && <CompsLineChart metrics={metrics} />}
      </div>
      <CompsList className="comps__list" />
      <CompsSelectionDrawer />
      <CompsColors />
    </ViewTransition>
  ) : null;
};

ViewComps.propTypes = propTypes;

export default ViewComps;
