import { createSelector } from '@reduxjs/toolkit';
import _some from 'lodash/some';
import _property from 'lodash/property';
import _isNil from 'lodash/isNil';

import { isLoading } from '@showtime/cea-dep-utility';

import { getMovementKey, getChangeKey } from '@modules/Metrics';
import { KEY as dates } from '@modules/FilterTypes/dates';
import { KEY as territory } from '@modules/FilterTypes/territory';
import { KEY as currency } from '@modules/FilterTypes/currency';
import { KEY as sites } from '@modules/FilterTypes/sites';
import { KEY as performances } from '@modules/FilterTypes/performance';

import { getParametersFactory } from '@modules/FilterTypes';

import { STATE_KEY } from './constants';

export const getParamsForMetrics = getParametersFactory([dates, territory, currency, sites, performances]);

export const getMetricList = (state) => state[STATE_KEY];

export const getMetricData = createSelector([getMetricList], _property('data'));

export const isFetchingMetrics = (state) => isLoading(state, 'metric.request');

export const getMetricValueFactory = (metricKey) =>
  createSelector([getMetricData], (data) => {
    return data[metricKey] || 0;
  });

export const getMetricValueMovementFactory = (metricKey) =>
  createSelector([getMetricData], (data) => {
    return { valueMovement: data[getMovementKey(metricKey)], valueChange: data[getChangeKey(metricKey)] };
  });

export const hasDataMovement = createSelector([getMetricData], (data) =>
  _some(data, (value, key) => key.includes('Movement') && !_isNil(value))
);
