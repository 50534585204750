import _debounce from 'lodash/debounce';
import { createAction } from '@reduxjs/toolkit';

import { asyncAction } from '@showtime/cea-dep-utility';

import { getDefaultParams, calculateCapacityRemaining, API_ROOT_PATH } from '@modules/Core';

const prefix = 'metricList';

const SET_METRICS = `${prefix}/SET_METRICS`;
const CLEAN_METRICS = `${prefix}/CLEAN_METRICS`;

export const setMetrics = createAction(SET_METRICS, (metrics, values) => {
  return {
    payload: {
      metrics,
      values,
    },
  };
});
export const cleanMetrics = createAction(CLEAN_METRICS);

// Putting a small debounce on the request so quick changing deps dont trigger multiple requests
const debouncedMetricRequest = _debounce((dispatch, metrics, params, subSectionPath = '', dataPath, excludeDeltas) => {
  dispatch(cleanMetrics());

  dispatch(
    asyncAction({
      key: 'metric.request',
      url: `${API_ROOT_PATH}${subSectionPath}${dataPath}?_query=json`,
      body: {
        ...params,
        ...getDefaultParams(),
        mets: metrics.join(','),
        ...(excludeDeltas ? {} : { delta: metrics.join(',') }), 
      },
      method: 'POST',
      success(response) {
        const allMetrics = [...metrics];
        if (metrics.includes('capacity')) {
          response.capacityRemaining = calculateCapacityRemaining(response.admissions, response.capacity);

          allMetrics.push('capacityRemaining');
        }
        this.dispatch(setMetrics(allMetrics, response));
      },
    })
  );
}, 100);

export function metricRequest(metrics, params, subSectionPath, dataPath) {
  return (dispatch) => {
    debouncedMetricRequest(dispatch, metrics, params, subSectionPath, dataPath);
  };
}
