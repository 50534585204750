import React from 'react';
import { useIntl } from 'react-intl';

import ViewNotAvailableState from './ViewNotAvailableState';

const ViewTheatres = () => {
  const { formatMessage } = useIntl();

  return <ViewNotAvailableState message={formatMessage({ id: 'circuits_view_not_available_advanced_sales' })} />;
};

export default ViewTheatres;
