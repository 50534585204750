import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import { hasRule } from '@showtime/cea-dep-permissions';

import { Spinner } from '@components';
import { AppLayout } from '@modules';
import { getIsAuthenticated } from '@modules/Auth';
import { isStudio, isExhibitor, URL_PATHS } from '@modules/Core';

import { hasExhibitorFilmPerspective } from './exhibitor/FilmPerspective';
import { hasExhibitorTheatrePerspective } from './exhibitor/TheatrePerspective';
import { hasExhibitorMarketPerspective } from './exhibitor/MarketPerspective';
import { hasExhibitorAdvancedSalesPerspective } from './exhibitor/AdvancedSalesPerspective';
import { hasStudioFilmPerspective } from './studio/FilmPerspective';
import { hasStudioAdvancedSalesPerspective } from './studio/AdvancedSalesPerspective';

import UserRouter from './user';

const lazyImport = (filename) => lazy(() => import(`${filename}`));

const Exhibitor = lazyImport('./exhibitor');
const Studio = lazyImport('./studio');

const isExhibitorApp = isExhibitor();
const isStudioApp = isStudio();

// TODO better fallback maybe? keeping it ultra basic for now
// If performance finds a need then we should look at making something
// a bit nicer w/designs help
const LoadingMessage = () => <Spinner />;

function renderDefaultPage(hasTheatres, hasMarket) {
  if (isExhibitorApp) {
    if (hasTheatres) {
      return <Route render={() => <UserRouter redirectTo={`/${URL_PATHS.THEATRES}`} />} />;
    } else if (hasMarket) {
      return <Route render={() => <UserRouter redirectTo={`/${URL_PATHS.MARKETS}`} />} />;
    }
    return <Route render={() => <UserRouter redirectTo={`/${URL_PATHS.FILMS}`} />} />;
  } else {
    return <Route render={() => <UserRouter redirectTo={`/${URL_PATHS.FILMS}`} />} />;
  }
}

function Routes() {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const hasFilms = (isExhibitorApp && hasExhibitorFilmPerspective()) || (isStudioApp && hasStudioFilmPerspective());
  const hasAdvancedSales =
    (isExhibitorApp && hasExhibitorAdvancedSalesPerspective()) || (isStudioApp && hasStudioAdvancedSalesPerspective());
  const hasTheatres = hasExhibitorTheatrePerspective();
  const hasMarket = hasExhibitorMarketPerspective();

  const paths = [
    hasFilms ? `/${URL_PATHS.FILMS}` : '',
    hasTheatres ? `/${URL_PATHS.THEATRES}` : '',
    hasMarket ? `/${URL_PATHS.MARKETS}` : '',
    hasAdvancedSales ? `/${URL_PATHS.ADVANCED_SALES}` : '',
  ].filter(Boolean);

  return (
    <AppLayout.Provider>
      <AppLayout.ErrorBoundary>
        <AppLayout.ThemeChanger />
        <Switch>
          {isAuthenticated ? (
            <Route
              path={paths}
              render={() => (
                <Suspense fallback={<LoadingMessage />}>
                  {isExhibitorApp && <Exhibitor />}
                  {isStudioApp && <Studio />}
                </Suspense>
              )}
            />
          ) : null}
          {renderDefaultPage(hasTheatres, hasMarket)}
        </Switch>
      </AppLayout.ErrorBoundary>
    </AppLayout.Provider>
  );
}

export default Routes;
