import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@components';
import { useResizeWidthChanged } from '@modules/Core';
import popcorn from '@images/popcorn.svg';

const DEFAULT_MAX_WIDTH = 768;

const componentName = 'no-access-wrapper';

/**
 * No Access Wrapper component
 * 
 * Wraps all pages of the app, which should only be visible on mobile. 
 * This component will return a no access error page when opening the application on screens larger than DEFAULT_MAX_WIDTH.
 * 
 */

const NoAccessWrapper = ({ children }) => {
  const [isNoAccessVisible, setIsNoAccessVisible] = useState(false);
  const [pageWidth, setPageWidth] = useState(DEFAULT_MAX_WIDTH);

  const handleWidthChange = useCallback(
    (width) => {
      setPageWidth(width);

      if (width < DEFAULT_MAX_WIDTH) {
        setIsNoAccessVisible(false);
      } else {
        setIsNoAccessVisible(true);
      }
    },
    [pageWidth]
  );

  useResizeWidthChanged(null, handleWidthChange);
  
  return (
    <>
      {isNoAccessVisible ? (
        <div className={`${componentName}__content`}>
          <img className={`${componentName}__image-popcorn`} src={popcorn} />
          <Text className={`${componentName}__title`} bold size="m">
            <FormattedMessage id="no_access_title" />
          </Text>
          <Text className={`${componentName}__text`} size="m">
            <FormattedMessage id="no_access_subtext" />
          </Text>
        </div>
      ) : (
        <>{ children }</>
      )}
    </>
  );
};

export default NoAccessWrapper;
