import _get from 'lodash/get';


export function getSearchParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * This function is able to return the first name in url excluding the rest of the params.
 * We will use this function whenever we need to know which main page are we at.
 *
 * return {string} Either 'films' or 'theatres'.
 * */
export function getCurrentPathname() {
  const pathname = getFullPathname();
  return pathname.length >= 1 ? pathname.split('/')[1] : undefined;
}

/**
 * This function is able to return the full pathname.
 * */
export function getFullPathname() {
  return _get(window.location, 'pathname', '');
}
