import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as classnames from 'classnames';
import moment from 'moment';
import _isNull from 'lodash/isNull';
import { useIsToday } from '@modules/Core';
import { TimeLine, ScreensCard, PerformanceContent, CurrentTimeLineDivider } from './../components';
import { leftPositionFromStartHour } from '../helpers';
import { getEarliestPerformanceTime, getPerformancesGroupedByScreen, isPerformanceRequestLoading } from '../selectors';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Text from '@components/Typography/Text';
import { Spinner } from '@components';

const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const componentClass = 'planner-view__content';

const NoDataAvailable = () => (
  <Text className={`${componentClass}__no-data`}>
    <FormattedMessage id={'no_data_available'} />
  </Text>
);

const PlannerViewMain = ({ className }) => {
  const containerYRef = useRef(null);
  const screenRef = useRef(null);
  const data = useSelector(getPerformancesGroupedByScreen);
  const earliestPerformanceTime = useSelector(getEarliestPerformanceTime);
  const isLoadingData = useSelector(isPerformanceRequestLoading);
  const isToday = useIsToday();

  useEffect(() => {
    if (!_isNull(earliestPerformanceTime)) {
      let scrollToTime = null;

      if (isToday) {
        scrollToTime = moment();
      } else {
        scrollToTime = moment(earliestPerformanceTime);
      }

      const width = containerYRef.current.clientWidth;
      const screenWidth = screenRef.current.clientWidth;
      const containerWidth = width - screenWidth;
      const leftPosition = leftPositionFromStartHour(scrollToTime);

      containerYRef.current.scrollLeft = leftPosition - containerWidth / 2;
    }
  }, [earliestPerformanceTime, isToday]);

  return (
    <div className={classnames(componentClass, className)}>
      <div className={'container-y'} ref={containerYRef}>
        <div className={'container-y__content'}>
          <div className={'container-x'}>
            <ScreensCard ref={screenRef} />
            <div className={'container-x__content'}>
              <TimeLine />
              <CurrentTimeLineDivider />
              <PerformanceContent />
            </div>
          </div>
        </div>
      </div>
      {!data.length && isLoadingData && <Spinner />}
      {!data.length && !isLoadingData && <NoDataAvailable />}
    </div>
  );
};

PlannerViewMain.propTypes = propTypes;
PlannerViewMain.defaultProps = defaultProps;

export default PlannerViewMain;
