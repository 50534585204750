import React, { useState } from 'react';
import { ShareDrawer, Bookmarks } from '@modules';
import { Button, ElementPopover } from '../../components';
import './_header-misc-actions.scss';

const componentClass = 'header-misc-actions';

const HeadersMiscActions = () => {
  const [isBookmarkOpen, setBookmarkOpen] = useState(false);
  const [isShareDrawerOpen, setShareDrawerOpen] = useState(false);

  return (
    <>
      <ElementPopover
        attachment="right"
        className={componentClass}
        contentClassName={`${componentClass}__menu`}
        portToElement={document.body}
        toggleElement={<Button className={`${componentClass}__button`} icon={'more'} />}
        contentElement={[
          <Bookmarks.Toggle key={'bookmark'} onClick={() => setBookmarkOpen(true)} />,
          <ShareDrawer.Toggle key={'share'} onClick={() => setShareDrawerOpen(true)} />,
        ]}
        closeOnClickContent
      />
      <Bookmarks isOpen={isBookmarkOpen} onClose={() => setBookmarkOpen(false)} />
      <ShareDrawer isOpen={isShareDrawerOpen} onClose={() => setShareDrawerOpen(false)} />
    </>
  );
}; 

export default HeadersMiscActions;
