import { createReducer } from '@reduxjs/toolkit';
import { STATE_KEY } from './constants';
import { SET_PERFORMANCES, SET_PERFORMANCE_ATTRIBUTES, SET_PERFORMANCE_MODAL_DATA } from './actionTypes';

let initialState = {
  data: [],
  performanceAttributes: [],
  performanceModalData: {},
};

const performancesReducer = createReducer(initialState, {
  [SET_PERFORMANCES]: (state, { performances }) => {
    state.data = performances ? performances : [];
    return state;
  },
  [SET_PERFORMANCE_ATTRIBUTES]: (state, { performanceAttributes }) => {
    state.performanceAttributes = performanceAttributes ? performanceAttributes : [];
    return state;
  },
  [SET_PERFORMANCE_MODAL_DATA]: (state, { performanceModalData }) => {
    state.performanceModalData = performanceModalData ? performanceModalData : [];
    return state;
  },
});

export default { [STATE_KEY]: performancesReducer };
