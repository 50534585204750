import { STATE_KEY } from './constants';

export const SET_BOOKMARKS_DATA = `${STATE_KEY}/SET_BOOKMARKS_DATA`;

export const APPEND_BOOKMARKS_DATA = `${STATE_KEY}/APPEND_BOOKMARKS_DATA`;

export const SET_BOOKMARK_SELECTED = `${STATE_KEY}/SET_BOOKMARK_SELECTED`;

export const CLEAR_BOOKMARKS_DATA = `${STATE_KEY}/CLEAR_BOOKMARKS_DATA`;

export const DELETE_BOOKMARK_ID = `${STATE_KEY}/DELETE_BOOKMARK_ID`;
