import { createAction } from '@reduxjs/toolkit';

/**
 * Global action describing global end
 * interpret as: Stop everything (if we could end pending async stuff ?) and cleanup.
 */

export const APP_END = 'core/APP_END';

export const appEnd = createAction(APP_END);
