import React from 'react';
import PropTypes from 'prop-types';

import ViewTransition from './ViewTransition';

import { Text } from '@components';

const componentClass = 'film-metadata__view';

function ViewNotAvailableState({ message }) {
  return (
    <ViewTransition className={`${componentClass} ${componentClass}--not-available`}>
      <div className="not-available-state">
        <Text className="not-available-state__text">{message}</Text>
      </div>
    </ViewTransition>
  );
}

ViewNotAvailableState.propTypes = {
  message: PropTypes.string,
};

export default ViewNotAvailableState;
