import { createAction } from '@reduxjs/toolkit';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';

import { asyncAction } from '@showtime/cea-dep-utility';
import { getDefaultParams, API_ROOT_PATH } from '@modules/Core';
import { STATE_KEY, POLLING_PERIOD } from './constants';
import { getPaused, getMetrics, getRefreshParams } from './selectors';

const UPDATE_VALUES = `${STATE_KEY}/UPDATE_VALUES`;
const SET_METRICS = `${STATE_KEY}/SET_METRICS`;
const SET_LAST_REFRESHED = `${STATE_KEY}/SET_LAST_REFRESHED`;
const PAUSED = `${STATE_KEY}/PAUSE_POLL`;
const RESET = `${STATE_KEY}/RESET`;

export const updateValues = createAction(UPDATE_VALUES);
export const setLastRefreshed = createAction(SET_LAST_REFRESHED);
export const pausePoll = createAction(PAUSED);
export const reset = createAction(RESET);
export const setMetrics = createAction(SET_METRICS);

let activeTimeout = null;

/**
 * Refresh poll runs in the background independently of the component lifecycle
 * The cleanUpRefreshPoll will end the poll if it is active.
 */
export const refreshPoll =
  (subSectionPath = '', dataPath, timeParams) =>
  (dispatch, getState) => {
    // This pulls values from the store when it runs,
    // as we want it to cycle outside of any events within the application
    // the useLastUpdatedUpdate hook resets the store when any dependencies
    // update.
    const state = getState();
    const metrics = getMetrics(state);
    const params = getRefreshParams(state);

    // If the metrics or params are empty, then just kick off a time out to try
    // again when there are values
    if (_isEmpty(metrics) || _isEmpty(params) || _isUndefined(dataPath)) {
      setTimeout(() => {
        dispatch(refreshPoll(subSectionPath, dataPath, timeParams));
      }, POLLING_PERIOD);
    } else {
      dispatch(
        asyncAction({
          key: 'refreshPoll.request',
          url: `${API_ROOT_PATH}${subSectionPath}${dataPath}?_query=json&component=refreshPoll`,
          body: {
            ...params,
            ...timeParams,
            ...getDefaultParams(),
            mets: metrics,
          },
          method: 'POST',
          success(response) {
            dispatch(updateValues(response));

            activeTimeout = setTimeout(() => {
              const paused = getPaused(getState());

              if (!paused) dispatch(refreshPoll(subSectionPath, dataPath, timeParams));
            }, POLLING_PERIOD);
          },
        })
      );
    }
  };

export function cleanUpRefreshPoll() {
  if (!_isNull(activeTimeout)) {
    clearTimeout(activeTimeout);
  }
}
