import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Section, HeaderGroup, Heading, useOverlay } from '@components';
import { TabLink, TAB_TRANSITION } from '@components/Tabs';
import { BASE_CLASS } from '../constants';

const propTypes = {
  prevIcon: PropTypes.string,
  prevPage: PropTypes.string,
  title: PropTypes.node,
};

const defaultProps = {
  prevIcon: 'exit',
};

const UserSettingsHeader = ({ prevIcon, prevPage, title }) => {
  const { close } = useOverlay();

  const onPrev = useCallback(() => {
    if (!prevPage) close();
  }, [close, prevPage]);

  return (
    <Section tag={HeaderGroup} className={`${BASE_CLASS}__header`}>
      <HeaderGroup.Left>
        <TabLink
          icon={prevIcon}
          tabTo={prevPage}
          onClick={onPrev}
          component={Button}
          tabTranistionType={TAB_TRANSITION.TORIGHT_REMAIN}
        />
      </HeaderGroup.Left>
      <HeaderGroup.Center>
        <Heading className={`${BASE_CLASS}__title`} level={2} size="m">
          {title}
        </Heading>
      </HeaderGroup.Center>
    </Section>
  );
};

UserSettingsHeader.propTypes = propTypes;
UserSettingsHeader.defaultProps = defaultProps;

export default UserSettingsHeader;
