import { useCallback } from 'react';
import { isStudio, isExhibitor } from '@modules/Core';
import { useStudioHelpers } from 'pages/studio/hooks';
import { useIsTodayOrFuture, useIsMarketAnalysis } from '../../Core';
import { COMPS_DATA_TYPE_MARKET, COMPS_DATA_TYPE_REAL, COMPS_DATA_TYPE_YOUR } from '../../Comps';

export const useCardType = () => {
  const isTodayOrFuture = useIsTodayOrFuture();
  const { isIntegrated } = useStudioHelpers();
  const isMarketAnalysis = useIsMarketAnalysis();

  const getCardType = useCallback(() => {
    if (isStudio() || (isExhibitor() && isMarketAnalysis)) {
      if (isIntegrated && isTodayOrFuture) return COMPS_DATA_TYPE_REAL;
      return COMPS_DATA_TYPE_MARKET;
    } else if (isExhibitor()) {
      return COMPS_DATA_TYPE_YOUR;
    }
  }, [isIntegrated, isTodayOrFuture, isMarketAnalysis]);

  const getCardTypeLabel = useCallback(() => {
    const cardType = getCardType();
    switch (cardType) {
      case COMPS_DATA_TYPE_REAL:
        return 'comps_real_time_data';
      case COMPS_DATA_TYPE_MARKET:
        return 'comps_market_data';
      case COMPS_DATA_TYPE_YOUR:
      default:
        return 'comps_your_data';
    }
  }, [getCardType]);

  return { getCardType, getCardTypeLabel };
};
