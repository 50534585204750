import { createReducer } from '@reduxjs/toolkit';

import _isUndefined from 'lodash/isUndefined';

import { METRICS, getMovementKey, getChangeKey } from '@modules/Metrics';

import { setMetrics, cleanMetrics } from './actions';
import { STATE_KEY } from './constants';

let initialState = {
  lastUpdated: new Date().toISOString(),
  data: {},
};

METRICS.forEach(({ key }) => {
  initialState.data[key] = null;
  initialState.data[getMovementKey(key)] = undefined;
  initialState.data[getChangeKey(key)] = null;
});

const metricListReducer = createReducer(initialState, {
  [setMetrics.toString()]: (state, { payload }) => {
    const { metrics, values } = payload;

    metrics.forEach((metricKey) => {
      const movementKey = getMovementKey(metricKey);
      const changeKey = getChangeKey(metricKey);

      state.data[metricKey] = values[metricKey];

      if (!_isUndefined(values[movementKey])) {
        state.data[movementKey] = values[movementKey];
      }

      if (!_isUndefined(values[movementKey])) {
        state.data[changeKey] = values[changeKey];
      }
    });

    return state;
  },
  [cleanMetrics.toString()]: () => initialState,
});

export default {
  [STATE_KEY]: metricListReducer,
};
