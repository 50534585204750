import { createAction } from '@reduxjs/toolkit';

import { asyncAction } from '@showtime/cea-dep-utility';

import _isArray from 'lodash/isArray';
import _debounce from 'lodash/debounce';

import { API_ROOT_PATH, getDefaultParams } from '@modules/Core';

import { THEATRES_LIST_PAGE_SIZE } from './constants';

import { STATE_KEY, SITE_LIST_FETCH_KEY, SITE_LAZY_LOAD_FETCH_KEY, SITE_FORMATS_FETCH_KEY } from './constants';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_LIST = 'SET_LIST';
export const setList = createAction(createActionType(SET_LIST));

const APPEND_LIST = 'APPEND_LIST';
export const appendList = createAction(createActionType(APPEND_LIST));

const SET_MAX_PAGES_REACHED = 'SET_MAX_PAGES_REACHED';
export const setMaxPagesReached = createAction(createActionType(SET_MAX_PAGES_REACHED));

const SET_LIST_FORMATS = 'SET_LIST_FORMATS';
export const setListFormats = createAction(createActionType(SET_LIST_FORMATS));

const CLEAR_LIST_FORMATS = 'CLEAR_LIST_FORMATS';
export const clearListFormats = createAction(createActionType(CLEAR_LIST_FORMATS));

const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const setCurrentPage = createAction(createActionType(SET_CURRENT_PAGE));

const SET_NUM_PAGES = 'SET_NUM_PAGES';
export const setNumPages = createAction(createActionType(SET_NUM_PAGES));

const debouncedSiteList = _debounce(
  (dispatch, siteAttributeGroupId, params, appendToExisting, subSectionPath = '', dataPath, excludeDeltas) => {
    let { ...restParams } = params;
    let groupBy = 'stanSiteId';

    if (siteAttributeGroupId !== null) {
      groupBy = 'stanSiteGroupAttributeId';

      restParams = {
        ...restParams,
        siteGroupId: [siteAttributeGroupId],
      };
    }

    dispatch(
      asyncAction({
        key: appendToExisting ? SITE_LAZY_LOAD_FETCH_KEY : SITE_LIST_FETCH_KEY,
        url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/groupBy/${groupBy}?_query=json`,
        body: {
          ...getDefaultParams(),
          ...restParams,
          ...(excludeDeltas ? {} : { delta: restParams.mets }),
          mets: restParams.mets === 'capacity' ? 'capacity,admissions' : restParams.mets,
          size: THEATRES_LIST_PAGE_SIZE,
          paginate: true,
        },
        method: 'POST',
        success(response) {
          const list = _isArray(response) ? response : response.values;

          if (appendToExisting) {
            this.dispatch(appendList(list));
          } else {
            this.dispatch(setList(list));
            this.dispatch(clearListFormats());

            if (!_isArray(response)) {
              this.dispatch(setNumPages(Math.ceil(response.length / THEATRES_LIST_PAGE_SIZE)));
            }
          }

          const maxPagesReached = response.length < THEATRES_LIST_PAGE_SIZE;
          this.dispatch(setMaxPagesReached(maxPagesReached));
        },
        useDefaultErrorHandler: false,
      })
    );
  },
  100
);

export function fetchSitesList(...args) {
  return (dispatch) => {
    debouncedSiteList(dispatch, ...args);
  };
}

/**
 * fetchSiteFormatData
 * @param {number} siteID
 */
export function fetchSiteFormatData(siteId, eventId, params, eventParamKey, subSectionPath = '', dataPath = '') {
  if (params.mets === 'capacity') {
    params.mets = 'capacity,admissions';
  }

  return asyncAction({
    key: `${SITE_FORMATS_FETCH_KEY}-${siteId}`,
    url: `${API_ROOT_PATH}${subSectionPath}${dataPath}/occupancy/performances?_query=json`,
    body: {
      ...getDefaultParams(),
      ...params,
      siteIds: [siteId],
      [eventParamKey]: eventId,
      sort: ['datetime,desc'],
    },
    method: 'POST',
    success(response) {
      this.dispatch(
        setListFormats({
          data: response.results,
          id: siteId,
        })
      );
    },
    useDefaultErrorHandler: false,
  });
}
