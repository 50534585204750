import { createReducer } from '@reduxjs/toolkit';
import update from 'immutability-helper';

import _isNull from 'lodash/isNull';

import { STATE_KEY } from './constants';
import {
  setUserProfile,
  resetUserProfile,
  setLoginError,
  setForgotPasswordSuccess,
  setResetPasswordSuccess,
  setUserProfileAttribute,
} from './actions';
import { getUserModel } from './middleware';
import { initPermissionsService } from './helpers';

const userModel = getUserModel();

const initialState = {
  userProfile: userModel,
  isEmailSent: false,
  error: null,
  hasResetPasswordSuccess: false,
};

if (!_isNull(userModel)) {
  initPermissionsService(userModel.policies, userModel.screenRules);
}

const authReducer = createReducer(initialState, {
  [setUserProfile.toString()]: (state, { payload }) => {
    return update(state, { userProfile: { $set: payload } });
  },
  [setUserProfileAttribute.toString()]: (state, { payload }) => {
    const { attribute, value } = payload;

    return update(state, { userProfile: { [attribute]: { $set: value } } });
  },
  [resetUserProfile.toString()]: (state) => {
    return update(state, { userProfile: { $set: null } });
  },
  [setLoginError.toString()]: (state, { payload }) => {
    return update(state, { error: { $set: null } });
  },
  [setForgotPasswordSuccess.toString()]: (state) => {
    return update(state, { isEmailSent: { $set: true } });
  },
  [setResetPasswordSuccess.toString()]: (state) => {
    return update(state, { hasResetPasswordSuccess: { $set: true } });
  },
});

export default {
  [STATE_KEY]: authReducer,
};
