import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { metricKeyPropType } from '@modules/Metrics';

import MetricOption from './MetricOption';
import { useSelectableItemAnimation } from '../hooks';

const componentName = 'list__item';
/**
 * Static list Item that is not selectable
 * Note: Passing index and setting class based on index is required only for useMetricListAnimation.
 * TODO Aim is to try and find a way to set this animation without the need for this extra class
 */
export const ListItemStatic = ({ index, isSoloList, metricKey }) => {
  return (
    <li
      className={classnames(`${componentName} ${componentName}--${metricKey}`, {
        [`${componentName}--${index}`]: index === 0,
      })}
    >
      <MetricOption inline={isSoloList} metricKey={metricKey} />
    </li>
  );
};

ListItemStatic.propTypes = {
  index: PropTypes.number.isRequired,
  isSoloList: PropTypes.bool,
  metricKey: metricKeyPropType.isRequired,
};
ListItemStatic.defaultProps = {
  isSoloList: false,
};

/**
 * Selectable metric list item
 * Note: Passing index and setting class based on index is required only for useMetricListAnimation.
 * TODO Aim is to try and find a way to set this animation without the need for this extra class
 */
export const ListItemSelectable = ({ index, isActive, metricKey, onSelectMetric }) => {
  const [metricItemRefBinder, metricAnimTimeline] = useSelectableItemAnimation();

  useEffect(() => {
    if (metricAnimTimeline.current) {
      if (isActive) {
        metricAnimTimeline.current.play();
      } else {
        metricAnimTimeline.current.reverse();
      }
    }
  }, [isActive, metricAnimTimeline]);

  return (
    <li
      className={classnames(`${componentName} ${componentName}--is-selectable ${componentName}--${metricKey}`, {
        [`${componentName}--is-selected`]: isActive,
        [`${componentName}--${index}`]: index === 0,
      })}
      onClick={onSelectMetric}
    >
      <MetricOption metricKey={metricKey} ref={metricItemRefBinder} />
    </li>
  );
};

ListItemSelectable.propTypes = {
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  metricKey: metricKeyPropType.isRequired,
  onSelectMetric: PropTypes.func.isRequired,
};
