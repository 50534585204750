import React from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';

const componentName = 'auth-content';

const propTypes = {
  children: PropTypes.node.isRequired,
};

export const Content = ({ children }) => {
  return (
    <div className={componentName}>
      {children}
    </div>
  );
}

Content.propTypes = propTypes;

export default Content;
