import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';
import _constant from 'lodash/constant';
import './_element-popover.scss';

const propTypes = {
  toggleElement: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  contentElement: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  portToElement: PropTypes.instanceOf(Element),
  contentClassName: PropTypes.string,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onOutsideClick: PropTypes.func,
  safeClose: PropTypes.bool,
  hasArrow: PropTypes.bool,
  attachment: PropTypes.oneOf(['left', 'right']),
  closeOnClickContent: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  toggleElement: null,
  contentElement: null,
  portToElement: null,
  contentClassName: '',
  isOpen: false,
  onOpen: _noop,
  onClose: _noop,
  onOutsideClick: _noop,
  safeClose: false,
  hasArrow: false,
  attachment: 'left',
  closeOnClickContent: false,
  className: '',
};

const componentClass = 'element-popover';
const contentElementClass = `${componentClass}__content`;

/**
 * ElementPopover Component.
 *
 * Available Props:
 * @param {function} toggleElement - Toggle element or function that renders one.
 *    function takes isOpen state as it's only parameter.
 * @param {function} contentElement - Content element or function that renders one.
 *    function is called only when popover is open, with close method as parameter.
 * @param {function} contentClassName -
 *    className passed to element-popover__content - the direct parent of contentElement.
 * @param {node} portToElement - If used elementPopover will use React.createPortal
 * to attach the contentElement to given element.
 * @param {boolean} isOpen - Controls whether popover is open.
 *    Changing the prop does not trigger onOpen/onClose events.
 * @param {function} onOpen - Callback function when the popover opens.
 * @param {function} onClose - Callback function when the popover closes.
 * @param {function} onOutsideClick - Callback function when clicked outside of popover elements.
 *    Takes current event as a parameter. Returning `false` will prevent component close.
 * @param {boolean} safeClose - When true outside click will only close the component,
 *    without triggering other click events.
 * @param {boolean} hasArrow - boolean controlling rendering of popover arrow.
 * @param {'left'|'right'} attachment - controls whether content will attach itself
 *  to parent's left or right boundary.
 * @param {boolean} closeOnClickContent - handy when we want to close this element upon clicking it's content.
 * @param {string} className - main class passed by for the entire component.
 */
const ElementPopover = ({
  toggleElement,
  contentElement,
  contentClassName,
  portToElement,
  isOpen,
  onOpen,
  onClose,
  onOutsideClick,
  safeClose,
  hasArrow,
  attachment,
  closeOnClickContent,
  className,
}) => {
  const popoverCtnElementRef = useRef();
  const popoverToggleRef = useRef();
  const popoverContentRef = useRef();
  const [isOpenLocal, setIsOpenLocal] = useState(isOpen);

  const [popoverCtnBottomPos, setPopoverCtnBottomePos] = useState(0);
  const [popoverCtnLeftPos, setPopoverCtnLeftPos] = useState(0);
  const [popoverCtnRightPos, setPopoverCtnRightPos] = useState(0);

  const toggle = useCallback(() => (isOpenLocal ? close() : open()), [isOpenLocal]);

  const open = () => setIsOpenLocal(true);

  const close = () => setIsOpenLocal(false);

  const onDocumentClick = (event) => {
    const noRef = { contains: _constant(false) };
    const content = popoverContentRef.current || noRef;
    const toggle = popoverToggleRef.current || noRef;

    if (!content.contains(event.target) && !toggle.contains(event.target) && onOutsideClick(event) !== false) {
      if (safeClose) {
        event.preventDefault();
        event.stopPropagation();
      }
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('click', onDocumentClick, true);
    return () => {
      document.removeEventListener('click', onDocumentClick, true);
    };
  }, []);

  useEffect(() => {
    if (isOpenLocal) onOpen();
    else onClose();
  }, [isOpenLocal]);

  useEffect(() => {
    if (portToElement && popoverCtnElementRef.current) {
      const popoverCtnElementRect = popoverCtnElementRef.current.getBoundingClientRect();

      setPopoverCtnBottomePos(popoverCtnElementRect.bottom);
      setPopoverCtnLeftPos(popoverCtnElementRect.left);
      setPopoverCtnRightPos(popoverCtnElementRect.right);
    }
  }, [portToElement, popoverCtnElementRef, popoverContentRef]);

  const popoverContentsStyles = useMemo(() => {
    if (portToElement) {
      const portToElementRect = portToElement.getBoundingClientRect();
      // getBoundingClientRect gets co-ords relative to viewport. 
      // If portToElement is a different element other than body, need to set correct positions relative to that portToElement element.
      return {
        top: popoverCtnBottomPos - portToElementRect.top,
        left: (attachment === 'left' ? popoverCtnLeftPos : popoverCtnRightPos) - portToElementRect.left,
      };
    }

    return null;
  }, [attachment, popoverCtnBottomPos, popoverCtnLeftPos, popoverCtnRightPos, portToElement]);

  const popoverContents = () => {
    return (
      <div
        style={popoverContentsStyles}
        ref={popoverContentRef}
        onClick={() => closeOnClickContent && close()}
        className={classnames(contentClassName, contentElementClass, `${contentElementClass}--attach-${attachment}`, {
          [`${contentElementClass}--has-arrow`]: hasArrow,
          [`${contentElementClass}--ported`]: portToElement,
        })}
      >
        {contentElement}
      </div>
    );
  };

  return (
    <span
      ref={popoverCtnElementRef}
      className={classnames(className, componentClass, { [`${componentClass}--open`]: isOpenLocal })}
    >
      <span ref={popoverToggleRef} role="button" className={`${componentClass}__toggle`} onClick={() => toggle()}>
        {toggleElement}
      </span>
      {isOpenLocal && <>{portToElement ? <>{createPortal(popoverContents(), portToElement)}</> : popoverContents()}</>}
    </span>
  );
};

ElementPopover.propTypes = propTypes;
ElementPopover.defaultProps = defaultProps;

export default ElementPopover;
