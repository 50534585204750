import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isFunction from 'lodash/isFunction';

import { BASE_CLASS, COLUMNS_SHAPE } from './constants';

const Body = ({ columns, data, dataUniqKey }) => {
  return (
    <tbody>
      {data.map((dataItem, index) => {
        return (
          <tr key={dataUniqKey ? dataItem[dataUniqKey] : index}>
            {columns.map((column) => {
              return (
                <td
                  key={column.key}
                  className={classnames(`${BASE_CLASS}__td`, {
                    [`td--${column.key}`]: column.key,
                    [`${column.className}`]: column.className,
                  })}
                  style={{ width: column.width }}
                >
                  {_isFunction(column.renderCell) &&
                    column.renderCell({
                      value: dataItem[column.key],
                      key: column.key,
                      rowData: dataItem,
                      columnData: column,
                    })}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

Body.propTypes = {
  columns: COLUMNS_SHAPE,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  /**
   * Data item key used to give each tr a unique key value
   */
  dataUniqKey: PropTypes.string,
};

Body.defaultProps = {
  data: null,
};

export default Body;
