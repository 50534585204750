
const AXIS_LABELS_COLOR = '#fff';
const AXIS_LABELS_SIZE = 11;
const labelsCommon = {
  fill: AXIS_LABELS_COLOR,
  fillOpacity: 0.4,
};

export const getCoreConfig = () => {
  return {
    data: [],
    xAxes: [
      {
        keepSelection: true,
        cursorTooltipEnabled: false,
        markUnitChange: false,

        renderer: {
          baseGrid: {
            disabled: true,
          },
          grid: {
            disabled: true,
          },
          fontSize: AXIS_LABELS_SIZE,
          labels: {
            ...labelsCommon,
          },
        },
      },
    ],
    yAxes: [
      {
        type: 'ValueAxis',
        rangeChangeDuration: 0,
        cursorTooltipEnabled: false,
        extraMax: 0.1,
        strictMinMax: true,
        min: 0,
        renderer: {
          baseGrid: {
            disabled: true,
          },
          grid: {
            disabled: true,
          },
          fontSize: AXIS_LABELS_SIZE,
          labels: {
            ...labelsCommon,
          },
        },
      },
    ],

    maskBullets: false,
  };
};
