import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Title } from '@components';

const componentName = 'page-back-nav-header';

const BackNavHeader = ({ title, subtext, backArrow, className }) => {
  return (
    <header className={classnames(`${componentName} ${className}`)}>
      {backArrow && <div className={classnames(`${componentName}__back`)}>{backArrow}</div>}
      {title && <Title className={classnames(`${componentName}__title`)} title={title} subtext={subtext} />}
    </header>
  );
};

BackNavHeader.propTypes = {
  title: PropTypes.node || PropTypes.string,
  subtext: PropTypes.node || PropTypes.string,
  backArrow: PropTypes.node,
  className: PropTypes.string,
};

BackNavHeader.defaultProps = {
  title: null,
  subtext: null,
  backArrow: null,
  className: '',
};

export default BackNavHeader;
