import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Marker from './Marker';
import { useDrawerContext } from '../hooks';

import { COMPONENT_BASE_CLASS } from '../constants';

const HEADER_BASE_CLASS = `${COMPONENT_BASE_CLASS}__header`;

function Header({ children, isVisibleOnScreen }) {
  const { toggleDrawer, isHeaderVisible, setIsHeaderVisible } = useDrawerContext();

  useEffect(() => {
    if (isHeaderVisible !== isVisibleOnScreen) {
      setIsHeaderVisible(isVisibleOnScreen);
    }
  }, [isVisibleOnScreen, setIsHeaderVisible, isHeaderVisible]);

  return (
    <div
      role="button"
      onClick={toggleDrawer}
      className={classnames(HEADER_BASE_CLASS, {
        [`${HEADER_BASE_CLASS}--visible-on-screen`]: isHeaderVisible,
      })}
    >
      <Marker />
      <div className={`${COMPONENT_BASE_CLASS}__header__children`}>{children}</div>
    </div>
  );
}

Header.defaultProps = {
  isVisibleOnScreen: true,
};

Header.propTypes = {
  children: PropTypes.node,
  isVisibleOnScreen: PropTypes.bool,
};

export default Header;
