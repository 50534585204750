import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoList, getPreviousUrl } from './selectors';
import { setInfoList, setPrevUrl } from './actions';

import { getTimeParams, useAction, useAPIPath, useIsToday, useIsAdvancedSales } from '@modules/Core';
import { useParametersListener } from '@modules/FilterTypes';

import { getParametersForTheatresMetadataInfo, getTheatreMetadataName, isLoadingInfo } from './selectors';
import { fetchMetadataInfo } from './actions';

export function useTheatreMetadata() {
  const dispatch = useDispatch();

  const setInfo = useCallback(
    (values) => {
      dispatch(setInfoList(values));
    },
    [dispatch]
  );

  const setPreviousUrl = useCallback(
    (url) => {
      dispatch(setPrevUrl(url));
    },
    [dispatch]
  );

  return {
    info: useSelector(getInfoList),
    setInfo,
    previousUrl: useSelector(getPreviousUrl),
    setPreviousUrl,
    theatreName: useSelector(getTheatreMetadataName),
    isLoadingInfo: useSelector(isLoadingInfo),
  };
}

/**
 * useTheatreMetadataDataFetch
 */
export const useTheatreMetadataDataFetch = (siteId) => {
  const fetchInfo = useAction(fetchMetadataInfo);
  const [subSectionPath, dataPath] = useAPIPath();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  useParametersListener(
    getParametersForTheatresMetadataInfo,
    (params) => {
      if (params.territoryId) {
        const timeParams = isAdvancedSales && isToday ? getTimeParams() : {};
        fetchInfo(siteId, { ...params, ...timeParams }, subSectionPath, dataPath);
      }
    },
    [subSectionPath, dataPath, isAdvancedSales, isToday]
  );
};
