import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIsAdvancedSales, useChart, useIsToday } from '@modules/Core';
import { getBaseEventId, useSelectedMetric, useCompColorPairs, BASE_COLOR } from '@modules/Comps';
import { useOverlayChartData, useChartValueAxisFormatter, useChartCategoryAxisFormatter } from '../hooks';
import { getFormattedField, getAdvancedSalesOverlayConfig, getOverlaySeriesConfig, getDataField } from '../helpers';

function CompsChartAdvancedSalesFullScreen({ className }) {
  const [data] = useOverlayChartData();
  const baseId = useSelector(getBaseEventId);
  const [selectedMetric] = useSelectedMetric();
  const compColorPairs = useCompColorPairs();
  const isToday = useIsToday();
  const isAdvancedSales = useIsAdvancedSales();

  const seriesConfig = useMemo(() => {
    const baseConfig = {
      dataField: getDataField(baseId, selectedMetric),
      color: BASE_COLOR,
    };
    const compsDataFields = compColorPairs.map(({ id, color }) => ({
      dataField: getDataField(id, selectedMetric),
      color: color,
    }));

    return getOverlaySeriesConfig(baseConfig, compsDataFields, isToday, isAdvancedSales);
  }, [baseId, compColorPairs, selectedMetric, isToday]);

  const chartConfig = useMemo(() => {
    const conf = getAdvancedSalesOverlayConfig(isToday);
    conf.xAxes[0].dataFields.category = 'id';
    conf.xAxes[0].dataFields.value = getFormattedField(`${baseId}_${selectedMetric}`);
    return { ...conf };
  }, [isToday, baseId]);

  const { chartRef, setChartData, updateChartSeriesConfig, chart } = useChart(chartConfig);

  useEffect(() => {
    seriesConfig[0].dataFields.categoryX = 'id';
    seriesConfig[0].dataFields.valueY = getFormattedField(`${baseId}_${selectedMetric}`);
    updateChartSeriesConfig(seriesConfig);
  }, [seriesConfig, updateChartSeriesConfig, baseId]);

  useEffect(() => {
    setChartData(data);
  }, [data, setChartData]);

  useChartValueAxisFormatter(chart, selectedMetric);

  return <div ref={chartRef} className={className}></div>;
}

export default CompsChartAdvancedSalesFullScreen;
