import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import { useIntl } from 'react-intl';

import ListItem from './ListItem';

import { isStudio } from '@modules/Core';

import { useIsElementInView } from '@modules/Core';

const propTypes = {
  className: PropTypes.string,
  componentName: PropTypes.string,
  hasAside: PropTypes.bool,
  onSelectItem: PropTypes.func,
  params: PropTypes.shape({}),
  listData: PropTypes.arrayOf(PropTypes.shape({})),
  onScrollBottom: PropTypes.func,
  emptyStateRenderer: PropTypes.func,
};
const defaultProps = {
  hasAside: false,
  className: '',
  onSelectItem: () => {},
  listData: null,
  onScrollBottom: () => {},
};

/**
 * TheatreList
 */
const List = ({ className, hasAside, onSelectItem, componentName, listData, onScrollBottom, emptyStateRenderer }) => {
  const { formatMessage } = useIntl();
  const [isElementVisible, setElement] = useIsElementInView();

  useEffect(() => {
    if (isElementVisible) {
      onScrollBottom();
    }
  }, [isElementVisible, onScrollBottom]);
  const renderEmptyState = useCallback(() => {
    if (_isFunction(emptyStateRenderer)) {
      return emptyStateRenderer();
    }

    return <div className={`${componentName}__empty-ctn`}>{formatMessage({ id: 'no_results_found' })}</div>;
  }, [emptyStateRenderer, formatMessage, componentName]);

  return _isEmpty(listData) ? (
    renderEmptyState()
  ) : (
    <>
      <div className={classnames(componentName, className)}>
        {listData.map((item, index) => {
          return (
            <ListItem
              ref={listData.length === index + 1 ? setElement : null}
              className={`${componentName}__item`}
              key={item.id}
              id={item.id}
              rank={item.rank}
              logo={item.logo}
              label={item.name}
              circuitName={isStudio() ? item.circuitName : null}
              hasAside={hasAside}
              onClick={() => {
                onSelectItem(item.id);
              }}
            />
          );
        })}
      </div>
    </>
  );
};

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
