import React from 'react';
import { Overlay, Button } from '@components';
import { useIsAdvancedSales } from '@modules/Core';
import { CompsMetricToggle } from '@modules/Comps';
import FullScreenChartLegend from './FullScreenChartLegend';
import CompsChartFullScreen from './CompsChartFullScreen';
import CompsAdvancedSalesChartFullScreen from './CompsAdvancedSalesChartFullScreen';
import { useOverlayChartFetcher } from '../hooks';
import { useSelector } from 'react-redux';
import { isLoadingFullscreenData } from '../selectors';
import { Spinner, Icon } from '@components';

const baseClass = 'comps-chart';

function FullscreenOverlay({ overlayContext, metrics }) {
  const isAdvancedSales = useIsAdvancedSales();
  const isLoading = useSelector(isLoadingFullscreenData);
  useOverlayChartFetcher(metrics);

  return (
    <Overlay.Content className={`${baseClass}__overlay`}>
      <Button onClick={overlayContext.close} className={`${baseClass}__overlay__button-close`}>
        <Icon icon="exit" />
      </Button>

      <CompsMetricToggle metrics={metrics} />

      <FullScreenChartLegend />

      {isLoading ? (
        <Spinner className={`${baseClass}__overlay__loading`} />
      ) : isAdvancedSales ? (
        <CompsAdvancedSalesChartFullScreen className={`${baseClass}__overlay-chart`} />
      ) : (
        <CompsChartFullScreen className={`${baseClass}__overlay-chart`} />
      )}
    </Overlay.Content>
  );
}

export default FullscreenOverlay;
