import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUnavailableMetrics, getUnavailableMarketMetrics } from '@modules/TerritorySelect';

import { getMarketAnalysisMetrics } from './helpers';

export const useMarketPerspectiveMetrics = () => {
  const unavailableMetrics = useSelector(getUnavailableMetrics);
  const unavailableMarketMetrics = useSelector(getUnavailableMarketMetrics);

  return useMemo(() => {
    return getMarketAnalysisMetrics().filter((metric) => !unavailableMetrics.concat(unavailableMarketMetrics).includes(metric));
  }, [unavailableMetrics]);
};
