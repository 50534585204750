import { createReducer, combineReducers } from '@reduxjs/toolkit';

import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';

import { STATE_KEY, COMPS_COLORS } from './constants';
import {
  setBaseEventId,
  setBaseEventMetadata,
  setCompsEventsMetadata,
  assignColors,
  removeCompsItem,
  cleanUpComps,
} from './actions';

const compShape = {
  id: null,
  name: '',
  imageUrl: null,
  admissionsOpeningDay: null,
  admissionsOpeningWknd: null,
  admissionsTotalRun: null,
  eventRevenueOpeningDay: null,
  eventRevenueOpeningWknd: null,
  eventRevenueTotalRun: null,
  day: null,
  circuitAdmissionsTotalRun: null,
  circuitEventRevenueTotalRun: null,
};

const returnPayload = (_, { payload }) => payload;

const initialBaseEventId = null;
const baseEventIdReducer = createReducer(initialBaseEventId, {
  [setBaseEventId.toString()]: returnPayload,
  [cleanUpComps.toString()]: () => initialBaseEventId,
});

const initialBaseEventMetadata = { ...compShape };
const baseEventMetadataReducer = createReducer(initialBaseEventMetadata, {
  [setBaseEventMetadata.toString()]: returnPayload,
  [cleanUpComps.toString()]: () => initialBaseEventMetadata,
});

const initialCompsTitles = [];
const compsTitlesReducer = createReducer(initialCompsTitles, {
  [setCompsEventsMetadata.toString()]: returnPayload,
  [removeCompsItem.toString()]: (state, { payload }) => {
    const indexToRemove = _findIndex(state, { id: payload });

    if (indexToRemove > -1) {
      state.splice(indexToRemove, 1);
    }

    return state;
  },
  [cleanUpComps.toString()]: () => initialCompsTitles,
});

const initialColorMap = [];
const colorReducer = createReducer(initialColorMap, {
  [assignColors.toString()]: (state, { payload }) => {
    // Removing existing colors
    state = _filter(state, ({ id }) => {
      return payload.includes(id);
    });

    //Getting available colors
    let availableColors = _filter([...COMPS_COLORS], (color) => {
      return state.length ? _findIndex(state, { color }) : true;
    }).reverse();

    payload.forEach((compId) => {
      const hasColor = _findIndex(state, { id: compId }) > -1;

      if (!hasColor) {
        state.push({
          id: compId,
          color: availableColors.pop(),
        });
      }
    });

    return state;
  },
  [cleanUpComps.toString()]: () => initialColorMap,
});

export default {
  [STATE_KEY]: combineReducers({
    baseEventId: baseEventIdReducer,
    baseEventMetadata: baseEventMetadataReducer,
    compsTitles: compsTitlesReducer,
    colorMap: colorReducer,
  }),
};
