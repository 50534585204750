import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Heading } from '@components';
import { CopyButton, ShareDrawerToggle } from './components';
import { componentName } from './constants';
import './_share-drawer.scss';

const ShareDrawer = ({ isOpen, onClose }) => {
  const { formatMessage } = useIntl();

  return (
    <Drawer defaultTheme isOpen={isOpen} hasClosed={onClose}>
      {isOpen && (
        <>
          <Drawer.Header isVisibleOnScreen={false}>
            <Heading size="m" className={`${componentName}__heading`}>
              {formatMessage({ id: 'shareDrawer_heading' })}
            </Heading>
          </Drawer.Header>
          <Drawer.Content>
            <div className={`${componentName}__content`}>
              <CopyButton closeDrawer={onClose} />
              {/* <Button icon="download" onClick={onDownLoad} className={classnames(`${componentName}__btn`)}>
                  <Text tag="span" size="m">
                    {formatMessage({ id: 'download' })}
                  </Text>
                </Button> */}
            </div>
          </Drawer.Content>
        </>
      )}
    </Drawer>
  );
};

ShareDrawer.Toggle = ShareDrawerToggle;

export default ShareDrawer;
