import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay } from '@components';
import { usePerformancesFetcher } from './hooks';
import { metricKeyPropType } from '@modules/Metrics';
import { usePerformance } from '@modules/FilterTypes/performance';
import { EmbeddedPlannerView, FullScreenPlannerView } from './components';
import PlannerViewContext from './Context';

import './_plannerView.scss';

const propTypes = {
  baseSiteId: PropTypes.number.isRequired,
  metrics: PropTypes.arrayOf(metricKeyPropType).isRequired,
};

const PlannerView = ({ baseSiteId, metrics }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [, setSelected] = usePerformance();

  useEffect(() => {
    // Refreshing performances selection
    return () => setSelected([]);
  }, [setSelected]);

  return (
    <PlannerViewContext.Provider value={{ metrics, baseSiteId }}>
      <EmbeddedPlannerView onExpand={() => setIsFullScreen(true)} />
      <Overlay.Provider type={'full'} showAppHeader isOpen={isFullScreen}>
        <FullScreenPlannerView onClose={() => setIsFullScreen(false)} />
      </Overlay.Provider>
    </PlannerViewContext.Provider>
  );
};

PlannerView.propTypes = propTypes;

export default PlannerView;
