import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Text } from '../Typography';
import Arrow from '../Arrow';

const THEMES = ['light', 'dark'];
const BUTTON_TYPES = ['submit', 'reset', 'button'];

const propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(THEMES),
  type: PropTypes.oneOf(BUTTON_TYPES),
  children: PropTypes.node,
  onClick: PropTypes.func,
};
const defaultProps = {
  className: '',
  type: 'button',
  theme: 'light',
  children: null,
  onClick: () => {},
};

const componentName = 'selector-button';

const SelectorButton = ({ className, type, children, onClick, theme }) => {
  return (
    <button
      data-testid="SelectorButton"
      onClick={onClick}
      className={classnames(`${componentName} ${className} bold`, {
        [`${componentName}--${theme}`]: theme,
      })}
      type={type}
    >
      <Text tag="span" size="l">
        {children}
      </Text>
      <Arrow className={classnames(`${componentName}__arrow`)} />
    </button>
  );
};

SelectorButton.propTypes = propTypes;
SelectorButton.defaultProps = defaultProps;

export default SelectorButton;
