import { createAction } from '@reduxjs/toolkit';
import { asyncAction } from '@showtime/cea-dep-utility';
import { API_ROOT_PATH, getDefaultParams } from '@modules/Core';

import { STATE_KEY, SUGGESTED_FETCH_KEY, SEARCH_FETCH_KEY } from './constants';

const createActionType = (action) => `${STATE_KEY}/${action}`;

const SET_SUGGESTED_TITLES = 'SET_SUGGESTED_TITLES';
export const setSuggestedTitles = createAction(createActionType(SET_SUGGESTED_TITLES));
export const fetchSuggestedTitles = (params, eventId, subSectionPath = '') => {
  return asyncAction({
    key: SUGGESTED_FETCH_KEY,
    url: `${API_ROOT_PATH}${subSectionPath}/filters/events/comps/${eventId}`,
    method: 'POST',
    body: {
      ...params,
      ...getDefaultParams(),
    },
    success(response) {
      this.dispatch(setSuggestedTitles(response));
    },
  });
};

const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const setSearchResults = createAction(createActionType(SET_SEARCH_RESULTS));
const SET_NO_RESULTS = 'SET_NO_RESULTS';
export const setNoResults = createAction(createActionType(SET_NO_RESULTS));

const CLEAN_NO_RESULTS = 'CLEAN_NO_RESULTS';
export const cleanNoResults = createAction(createActionType(CLEAN_NO_RESULTS));

export const searchForTitles = (searchText, params = {}, subSectionPath = '') => {
  return (dispatch) => {
    dispatch(cleanNoResults());
    dispatch(
      asyncAction({
        key: SEARCH_FETCH_KEY,
        url: `${API_ROOT_PATH}${subSectionPath}/filters/events?_query=json`,
        method: 'POST',
        body: {
          page: 1,
          size: 20,
          search: searchText,
          ...params,
        },
        success(response) {
          if (!response.length) {
            this.dispatch(setNoResults());
          } else {
            this.dispatch(setSearchResults(response));
          }
        },
      })
    );
  };
};

const SELECT_COMP_TITLE = 'SELECT_COMP_TITLE';
const DESELECT_COMP_TITLE = 'DESELECT_COMP_TITLE';
const CLEAR_COMPS_SELECTIONS = 'CLEAR_COMPS_SELECTIONS';
const SET_SELECTED_COMPS = 'SET_SELECTED_COMPS';
export const selectCompTitle = createAction(createActionType(SELECT_COMP_TITLE));
export const deselectCompTitle = createAction(createActionType(DESELECT_COMP_TITLE));
export const clearCompsSelections = createAction(createActionType(CLEAR_COMPS_SELECTIONS));
export const setSelectedComps = createAction(createActionType(SET_SELECTED_COMPS));
