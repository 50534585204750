import { useSelector } from 'react-redux';

import { getMetricValueMovementFactory } from '../selectors';

const useMetricValueMovement = (key) => {
  const { valueMovement, valueChange } = useSelector(getMetricValueMovementFactory(key));
  return [valueMovement, valueChange];
};

export default useMetricValueMovement;
