import { useEffect } from 'react';
import { useAppContextState } from '@modules';
import { lockBodyScroll, unlockBodyScroll } from '../helpers';

export default function (isLocked) {
  const { isOverlayOpen, isDrawerOpen } = useAppContextState();
  useEffect(() => {
    if (isLocked) {
      lockBodyScroll();
    } else {
      // Only unlockBodyScroll if no other overlays or drawers are open.
      if (!isOverlayOpen && !isDrawerOpen) {
        unlockBodyScroll();
      }
    }

    return () => {
      if (!isOverlayOpen && !isDrawerOpen) {
        unlockBodyScroll();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked]);
}
