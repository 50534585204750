import React, { Fragment, useState, useMemo, useCallback, useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _noop from 'lodash/noop';

import { useLockBodyScroll } from '@modules/Core';
import { useSetOverlayOpen } from '@modules/AppLayout';

import './overlay.scss';
import { OverlayProvider } from './Context';
import OverlayTransition from './OverlayTransition';

/**
 * Overlay component/provider
 * Supports body overlays for components such as Modals
 * Handles only the overlay block (e.g. outside clicks & backdrops)
 * Content is provided with context when overlay state needs to be changed from within (like cancel buttons).
 * The idea here is that one could build common modals on top of this as well as compose very custom ones.
 */

const propTypes = {
  /**
   * type of the overlay.
   * 'full' - content entirely overlays the body. Default.
   * 'modal' - content partially overlays the body.
   */
  type: PropTypes.oneOf(['full', 'modal']),
  className: PropTypes.string,
  /**
   * Component that renders overlay's content.
   * Always mounted. Context provides means of opening, closing, and rendering.
   */
  component: PropTypes.elementType,
  /**
   * Content of the overlay. Ignored if `component` is defined.
   * Rendered only when open. Context provides means of closing the overlay.
   */
  children: PropTypes.node,
  /**
   * Prop for outside overlay control.
   */
  isOpen: PropTypes.bool,
  /**
   * Callback fired when overlay opens via context call.
   */
  onOpen: PropTypes.func,
  /**
   * Callback fired when overlay closes via context call.
   */
  onClose: PropTypes.func,
  /**
   * By default clicks on backdrop closes the overlay. Takes effect only when type !== 'full'
   */
  disableBackdropClose: PropTypes.bool,
  /**
   * By default app header is hidden
   */
  showAppHeader: PropTypes.bool,
};

const defaultProps = {
  type: 'full',
  isOpen: false,
  onOpen: _noop,
  onClose: _noop,
  disableBackdropClose: false,
  showAppHeader: false,
};

const renderNothing = () => null;

const Overlay = (props) => {
  const {
    type,
    className,
    component: Component,
    children,
    onOpen,
    onClose,
    disableBackdropClose,
    showAppHeader,
  } = props;

  // Body overlays only - there don't seem to be a need for block overlays.
  const overlayParent = document.body;
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const backdropElement = useRef();
  const contentElement = useRef();

  useLayoutEffect(() => {
    // When controlled by parent override local state.
    setIsOpen(props.isOpen);
  }, [props.isOpen, setIsOpen]);

  useSetOverlayOpen(isOpen);
  useLockBodyScroll(isOpen);

  const open = useCallback(() => {
    setIsOpen(true);
    onOpen();
  }, [setIsOpen, onOpen]);

  const close = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen, onClose]);

  const backdropClick = useCallback(
    (event) => {
      const backdropCloseEnabled = type !== 'full' && !disableBackdropClose;
      if (backdropCloseEnabled && !contentElement.current.contains(event.target)) {
        close();
      }
    },
    [type, disableBackdropClose, close]
  );

  const renderOverlay = useCallback(
    (node) => (
      <OverlayTransition isOpen={isOpen} backdrop={backdropElement} content={contentElement}>
        <Fragment>
          {createPortal(
            <div
              ref={backdropElement}
              onClick={backdropClick}
              className={classnames(className, 'overlay', `overlay--${type}`, {
                ['overlay--show-app-header']: showAppHeader,
              })}
            >
              <div ref={contentElement} className="overlay__content">
                {node}
              </div>
            </div>,
            overlayParent
          )}
        </Fragment>
      </OverlayTransition>
    ),
    [isOpen, backdropClick, className, type, overlayParent]
  );

  const ctx = useMemo(
    () => ({
      close,
      open: Component ? open : _noop,
      render: Component ? renderOverlay : renderNothing,
    }),
    [Component, close, open, renderOverlay]
  );

  return (
    <OverlayProvider value={ctx}>{Component ? <Component {...props} /> : renderOverlay(children)}</OverlayProvider>
  );
};

Overlay.propTypes = propTypes;
Overlay.defaultProps = defaultProps;

export default Overlay;
