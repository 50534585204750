import Table from './Table';
import Body from './Body';
import Head from './Head';

import './_table.scss';

/**
 * Table Component
 * 
 * Note: column.keys should map to the matching tableData key
 * 
 * Example Usuage:
 * 
  const tableData = [
    {
      circuit: 'cinemex',
      sitesUpdated: 352,
      feed: 'comScore',
    },
    {
      circuit: 'cinemex 1234',
      sitesUpdated: 352,
      feed: 'comScore',
    },
  ];

  const renderCell = ({ value }) => <Text size="s">{value}</Text>;
  const renderHeaderCell = (label) => <Text size="s" bold>{label}</Text>;
  const initialSort = { id: 'circuit', dir: 'desc' };
  const [sortBy, setSortBy] = useState(initialSort);

  const tableColumns = useMemo(
    () => [
      {
        key: 'feed',
        label: formatMessage({ id: 'report_indicator_th_data_feed' }),
        renderCell,
        renderHeaderCell,
        width: '14%',
      },
      {
        key: 'circuit',
        label: formatMessage({ id: 'report_indicator_th_circuit' }),
        renderCell,
        renderHeaderCell,
        width: '43%',
        sort: true,
      },
      {
        key: 'sitesUpdated',
        label: formatMessage({ id: 'report_indicator_th_sites_updated' }),
        renderCell,
        renderHeaderCell,
        width: '43%',
        sort: true,
      },
    ],
    [formatMessage]
  );

  <Table>
      <Table.Head columns={tableColumns} onSortChange={setSortBy} sortBy={sortBy} initialSort={initialSort} />
      <Table.Body columns={tableColumns} data={itemData} dataUniqKey={'circuit'} />
  </Table>
 */

Table.Head = Head;
Table.Body = Body;

export default Table;
