import React from 'react';
import { Overlay } from '@components';
import { COMPONENT_BASE_CLASS } from '../constants';
import PlannerViewMain from './PlannerViewMain';
import PlannerViewActions from './PlannerViewActions';

function FullScreenPlannerView({ onClose }) {
  return (
    <Overlay.Content className={`${COMPONENT_BASE_CLASS}__overlay`}>
      <PlannerViewActions isFullscreen onClose={onClose} />
      <PlannerViewMain />
    </Overlay.Content>
  );
}

export default FullScreenPlannerView;
